import * as React from "react";
import { Field } from "saltui";
import { ITagInfo } from "../../app/WebAPIClients";
import ESTag from "../ESTag";
import Util from "../Util";

declare interface IESTagListProps {
    tagInfos: ITagInfo[];
}

class ESTagList extends React.Component<IESTagListProps, {}> {
    public static defaultProps: IESTagListProps;

    constructor(props: IESTagListProps) {
        super(props);
    }

    public render(): JSX.Element {
        const t = this;

        const { tagInfos } = t.props;
        if (!Util.isNotNullAndNotEmptyArray(tagInfos)) {
            return null;
        }

        return (
            <div>
                <Field label={"标签"}>
                    {tagInfos.map((tg, index) => {
                        return <ESTag key={index} tag={tg} />;
                    })}
                </Field>
            </div>
        );
    }
}

ESTagList.defaultProps = {
    tagInfos: null
};

export default ESTagList;
