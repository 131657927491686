import {
  IAppliedProcessDTO,
  IBlockAppliedProcessDTO,
  ProcessDisplayType,
  WorkSegments,
} from "../app/WebAPIClients";
import Consts from "./Consts";
import DateUtil from "./DateUtil";
import { IAppliedProcessGroupByItem } from "./type";
import Util from "./Util";

const { ProcessConst } = Consts;
class AppliedProcessUtil {
  public GroupByAppliedProcess(
    appliedProcesses: IAppliedProcessDTO[],
  ): IAppliedProcessGroupByItem[] {
    if (!Util.isNotNullAndNotEmptyArray(appliedProcesses)) {
      return null;
    }

    const apGroupByList: IAppliedProcessGroupByItem[] = [];
    appliedProcesses.forEach((process) => {
      const ap = apGroupByList.find(
        (a) =>
          DateUtil.formatDateTime(a.startTime) ===
            DateUtil.formatDateTime(process.startTime) &&
          DateUtil.formatDateTime(a.endTime) ===
            DateUtil.formatDateTime(process.endTime) &&
          a.machineryId === process.machineryId &&
          a.operatorName === process.operatorName,
      );
      if (Util.isDefinedAndNotNull(ap)) {
        ap.appliedProcesses.push(process);
      } else {
        const apgb: IAppliedProcessGroupByItem = {
          startTime: process.startTime,
          endTime: process.endTime,
          machineryId: process.machineryId,
          operatorName: process.operatorName,
          appliedProcesses: [process],
        };
        apGroupByList.push(apgb);
      }
    });

    return apGroupByList;
  }

  public getTotalCuts = (appliedProcesses: IAppliedProcessDTO[]): number => {
    let totalCuts: number = 0;
    if (!appliedProcesses) {
      return totalCuts;
    }
    appliedProcesses.forEach((process) => {
      if (
        process &&
        process.unit === ProcessConst.trimmingProcessUnitName &&
        (process.displayType === ProcessDisplayType.IntegerNumber ||
          process.displayType === ProcessDisplayType.RealNumber)
      ) {
        totalCuts += process.quantity;
      }
    });
    return totalCuts;
  };

  public getAppliedProcessesBySegments(
    blockAppliedProcesses: IBlockAppliedProcessDTO[],
    segments: WorkSegments,
  ): IBlockAppliedProcessDTO[] {
    let appliedProcesses: IBlockAppliedProcessDTO[] = [];
    if (!Util.isNotNullAndNotEmptyArray(blockAppliedProcesses)) {
      return appliedProcesses;
    }

    appliedProcesses = blockAppliedProcesses.filter(
      (ap) => ap.segments === segments,
    );
    return appliedProcesses;
  }
}

export default new AppliedProcessUtil();
