import React from "react";
import { Popup } from "saltui";

declare interface IPickerProps extends React.Props<Picker> {
    visible: boolean;
    onVisibleChange: (visible: boolean) => void;
    className?: string;
}

class Picker extends React.Component<IPickerProps> {
    public static defaultProps: IPickerProps;
    public static displayName: string;
    private listener;
    private historyStamp: string;
    constructor(props) {
        super(props);
        this.listener = this.handleHidePopup.bind(this);
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.visible === false && this.props.visible === true) {
            this.historyStamp = `Picker.index_${Date.now()}`;
            window.history.pushState(
                {
                    PickerField: this.historyStamp
                },
                "",
                this.addUrlParam("PICKER", Date.now())
            );

            window.addEventListener("popstate", this.listener, false);
        } else if (prevProps.visible === true && this.props.visible === false) {
            this.hide();
        }
    }

    public componentWillUnmount() {
        window.removeEventListener("popstate", this.listener, false);
    }

    public render() {
        const { visible, children, className } = this.props;

        return (
            <Popup
                className={className}
                animationType="slide-left"
                stopBodyScrolling={false}
                visible={visible}
                content={children}
            />
        );
    }

    public handleHidePopup(e) {
        const { state } = e;
        if (
            !state ||
            !state.PickerField ||
            state.PickerField !== this.historyStamp
        ) {
            this.hide(true);
        }
    }

    public hide(fromEvent: boolean = true) {
        if (this.historyStamp) {
            const t = this;
            window.removeEventListener("popstate", t.listener, false);
            if (!fromEvent) {
                window.history.go(-1);
            }
            this.historyStamp = "";
            t.props.onVisibleChange(false);
        }
    }

    private addUrlParam = (name, value) => {
        let currentUrl = window.location.href;
        let reg;
        if (/\?/g.test(currentUrl)) {
            reg = new RegExp(`${name}=[-\\w]{4,25}`, "g");
            if (reg.test(currentUrl)) {
                currentUrl = currentUrl.replace(reg, `${name}=${value}`);
            } else {
                currentUrl += `&${name}=${value}`;
            }
        } else {
            currentUrl += `?${name}=${value}`;
        }
        return currentUrl;
    };
}

Picker.defaultProps = {
    visible: false,
    onVisibleChange: () => {},
    className: ""
};

Picker.displayName = "Picker";

export default Picker;
