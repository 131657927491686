import React from "react";
import Loadable from "react-loadable";
import Loading from "./Loading";

declare interface ILoader {
    loader: () => Promise<any>;
}

export default function ESLoadable(opts: ILoader) {
    return Loadable<any, any>({
        loading: Loading,
        delay: 200,
        timeout: 10,
        render(loaded, props) {
            const LoadedComponent = loaded.default;
            return <LoadedComponent {...props} />;
        },
        ...opts
    });
}
