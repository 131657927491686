import * as React from "react";
import { Boxs } from "saltui";
import { ITagInfo } from "../../app/WebAPIClients";
import Util from "../Util";
const { HBox, Box } = Boxs;

declare interface IESTagProps {
    tag: ITagInfo;
}

class ESTag extends React.Component<IESTagProps, {}> {
    public static defaultProps: IESTagProps;

    constructor(props: IESTagProps) {
        super(props);
    }

    public render(): JSX.Element {
        const t = this;

        const { tag } = t.props;
        if (!tag) {
            return null;
        }

        const colorName = Util.isNotNullAndNotEmpty(tag.colorName)
            ? tag.colorName
            : "orangeTag";

        return (
            <HBox>
                <Box flex={1} className="tagArea">
                    <span className={colorName + " tagStyl"}>{tag.name}</span>
                </Box>
            </HBox>
        );
    }
}

ESTag.defaultProps = {
    tag: null
};

export default ESTag;
