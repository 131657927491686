import React from "react";
import { PhotoField } from "saltui";
import { ICloudStorageInfoDTO } from "../../app/WebAPIClients";
import cloudStorage from "../CloudStorage";
import ImageViewer from "../ImageViewer";
import userInfo from "../UserInfo";

declare interface IPhotoInfo {
    name: string;
    url: string;
}

declare interface IESPhotoFieldState {
    imageViewerVisible: boolean;
    imageFileName: string;
    imageNames: string;
    jwtToken: string;
    cloudStorageInfo: ICloudStorageInfoDTO;
}

declare interface IESPhotoFieldProps extends React.Props<ESPhotoField> {
    required?: boolean;
    imageNames?: string;
    label?: string;
    placeholder?: string;
    readOnly?: boolean;
    maxUpload?: number;
    onDelete?: (imageNames: string) => void;
    onImageUpload?: (imageNames: string) => void;
}

class ESPhotoField extends React.Component<
    IESPhotoFieldProps,
    IESPhotoFieldState
> {
    public static defaultProps: IESPhotoFieldProps;

    constructor(props: IESPhotoFieldProps) {
        super(props);
        this.state = {
            imageViewerVisible: false,
            imageFileName: null,
            imageNames: props.imageNames,
            jwtToken: userInfo.getAccessToken(),
            cloudStorageInfo: null
        };
    }

    public componentDidUpdate(prevProps) {
        if (
            this.props.imageNames !== prevProps.imageNames &&
            this.props.imageNames !== this.state.imageNames
        ) {
            this.setState({
                imageNames: this.props.imageNames
            });
        }
    }

    public async componentDidMount() {
        const cloudStorageInfo = await cloudStorage.getCloudStorageInfo();
        this.setState({ cloudStorageInfo });
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const { label, placeholder, maxUpload, readOnly, required } = t.props;
        const imageViewerVisible = s.imageViewerVisible;
        const imageFileName = s.imageFileName;
        const headers = s.jwtToken
            ? [{ name: "Authorization", value: `Bearer ${s.jwtToken}` }]
            : [];
        const uploadUrl = s.cloudStorageInfo
            ? s.cloudStorageInfo.cloudStorageEndPoint
            : "";

        const photoList = t.loadPhotosByImageNames(s.imageNames);

        return (
            <div>
                <PhotoField.H5
                    label={label}
                    placeholder={placeholder}
                    required={required}
                    maxUpload={maxUpload}
                    name="file"
                    url={uploadUrl}
                    readOnly={readOnly}
                    photoList={photoList}
                    onChange={this.onImageUpload}
                    onDelete={this.onDelete}
                    onImagePreview={this.onImagePreview}
                    withCredentials={true}
                    onfileuploadpreparing={this.onImageUploading}
                    onfileuploadcompleting={this.onImageUploaded}
                />
                {!s.imageViewerVisible ? null : (
                    <ImageViewer
                        visible={imageViewerVisible}
                        imageFileName={imageFileName}
                        onHide={this.onHideImage}
                    />
                )}
            </div>
        );
    }

    private loadPhotosByImageNames(imageNames: string): IPhotoInfo[] {
        let imageNameArray = [];
        if (imageNames) {
            imageNameArray = imageNames.includes(",")
                ? imageNames.split(",")
                : (imageNameArray = [imageNames]);
        }

        return this.getImagePhotoInfoList(imageNameArray);
    }

    private getImagePhotoInfoList(imageNameArray: string[]): IPhotoInfo[] {
        if (
            !imageNameArray ||
            !imageNameArray.length ||
            imageNameArray.length === 0
        ) {
            return [];
        }

        const si = this.state.cloudStorageInfo;
        const urls = cloudStorage.getCloudStorageAccessUrlsWithStorageInfo(
            imageNameArray,
            si
        );
        if (!(urls && urls.length && urls.length > 0)) {
            return [];
        }

        return imageNameArray.map((imageName, index) => {
            return { name: imageName, url: urls[index] };
        });
    }

    private onDelete = (index: number): void => {
        const t = this;
        const s = t.state;
        let imageNameList = t.getImageNameList();
        imageNameList = imageNameList.filter((_, i) => index !== i);

        const imageNames = this.getImageNames(imageNameList);
        this.setState({
            imageNames
        });

        this.props.onDelete(imageNames);
    };

    private onImageUploading = async (request): Promise<void> => {
        return cloudStorage.setFileRequestParams(request);
    };

    private onImageUploaded = (response): void => {
        // todo: check the status code of the response
        // 200/204: Upload succeeded
        // 203: upload succeeded but callback failed
        // others: Upload failed
        // set the response content only for 200/204
        const request = response.getFileRequest();
        response.setResponse({
            fileNames: [request.getFile().name]
        });
    };

    private onImageUpload = async (_, photos: any): Promise<void> => {
        for (const photo of photos) {
            if (photo.response) {
                const name = photo.response.fileNames[0];
                const newPhotos = await this.getImagePhotoInfoList([name]);
                if (newPhotos.length > 0) {
                    photo.name = newPhotos[0].name;
                    photo.url = newPhotos[0].url;
                    photo.response = null;
                }
            }
        }

        const imageNames = this.getImageNames(photos.map(p => p.name));
        this.setState({
            imageNames
        });

        // 调用父类的触发函数 将值返回
        this.props.onImageUpload(imageNames);
    };

    private onImagePreview = (index: number): void => {
        const t = this;
        const imageFileName = t.getImageName(index);
        t.setState({
            imageFileName,
            imageViewerVisible: true
        });
    };

    private onHideImage = (): void => {
        this.setState({
            imageFileName: null,
            imageViewerVisible: false
        });
    };

    private getImageName(index: number): string {
        return this.getImageNameList()[index];
    }

    private getImageNameList(): string[] {
        return this.state.imageNames.split(",");
    }

    private getImageNames(imageNameList: string[]): string {
        imageNameList = imageNameList || [];
        return imageNameList.join(",");
    }
}

ESPhotoField.defaultProps = {
    required: false,
    imageNames: null,
    label: null,
    placeholder: null,
    readOnly: false,
    maxUpload: 4, // 最大上传张数
    onDelete: () => {},
    onImageUpload: () => {}
};

export default ESPhotoField;
