import React from "react";
import { ISlabGradeChangeRequestForListDTO } from "../../app/WebAPIClients";
import ListItem, { IListItemBaseProps } from "../ListItem/ListItem";
import Util from "../Util";
import RequestUtil from "../RequestUtil";

function SlabGradeChangeRequestListItem(props: IListItemBaseProps) {
    const { item, onClick } = props;
    const request = item as ISlabGradeChangeRequestForListDTO;

    const { id } = request;

    const getDescription = (item: { slabCount: number }): string => {
        let desc = "";
        if (Util.isDefinedAndNotNull(item)) {
            desc = "变更大板数量：";
            if (Util.isDefinedAndNotNull(item.slabCount)) {
                desc = `${desc}${item.slabCount}片`;
            }
        }

        return desc;
    };

    const avatarTitle = "等级";
    const title = RequestUtil.getRequestTitle(request);

    return (
        <ListItem
            key={id}
            item={request}
            avatarTitle={avatarTitle}
            title={title}
            description={getDescription(request)}
            onClick={onClick}
        />
    );
}

export default SlabGradeChangeRequestListItem;
