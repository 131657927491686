import * as React from "react";
import { Field, Group } from "saltui";
import {
  IAppliedProcessViewModel,
  IProcessDTO,
  ProcessDisplayType,
} from "../../app/WebAPIClients";
import AppliedProcessItem from "../AppliedProcessItem";
import ESHeader from "../ESHeader";
import Util from "../Util";

declare interface IAppliedProcessListProps {
  processes: IProcessDTO[];
  onChange: (appliedProcesses: IAppliedProcessViewModel[]) => void;
  showHeader?: boolean;
  label?: string;
  extraInfo?: JSX.Element;
  appliedProcesses?: IAppliedProcessViewModel[];
}

declare interface IAppliedProcessListState {
  appliedProcesses: IAppliedProcessViewModel[];
}

class AppliedProcessList extends React.Component<
  IAppliedProcessListProps,
  IAppliedProcessListState
> {
  public static defaultProps: IAppliedProcessListProps;
  constructor(props) {
    super(props);
    this.state = {
      appliedProcesses: props.appliedProcesses,
    };
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.appliedProcesses !== this.props.appliedProcesses) {
      this.setState({ appliedProcesses: nextProps.appliedProcesses });
    }
  }

  public render() {
    const t = this;
    const s = t.state;
    const { processes, showHeader, label, extraInfo } = t.props;

    return (
      <div>
        <Group>
          {!showHeader ? null : (
            <ESHeader
              label={Util.isNotNullAndNotEmpty(label) ? label : "工艺列表"}
            />
          )}
          <Group.List lineIndent={15}>
            {Util.isNotNullAndNotEmptyArray(processes) ? (
              processes.map((p) => {
                const ap = s.appliedProcesses.find((a) => a.processId === p.id);
                return (
                  <AppliedProcessItem
                    key={p.id}
                    process={p}
                    quantity={
                      Util.isDefinedAndNotNull(ap) ? ap.quantity : undefined
                    }
                    onChange={t.handleChange}
                  />
                );
              })
            ) : (
              <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">没有数据</div>
            )}
            {extraInfo}
          </Group.List>
        </Group>
      </div>
    );
  }

  private handleChange = (appliedProecss: IAppliedProcessViewModel) => {
    const t = this;
    const s = t.state;
    let appliedProcesses = s.appliedProcesses;
    const ap = appliedProcesses.find(
      (a) => a.processId === appliedProecss.processId,
    );

    const process = this.props.processes.find(
      (p) => p.id === appliedProecss.processId,
    );

    let addToProcesses: boolean = true;

    if (
      process &&
      (((process.displayType === ProcessDisplayType.IntegerNumber ||
        process.displayType === ProcessDisplayType.RealNumber) &&
        (!Util.isNotNullAndNotNaN(appliedProecss.quantity) ||
          appliedProecss.quantity === 0)) ||
        (process.displayType === ProcessDisplayType.Switch &&
          (!Util.isDefinedAndNotNull(appliedProecss.onSwitch) ||
            appliedProecss.onSwitch === false)))
    ) {
      addToProcesses = false;
    }

    if (Util.isDefinedAndNotNull(ap)) {
      if (addToProcesses) {
        const itemIndex = appliedProcesses.indexOf(ap);
        appliedProcesses[itemIndex] = appliedProecss;
      } else {
        appliedProcesses = appliedProcesses.filter(
          (a) => a.processId !== appliedProecss.processId,
        );
      }
    } else if (addToProcesses) {
      appliedProcesses.push(appliedProecss);
    }

    this.setState({ appliedProcesses });

    const { onChange } = this.props;

    if (onChange) {
      onChange(appliedProcesses);
    }
  };
}

AppliedProcessList.defaultProps = {
  onChange: (appliedProcesses: IAppliedProcessViewModel[]) => {},
  processes: [],
  showHeader: false,
  label: "",
  extraInfo: null,
  appliedProcesses: [],
};

export default AppliedProcessList;
