import React from "react";
import {
    Boxs,
    Button,
    CascadeSelectField,
    Field,
    Group,
    Scroller,
    Toast
} from "saltui";
import { IWarehouseDTO, WarehouseType } from "../../app/WebAPIClients";
import UserInfo from "../../components/UserInfo";
import Consts from "../Consts";
import ESIcon from "../ESIcon";
import ListFooter from "../ListFooter";
import Picker from "../Picker";
import StockingAreaUtil from "../StockingAreaUtil";
import Util from "../Util";

const HBox = Boxs.HBox;
const Box = Boxs.Box;

declare interface IStockingAreaSelectorProps {
    placeholder?: string;
    readOnly?: boolean;
    allowSelection?: boolean;
    warehouseId?: number;
    label: string;
    stockingAreaId: number;
    warehouseType: WarehouseType;
    required?: boolean;
    isFilter?: boolean;
    onSelect: (id: number, warehouseId: number) => void;
}

declare interface IStockingAreaSelectorState {
    saList: any; // 区域列表
    factories: any; // 工厂列表
    warehouses: any; // 仓库列表
    warehouseOptions: ITwoStageCascade[];
    stockingAreaOptions: IThreeStageCascade[]; // 区域选择列表
    selectWarehouseId?: number; // 选择的仓库Id
    visible: boolean; // 是否显示弹出层
    selectStockingArea: { section: string; segment: string; slot: string };
    columns: string[];
    showStockingAreaName: string;
    uniqueWarehouse: boolean;
}

class StockingAreaSelector extends React.Component<
    IStockingAreaSelectorProps,
    IStockingAreaSelectorState
> {
    public static defaultProps: IStockingAreaSelectorProps;
    private sc: IScroller;
    constructor(props) {
        super(props);
        this.state = {
            saList: [],
            factories: [],
            warehouses: [],
            columns: ["工厂", "仓库"],
            visible: false,
            warehouseOptions: [],
            stockingAreaOptions: [],
            selectStockingArea: { section: null, segment: null, slot: null },
            showStockingAreaName: null,
            uniqueWarehouse: false
        };
    }

    public async componentWillReceiveProps(nextProps) {
        const t = this;
        const s = t.state;
        let selectWarehouseId = s.selectWarehouseId;
        let warehouseOptions = [...s.warehouseOptions];
        let stockingAreaOptions = [...s.stockingAreaOptions];
        let saList = [...s.saList];
        let uniqueWarehouse = s.uniqueWarehouse;

        if (nextProps.warehouseId !== t.props.warehouseId) {
            selectWarehouseId = nextProps.warehouseId;
        }

        if (nextProps.warehouseType !== t.props.warehouseType) {
            const warehouses = s.warehouses.filter(
                w => w.type === nextProps.warehouseType
            );

            uniqueWarehouse = warehouses.length === 1;

            saList =
                nextProps.warehouseType === WarehouseType.Block
                    ? await Consts.BlockStockingAreas.getStockingAreas()
                    : await Consts.ProductStockingAreas.getStockingAreas();
            warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
                warehouses,
                s.factories
            );
            stockingAreaOptions = t.getStockingAreaOptions(
                saList,
                selectWarehouseId
            );
        }

        let showStockingAreaName = s.showStockingAreaName;
        if (
            nextProps.stockingAreaId !== t.props.stockingAreaId ||
            nextProps.warehouseId !== t.props.warehouseId
        ) {
            showStockingAreaName = t.getShowName(
                nextProps.stockingAreaId,
                nextProps.warehouseId,
                s.saList,
                s.factories,
                s.warehouses
            );
        }

        if (
            !Util.isDefinedAndNotNull(t.props.stockingAreaId) &&
            !Util.isDefinedAndNotNull(t.props.warehouseId)
        ) {
            const factoryId = UserInfo.getFactoryId();
            const warehouseOption = warehouseOptions.find(
                w => w.value === factoryId
            );

            if (
                warehouseOption ||
                (warehouseOptions && warehouseOptions.length > 0)
            ) {
                selectWarehouseId =
                    warehouseOption && warehouseOption.children.length > 0
                        ? (warehouseOption.children[0].value as number)
                        : (warehouseOptions[0].children[0].value as number);
            }
        }

        this.setState({
            saList,
            uniqueWarehouse,
            selectWarehouseId,
            warehouseOptions,
            stockingAreaOptions,
            showStockingAreaName
        });
    }

    public async componentDidMount() {
        const t = this;
        const { warehouseType, stockingAreaId, warehouseId } = t.props;

        const factories = await Consts.Factories.getFactories(); // 工厂列表
        const warehouses: IWarehouseDTO[] = await Consts.Warehouses.getWarehouses(); // 仓库列表

        const saList = // 区域列表
            warehouseType === WarehouseType.Block
                ? await Consts.BlockStockingAreas.getStockingAreas()
                : await Consts.ProductStockingAreas.getStockingAreas();

        const filterWarehouses = warehouses.filter(
            w => w.type === warehouseType
        );
        const uniqueWarehouse = filterWarehouses.length === 1;
        const warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
            filterWarehouses,
            factories
        );

        // 初始化默认仓库
        let selectWarehouseId: number = null;
        const stockingArea = saList.find(s => s.id === stockingAreaId);

        const showStockingAreaName = t.getShowName(
            stockingAreaId,
            warehouseId,
            saList,
            factories,
            warehouses
        );

        if (warehouseId) {
            selectWarehouseId = warehouseId;
        } else if (!Util.isDefinedAndNotNull(stockingArea)) {
            const factoryId = UserInfo.getFactoryId();
            const warehouseOption = warehouseOptions.find(
                w => w.value === factoryId
            );

            selectWarehouseId =
                warehouseOption && warehouseOption.children.length > 0
                    ? (warehouseOption.children[0].value as number)
                    : (warehouseOptions[0].children[0].value as number);
        } else {
            selectWarehouseId = stockingArea.warehouseId;
        }

        t.setState({
            saList,
            uniqueWarehouse,
            warehouseOptions,
            selectWarehouseId,
            warehouses,
            factories,
            showStockingAreaName
        });
    }

    public render(): JSX.Element {
        const t = this;
        const s = this.state;
        const { label, placeholder, readOnly, required } = t.props;

        const icon: JSX.Element = readOnly ? null : (
            <ESIcon
                name="icon-right"
                fill="rgba(31,56,88,.4)"
                onClick={t.handleVisibleChang}
                height={25}
                width={25}
            />
        );
        return (
            <div>
                <Field label={label} icon={icon} required={required}>
                    <div
                        className="t-picker-field-content"
                        onClick={readOnly ? null : t.handleVisibleChang}
                    >
                        <span
                            className={
                                s.showStockingAreaName
                                    ? null
                                    : "t-picker-field-placeholder"
                            }
                        >
                            {s.showStockingAreaName
                                ? s.showStockingAreaName
                                : placeholder}
                        </span>
                    </div>
                    {t.showPopupJSX()}
                </Field>
            </div>
        );
    }

    // 选择工厂仓库
    private handleWarehouseChange = selectWarehouse => {
        const t = this;
        const s = t.state;

        if (selectWarehouse[1].value === s.selectWarehouseId) {
            return;
        }

        // 重新获取区域选择数据
        const stockingAreaOptions = t.getStockingAreaOptions(
            s.saList,
            selectWarehouse[1].value
        );

        this.setState({
            selectWarehouseId: selectWarehouse[1].value,
            stockingAreaOptions,
            selectStockingArea: { section: null, segment: null, slot: null } // 清空已选择的区域
        });
    };

    // 关闭弹出层
    private handleNoVisibleChange = () => {
        window.history.go(-1);
        this.setState({ visible: false });
    };

    // 打开弹出层
    private handleVisibleChang = () => {
        const t = this;
        const s = t.state;
        const { stockingAreaId } = t.props;
        let selectWarehouseId = s.selectWarehouseId;
        let selectStockingArea = {
            section: null,
            segment: null,
            slot: null
        };

        if (!s.saList) {
            return;
        }

        const stockingArea = s.saList.find(sa => sa.id === stockingAreaId);
        if (stockingArea) {
            selectStockingArea = {
                section: stockingArea.section,
                segment: stockingArea.segment,
                slot: stockingArea.slot
            };

            selectWarehouseId = stockingArea.warehouseId;
        }

        // 获取区域Options
        const stockingAreaOptions = t.getStockingAreaOptions(
            s.saList,
            selectWarehouseId
        );

        t.setState({
            visible: true,
            stockingAreaOptions,
            selectStockingArea,
            selectWarehouseId
        });
    };

    private visibleChange = value => {
        this.setState({
            visible: value
        });
    };

    // 弹出层
    private showPopupJSX(): JSX.Element {
        const t = this;
        const s = t.state;
        const { allowSelection, isFilter } = t.props;
        const stockingArea = s.selectStockingArea;
        const selectWarehouse = StockingAreaUtil.getWarehouAreaForUI(
            s.selectWarehouseId,
            s.warehouses,
            s.factories
        );

        const segmentOptions = t.getButtonOptions(
            s.stockingAreaOptions,
            stockingArea.section
        );

        let slotOptions = null;
        if (segmentOptions) {
            slotOptions = t.getButtonOptions(
                segmentOptions.children,
                stockingArea.segment
            );
        }

        return (
            <Picker
                visible={s.visible}
                onVisibleChange={t.visibleChange}
                className="t-picker-searchpanel-inner"
            >
                <div>
                    <Group>
                        {s.uniqueWarehouse ? null : (
                            <Group.List>
                                <CascadeSelectField
                                    label="选择仓库"
                                    required={allowSelection}
                                    readOnly={!allowSelection}
                                    onSelect={t.handleWarehouseChange}
                                    options={s.warehouseOptions}
                                    value={selectWarehouse}
                                    placeholder="请选择仓库"
                                    columns={s.columns}
                                />
                            </Group.List>
                        )}
                    </Group>
                    <Scroller
                        ref={sc => {
                            this.sc = sc;
                        }}
                        className={
                            "scrollerBottom " +
                            (s.uniqueWarehouse ? "" : "filterCollapsed")
                        }
                        disablePointer={true}
                        mouseWheel={true}
                        scrollBar={true}
                        click={true}
                    >
                        {s.stockingAreaOptions
                            ? t.selectButtonJSX(
                                  "请选择大区域",
                                  stockingArea.section,
                                  s.stockingAreaOptions,
                                  t.handleSelectSection
                              )
                            : null}
                        {segmentOptions &&
                        segmentOptions.children &&
                        segmentOptions.children.length
                            ? t.selectButtonJSX(
                                  "请选择架号",
                                  stockingArea.segment,
                                  segmentOptions.children,
                                  t.handleSelectSegment
                              )
                            : null}
                        {slotOptions &&
                        slotOptions.children &&
                        slotOptions.children.length
                            ? t.selectButtonJSX(
                                  "请选择分段号",
                                  stockingArea.slot,
                                  slotOptions.children,
                                  t.handleSelectSlot
                              )
                            : null}
                    </Scroller>
                    {(stockingArea && stockingArea.section) ||
                    (!s.uniqueWarehouse &&
                        isFilter &&
                        Util.isDefinedAndNotNull(s.selectWarehouseId)) ? (
                        <ListFooter
                            className="footerAboveActionButton"
                            content={
                                <div>
                                    <span>
                                        已选：{stockingArea &&
                                        stockingArea.section
                                            ? t.getSelectStockingAreaText(
                                                  stockingArea
                                              )
                                            : isFilter &&
                                              Util.isDefinedAndNotNull(
                                                  s.selectWarehouseId
                                              )
                                                ? StockingAreaUtil.getWarehouseName(
                                                      s.selectWarehouseId,
                                                      s.factories,
                                                      s.warehouses
                                                  )
                                                : null}
                                    </span>
                                </div>
                            }
                        />
                    ) : null}
                    <div className="stockingAreaStyl">
                        <HBox>
                            <Box flex={1} className="t-PR8">
                                <Button onClick={t.handleSubmit}>提交</Button>
                            </Box>
                            <Box flex={1} className="t-PR8">
                                <Button onClick={t.handleNoVisibleChange}>
                                    返回
                                </Button>
                            </Box>
                        </HBox>
                    </div>
                </div>
            </Picker>
        );
    }

    private getButtonOptions(options, value: string) {
        let newOptions = null;
        if (options) {
            newOptions = options.find(sao => sao.value === value);
        }
        return newOptions;
    }

    // 根据仓库Id获取区域选择列表
    private getStockingAreaOptions(
        saList,
        warehouseId: number
    ): IThreeStageCascade[] {
        saList = saList.filter(sa => sa.warehouseId === warehouseId);
        const stockingAreaOptions = StockingAreaUtil.generateCascadeData(
            saList
        );
        return stockingAreaOptions;
    }

    // 提交数据
    private handleSubmit = () => {
        const t = this;
        const s = t.state;
        const { onSelect, isFilter } = t.props;
        let stockingArea = null;

        if (!isFilter && !s.selectStockingArea.section) {
            Toast.show({
                content: "请选择区域"
            });
            return;
        }

        if (
            Util.isDefinedAndNotNull(s.selectStockingArea) &&
            Util.isDefinedAndNotNull(s.selectStockingArea.section)
        ) {
            stockingArea = StockingAreaUtil.getStockingArea(
                s.selectStockingArea,
                s.selectWarehouseId,
                s.saList
            );

            if (!stockingArea) {
                Toast.show({
                    content: `选中区域${t.getSelectStockingAreaText(
                        s.selectStockingArea
                    )}不存在`
                });
                return;
            }
        }

        window.history.go(-1);
        this.setState({
            visible: false
        });

        const stockingAreaId = Util.isDefinedAndNotNull(stockingArea)
            ? stockingArea.id
            : null;
        if (onSelect) {
            onSelect(stockingAreaId, s.selectWarehouseId);
        }
    };

    private handleSelectSection = (value: string) => {
        const t = this;
        const s = t.state;
        const selectStockingArea = { ...s.selectStockingArea };

        selectStockingArea.section =
            selectStockingArea.section !== value
                ? value
                : (selectStockingArea.section = null);

        selectStockingArea.segment = null;
        selectStockingArea.slot = null;
        t.setState({ selectStockingArea });
    };

    private handleSelectSegment = (value: string) => {
        const t = this;
        const s = t.state;
        const selectStockingArea = { ...s.selectStockingArea };
        selectStockingArea.segment =
            selectStockingArea.segment !== value ? value : null;
        selectStockingArea.slot = null;
        t.setState({ selectStockingArea });
    };

    private handleSelectSlot = (value: string) => {
        const t = this;
        const s = t.state;
        const selectStockingArea = { ...s.selectStockingArea };
        selectStockingArea.slot =
            selectStockingArea.slot !== value ? value : null;
        t.setState({ selectStockingArea });
    };

    private selectButtonJSX(lable: string, value: string, options, onClickFun) {
        const t = this;
        return (
            <Group>
                <Group.Head className="t-FS14 t-LH1_5 t-LH20 t-PT10 t-PB10 t-PL18">
                    {lable}
                </Group.Head>
                {options.map(o => {
                    return (
                        <Button
                            key={o.value}
                            display="inline"
                            size={"small"}
                            type={value === o.value ? "primary" : "minor"}
                            style={{
                                margin: "6px",
                                fontSize: "14px"
                            }}
                            onClick={onClickFun.bind(t, o.value)}
                        >
                            {o.label}
                        </Button>
                    );
                })}
            </Group>
        );
    }

    private getSelectStockingAreaText(stockingArea) {
        let saText = "";
        saText += Util.isNotNullAndNotEmpty(stockingArea.section)
            ? stockingArea.section
            : "";
        saText += Util.isNotNullAndNotEmpty(stockingArea.segment)
            ? "-" + stockingArea.segment
            : "";
        saText +=
            Util.isNotNullAndNotEmpty(stockingArea.segment) &&
            Util.isNotNullAndNotEmpty(stockingArea.slot)
                ? "-" + stockingArea.slot
                : "";
        return saText;
    }

    private getShowName(
        stockingAreaId,
        warehouseId,
        saList,
        factories,
        warehouses
    ) {
        const showStockingAreaName = Util.isDefinedAndNotNull(stockingAreaId)
            ? StockingAreaUtil.showStockingAreaText(
                  stockingAreaId,
                  saList,
                  factories,
                  warehouses
              )
            : Util.isDefinedAndNotNull(warehouseId)
                ? StockingAreaUtil.getWarehouseName(
                      warehouseId,
                      factories,
                      warehouses
                  )
                : null;

        return showStockingAreaName;
    }
}

StockingAreaSelector.defaultProps = {
    label: "",
    warehouseType: WarehouseType.Block,
    onSelect: () => {},
    stockingAreaId: null,
    placeholder: null,
    readOnly: false,
    required: false,
    allowSelection: true,
    warehouseId: null,
    isFilter: false
};

export default StockingAreaSelector;
