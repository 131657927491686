import React, { useEffect, useState } from "react";
import Util from "../Util";

interface IPercentagInputProps<T> {
    rate: number | undefined | null;
    item: T;
    onChange?: (item: T, rate: number | null) => void;
    className?: string;
    placeholder?: string;
}

function PercentageInput<T>(props: IPercentagInputProps<T>) {
    const { rate, item, onChange, className, placeholder } = props;
    const percentageTxt =
        rate === null || rate === undefined
            ? ""
            : Util.round(rate * 100, 0).toString();
    const [percentage, setPercentage] = useState(percentageTxt);

    useEffect(
        () => {
            setPercentage(percentageTxt);
        },
        [item, rate]
    );

    const onValueChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        setPercentage(e.target.value);
    };

    const onBlur: React.ChangeEventHandler<HTMLInputElement> = e => {
        const newPercentage: number | null = Util.isNotNullAndNotEmpty(
            e.target.value
        )
            ? Util.parseToInt(e.target.value)
            : null;
        const newRate: number | null = Util.isDefinedAndNotNull(newPercentage)
            ? Util.round(newPercentage / 100, 2)
            : null;
        console.log(item);
        console.log("old rate:", rate);
        console.log("new rate:", newRate);
        if (onChange && newRate !== rate) {
            onChange(item, newRate);
        }

        if (e) {
            e.stopPropagation();
        }
    };

    const classNameTxt = Util.isDefinedAndNotNull(className)
        ? className
        : "unitPriceTextBox";

    return (
        <div>
            <input
                type="number"
                min="0"
                max="100"
                step="1"
                name="percentageInput"
                onBlur={onBlur}
                onChange={onValueChange}
                className={classNameTxt}
                value={percentage}
                placeholder={placeholder}
            />%
        </div>
    );
}

export default PercentageInput;
