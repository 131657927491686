import * as React from "react";
import {
  ApprovalStatus,
  ISlabCheckOutRequestForListDTO,
  ISlabReturnRequestForListDTO,
} from "../app/WebAPIClients";
import ListItem from "./ListItem";
import Util from "./Util";
import ApprovalStatusUtil from "./ApprovalStatusUtil";

class RequestUtil {
  public getRequestTitle(request: {
    orderNumber: string;
    status: ApprovalStatus;
  }): JSX.Element {
    if (typeof request === "undefined" && request === null) {
      return null;
    }
    return (
      <div>
        <span>{request.orderNumber + " | "}</span>
        {ApprovalStatusUtil.getStatusJSX(request.status)}
      </div>
    );
  }

  public getRequestJSX(
    request: ISlabCheckOutRequestForListDTO | ISlabReturnRequestForListDTO,
    requestType: string,
    onClick: (
      item: ISlabCheckOutRequestForListDTO | ISlabReturnRequestForListDTO,
    ) => void,
  ): JSX.Element {
    const t = this;
    const avatarTitle = requestType === "slabCheckOut" ? "领料" : "退料";
    return (
      <ListItem
        key={request.id}
        item={request}
        avatarTitle={avatarTitle}
        title={t.getRequestTitle(request)}
        description={Util.getSlabRequestDescription(request, requestType)}
        onClick={onClick}
      />
    );
  }
}

export default new RequestUtil();
