import * as React from "react";
import { Group } from "saltui";
import { IUntrackedProductSheetForListDTO } from "../../app/WebAPIClients";
import DateUtil from "../DateUtil";
import ListItem from "../ListItem";
import Util from "../Util";

declare interface IUntrackedProductSheetListProps {
    untrackedProductSheets: IUntrackedProductSheetForListDTO[];
    onClick?: (item: IUntrackedProductSheetForListDTO) => void;
}

class UntrackedProductSheetList extends React.PureComponent<
    IUntrackedProductSheetListProps,
    {}
> {
    public static defaultProps: IUntrackedProductSheetListProps;

    public render() {
        const t = this;
        const { untrackedProductSheets, onClick } = t.props;
        return (
            <div>
                <Group>
                    <Group.List lineIndent={15} itemIndent={[15, 15]}>
                        {untrackedProductSheets &&
                        untrackedProductSheets.length &&
                        untrackedProductSheets.length > 0 ? (
                            untrackedProductSheets.map(item => {
                                let title = item.number;

                                if (
                                    Util.isNotNullAndNorthOfZero(
                                        item.totalCount
                                    )
                                ) {
                                    title += " | 共 " + item.totalCount + " 片";
                                }

                                title += " | 共 " + item.totalArea + " 平方";

                                const description: string =
                                    "创建信息： " +
                                    item.creator +
                                    " | " +
                                    DateUtil.formatDateTime(item.createdTime);
                                const avatarTitle = item.number;

                                return (
                                    <ListItem
                                        key={item.id}
                                        avatarTitle={avatarTitle}
                                        title={title}
                                        description={description}
                                        item={item}
                                        onClick={onClick}
                                    />
                                );
                            })
                        ) : (
                            <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                                没有数据
                            </div>
                        )}
                    </Group.List>
                </Group>
            </div>
        );
    }
}

UntrackedProductSheetList.defaultProps = {
    untrackedProductSheets: [],
    onClick: () => {}
};

export default UntrackedProductSheetList;
