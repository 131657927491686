import React from "react";
import { IERPUserDTO } from "../app/WebAPIClients";
import ESPickerField from "./ESPickerField";
import Util from "./Util";

interface ISalesRepPickerFieldProps {
  salesReps: ISalesRep[];
  selectedSalesRep?: ISalesRep | null | undefined;
  onChange?: (salesRep?: ISalesRep) => void;
}

export interface ISalesRep extends Pick<IERPUserDTO, "id" | "name"> {}

export const SalesRepPickerField: React.FC<ISalesRepPickerFieldProps> = ({
  salesReps,
  selectedSalesRep,
  onChange,
}) => {
  const formatSalesRepId = (
    salesRepId: string | null | undefined,
    salesRepName: string,
  ) => {
    return !salesRepId || salesRepId.trim() === "" ? salesRepName : salesRepId;
  };

  const options: IOption[] = salesReps.map((salesRep) => ({
    value: formatSalesRepId(salesRep.id, salesRep.name),
    text: salesRep.name,
  }));

  const orderedOptions = Util.getOptionsWithPhoneticByOptionArr(options);
  const selectedOption: IOption | null = selectedSalesRep
    ? {
        value: formatSalesRepId(selectedSalesRep?.id, selectedSalesRep?.name),
        text: selectedSalesRep.name,
      }
    : null;

  const handlePickerChange = (value: IOption) => {
    const salesRep = salesReps.find((salesRep) => salesRep.id === value.value);

    // when the id and name of the sales rep is the same, we knew that the sales rep
    // didn't have an Id, and the previous code used the name as the Id.
    // so we will set the Id to null.
    const selectedSalesRep = salesRep
      ? salesRep.id === salesRep.name
        ? { id: null, name: salesRep.name }
        : salesRep
      : null;

    onChange?.(selectedSalesRep);
  };

  return (
    <ESPickerField
      label={"销售代表"}
      required={true}
      placeholder="请选择销售代表"
      options={orderedOptions}
      onSelect={handlePickerChange}
      value={selectedOption}
    />
  );
};

/**
 * Prepare the sales rep user list by checking if the existing sales rep is in the list.
 * If the existing sales rep is not in the list, add it to the list.
 * If the existing sales rep has no Id, use the name as its Id.
 * @param salesReps A list of sales reps
 * @param existingSalesRepId The Id of the existing sales rep
 * @param existingSalesRepName The name of the existing sales rep
 */
export const prepareSalesRepList: (
  salesReps: ISalesRep[],
  existingSalesRepName: string | null | undefined,
  existingSalesRepId: string | undefined | null,
) => ISalesRep[] = (salesReps, existingSalesRepName, existingSalesRepId) => {
  const returnSalesReps = [...salesReps];

  if (!existingSalesRepName) {
    return returnSalesReps;
  }

  if (
    !returnSalesReps.some((salesRep) => salesRep.name === existingSalesRepName)
  ) {
    returnSalesReps.push({
      id: existingSalesRepId,
      name: existingSalesRepName,
    });
  }

  return returnSalesReps;
};
