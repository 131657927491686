import { IBundleForListDTO } from "../../app/WebAPIClients";
import ClientFactory from "../../app/WebAPIClientFactory";

class BundleFetcher {
  public static getBundlesWithSamePrefixes(
    pendingFetchingBundles: IBundleForListDTO[],
    fetchedBundlesWithSamePrefixes: IBundleForListDTO[] = [],
    customerId: number = null,
  ) {
    const t = this;
    const client = ClientFactory.getStoneBundleClient();
    const pendingFetchingPrefixes = t.getUnfetchedBundlePrefixes(
      pendingFetchingBundles,
      fetchedBundlesWithSamePrefixes,
    );
    if (pendingFetchingPrefixes.length === 0) {
      return Promise.resolve<IBundleForListDTO[]>([]);
    }
    return client.getByPrefixes(pendingFetchingPrefixes, customerId);
  }

  private static getUnfetchedBundlePrefixes(
    pendingFetchingBundles: IBundleForListDTO[],
    fetchedBundlesWithSamePrefixes: IBundleForListDTO[],
  ) {
    const t = this;
    const pendingFetchingPrefixes = pendingFetchingBundles.map((b) => b.prefix);
    const uniquePendingFetchingPrefixes = [...new Set(pendingFetchingPrefixes)];
    return uniquePendingFetchingPrefixes.filter(
      (p) => !fetchedBundlesWithSamePrefixes.some((b) => b.prefix === p),
    );
  }
}

export default BundleFetcher;
