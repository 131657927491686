import React from "react";
import { NumberField, TextField } from "saltui";
const { LeftAddon } = TextField;

declare interface IESNumberFieldProps {
    label: string;
    className?: string;
    value: string;
    readOnly?: boolean;
    required?: boolean;
    type?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
}

class ESNumberField extends React.Component<IESNumberFieldProps, {}> {
    public static defaultProps: IESNumberFieldProps;

    public render(): JSX.Element {
        const t = this;

        const {
            label,
            className,
            value,
            readOnly,
            required,
            type,
            placeholder,
            ...other
        } = t.props;
        return (
            <NumberField
                label={label}
                className={className}
                type={type}
                delimiter=","
                value={value}
                readOnly={readOnly}
                fixedNum={2}
                required={required}
                onChange={this.handleChange}
                placeholder={placeholder}
            >
                <LeftAddon>
                    <span>￥</span>
                </LeftAddon>
            </NumberField>
        );
    }

    private handleChange = (value: string) => {
        const { onChange } = this.props;

        if (onChange) {
            onChange(value);
        }
    };
}

ESNumberField.defaultProps = {
    label: null,
    className: null,
    value: null,
    readOnly: true,
    required: false,
    type: "money",
    placeholder: null,
    onChange: value => {}
};

export default ESNumberField;
