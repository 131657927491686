import React from "react";
import { Boxs } from "saltui";
import ESButton from "../ESButton";
const HBox = Boxs.HBox;
const Box = Boxs.Box;

export interface IFilterButtonGroupProps {
    className?: string;
    onSearch: () => void;
    onClearData: () => void;
    onMoreClickChange?: () => void;
    buttonName?: string;
    showExtraButton?: boolean;
}

class FilterButtonGroup extends React.Component<IFilterButtonGroupProps, {}> {
    public static defaultProps: IFilterButtonGroupProps;
    constructor(props: IFilterButtonGroupProps) {
        super(props);
    }

    public render(): JSX.Element {
        const t = this;
        const {
            className,
            buttonName,
            showExtraButton,
            onClearData,
            onSearch,
            onMoreClickChange
        } = t.props;

        const divClassName = className ? className : "topActionBtnContainer";

        return (
            <div className={divClassName}>
                <HBox>
                    <Box flex={1} className="t-PR8">
                        <ESButton onClick={onSearch}>搜索</ESButton>
                    </Box>
                    <Box flex={1} className="t-PR8">
                        <ESButton onClick={onClearData}>清除</ESButton>
                    </Box>
                    {showExtraButton ? (
                        <Box className="t-PR8">
                            <ESButton
                                onClick={onMoreClickChange}
                                type={"secondary"}
                            >
                                {buttonName}
                            </ESButton>
                        </Box>
                    ) : null}
                </HBox>
            </div>
        );
    }
}

FilterButtonGroup.defaultProps = {
    className: "",
    buttonName: "更多",
    showExtraButton: false,
    onSearch: () => {},
    onClearData: () => {},
    onMoreClickChange: () => {}
};

export default FilterButtonGroup;
