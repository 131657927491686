import * as React from "react";
import { CascadeSelectField, Group } from "saltui";
import Perm from "../../app/Perm";
import {
    IFactoryDTO,
    IWarehouseDTO,
    StoneArtifactType,
    WarehouseType
} from "../../app/WebAPIClients";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ESPickerField from "../ESPickerField";
import ESTextField from "../ESTextField";
import FilterButtonGroup from "../FilterButtonGroup";
import PermCtl from "../PermCtrl";
import StockingAreaUtil from "../StockingAreaUtil";
import Util from "../Util";
const {
    Factories,
    Warehouses,
    ApprovalStatus: ApprovalStatusOptions,
    StoneArtifactTypeOptions
} = Consts;

declare interface ITransferOrderListFilterProps
    extends IFilterProps<ITransferOrderListFilter> {}

export interface ITransferOrderListFilter {
    selectedOrderNumber?: string;
    selectedTypeOptions?: IOption[];
    selectedFromWarehouse?: number;
    selectedToWarehouse?: number;
    selectedStatusOptions?: IOption[];
}

declare interface ITransferOrderListFilterState
    extends ITransferOrderListFilter {
    statusOptions: IOptionWithPhonetic[];
    factories: IFactoryDTO[];
    warehouseOptions: ITwoStageCascade[];
    warehouses: IWarehouseDTO[];
    toTypeOptions?: IOption[];
    columns: string[];
}

class TransferOrderListFilter extends React.Component<
    ITransferOrderListFilterProps,
    ITransferOrderListFilterState
> {
    public static defaultProps: ITransferOrderListFilterProps;
    constructor(props: ITransferOrderListFilterProps) {
        super(props);
        this.state = {
            statusOptions: Util.getOptionsWithPhoneticByOptionArr(
                ApprovalStatusOptions.approvalData
            ),
            factories: [],
            warehouses: [],
            warehouseOptions: [],
            toTypeOptions: this.getTOTypeOptions(),
            columns: ["工厂", "仓库"],
            ...props.filterData
        };
    }

    public componentWillReceiveProps(nextProps: ITransferOrderListFilterProps) {
        if (nextProps.filterData !== this.props.filterData) {
            this.setState({ ...nextProps.filterData });
        }
    }

    public async componentDidMount() {
        const factories = await Factories.getFactories();
        let warehouses = await Warehouses.getWarehouses();
        warehouses = this.getWarehouseOptions(warehouses);
        const warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
            warehouses,
            factories
        );
        this.setState({
            warehouseOptions,
            factories,
            warehouses
        });
    }

    public render() {
        const t = this;
        const s = t.state;
        const {
            selectedOrderNumber,
            selectedTypeOptions,
            selectedFromWarehouse,
            selectedToWarehouse,
            selectedStatusOptions
        } = s;

        const { onExpansionChanged, expanded, ...other } = t.props;
        const fromWarehouse = StockingAreaUtil.getWarehouAreaForUI(
            selectedFromWarehouse,
            s.warehouses,
            s.factories
        );

        const toWarehouse = StockingAreaUtil.getWarehouAreaForUI(
            selectedToWarehouse,
            s.warehouses,
            s.factories
        );
        return (
            <Collapsible
                label={"筛选条件"}
                onExpansionChanged={onExpansionChanged}
                expanded={expanded}
            >
                <Group.List itemIndent={[15, 15]}>
                    <ESTextField
                        label="调拨单号"
                        value={selectedOrderNumber}
                        onChange={t.handleOrderNumberChange}
                        placeholder="请输入调拨单号"
                    />
                    <ESPickerField
                        label="调拨类型"
                        options={s.toTypeOptions}
                        readOnly={s.toTypeOptions.length === 1}
                        placeholder="请选择调拨类型"
                        onSelect={t.handleTypeChange}
                        value={selectedTypeOptions}
                        multiple={true}
                    />
                    <CascadeSelectField
                        label="调出仓库"
                        placeholder="请选择调出仓库"
                        options={s.warehouseOptions}
                        columns={s.columns}
                        onSelect={t.handleFromWarehouseChange}
                        value={fromWarehouse}
                    />
                    <CascadeSelectField
                        label="调入仓库"
                        placeholder="请选择调入仓库"
                        options={s.warehouseOptions}
                        columns={s.columns}
                        onSelect={t.handleToWarehouseChange}
                        value={toWarehouse}
                    />
                    <ESPickerField
                        label="状态"
                        options={s.statusOptions}
                        onSelect={t.handleStatusChange}
                        value={selectedStatusOptions}
                        placeholder="请选择状态"
                        multiple={true}
                    />
                    <FilterButtonGroup
                        onSearch={t.handleSearch}
                        onClearData={t.handleClearData}
                    />
                </Group.List>
            </Collapsible>
        );
    }

    private handleOrderNumberChange = (value: string) => {
        this.setState({ selectedOrderNumber: value });
    };

    private handleTypeChange = (value: IOption[]) => {
        this.setState({ selectedTypeOptions: value });
    };

    private handleFromWarehouseChange = value => {
        this.setState({ selectedFromWarehouse: value[1].value });
    };

    private handleToWarehouseChange = value => {
        this.setState({ selectedToWarehouse: value[1].value });
    };

    private handleStatusChange = (value: IOption[]) => {
        this.setState({ selectedStatusOptions: value });
    };

    private handleSearch = () => {
        const t = this;
        const s = t.state;
        const p: ITransferOrderListFilter = {
            selectedFromWarehouse: s.selectedFromWarehouse,
            selectedOrderNumber: s.selectedOrderNumber,
            selectedStatusOptions: s.selectedStatusOptions,
            selectedToWarehouse: s.selectedToWarehouse,
            selectedTypeOptions: s.selectedTypeOptions
        };

        const { onSearch } = t.props;
        if (onSearch) {
            onSearch(p);
        }
    };

    private handleClearData = () => {
        const t = this;
        const s = t.state;
        const p: ITransferOrderListFilter = {
            selectedStatusOptions: [],
            selectedFromWarehouse: undefined,
            selectedToWarehouse: undefined,
            selectedOrderNumber: undefined,
            selectedTypeOptions:
                s.toTypeOptions.length === 1 ? this.getTOTypeOptions() : []
        };

        t.setState(p);

        const { onClear } = t.props;
        if (onClear) {
            onClear(p);
        }
    };

    private getTOTypeOptions = () => {
        let typesOptions = StoneArtifactTypeOptions.data.filter(
            o => o.value !== 50
        );
        if (!PermCtl.isAuthorized(Perm.TO_B_AP)) {
            typesOptions = typesOptions.filter(
                o => o.value !== StoneArtifactType.Block
            );
        }
        if (!PermCtl.isAnyAuthorized([Perm.TO_S_AP, Perm.TO_T_AP])) {
            typesOptions = typesOptions.filter(
                o =>
                    ![
                        StoneArtifactType.Slab,
                        StoneArtifactType.StoneBundle,
                        StoneArtifactType.Tile
                    ].includes(o.value as number)
            );
        }
        return Util.getOptionsWithPhoneticByOptionArr(typesOptions);
    };

    private getWarehouseOptions = (warehouses: IWarehouseDTO[]) => {
        if (!PermCtl.isAuthorized(Perm.TO_B_AP)) {
            warehouses = warehouses.filter(o => o.type !== WarehouseType.Block);
        }
        if (!PermCtl.isAnyAuthorized([Perm.TO_S_AP, Perm.TO_T_AP])) {
            warehouses = warehouses.filter(
                o => o.type !== WarehouseType.Product
            );
        }
        return warehouses;
    };
}

export default TransferOrderListFilter;
