import React, { useEffect, useState } from "react";

import { Boxs, Field } from "saltui";
import ReferencePrice from "../ReferencePrice";
import StoneGradeStore from "../../store/StoneGradeStore";
const { VBox, Box } = Boxs;

declare interface ISlabGradePriceInfoProps {
  gradeId?: number;
  price?: number;
  showUnitPrice?: boolean;
}

function SlabGradePriceInfo(props: ISlabGradePriceInfoProps) {
  const { gradeId, price, showUnitPrice } = props;
  const [gradeName, setGradeName] = useState<string | null>(null);

  useEffect(() => {
    const getGradeName = async () => {
      if (gradeId !== null) {
        const grade = await StoneGradeStore.getGrade(gradeId);
        setGradeName(grade.name);
      }
    };

    getGradeName();
  }, [gradeId]);

  return (
    <VBox>
      <Box className="t-FBAC slabGradePriceInfoGradeName">{gradeName}</Box>
      <Box className="t-FBAC slabGradeCentered">
        {showUnitPrice ? <ReferencePrice referencePrice={price} /> : null}
      </Box>
    </VBox>
  );
}

export default SlabGradePriceInfo;
