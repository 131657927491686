import * as React from "react";
import Consts from "./Consts";
const { BlockStatus: BlockStatusOptions } = Consts;
import {
    BlockStatus,
    IBlockDTO,
    IBlockForListDTO,
    IBlockTrimmingRecordListDTO,
    IBlockWithBundlesSlabsDTO
} from "../app/WebAPIClients";
import DateUtil from "./DateUtil";
import ListItem from "./ListItem";
import Settings from "./SettingInfo";
import SettingsDefinition from "./SettingsDefinition";
import StockingAreaUtil from "./StockingAreaUtil";
import { BlockDimensionType } from "./type";
import Util from "./Util";

class BlockUtil {
    /**
     * 根据荒料状态获取一个用于列表显示的带颜色的span对象
     * @param status 荒料状态
     */
    public getBlockStatus(status: number): JSX.Element {
        const blockStatusName = BlockStatusOptions.getStatus(status).text;
        let className = "";
        switch (status) {
            case 5:
                className = "blockPurchased";
                break;
            case 10:
                className = "blockInStock";
                break;
            case 20:
                className = "blockManufacturing";
                break;
            case 30:
                className = "blockProcessed";
                break;
            case 40:
                className = "blockSold";
                break;
            case 50:
                className = "blockReserved";
                break;
            case 100:
                className = "blockDiscarded";
                break;
        }
        return <span className={className}>{blockStatusName}</span>;
    }

    /**
     * 获取格式化后的荒料尺寸和体积的文字
     * @param length
     * @param width
     * @param height
     */
    public getVolumeSpecText(
        length: number,
        width: number,
        height: number
    ): string {
        return (
            this.getBlockDimension(length, width, height) +
            " | " +
            Util.calculateVolume(length, width, height) +
            "立方"
        );
    }

    /**
     * 获取根据设置的宽和高顺序整理好的荒料尺寸的标题
     */
    public getBlockDimensionTitle(): string {
        const order = this.getDimensionDisplayOrder();
        return order === SettingsDefinition.BlockDimensionLengthHeightWidth
            ? "长高宽"
            : "长宽高";
    }

    /**
     * 获取根据设置的宽和高顺序整理好的荒料尺寸的文字
     * @param length 长
     * @param width 宽
     * @param height 高
     */
    public getBlockDimension(
        length: number,
        width: number,
        height: number
    ): string {
        const order = this.getDimensionDisplayOrder();

        const widthHeightText =
            order === SettingsDefinition.BlockDimensionLengthHeightWidth
                ? height + " x " + width
                : width + " x " + height;
        return length + " x " + widthHeightText;
    }

    /**
     * 获取根据设置的宽和高顺序整理好的指定类型的荒料尺寸的文字
     * @param block 荒料对象
     * @param type 荒料尺寸类型
     */
    public getBlockDimensionByType(
        block: IBlockDTO | IBlockWithBundlesSlabsDTO | IBlockForListDTO,
        type: BlockDimensionType
    ): string {
        const { length, width, height } = this.getBlockSize(block, type);
        return this.getBlockDimension(length, width, height);
    }

    /**
     * 获取用于在列表中显示的荒料标题和描述
     * @param block 荒料对象
     * @param categoryList 石材种类列表
     * @param gradeList 石材等级列表
     */
    public getBlockInfoForList(
        block: IBlockForListDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ): IListItem {
        if (!block) {
            return Util.getEmptyListItemData();
        }

        return {
            title: this.getBlockTitle(block, categoryList),
            text: this.getBlockSpec(block, categoryList, gradeList)
        };
    }

    /**
     * 获取用于在列表中显示的荒料标题
     * @param block 荒料对象
     * @param categoryList 石材种类列表
     */
    public getBlockTitle(
        block: IBlockForListDTO,
        categoryList: IIdNameItem[]
    ): string {
        if (!block) {
            return "";
        }

        let title = block.blockNumber;
        const cateName = Util.getItemName(block.categoryId, categoryList);
        if (cateName) {
            title += " | " + cateName;
        }

        return title;
    }

    /**
     * 获取用于在列表中显示的荒料规格文字
     * @param block 荒料对象
     * @param categoryList 石材种类列表
     * @param gradeList 石材等级列表
     */
    public getBlockSpec(
        block: IBlockForListDTO | IBlockDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ): string {
        if (!block) {
            return "";
        }

        let text = "";
        const gradeName = Util.getItemName(block.gradeId, gradeList);

        if (gradeName) {
            text += gradeName + " | ";
        }

        if (
            block.status === 5 ||
            (block.validatedLength === null ||
                block.validatedWidth === null ||
                block.validatedHeight === null)
        ) {
            text += this.getVolumeSpecText(
                block.quarryReportedLength,
                block.quarryReportedWidth,
                block.quarryReportedHeight
            );
        } else {
            text += this.getVolumeSpecText(
                block.validatedLength,
                block.validatedWidth,
                block.validatedHeight
            );
        }
        return text;
    }

    public getBlockSpecWithShowStockingArea(
        block: IBlockForListDTO | IBlockDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ): JSX.Element {
        const showStockingArea = [
            BlockStatus.InStock,
            BlockStatus.Purchased,
            BlockStatus.Reserved
        ].includes(block.status);
        return (
            <div>
                <div>{this.getBlockSpec(block, categoryList, gradeList)}</div>
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    block.stockingAreaId
                )}
            </div>
        );
    }

    /**
     * 获取按照设置中的显示顺序排列好的荒料宽和高输入控件的数组
     * @param widthField 荒料宽输入控件
     * @param heightField 荒料高输入控件
     */
    public getWidthHeightFieldsInOrder(
        widthField: React.ReactNode,
        heightField: React.ReactNode
    ): React.ReactNode[] {
        const order = this.getDimensionDisplayOrder();
        if (order === SettingsDefinition.BlockDimensionLengthHeightWidth) {
            return [heightField, widthField];
        } else {
            return [widthField, heightField];
        }
    }

    public getBlockSize(
        block: IBlockDTO | IBlockWithBundlesSlabsDTO | IBlockForListDTO,
        type: BlockDimensionType
    ): IBlockSize {
        let length: number;
        let width: number;
        let height: number;
        switch (type) {
            case BlockDimensionType.QuarryReported:
                length = block.quarryReportedLength;
                width = block.quarryReportedWidth;
                height = block.quarryReportedHeight;
                break;
            case BlockDimensionType.Validated:
                length = block.validatedLength;
                width = block.validatedWidth;
                height = block.validatedHeight;
                break;
            case BlockDimensionType.Trimmed:
                length = block.trimmedLength;
                width = block.trimmedWidth;
                height = block.trimmedHeight;
                break;
        }

        return { length, width, height };
    }

    public calculateTrimmingTotalCutCount(
        creviceBrimCuts: number,
        conventionalCuts: number,
        dilatedCut: number,
        thickenCuts: number,
        creviceHeadCuts: number
    ): number {
        if (
            !creviceBrimCuts &&
            !conventionalCuts &&
            !dilatedCut &&
            !thickenCuts &&
            !creviceHeadCuts
        ) {
            return 0;
        }
        return (
            creviceBrimCuts +
            conventionalCuts +
            dilatedCut +
            thickenCuts +
            creviceHeadCuts
        );
    }

    public getBTRListItem(
        item: IBlockTrimmingRecordListDTO,
        onClickFunc: (item: IBlockTrimmingRecordListDTO) => void,
        showStatus: boolean = true
    ) {
        const description = (
            <div>
                <div>操作员：{item.operatorName}</div>
                <div>修边完成日期：{DateUtil.formatDate(item.endTime)}</div>
            </div>
        );

        const text = item.onlySplittedBlock
            ? "对剖"
            : item.totalCutCount + "刀";
        const title = (
            <span>
                {item.blockNumber + " | " + text}
                {showStatus ? " | " : null}
                {showStatus ? this.getApprovalStatusState(item.status) : null}
            </span>
        );

        return (
            <ListItem
                key={item.id}
                item={item}
                title={title}
                avatarTitle={item.totalCutCount.toString()}
                description={description}
                onClick={onClickFunc}
            />
        );
    }

    public getApprovalStatusState(status: number): JSX.Element {
        const mrStateName = this.getStatusText(status);
        let className = "";
        switch (status) {
            case 10:
                className = "notStartedWO";
                break;
            case 20:
                className = "sawingWO";
                break;
            case 30:
                className = "trimmingWO";
                break;
            case 40:
                className = "abnormalSO";
                break;
        }
        return <span className={className}>{mrStateName}</span>;
    }

    public getStatusText(status: number) {
        return Consts.ApprovalStatus.getState(status).text;
    }

    private getDimensionDisplayOrder(): BlockDimensionDisplayOrder {
        return Settings.getValue(
            SettingsDefinition.BlockDimensionDisplayOrderKey
        ) as BlockDimensionDisplayOrder;
    }
}

export default new BlockUtil();
