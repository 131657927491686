import { observer } from "mobx-react";
import React from "react";
import { Button } from "saltui";
import Store from "./store";

export interface IESButtonProps {
    className?: string;
    children: JSX.Element | string;
    type?: string;
    onClick: () => void;
    disabled?: boolean; // 用于界面控制Button是否可用
}

export interface IESButtonState {}

@observer
class ESButton extends React.Component<IESButtonProps, IESButtonState> {
    public static defaultProps: IESButtonProps;
    constructor(props: IESButtonProps) {
        super(props);
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const { className, children, disabled } = t.props;
        let { type } = t.props;
        type = type ? type : "primary";
        const buttonDisabled = Store.buttonDisabled;

        return (
            <Button
                type={type}
                onClick={t.handleSubmitChange}
                disabled={buttonDisabled || disabled}
                className={className}
            >
                {children}
            </Button>
        );
    }

    private handleSubmitChange = () => {
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }
    };
}

ESButton.defaultProps = {
    className: "",
    children: null,
    type: null,
    onClick: () => {},
    disabled: false
};

export default ESButton;
