import * as React from "react";
import { Group } from "saltui";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ESPickerField from "../ESPickerField";
import ESTextField from "../ESTextField";
import FilterButtonGroup from "../FilterButtonGroup";
import Settings from "../SettingInfo";
import SettingsDefinition from "../SettingsDefinition";
import Util from "../Util";
const {
    Customers,
    SalesOrderStatusOptions,
    SalesOrderType,
    SalesOrderReservationTypeOptions
} = Consts;

declare interface ISalesOrderListFilterProps
    extends IFilterProps<ISOListFilter> {
    allSaleStatus?: boolean;
}

export interface ISOListFilter {
    selectCreator?: string;
    selectOrderNumber?: string;
    selectProjectName?: string;
    selectCustomerOptions?: IOption[];
    selectSalesStatusOptions?: IOption[];
    selectedSalesTypeOptions?: IOption[];
    selectedType?: number;
    selectedReservationTypeOptions?: IOption[];
}

declare interface ISalesOrderListFilterState extends ISOListFilter {
    statusOptions: IOptionWithPhonetic[];
    salesOrderTypeOptions: IOptionWithPhonetic[];
    customerOptions: IOptionWithPhonetic[];
    typeList: IOptionWithPhonetic[];
    reservationTypeOptions: IOptionWithPhonetic[];
    enableReservedSOCancellation: boolean;
}

class SalesOrderListFilter extends React.Component<
    ISalesOrderListFilterProps,
    ISalesOrderListFilterState
> {
    public static defaultProps: ISalesOrderListFilterProps;
    constructor(props: ISalesOrderListFilterProps) {
        super(props);
        this.state = {
            statusOptions:
                this.props.allSaleStatus === true
                    ? Util.getOptionsWithPhoneticByOptionArr(
                          SalesOrderStatusOptions.data
                      )
                    : Util.getOptionsWithPhoneticByOptionArr(
                          SalesOrderStatusOptions.approvalData
                      ),
            salesOrderTypeOptions: Util.getOptionsWithPhoneticByOptionArr(
                SalesOrderType.data
            ),
            customerOptions: [],
            typeList: Util.getOptionsWithPhoneticByOptionArr([
                {
                    value: 0,
                    text: "内部订单"
                },
                {
                    value: 1,
                    text: "客户订单"
                },
                {
                    value: 2,
                    text: "全部"
                }
            ]),
            reservationTypeOptions: Util.getOptionsWithPhoneticByOptionArr(
                SalesOrderReservationTypeOptions.data
            ),
            enableReservedSOCancellation: Settings.getBoolValue(
                SettingsDefinition.EnableReservedSOCancellationKey
            ),
            ...props.filterData
        };
    }

    public componentWillReceiveProps(nextProps: ISalesOrderListFilterProps) {
        if (nextProps.filterData !== this.props.filterData) {
            this.setState({ ...nextProps.filterData });
        }
    }

    public async componentDidMount() {
        const customers = await Customers.getCustomers();
        const customerOptions = Util.getOptionsWithPhoneticForUI(customers);
        this.setState({ customerOptions });
    }

    public render() {
        const t = this;
        const s = t.state;
        const {
            selectCreator,
            selectOrderNumber,
            selectProjectName,
            selectCustomerOptions,
            selectSalesStatusOptions,
            selectedSalesTypeOptions,
            selectedType,
            selectedReservationTypeOptions,
            enableReservedSOCancellation
        } = s;

        let selectedTypeOption = null;

        if (Util.isDefinedAndNotNull(selectedType)) {
            selectedTypeOption = s.typeList.find(type => {
                return type.value === selectedType;
            });
        }
        const {
            onExpansionChanged,
            expanded,
            allSaleStatus,
            ...other
        } = t.props;

        return (
            <Collapsible
                label={"筛选条件"}
                onExpansionChanged={onExpansionChanged}
                expanded={expanded}
            >
                <Group.List itemIndent={[15, 15]}>
                    <ESTextField
                        label="创建人"
                        value={selectCreator}
                        onChange={t.handleSelectCreatorChange}
                        placeholder="请输入创建人"
                    />
                    <ESTextField
                        label="订单编号"
                        value={selectOrderNumber}
                        onChange={t.handleSelectOrderNumberChange}
                        placeholder="请输入订单编号"
                    />
                    <ESTextField
                        label="工程名称"
                        onChange={t.handleSelectProjectNameChange}
                        value={selectProjectName}
                        placeholder="请输入工程名称"
                    />
                    <ESPickerField
                        label="客户名称"
                        options={s.customerOptions}
                        onSelect={t.handleSelectCustomerChange}
                        value={selectCustomerOptions}
                        placeholder="请选择客户名称"
                        multiple={true}
                    />
                    <ESPickerField
                        label="订单状态"
                        options={s.statusOptions}
                        onSelect={t.handleSelectSalesStatusChange}
                        value={selectSalesStatusOptions}
                        placeholder="请选择订单状态"
                        multiple={true}
                    />
                    <ESPickerField
                        label="销售类型"
                        options={s.salesOrderTypeOptions}
                        onSelect={t.handleSelectSalesTypeChange}
                        value={selectedSalesTypeOptions}
                        placeholder="请选择销售类型"
                        multiple={true}
                    />
                    <ESPickerField
                        label="订单类型"
                        options={s.typeList}
                        onSelect={t.handleSelectTypeChange}
                        value={selectedTypeOption}
                        placeholder="请选择订单类型"
                        groupingIndicator={false}
                        grouping={false}
                    />
                    {enableReservedSOCancellation ? (
                        <ESPickerField
                            label="冻结类型"
                            options={s.reservationTypeOptions}
                            onSelect={t.handleSelectReservationTypeChange}
                            value={selectedReservationTypeOptions}
                            placeholder="冻结类型"
                            multiple={true}
                        />
                    ) : null}
                    <FilterButtonGroup
                        onSearch={t.handleSearch}
                        onClearData={t.handleClearData}
                    />
                </Group.List>
            </Collapsible>
        );
    }

    private handleSelectCreatorChange = (value: string) => {
        this.setState({ selectCreator: value });
    };

    private handleSelectOrderNumberChange = (value: string) => {
        this.setState({ selectOrderNumber: value });
    };

    private handleSelectProjectNameChange = (value: string) => {
        this.setState({ selectProjectName: value });
    };

    private handleSelectSalesStatusChange = (value: IOption[]) => {
        this.setState({ selectSalesStatusOptions: value });
    };
    private handleSelectCustomerChange = (value: IOption[]) => {
        this.setState({ selectCustomerOptions: value });
    };
    private handleSelectSalesTypeChange = (value: IOption[]) => {
        this.setState({ selectedSalesTypeOptions: value });
    };
    private handleSelectReservationTypeChange = (value: IOption[]) => {
        this.setState({ selectedReservationTypeOptions: value });
    };
    private handleSelectTypeChange = (value: IOption) => {
        this.setState({ selectedType: value.value as number });
    };

    private handleSearch = () => {
        const t = this;
        const s = t.state;
        const p: ISOListFilter = {
            selectSalesStatusOptions: s.selectSalesStatusOptions,
            selectCreator: s.selectCreator,
            selectOrderNumber: s.selectOrderNumber,
            selectedType: s.selectedType,
            selectCustomerOptions: s.selectCustomerOptions,
            selectProjectName: s.selectProjectName,
            selectedSalesTypeOptions: s.selectedSalesTypeOptions,
            selectedReservationTypeOptions: s.selectedReservationTypeOptions
        };

        const { onSearch } = t.props;
        if (onSearch) {
            onSearch(p);
        }
    };

    private handleClearData = () => {
        const t = this;
        const p: ISOListFilter = {
            selectSalesStatusOptions: [],
            selectCreator: undefined,
            selectOrderNumber: undefined,
            selectedType: 2,
            selectCustomerOptions: [],
            selectProjectName: undefined,
            selectedSalesTypeOptions: []
        };

        t.setState(p);

        const { onClear } = t.props;
        if (onClear) {
            onClear(p);
        }
    };
}

export default SalesOrderListFilter;
