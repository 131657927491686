import React from "react";
import UserInfo from "./UserInfo";

import { Route } from "react-router-dom";

export default ({ component: Component, ...rest }) => {
    if (!UserInfo.loggedIn()) {
        return <div>未授权，请联系管理员</div>;
    }

    return <Route component={Component} {...rest} />;
};
