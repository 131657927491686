import Hammer from "hammerjs";
import PropTypes from "prop-types";
import React from "react";
import { Button, Mask } from "saltui";
import cloudStorage from "../CloudStorage";
import ESIcon from "../ESIcon";
import { ImageSize } from "../type";
import "./ImageViewer.styl";

declare interface IImageViewerProps extends React.Props<ImageViewer> {
    visible: boolean;
    imageFileName: string;
    onHide: (imageFileName: string, visible: boolean) => void;
}

declare interface IImageViewerState {
    visible: boolean;
    smallSizeImageUrl: string;
    fullSizeImageUrl: string;
}

class ImageViewer extends React.Component<
    IImageViewerProps,
    IImageViewerState
> {
    public static defaultProps: IImageViewerProps;

    private lastScale: number = 1; // 放大倍数
    private lastDeltaX: number = 0; // 图片移动X轴的偏移量
    private lastDeltaY: number = 0; // 图片移动Y轴偏移量
    private lastEndDeltaX: number = 0; // 图片停留位置时X轴偏移量
    private lastEndDeltaY: number = 0; // 图片停留时位置Y轴偏移量
    private minScale: number = 0;
    private imageBox: HTMLDivElement;
    private mc: Hammer;
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            smallSizeImageUrl: null,
            fullSizeImageUrl: null
        };
    }

    public async componentDidMount() {
        const imageUrl = await cloudStorage.getCloudStorageAccessUrl(
            this.props.imageFileName,
            ImageSize.SmallSize
        );
        this.setState({ smallSizeImageUrl: imageUrl });
    }

    public componentWillUnmount() {
        this.removeHammer();
    }

    public render() {
        const { smallSizeImageUrl } = this.state;
        const { visible } = this.props;

        if (!smallSizeImageUrl) {
            return null;
        }

        return (
            <div className="popup-body">
                <Mask
                    className="popup-mask"
                    opacity={1}
                    visible={visible}
                    closeable={true}
                />
                {visible ? (
                    <div>
                        <div className="popup-cancel" onClick={this.onHide}>
                            <ESIcon
                                name={"icon-cancel"}
                                className="icon-cancel"
                                height={40}
                                width={40}
                            />
                        </div>
                        <div
                            className="popup-view"
                            ref={c => {
                                this.imageBox = c;
                            }}
                        >
                            <img
                                className="popup-image"
                                src={smallSizeImageUrl}
                                onLoad={this.initLoadImage}
                            />
                        </div>
                        <div>
                            <Button
                                type="primary"
                                className="popup-button"
                                onClick={this.onViewFullSize}
                            >
                                查看原图
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    private bindHammer() {
        const visible = this.props.visible;
        if (!visible) {
            return;
        }
        const pinch = new Hammer.Pinch();
        const myElement = this.imageBox;
        this.mc = new Hammer.Manager(myElement);
        let scale = 1;
        this.mc.add([pinch]);
        this.mc.add(
            new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 })
        );
        // 开始缩放
        this.mc.on("pinchmove", ev => {
            scale = ev.scale * this.lastScale;
            const winW = window.innerWidth;
            const winH = window.innerHeight;
            const imgObj = this.imageBox.firstChild as HTMLImageElement;
            const imageWidth = imgObj.clientWidth;
            const imageHeight = imgObj.clientHeight;
            const W = scale * imageWidth;
            const H = scale * imageHeight;
            // 缩放时，图片宽高小于屏幕宽高的1/3时 不能再缩小
            if (W < winW / 3 || H < winH / 3) {
                if (this.minScale === 0) {
                    this.minScale = scale;
                }
            }

            if (scale < this.minScale) {
                scale = this.minScale;
            } else if (scale > 4) {
                scale = 4;
            }
            this.imageBox.style.webkitTransition = "none";
            this.imageBox.style.webkitTransform = `scale(${scale},${scale})`;
        });

        // 缩放结束
        this.mc.on("pinchend", () => {
            this.lastScale = scale;
        });

        this.mc.on("panmove", ev => {
            const lastScale = this.lastScale;
            let deltaX = Math.round(ev.deltaX / lastScale);
            let deltaY = Math.round(ev.deltaY / lastScale);
            // 图片上一次的偏移量
            const lastDeltaX = this.lastDeltaX;
            const lastDeltaY = this.lastDeltaY;
            // 本次相对于上次的偏移量
            // tslint:disable-next-line:variable-name
            const current_deltaX = deltaX - lastDeltaX;
            // tslint:disable-next-line:variable-name
            const current_deltaY = deltaY - lastDeltaY;
            // 图片上一次的停留位置
            const lastEndDeltaX = this.lastEndDeltaX;
            const lastEndDeltaY = this.lastEndDeltaY;

            const winW = window.innerWidth;
            const winH = window.innerHeight;
            const imgObj = this.imageBox.firstChild as HTMLImageElement;
            const imageWidth = imgObj.clientWidth;
            const imageHeight = imgObj.clientHeight;
            const W = lastScale * imageWidth;
            const H = lastScale * imageHeight;
            // 如果图片缩放后的大小比屏幕高宽都小的话，不能移动图片
            if (W < winW && H < winH) {
                return;
            }
            /**
             * 图片移动时，上下左右均不能超出屏幕边界，以免出现白边
             * 能够移动的最大距离 图片的宽-屏幕的宽 除 放大的倍数scale*2
             * 图片横纵坐标的偏移量相加 不能超过边界值
             */
            let maxTranslateX = (W - winW) / (2 * lastScale);
            let maxTranslateY = (H - winH) / (2 * lastScale);
            // 图片缩放后宽度小于屏幕宽度，不允许横向移动
            if (W < winW) {
                deltaX = 0;
                maxTranslateX = 0;
            }
            // 图片缩放后高度小于屏幕宽度，不允许纵向移动
            if (H < winH) {
                deltaY = 0;
                maxTranslateY = 0;
            }
            // 最后停留的位置
            let endDeltaX = lastEndDeltaX + current_deltaX;
            let endDeltaY = lastEndDeltaY + current_deltaY;
            // X正向超出边界(向右移动)
            if (endDeltaX > maxTranslateX) {
                deltaX = maxTranslateX - lastEndDeltaX;
                endDeltaX = maxTranslateX;
            }
            // X反向超出边界(向左移动)
            if (endDeltaX < -maxTranslateX) {
                deltaX = -(maxTranslateX + lastEndDeltaX);
                endDeltaX = -maxTranslateX;
            }
            // Y正向超出边界(向下)
            if (endDeltaY > maxTranslateY) {
                deltaY = maxTranslateY - lastEndDeltaY;
                endDeltaY = maxTranslateY;
            }
            // Y反向超出边界(向上)
            if (endDeltaY < -maxTranslateY) {
                deltaY = -(maxTranslateY + lastEndDeltaY);
                endDeltaY = -maxTranslateY;
            }
            // 记录图片本次偏移量以及停留位置
            this.lastDeltaX = deltaX;
            this.lastDeltaY = deltaY;
            this.lastEndDeltaX = endDeltaX;
            this.lastEndDeltaY = endDeltaY;

            this.imageBox.style.webkitTransform =
                "scale(" +
                lastScale +
                "," +
                lastScale +
                ") translate(" +
                endDeltaX +
                "px, " +
                endDeltaY +
                "px)";
        });

        this.mc.on("panend", ev => {
            // deltaX deltaY是在滑动过程中不断变化的，若停止滑动，再次滑动时，将从0开始计数，为使得停止滑动后，再次滑动时的相对偏移量准确，所以停止滑动时，将deltaX deltaY设置为0
            this.lastDeltaX = 0;
            this.lastDeltaY = 0;
        });
    }

    private removeHammer() {
        if (!this.mc) {
            return;
        }
        this.mc.off("pinchmove");
        this.mc.off("pinchend");
        this.mc.off("panmove");
        this.mc.off("panend");
        this.mc = null;
    }

    private onViewFullSize = async () => {
        const t = this;
        const s = t.state;
        let fullSizeImageUrl: string = s.fullSizeImageUrl;
        if (!fullSizeImageUrl) {
            fullSizeImageUrl = await cloudStorage.getCloudStorageAccessUrl(
                t.props.imageFileName,
                ImageSize.FullSize
            );
            t.setState({ fullSizeImageUrl });
        }
        dd.ready(() => {
            dd.biz.util.previewImage({
                urls: [fullSizeImageUrl], // 图片地址列表
                current: fullSizeImageUrl // 当前显示的图片链接
            });
        });
    };

    private reset() {
        this.lastDeltaX = 0;
        this.lastDeltaY = 0;
        this.lastScale = 1;
        this.lastEndDeltaX = 0;
        this.lastEndDeltaY = 0;
        this.imageBox.style.webkitTransform = "scale(1,1) translate(0px, 0px)";
    }

    private onHide = () => {
        const imageFileName = null;
        const visible = false;
        this.reset();

        this.props.onHide(imageFileName, visible);
    };

    private initLoadImage = () => {
        this.bindHammer();
        const winW = window.innerWidth;
        const winH = window.innerHeight;
        const imgObj = this.imageBox.firstChild as HTMLImageElement;
        const imageWidth = imgObj.clientWidth;
        const imageHeight = imgObj.clientHeight;

        if (imageWidth <= winW && imageHeight <= winH) {
            return;
        }
        let scale = 1;
        let widthScale = 0;
        let heightScale = 0;
        // 图片初始加载时，进行缩放适应手机屏幕大小
        if (imageWidth > winW) {
            widthScale = (imageWidth - winW) / winW;
        }
        if (imageHeight > winH) {
            heightScale = (imageHeight - winH) / winH;
        }

        scale =
            widthScale > heightScale ? winW / imageWidth : winH / imageHeight;
        this.imageBox.style.webkitTransition = "none";
        this.imageBox.style.webkitTransform = `scale(${scale},${scale})`;
        this.lastScale = scale;
    };
}

ImageViewer.defaultProps = {
    visible: false,
    imageFileName: null,
    onHide: () => {}
};

export default ImageViewer;
