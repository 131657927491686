import * as React from "react";
import {
    IMachineryDTO,
    IStoneCategoryDTO,
    IWorkOrderForListDTO,
    IWorkOrderFullInfoDTO
} from "../app/WebAPIClients";
import Consts from "./Consts";
import ListItemWithPriority from "./ListItemWithPriority";
import Util from "./Util";

const { WorkOrderType, ManufacturingState } = Consts;
class WorkOrderUtil {
    public getTitle(wo: IWorkOrderForListDTO): JSX.Element {
        if (!wo) {
            return null;
        }

        let title = wo.blockNumber ? wo.blockNumber : "尚未领料";

        const materialSpec = wo.materialSpec;
        if (
            materialSpec &&
            typeof materialSpec.thickness !== "undefined" &&
            materialSpec.thickness !== null &&
            materialSpec.thickness.toString() !== ""
        ) {
            title += " | 厚：" + materialSpec.thickness;
        }
        return (
            <div>
                <span>{title + " | "}</span>
                {this.getManufacturingState(wo)}
            </div>
        );
    }

    public getDescription(
        wo: IWorkOrderForListDTO,
        categoryList: IStoneCategoryDTO[]
    ) {
        let desc = "";

        const type = this.getWorkOrderType(wo);
        desc += type !== null ? type : "";

        const materialSpec = wo.materialSpec;
        const area = Util.getWOArea(wo);
        const title =
            wo.orderType === 10 ||
            wo.manufacturingState <= 35 ||
            wo.manufacturingState === 80 ||
            wo.blockDiscarded
                ? ""
                : " | 面积：" + area;

        let category = "";

        if (materialSpec) {
            category = Util.getItemName(materialSpec.categoryId, categoryList);
            desc += " | " + category;
        }
        desc += " | " + wo.orderNumber;
        desc += title;

        return {
            description: desc,
            category: Util.getPreAvatarText(category, 2)
        };
    }

    public getWorkOrderType(
        wo: IWorkOrderForListDTO | IWorkOrderFullInfoDTO
    ): string {
        let type: string = null;
        if (!wo) {
            return type;
        }

        if (
            wo.manufacturingState === 90 &&
            wo.endState &&
            wo.orderType !== 10
        ) {
            type = wo.endState >= 60 ? "光板" : "毛板";
        } else {
            const woType = WorkOrderType.getOrderType(wo.orderType);
            if (woType) {
                type = woType.text;
            }
        }

        return type;
    }

    public getWorkOrderJSX(
        wo: IWorkOrderForListDTO,
        categoryList: IStoneCategoryDTO[],
        factoryOptions: IOption[],
        onClickFunc: (item) => void
    ): JSX.Element {
        const woDescription = this.getDescription(wo, categoryList);
        // 若工厂存在多个，avatarTitle显示工厂名称；若只有一个，显示品名
        const avatarTitle =
            factoryOptions.length > 1 ? wo.factoryName : woDescription.category;
        return (
            <ListItemWithPriority
                key={wo.id}
                item={wo}
                avatarTitle={avatarTitle}
                title={this.getTitle(wo)}
                description={woDescription.description}
                priority={wo.priority}
                onClick={onClickFunc}
            />
        );
    }

    public getMachineryName(
        machineryId: number,
        factoryOptions: IOption[],
        machineries: IMachineryDTO[]
    ): string {
        if (
            !(
                machineryId &&
                factoryOptions &&
                machineries &&
                factoryOptions.length > 0 &&
                machineries.length > 0
            )
        ) {
            return;
        }

        let name = "";
        const item = machineries.find(i => i.id === machineryId);
        if (item) {
            name = item.typeName + "-" + item.name;
        }

        if (factoryOptions.length === 1) {
            return name;
        }

        const factoryId: number = machineries.find(m => m.id === machineryId)
            .factoryId;

        if (factoryId) {
            const factoryName = Util.getOption(factoryOptions, factoryId).text;
            name = factoryName + "-" + name;
        }

        return name;
    }

    private getManufacturingState(wo: IWorkOrderForListDTO): JSX.Element {
        const woStateName: string = ManufacturingState.getState(
            wo.manufacturingState
        ).text;
        let className: string = "";
        switch (wo.manufacturingState) {
            case 10:
                className = "notStartedWO";
                break;
            case 15:
            case 20:
                className = "materialRequisitionWO";
                break;
            case 25:
            case 30:
                className = "trimmingWO";
                break;
            case 35:
            case 43:
                className = "sawingWO";
                break;
            case 40:
                className = "determineMP";
                break;
            case 45:
            case 50:
                className = "fillingWO";
                break;
            case 55:
            case 60:
            case 70:
            case 90:
                className = "polishingWO";
                break;
            case 80:
                className = "cancelledWO";
                break;
        }
        return <span className={className}>{woStateName}</span>;
    }
}

export default new WorkOrderUtil();
