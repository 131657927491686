import * as React from "react";
import { Boxs, Timeline } from "saltui";
import {
    IAppliedProcessDTO,
    IBlockTrimmingRecordDTO,
    IMachineryDTO
} from "../../app/WebAPIClients";
import BlockUtil from "../BlockUtil";
import Consts from "../Consts";
import DateUtil from "../DateUtil";
import ESIcon from "../ESIcon";
import Util from "../Util";
import WorkOrderUtil from "../WorkOrderUtil";
const HBox = Boxs.HBox;
const Box = Boxs.Box;
const { ProcessConst } = Consts;

export interface ITrimmingRecordInfoProps {
    blockTrimmingRecords: IBlockTrimmingRecordDTO[];
    allowDeletion?: boolean;
    factoryOptions: IOption[];
    machineries: IMachineryDTO[];
    className?: string;
    onDelete?: (item: IBlockTrimmingRecordDTO) => void;
}

class TrimmingRecordInfo extends React.Component<ITrimmingRecordInfoProps, {}> {
    public static defaultProps: ITrimmingRecordInfoProps;

    public render() {
        const t = this;

        const {
            blockTrimmingRecords,
            factoryOptions,
            machineries,
            className,
            allowDeletion,
            onDelete
        } = t.props;

        return (
            <div>
                {Util.isNotNullAndNotEmptyArray(blockTrimmingRecords) ? (
                    <Timeline className={className}>
                        {blockTrimmingRecords.map((item, index) => {
                            const title =
                                DateUtil.formatDateTime(item.createdTime) +
                                " | " +
                                item.operatorName;
                            const description = this.getBlockTrimmingDescription(
                                item,
                                factoryOptions,
                                machineries
                            );

                            if (item !== null) {
                                return (
                                    <Timeline.Item
                                        key={item}
                                        active={index === 0}
                                        description={description}
                                    >
                                        <HBox flex={1}>
                                            <Box
                                                className="listItemContentArea"
                                                flex={1}
                                            >
                                                {title}
                                            </Box>
                                            {allowDeletion &&
                                            item.workOrderId === null ? (
                                                <Box>
                                                    <div
                                                        // tslint:disable-next-line:jsx-no-lambda
                                                        onClick={e => {
                                                            if (onDelete) {
                                                                onDelete(item);
                                                            }

                                                            if (e) {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    >
                                                        <ESIcon
                                                            name={"icon-delete"}
                                                            className="icon-is iconField"
                                                            height={20}
                                                            width={20}
                                                        />
                                                    </div>
                                                </Box>
                                            ) : null}
                                        </HBox>
                                    </Timeline.Item>
                                );
                            }
                        })}
                    </Timeline>
                ) : (
                    <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                        没有修边记录
                    </div>
                )}
            </div>
        );
    }

    private getBlockTrimmingDescription(
        item: IBlockTrimmingRecordDTO,
        factoryOptions: IOption[],
        machineries: IMachineryDTO[]
    ): JSX.Element {
        if (!item) {
            return <span />;
        }

        // 如果这组修边工艺中存在多个工艺，则默认取的第一个工艺不能是对剖工艺，因为对剖工艺上的extraInfo信息中没有修边尺寸信息
        const appliedProcess: IAppliedProcessDTO =
            Util.isNotNullAndNotEmptyArray(item.appliedProcesses) &&
            item.appliedProcesses.length > 1
                ? item.appliedProcesses.filter(
                      a => a.name !== ProcessConst.trimmingProcessName
                  )[0]
                : item.appliedProcesses[0];

        // 如果存在对剖工艺，若是荒料对剖，不能删除数据
        const splittedBlock = item.appliedProcesses.some(
            a => a.name === ProcessConst.trimmingProcessName
        );

        // 由于修边尺寸和体积是存放在appliedProcess.extraInfo中的，这里反序列化获取其对应值
        const extraInfo = Util.isNotNullAndNotEmpty(appliedProcess.extraInfo)
            ? JSON.parse(appliedProcess.extraInfo)
            : null;

        const totalCuts = item.totalCutCount;
        return (
            <div>
                <div>
                    {"开始时间：" +
                        DateUtil.formatDateTime(item.startTime, false)}
                </div>
                <div>
                    {"结束时间：" +
                        DateUtil.formatDateTime(item.endTime, false)}
                </div>
                {Util.isDefinedAndNotNull(item.duration) ? (
                    <div>
                        {"修边耗时：" + Util.getTimeConsuming(item.duration)}
                    </div>
                ) : null}
                {Util.isDefinedAndNotNull(item.machineryId) ? (
                    <div>
                        {"修边机械：" +
                            WorkOrderUtil.getMachineryName(
                                item.machineryId,
                                factoryOptions,
                                machineries
                            )}
                    </div>
                ) : null}
                <div>是否对剖：{splittedBlock ? "是" : "否"}</div>
                {Util.isDefinedAndNotNull(extraInfo && extraInfo.l) &&
                Util.isDefinedAndNotNull(extraInfo && extraInfo.w) &&
                Util.isDefinedAndNotNull(extraInfo && extraInfo.h) ? (
                    <div>
                        {"修边尺寸：" +
                            BlockUtil.getBlockDimension(
                                extraInfo.l,
                                extraInfo.w,
                                extraInfo.h
                            )}
                    </div>
                ) : null}
                {Util.isDefinedAndNotNull(extraInfo && extraInfo.v) ? (
                    <div>{"完成体积：" + extraInfo.v + " 立方"}</div>
                ) : null}
                {item.appliedProcesses
                    ? this.getAppliedProcessesJSX(item.appliedProcesses)
                    : null}
                {totalCuts > 0 ? (
                    <div>{"总计：" + totalCuts + " 刀"}</div>
                ) : null}
                <div>{"数据来源：" + this.getSourceName(item)}</div>
                {Util.isNotNullAndNotEmpty(item.notes) ? (
                    <div>{"备注：" + item.notes}</div>
                ) : null}
            </div>
        );
    }

    private getAppliedProcessesJSX(
        appliedProcesses: IAppliedProcessDTO[]
    ): JSX.Element {
        if (!Util.isNotNullAndNotEmptyArray(appliedProcesses)) {
            return <span />;
        }
        return (
            <div>
                {appliedProcesses.map(p => {
                    return (
                        <div key={p.id}>{`${p.name}：${p.quantity} ${
                            p.unit
                        }`}</div>
                    );
                })}
            </div>
        );
    }

    private getSourceName(item: IBlockTrimmingRecordDTO): string {
        if (!item) {
            return "";
        }

        return Util.isDefinedAndNotNull(item.workOrderId) ? "工单" : "荒料";
    }
}

TrimmingRecordInfo.defaultProps = {
    blockTrimmingRecords: null,
    factoryOptions: [],
    machineries: [],
    className: "trimmingSelectorTimeLineStyl",
    onDelete: null,
    allowDeletion: false
};

export default TrimmingRecordInfo;
