import PropTypes from "prop-types";
import React from "react";
import { TextField } from "saltui";

declare interface IESTextFieldProps extends React.Props<ESTextField> {
    value: string | number;
    [propertyName: string]: any;
}

class ESTextField extends React.Component<IESTextFieldProps, {}> {
    public static defaultProps: IESTextFieldProps;

    public render(): JSX.Element {
        const t = this;

        const { value, ...other } = t.props;

        const valueText =
            typeof value === "number"
                ? value.toString()
                : value === null
                    ? ""
                    : value;

        return <TextField value={valueText} {...other} />;
    }
}

ESTextField.defaultProps = {
    value: ""
};

export default ESTextField;
