import React from "react";
import { IPagingInfo } from "../../app/WebAPIClients";
import ESScroller from "../ESScroller";
import ListFooter from "../ListFooter";
import Util from "../Util";

declare interface IPagedListProps {
    className?: string;
    children?: JSX.Element;
    pagingInfo?: IPagingInfo;
    showTotalRecordCount?: boolean;
    footer?: JSX.Element;
    loadMoreDataActionFun: any;
    dataType: string;
    error?: boolean;
    showDataLoadingMessage?: boolean;
    parameter?: any;
}

class PagedList extends React.PureComponent<IPagedListProps>
    implements IScroller {
    public static defaultProps: IPagedListProps;
    public scroller: any;

    public render(): JSX.Element {
        const t = this;
        const {
            className,
            children,
            footer,
            showTotalRecordCount,
            pagingInfo: pi,
            showDataLoadingMessage,
            ...other
        } = t.props;

        const scrollerCssClass = className
            ? "scroller " + className
            : "scroller";
        const totalRecordCount = pi ? pi.totalRecordCount : 0;
        const noMoreData = pi ? pi.noMoreData : false;

        const footerJSX = footer ? (
            footer
        ) : (
            <ListFooter totalRecordCount={totalRecordCount} />
        );
        return (
            <div>
                <ESScroller
                    className={scrollerCssClass}
                    noMoreData={noMoreData}
                    showDataLoadingMessage={showDataLoadingMessage}
                    {...other}
                >
                    {children}
                </ESScroller>
                {showTotalRecordCount &&
                pi &&
                Util.isDefinedAndNotNull(pi.totalRecordCount)
                    ? footerJSX
                    : null}
            </div>
        );
    }
}

PagedList.defaultProps = {
    className: "",
    children: null,
    pagingInfo: null,
    footer: null,
    showTotalRecordCount: true,
    showDataLoadingMessage: true,
    error: false,
    loadMoreDataActionFun: null,
    dataType: "",
    parameter: null
};

export default PagedList;
