import React from "react";
import { Boxs, SelectField } from "saltui";
import {
    BlockTilePlanningLengthSource,
    BlockTilePlanningSizeType,
    IBlockTilePlanningSizeDTO
} from "../../app/WebAPIClients";

const { Box, HBox, VBox } = Boxs;

interface IBlockTilePlanningSizes {
    planningTileLength?: number;
    planningTileWidth?: number;
}

interface IBlockTilePlanningSizesFieldProps {
    source?: BlockTilePlanningLengthSource;
    data: IBlockTilePlanningSizes;
    tileSizes: IBlockTilePlanningSizeDTO[];
    onChange?: (data: IBlockTilePlanningSizes) => void;
}

function BlockTilePlanningSizesField(props: IBlockTilePlanningSizesFieldProps) {
    const { source, data, tileSizes, onChange } = props;

    if (tileSizes === null || tileSizes.length === 0) {
        return null;
    }

    const blockLengthAsTileLengthSizes = tileSizes.filter(
        s => s.type === BlockTilePlanningSizeType.BlockLengthAsTileLength
    );
    const blockWidthAsTileLengthSizes = tileSizes.filter(
        s => s.type === BlockTilePlanningSizeType.BlockWidthAsTileLength
    );
    const tileWidthSizes = tileSizes.filter(
        s => s.type === BlockTilePlanningSizeType.BlockHeightAsTileWidth
    );

    let tileLengthSizeOptions: IOption[] = [];

    if (source === null) {
        const lengthSizes1 = blockLengthAsTileLengthSizes.map(s => s.size);
        const lengthSizes2 = blockWidthAsTileLengthSizes.map(s => s.size);
        tileLengthSizeOptions = [
            ...new Set([...lengthSizes1, ...lengthSizes2])
        ].map(s => {
            return { value: s, text: s.toString() };
        });
    } else {
        const lengthSizes = (source ===
        BlockTilePlanningLengthSource.BlockLength
            ? blockLengthAsTileLengthSizes
            : blockWidthAsTileLengthSizes
        ).map(s => s.size);
        tileLengthSizeOptions = lengthSizes.map(s => {
            return { value: s, text: s.toString() };
        });
    }

    const tileWidthSizeOptions: IOption[] = tileWidthSizes.map(s => {
        return { value: s.size, text: s.size.toString() };
    });
    tileLengthSizeOptions.unshift({ value: 0, text: "不指定长度" });
    tileWidthSizeOptions.unshift({ value: 0, text: "不指定宽度" });

    const selectedLengthOption =
        data.planningTileLength === null
            ? null
            : tileLengthSizeOptions.find(
                  o => o.value === data.planningTileLength
              );
    const selectedWidthOption =
        data.planningTileWidth === null
            ? null
            : tileWidthSizeOptions.find(
                  o => o.value === data.planningTileWidth
              );

    const onDataChange: (length?: number, width?: number) => void = (
        length,
        width
    ) => {
        if (onChange) {
            onChange({ planningTileLength: length, planningTileWidth: width });
        }
    };

    const onLengthSelect: (opt: IOption) => void = opt => {
        const length: number | null =
            opt === null || opt.value === 0 ? null : (opt.value as number);
        onDataChange(length, data.planningTileWidth);
    };

    const onWidthSelect: (opt: IOption) => void = opt => {
        const width: number | null =
            opt === null || opt.value === 0 ? null : (opt.value as number);
        onDataChange(data.planningTileLength, width);
    };

    return (
        <VBox>
            <Box>
                <SelectField
                    label="规划长度"
                    options={tileLengthSizeOptions}
                    onSelect={onLengthSelect}
                    value={selectedLengthOption}
                    placeholder="规格板长度"
                    required={true}
                    tip="请同时提供规划长度和宽度，否则方案不完整"
                />
            </Box>
            <Box>
                <SelectField
                    label="规划宽度"
                    options={tileWidthSizeOptions}
                    onSelect={onWidthSelect}
                    value={selectedWidthOption}
                    placeholder="规格板宽度"
                    required={true}
                    tip="请同时提供规划长度和宽度，否则方案不完整"
                />
            </Box>
        </VBox>
    );
}

export default BlockTilePlanningSizesField;
