import React from "react";
import ESIcon from "../ESIcon";
import ListItem from "../ListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";

declare interface IListItemWithPriorityProps extends IListItemBaseProps {
    priority?: number;
}

class ListItemWithPriority extends React.PureComponent<
    IListItemWithPriorityProps
> {
    public static defaultProps: IListItemWithPriorityProps;
    public render() {
        const t = this;
        const { priority, ...others } = t.props;

        let icon: JSX.Element = null;
        let iconName: string = null;

        if (priority === 3) {
            iconName = "icon-showExtraUrgentPriority";
        } else if (priority === 2) {
            iconName = "icon-showUrgentPriority";
        }

        if (iconName) {
            icon = (
                <ESIcon
                    name={iconName}
                    fill="#d81e06"
                    className="icon-is iconField"
                    height={20}
                    width={20}
                />
            );
        }

        return <ListItem icon={icon} {...others} />;
    }
}

ListItemWithPriority.defaultProps = {
    item: null,
    avatarTitle: "",
    title: null,
    description: null,
    priority: null,
    onClick: item => {}
};

export default ListItemWithPriority;
