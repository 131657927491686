import React, { useEffect, useState } from "react";
import { ICostPriceSpec } from "../type";
import Util from "../Util";

interface IAmountInputProps {
    value: number;
    item: ICostPriceSpec;
    onChange?: (item: ICostPriceSpec, value: number) => void;
}

function AmountInput(props: IAmountInputProps) {
    const { value, item, onChange } = props;
    const valueTxt = value === null ? "" : value.toString();
    const [amount, setAmount] = useState(valueTxt);

    useEffect(
        () => {
            setAmount(valueTxt);
        },
        [item]
    );

    const onValueChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        setAmount(e.target.value);
    };

    const onBlur: React.ChangeEventHandler<HTMLInputElement> = e => {
        const amountTxt = Util.toFixedTowDecimal(e.target.value);
        // 金额只保留两位小数
        const amount = Util.parseToFloat(amountTxt);
        console.log(item);
        console.log("value:", value);
        console.log("amount:", amount);
        if (onChange && amount !== value) {
            onChange(item, amount);
        }

        if (e) {
            e.stopPropagation();
        }
    };

    return (
        <input
            type="number"
            min="0"
            step="0.1"
            name="amountInput"
            onBlur={onBlur}
            onChange={onValueChange}
            className={"unitPriceTextBox"}
            value={amount}
        />
    );
}

export default AmountInput;
