import { action, observable } from "mobx";

class Store {
    @observable public scrollerDisabled: boolean = false;

    @action
    public clearData = () => {
        this.scrollerDisabled = false;
    };

    @action
    public updateState = () => {
        this.scrollerDisabled = true;
    };
}

export default new Store();
