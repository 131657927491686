import { action, observable } from "mobx";

class Store {
    @observable public buttonDisabled: boolean = false;

    @action
    public clearData = () => {
        this.buttonDisabled = false;
    };

    @action
    public updateState = () => {
        this.buttonDisabled = true;
    };
}

export default new Store();
