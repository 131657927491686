import React from "react";
import { ITileDTO } from "../../app/WebAPIClients";
import ExpandableListItem from "../ExpandableListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";
import TileUtil from "../TileUtil";
import { ITileAreaCountByAreaNameWithTileForUI } from "../type";

declare interface INonPackedTileListItemProps extends IListItemBaseProps {
    nonPackedTile?: ITileAreaCountByAreaNameWithTileForUI;
    onExpansionChange?: (item: object, expanded: boolean) => void;
    onChildClick?: (tile: ITileDTO) => void;
}

class NonPackedTileListItem extends React.PureComponent<
    INonPackedTileListItemProps
> {
    public static defaultProps: INonPackedTileListItemProps;
    public render() {
        const t = this;

        const { nonPackedTile, onClick, onExpansionChange, ...other } = t.props;

        return (
            <div>
                <ExpandableListItem
                    item={nonPackedTile}
                    avatarTitle={nonPackedTile.areaName}
                    title={nonPackedTile.areaName}
                    description={TileUtil.getNoPackedTileText(nonPackedTile)}
                    onClick={onClick}
                    onExpansionChange={onExpansionChange}
                    content={t.getChildren()}
                />
            </div>
        );
    }

    private getChildren() {
        const t = this;
        const children = [];

        const { nonPackedTile, onChildClick } = t.props;

        if (nonPackedTile.tiles && nonPackedTile.tiles.length > 0) {
            TileUtil.getTilesGroupBy(nonPackedTile.tiles).forEach(tile => {
                const newChild = {
                    item: tile,
                    avatarTitle: tile.tileNumber.toString(),
                    title: TileUtil.getTileTitle(tile),
                    description: TileUtil.getTileTitleDescription(tile),
                    onClick: onChildClick,
                    selected: false
                };
                children.push(newChild);
            });
        }
        return children;
    }
}

NonPackedTileListItem.defaultProps = {
    nonPackedTile: null,
    onClick: item => {},
    onChildClick: tile => {},
    onExpansionChange: (item, expanded) => {}
};

export default NonPackedTileListItem;
