import React from "react";
import Perm from "../../app/Perm";
import {
    IBundleWithSlabsForListDTO,
    ISlabCheckOutContentCreationViewModel,
    SlabBundleStatus
} from "../../app/WebAPIClients";
import ExpandableListItem from "../ExpandableListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";
import PermCtrl from "../PermCtrl";
import ReferencePrice from "../ReferencePrice";
import SlabUtil from "../SlabUtil";
import StockingAreaUtil from "../StockingAreaUtil";
import StoneBundleUtil from "../StoneBundleUtil";
import Util from "../Util";
import { Boxs } from "saltui";
const HBox = Boxs.HBox;
const Box = Boxs.Box;

declare interface IStoneBundleListItemProps
    extends Pick<
            IListItemBaseProps,
            Exclude<keyof IListItemBaseProps, "onSelect">
        > {
    stoneBundle?: IBundleWithSlabsForListDTO;
    details?: ISlabCheckOutContentCreationViewModel[];
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
    slabAllowSelection?: boolean;
    onExpansionChange?: (
        item: IBundleWithSlabsForListDTO,
        expanded: boolean
    ) => void;
    onSelect?: (
        bundleSlabSelection: ISlabCheckOutContentCreationViewModel[]
    ) => void;
    showSlabNotes?: boolean;
    showUnitPrice?: boolean;
}

declare interface IStoneBundleListItemState {
    showBundleGrades: boolean;
}

class StoneBundleListItem extends React.PureComponent<
    IStoneBundleListItemProps,
    IStoneBundleListItemState
> {
    constructor(props) {
        super(props);
        this.state = {
            showBundleGrades: PermCtrl.isAuthorized(Perm.SB_G_R)
        };
    }
    public static defaultProps: IStoneBundleListItemProps;
    public render() {
        const t = this;

        const {
            stoneBundle,
            allowSelection,
            details,
            onClick,
            onSelect,
            onExpansionChange,
            title,
            description,
            categoryList,
            gradeList,
            showUnitPrice,
            ...other
        } = t.props;

        if (!stoneBundle) {
            return null;
        }

        const { showBundleGrades } = t.state;

        const avatarTitle = Util.getBundleAvatarText(stoneBundle);

        // 判断是否传入title和description，若传入，则显示title; 若不传入，显示默认的title和description
        const newTitle =
            title !== null
                ? title
                : StoneBundleUtil.getBundleTitleWithCountFromSlabs(stoneBundle);
        let newDescription =
            description !== null
                ? description
                : StoneBundleUtil.getBundleSpecWithAreaFromSlabs(
                      stoneBundle,
                      categoryList,
                      gradeList,
                      showBundleGrades
                  );
        let selectionClickEvent = (item: object) => {};
        let onClickFun = onClick;
        let selected: SelectionStatus = false;
        if (allowSelection) {
            selectionClickEvent = t.handleBundleSelection;
            onClickFun = t.handleBundleSelection;
            selected = t.getBundleSelection(stoneBundle);
        }

        const showStockingArea = [
            SlabBundleStatus.InStock,
            SlabBundleStatus.ReservedBySlabCheckOutRequest
        ].includes(stoneBundle.status);
        newDescription = (
            <div>
                {newDescription}
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    stoneBundle.stockingAreaId
                )}
                {showUnitPrice ? (
                    <div className="referencePriceArea">
                        <HBox>
                            <Box className="referencePriceLabel">单价</Box>
                            <Box>
                                <ReferencePrice
                                    referencePrice={stoneBundle.unitPrice}
                                />
                            </Box>
                        </HBox>
                    </div>
                ) : null}
            </div>
        );

        return (
            <ExpandableListItem
                item={stoneBundle}
                avatarTitle={avatarTitle}
                title={newTitle}
                description={newDescription}
                onClick={onClickFun}
                content={t.getChildren()}
                onExpansionChange={onExpansionChange}
                onSelect={selectionClickEvent}
                allowSelection={allowSelection}
                selected={selected}
                {...other}
            />
        );
    }

    private getSelectedSlabState(slabItem) {
        const t = this;
        let slabSelected = false;
        const { details } = t.props;
        if (typeof details === "undefined" || details === null) {
            return slabSelected;
        }
        const detail = details.find(b => {
            return b.bundleId === slabItem.bundleId;
        });

        if (detail) {
            slabSelected = detail.slabIds.some(d => {
                return d === slabItem.id;
            });
        }

        return slabSelected;
    }

    // 选择光板
    private handleSlabSelection = slab => {
        const t = this;
        const { stoneBundle: bundle } = t.props;

        const slabId = slab.id;
        if (!slabId) {
            return;
        }

        const { details, onSelect } = t.props;
        let newDetails = [...details];

        // 该片板所在扎是否在被选中
        const bundleSelected = details.some(d => {
            return d.bundleId === slab.bundleId;
        });
        // 如果扎没被选中
        if (!bundleSelected) {
            // 判断该扎板是否只有一片板，如果只有一片板，allSlabSelected=true。
            const allSlabSelected = bundle.slabs.length > 1 ? false : true;
            const detail = {
                bundleId: slab.bundleId,
                allSlabSelected,
                slabIds: [slabId]
            };
            newDetails.push(detail);
        }
        // 如果扎被选中
        else {
            const detail = newDetails.find(b => {
                return b.bundleId === slab.bundleId;
            });
            // 判断该块板是否被选中
            const slabSelected = detail.slabIds.some(s => {
                return s === slabId;
            });
            if (slabSelected) {
                if (detail.slabIds.length > 1) {
                    // 如果该扎选中有多片板，将该片板移除
                    detail.allSlabSelected = false;
                    const slabIds = detail.slabIds.filter(d => {
                        return d !== slabId;
                    });
                    detail.slabIds = slabIds;
                } else {
                    // 如果只有一片板，将该扎移除
                    newDetails = details.filter(d => {
                        return d.bundleId !== slab.bundleId;
                    });
                }
            } else {
                // 如果没被选中，添加该片板
                detail.slabIds.push(slabId);
                // 如果该扎下的所有在库存状态板被选中，如果完全选中，把allSlabSeleted设为true
                if (detail.slabIds.length === bundle.slabs.length) {
                    detail.allSlabSelected = true;
                }
            }
        }

        if (onSelect) {
            onSelect(newDetails);
        }

        if (event) {
            event.stopPropagation();
        }
    };

    // 选择扎
    private handleBundleSelection = item => {
        const t = this;
        const s = t.state;

        const bundleId = item.id;
        if (!bundleId) {
            return;
        }

        const { details, onSelect } = t.props;

        let newDetails = [...details];

        const bundleSelected = details.some(d => {
            return d.bundleId === bundleId;
        });

        if (!bundleSelected) {
            // 没有被选中，添加扎
            newDetails = t.addBundleDetail(item, newDetails);
        } else {
            // 已经被选中
            const selectedDetail = details.find(d => {
                return d.bundleId === bundleId;
            });
            newDetails = details.filter(d => {
                return d.bundleId !== bundleId;
            });
            // 如果该扎下的大板没有被全选，添加整扎
            if (!selectedDetail.allSlabSelected) {
                newDetails = t.addBundleDetail(item, newDetails);
            }
        }

        if (onSelect) {
            onSelect(newDetails);
        }

        if (event) {
            event.stopPropagation();
        }
    };

    // 添加扎
    private addBundleDetail(item, newDetails) {
        const t = this;
        const detail = {
            bundleId: null,
            allSlabSelected: null,
            slabIds: []
        };

        detail.bundleId = item.id;
        detail.allSlabSelected = true;
        detail.slabIds = item.slabs
            ? item.slabs.map(slabItem => {
                  return slabItem.id;
              })
            : [];
        newDetails.push(detail);
        return newDetails;
    }

    // 获取扎的是否选中颜色
    private getBundleSelection(item): SelectionStatus {
        const t = this;
        let selectedFlag: SelectionStatus = false;
        let detail = null;
        let slabs = [];

        const { details } = t.props;

        if (typeof details === "undefined" || details === null) {
            return selectedFlag;
        }

        if (item.id) {
            detail = details.find(b => {
                return b.bundleId === item.id;
            });
            slabs = item.slabs ? item.slabs : [];
        }

        // 扎下面的大板被全部选中
        if (detail && detail.allSlabSelected) {
            selectedFlag = "all";
        }
        // 扎下面的大板被部分选中
        else if (
            detail &&
            detail.slabIds.length > 0 &&
            detail.slabIds.length < slabs.length
        ) {
            selectedFlag = "partially";
        }
        // 扎下面的大板全部没有选中
        else {
            selectedFlag = "none";
        }
        return selectedFlag;
    }

    private getChildren() {
        const t = this;
        const children = [];

        const {
            stoneBundle,
            allowSelection,
            slabAllowSelection,
            categoryList,
            gradeList,
            showSlabNotes,
            showUnitPrice
        } = t.props;

        // 调用此控件，若传入slabAllowSelection，则用此标志位控制大板是否允许选择; 若不传入slabAllowSelection，则大板是否允许选择，用allowSelection标志位控制
        const slabAllowSelected =
            slabAllowSelection === null ? allowSelection : slabAllowSelection;

        if (stoneBundle.slabs && stoneBundle.slabs.length > 0) {
            Util.getSlabGroupBy(stoneBundle.slabs).forEach(slab => {
                let slabDescription: string | JSX.Element =
                    slab.bundlePrefix === slab.blockNumber
                        ? SlabUtil.getSlabSpec(slab, gradeList)
                        : SlabUtil.getSlabInfoSpec(
                              slab,
                              categoryList,
                              gradeList
                          );

                // 显示每片大板的备注信息
                if (showSlabNotes) {
                    const slabNotes = SlabUtil.getSlabNotes(slab);
                    if (Util.isNotNullAndNotEmpty(slabNotes)) {
                        slabDescription = (
                            <div>
                                <div>{slabDescription}</div>
                                <div>{slabNotes}</div>
                            </div>
                        );
                    }
                }

                let newChild: IListItemBaseProps = {
                    item: slab,
                    avatarTitle: slab.sequenceNumber.toString(),
                    title: SlabUtil.getSlabTitle(slab, showUnitPrice),
                    description: slabDescription,
                    allowSelection: slabAllowSelected
                };

                if (slabAllowSelected) {
                    newChild = {
                        ...newChild,
                        selected: t.getSelectedSlabState(slab),
                        onSelect: t.handleSlabSelection,
                        onClick: t.handleSlabSelection
                    };
                } else {
                    newChild = {
                        ...newChild,
                        selected: false,
                        onSelect: () => {},
                        onClick: () => {}
                    };
                }

                children.push(newChild);
            });
        }
        return children;
    }
}

StoneBundleListItem.defaultProps = {
    stoneBundle: null,
    details: [],
    onClick: () => {},
    onSelect: () => {},
    onExpansionChange: () => {},
    categoryList: [],
    gradeList: [],
    allowSelection: false,
    slabAllowSelection: null,
    title: null,
    description: null,
    showSlabNotes: false,
    showUnitPrice: false
};

export default StoneBundleListItem;
