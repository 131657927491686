import * as React from "react";
import { Group } from "saltui";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ESPickerField from "../ESPickerField";
import ESTextField from "../ESTextField";
import FilterButtonGroup from "../FilterButtonGroup";
import Settings from "../SettingInfo";
import SettingsDefinition from "../SettingsDefinition";
import Util from "../Util";
const { Customers, PaymentOrderStatusOptions } = Consts;

declare interface IPOFilterProps
    extends IFilterProps<IPaymentOrderFilterData> {}

export interface IPaymentOrderFilterData {
    selectedCustomerOptions?: IOption[];
    orderNumber?: string;
    selectedStatusOptions?: IOption[];
}

declare interface IPOFilterState extends IPaymentOrderFilterData {
    statusOptions: IOption[];
    customerOptions: IOptionWithPhonetic[];
}

class POListFilter extends React.Component<IPOFilterProps, IPOFilterState> {
    public static defaultProps: IPOFilterProps;
    constructor(props: IPOFilterProps) {
        super(props);
        this.state = {
            statusOptions: Util.getOptionsWithPhoneticByOptionArr(
                Settings.getBoolValue(
                    SettingsDefinition.EnableApprovalPOKey
                ) === true
                    ? PaymentOrderStatusOptions.dataWithApproval
                    : PaymentOrderStatusOptions.data
            ),
            customerOptions: [],
            ...props.filterData
        };
    }

    public componentWillReceiveProps(nextProps: IPOFilterProps) {
        if (nextProps.filterData !== this.props.filterData) {
            this.setState({ ...nextProps.filterData });
        }
    }

    public async componentDidMount() {
        const customers = await Customers.getCustomers();
        const customerOptions = Util.getOptionsWithPhoneticForUI(customers);
        this.setState({ customerOptions });
    }

    public render() {
        const t = this;
        const s = t.state;
        const {
            onExpansionChanged,
            expanded,
            allowCustomerChange,
            showStatusSelector,
            ...other
        } = t.props;

        return (
            <Collapsible
                label={"筛选条件"}
                onExpansionChanged={onExpansionChanged}
                expanded={expanded}
            >
                <Group.List itemIndent={[15, 15]}>
                    <ESTextField
                        label="结算单号"
                        value={s.orderNumber}
                        onChange={t.handleOrderNumberChange}
                        placeholder="请输入结算单号"
                    />
                    <ESPickerField
                        label="客户名称"
                        options={s.customerOptions}
                        onSelect={t.handleCustomerChange}
                        readOnly={!allowCustomerChange}
                        value={s.selectedCustomerOptions}
                        placeholder="请选择客户"
                        multiple={allowCustomerChange}
                    />
                    {showStatusSelector ? (
                        <ESPickerField
                            label="状态"
                            options={s.statusOptions}
                            onSelect={t.handleSelectStatusChange}
                            value={s.selectedStatusOptions}
                            placeholder="请选择状态"
                            multiple={true}
                        />
                    ) : null}
                    <FilterButtonGroup
                        onSearch={t.handleSearch}
                        onClearData={t.handleClearData}
                    />
                </Group.List>
            </Collapsible>
        );
    }

    private handleSearch = () => {
        const t = this;
        const s = t.state;
        const p: IPaymentOrderFilterData = {
            selectedStatusOptions: s.selectedStatusOptions,
            orderNumber: s.orderNumber,
            selectedCustomerOptions: s.selectedCustomerOptions
        };

        const { onSearch } = t.props;
        if (onSearch) {
            onSearch(p);
        }
    };

    private handleClearData = () => {
        const t = this;
        const { allowCustomerChange } = t.props;
        const p: IPaymentOrderFilterData = {
            selectedStatusOptions: [],
            orderNumber: undefined,
            selectedCustomerOptions: allowCustomerChange
                ? []
                : t.state.selectedCustomerOptions
        };

        t.setState(p);

        const { onClear } = t.props;
        if (onClear) {
            onClear(p);
        }
    };

    private handleSelectStatusChange = (value: IOption[]) => {
        const t = this;
        t.setState({ selectedStatusOptions: value });
    };

    private handleOrderNumberChange = (value: string) => {
        this.setState({ orderNumber: value });
    };

    private handleCustomerChange = (value: IOption[]) => {
        this.setState({ selectedCustomerOptions: value });
    };
}

export default POListFilter;
