import React, { useEffect, useState } from "react";
import { Table } from "saltui";
import {
    BlockTilePlanningSizeType,
    IBlockTilePlanningOutputRates,
    IBlockTilePlanningSizeDTO
} from "../../app/WebAPIClients";
import PercentageInput from "./PercentageInput";
import ESHeader from "../ESHeader";
import {
    IPlanningOutputRatesForBackend,
    ITilePlanningItemForUI
} from "./interfaces";
import PlanningRecordUtil from "./PlanningRecordUtil";

interface ITilePlanningItemsEditorProps {
    outputRates: IBlockTilePlanningOutputRates;
    tileSizes: IBlockTilePlanningSizeDTO[];
    onChange?: (outputRates: IBlockTilePlanningOutputRates) => void;
}

function TilePlanningItemsEditor(props: ITilePlanningItemsEditorProps) {
    const { outputRates, tileSizes, onChange } = props;
    const fontSize = "12px";
    const noDataComponent = (
        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">无数据</div>
    );

    if (
        outputRates === null &&
        (tileSizes === null || tileSizes.length === 0)
    ) {
        return noDataComponent;
    }

    const getSizesForUIFromPredefinedSizes: () => ITilePlanningItemForUI[] = () => {
        return tileSizes.map(s => {
            return { size: s.size, outputRate: null, type: s.type };
        });
    };

    const getSizesForUIFromSavedOutputRates: () => ITilePlanningItemForUI[] = () => {
        if (outputRates === null) {
            return [];
        }

        const blockLengthAsTileLengthSizes: ITilePlanningItemForUI[] =
            outputRates.blockLengthAsTileLengthOutputRate === null
                ? []
                : Object.entries(
                      outputRates.blockLengthAsTileLengthOutputRate
                  ).map<ITilePlanningItemForUI>(([size, rate]) => ({
                      size: Number(size),
                      outputRate: rate,
                      type: BlockTilePlanningSizeType.BlockLengthAsTileLength
                  }));
        const blockWidthAsTileLengthSizes: ITilePlanningItemForUI[] =
            outputRates.blockWidthAsTileLengthOutputRate === null
                ? []
                : Object.entries(
                      outputRates.blockWidthAsTileLengthOutputRate
                  ).map<ITilePlanningItemForUI>(([size, rate]) => ({
                      size: Number(size),
                      outputRate: rate,
                      type: BlockTilePlanningSizeType.BlockWidthAsTileLength
                  }));
        const blockHeightAsTileWidthSizes: ITilePlanningItemForUI[] =
            outputRates.blockHeightAsTileWidthOutputRate === null
                ? []
                : Object.entries(
                      outputRates.blockHeightAsTileWidthOutputRate
                  ).map<ITilePlanningItemForUI>(([size, rate]) => ({
                      size: Number(size),
                      outputRate: rate,
                      type: BlockTilePlanningSizeType.BlockHeightAsTileWidth
                  }));
        return [
            ...blockLengthAsTileLengthSizes,
            ...blockWidthAsTileLengthSizes,
            ...blockHeightAsTileWidthSizes
        ];
    };

    const getSizesForUI: () => ITilePlanningItemForUI[] = () => {
        const sizesFromPredefinedSizes = getSizesForUIFromPredefinedSizes();
        const sizesFromSavedOutputRates = getSizesForUIFromSavedOutputRates();

        // The UI displays a combined size list from the two arrays, the base is the pre-defined
        // types and sizes combinations with empty output rates, if the type and size combination
        // has a output rate from
        // the DB, use the saved output rate, if the combination is not in the pre-defined types
        // and sizes combinations but is
        // in the saved combination list from the DB, then add the size into the display size list
        // The following code merges the two array by using the unique type and size combination,
        // if the same combination exists in both arrays, use the object from the output rates array
        // (saved value in the db), otherwise, use the object from the sizes array
        // (predefined size with empty output rate)
        const mapA = sizesFromPredefinedSizes.reduce<{
            [key: string]: ITilePlanningItemForUI;
        }>(
            (acc, obj) => ({
                ...acc,
                [`${obj.type.toString()}-${obj.size.toString()}`]: obj
            }),
            {}
        );
        const mapB = sizesFromSavedOutputRates.reduce<{
            [key: string]: ITilePlanningItemForUI;
        }>(
            (acc, obj) => ({
                ...acc,
                [`${obj.type.toString()}-${obj.size.toString()}`]: obj
            }),
            {}
        );
        const mergedMap = { ...mapA, ...mapB };
        return Object.values(mergedMap);
    };

    const [sizesForUI, setSizesForUI] = useState(
        [] as ITilePlanningItemForUI[]
    );

    useEffect(
        () => {
            setSizesForUI(getSizesForUI());
        },
        [outputRates, tileSizes]
    );

    const convertSizesForBackend: (
        sizeType: BlockTilePlanningSizeType
    ) => IPlanningOutputRatesForBackend = sizeType => {
        const items = sizesForUI.filter(i => i.type === sizeType);
        return PlanningRecordUtil.convertTilePlanningItemsToOutputRates(items);
    };

    const onOutputRateChange: (
        item: ITilePlanningItemForUI,
        rate: number
    ) => void = (item, rate) => {
        const newSizesForUI = [...sizesForUI];
        const changedSize = newSizesForUI.find(
            i => i.type === item.type && i.size === item.size
        );
        if (!changedSize) {
            return;
        }

        changedSize.outputRate = rate;
        setSizesForUI(newSizesForUI);

        if (onChange) {
            const blockLengthAsTileLengthOutputRate = convertSizesForBackend(
                BlockTilePlanningSizeType.BlockLengthAsTileLength
            );
            const blockWidthAsTileLengthOutputRate = convertSizesForBackend(
                BlockTilePlanningSizeType.BlockWidthAsTileLength
            );
            const blockHeightAsTileWidthOutputRate = convertSizesForBackend(
                BlockTilePlanningSizeType.BlockHeightAsTileWidth
            );

            const newOutputRates: IBlockTilePlanningOutputRates = {
                blockLengthAsTileLengthOutputRate,
                blockWidthAsTileLengthOutputRate,
                blockHeightAsTileWidthOutputRate
            };

            onChange(newOutputRates);
        }
    };

    const columns = [
        {
            dataKey: "size",
            title: "尺寸",
            width: 0.5,
            align: "center",
            render: (cellData, rowData: ITilePlanningItemForUI) => {
                return rowData.size;
            }
        },
        {
            dataKey: "outputRate",
            title: "出材率",
            width: 0.5,
            align: "center",
            render: (cellData, rowData: ITilePlanningItemForUI) => {
                return (
                    <PercentageInput
                        item={rowData}
                        rate={rowData.outputRate}
                        onChange={onOutputRateChange}
                    />
                );
            }
        }
    ];

    return (
        <div>
            <ESHeader
                label={"荒料长度适配规格板长度出材率"}
                fontSize={fontSize}
            />
            <Table
                columns={columns}
                data={{
                    data: sizesForUI.filter(
                        s =>
                            s.type ===
                            BlockTilePlanningSizeType.BlockLengthAsTileLength
                    )
                }}
            />
            <ESHeader
                label={"荒料宽度适配规格板长度出材率"}
                fontSize={fontSize}
            />
            <Table
                columns={columns}
                data={{
                    data: sizesForUI.filter(
                        s =>
                            s.type ===
                            BlockTilePlanningSizeType.BlockWidthAsTileLength
                    )
                }}
            />
            <ESHeader
                label={"荒料高度适配规格板宽度出材率"}
                fontSize={fontSize}
            />
            <Table
                columns={columns}
                data={{
                    data: sizesForUI.filter(
                        s =>
                            s.type ===
                            BlockTilePlanningSizeType.BlockHeightAsTileWidth
                    )
                }}
            />
        </div>
    );
}

export default TilePlanningItemsEditor;
