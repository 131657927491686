import {
    IAddressBaseViewModel,
    IPublicShippingOrderDTO
} from "../app/WebAPIClients";
import Util from "./Util";

class AddressUtil {
    public getAddressInfo(addressGBCode: string, districtData: ICityData[]) {
        let addrTextArray: string[] = [];
        const addrCityArray: Array<Partial<ICityData>> = [];
        const found = this.getAddressText(
            addressGBCode,
            districtData,
            addrCityArray
        );

        if (found) {
            addrCityArray.reverse(); // 递归算法调用的时候把districtData中最底层的元素放在前面了，需要完全反转整个数组。
            addrTextArray = addrCityArray.map(a => a.label);
        }

        return addrTextArray;
    }

    public getCitySelectFieldValue(addressGBCode: string) {
        const addressGBCodes = [];
        if (Util.isNotNullAndNotEmpty(addressGBCode)) {
            addressGBCodes.push(
                addressGBCode.substr(0, 2) + "0000",
                addressGBCode.substr(0, 4) + "00",
                addressGBCode
            );
        }
        return addressGBCodes;
    }

    public getGBCode(citySelectFieldValue: string[]): string {
        if (!(citySelectFieldValue && citySelectFieldValue.length > 0)) {
            return null;
        }

        return [...citySelectFieldValue].pop();
    }

    public getAddressForDisplay(
        address: IAddressBaseViewModel | IPublicShippingOrderDTO
    ): string {
        let displayText = "";
        const { country, province, city, county, detailAddress } = address;
        const text = [country, province, city, county, detailAddress];
        text.forEach(item => {
            displayText += Util.isNotNullAndNotEmpty(item) ? item : "";
        });

        return displayText;
    }

    private getAddressText(
        addressGBCode: string,
        districtData: ICityData[],
        addressText: Array<Partial<ICityData>>
    ): boolean {
        for (const address of districtData) {
            if (address.children && address.children.length > 0) {
                const found = this.getAddressText(
                    addressGBCode,
                    address.children,
                    addressText
                );
                if (found) {
                    addressText.push({
                        value: address.value,
                        label: address.label,
                        phonetic: address.phonetic
                    });
                    return true;
                }
            } else if (address.value === addressGBCode) {
                addressText.push({
                    value: address.value,
                    label: address.label,
                    phonetic: address.phonetic
                });
                return true;
            }
        }

        return false;
    }
}

export default new AddressUtil();
