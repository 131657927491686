import React, { useEffect, useState } from "react";
import { Field, Group, SelectField } from "saltui";
import {
    BlockTilePlanningLengthSource,
    IBlockTilePlanningSizeDTO
} from "../../app/WebAPIClients";
import BlockTilePlanningLengthSourceField from "./BlockTilePlanningLengthSourceField";
import BlockTilePlanningSizesField from "./BlockTilePlanningSizesField";
import BlockTilePlanningOutputRatesField from "./BlockTilePlanningOutputRatesField";
import { IPlanningRecordFilterData } from "./interfaces";
import ESIcon from "../ESIcon";
import Picker from "../Picker/Picker";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import PlanningRecordUtil from "./PlanningRecordUtil";
import Util from "../Util";

interface IPlanningRecordFilterFieldProps {
    data: IPlanningRecordFilterData;
    tileSizes: IBlockTilePlanningSizeDTO[];
    onChange?: (data: IPlanningRecordFilterData) => void;
}

function PlanningRecordFilterField(props: IPlanningRecordFilterFieldProps) {
    const { data, tileSizes, onChange } = props;
    const [pickerVisible, setPickerVisible] = useState(false);
    const [outputRateOptionValue, setOutputRateOptionValue] = useState(0);

    useEffect(
        () => {
            let optionValue = 0;
            if (
                Util.isDefinedAndNotNull(data) &&
                Util.isDefinedAndNotNull(data.noTileOutputRates)
            ) {
                optionValue = data.noTileOutputRates ? 20 : 10;
            }
            setOutputRateOptionValue(optionValue);
        },
        [data]
    );

    const onPickerVisibleChange: (visible: boolean) => void = visible => {
        setPickerVisible(visible);
    };

    const onShowPickerIconClick: () => void = () => {
        setPickerVisible(true);
    };

    const onPickerSubmitClick: () => void = () => {
        window.history.back();
    };

    const onDataChange: (data: IPlanningRecordFilterData) => void = data => {
        if (onChange) {
            onChange(data);
        }
    };

    const onSourceChange: (
        planningTileLengthSource?: BlockTilePlanningLengthSource
    ) => void = planningTileLengthSource => {
        onDataChange({ ...data, planningTileLengthSource });
    };

    const onSizesChange: (
        data: { planningTileLength?: number; planningTileWidth?: number }
    ) => void = ({ planningTileLength, planningTileWidth }) => {
        onDataChange({ ...data, planningTileLength, planningTileWidth });
    };

    const onOutputRatesChange: (
        data: {
            planningTileMinOutputRate?: number;
            planningTileMaxOutputRate?: number;
        }
    ) => void = ({ planningTileMinOutputRate, planningTileMaxOutputRate }) => {
        onDataChange({
            ...data,
            planningTileMinOutputRate,
            planningTileMaxOutputRate
        });
    };

    const onClearClick: () => void = () => {
        onDataChange({
            noTileOutputRates: undefined,
            planningTileLength: undefined,
            planningTileLengthSource: undefined,
            planningTileMaxOutputRate: undefined,
            planningTileMinOutputRate: undefined,
            planningTileWidth: undefined
        });
    };

    const icon: JSX.Element = (
        <ESIcon
            name="icon-right"
            fill="rgba(31,56,88,.4)"
            onClick={onShowPickerIconClick}
            height={25}
            width={25}
        />
    );
    const isFilterDataEmpty = PlanningRecordUtil.isPlanningRecordFilterDataEmpty(
        data
    );
    const isFilterDataValid = PlanningRecordUtil.isPlanningRecordFilterDataValid(
        data
    );
    const descriptionClassName: string =
        !isFilterDataEmpty && isFilterDataValid
            ? null
            : "t-picker-field-placeholder";
    const description: string = isFilterDataEmpty
        ? "请选择规划方案"
        : isFilterDataValid
            ? PlanningRecordUtil.getPlanningRecordFilterDataDescription(data)
            : "方案参数不完整";

    const outputRateOptions: IOption[] = [
        { text: "请选择荒料有无成品出材率", value: 0 },
        { text: "荒料有成品板出材率", value: 10 },
        { text: "荒料无成品板出材率", value: 20 }
    ];

    const selectedOutputRateOption = outputRateOptions.find(
        o => o.value === outputRateOptionValue
    );

    const onSelect: (value: IOption) => void = value => {
        const optionValue = Util.isUndefinedOrNull(value)
            ? 0
            : (value.value as number);
        const noTileOutputRates: boolean | null =
            optionValue === 0 ? null : optionValue === 20;
        setOutputRateOptionValue(optionValue);
        onDataChange({ ...data, noTileOutputRates });
    };

    const hideFilterControls = outputRateOptionValue === 20;

    return (
        <Field label="规划方案" icon={icon}>
            <div
                className="t-picker-field-content"
                onClick={onShowPickerIconClick}
            >
                <span className={descriptionClassName}>{description}</span>
            </div>
            <Picker
                visible={pickerVisible}
                onVisibleChange={onPickerVisibleChange}
                className="t-picker-searchpanel-inner"
            >
                <Group>
                    <Group.List>
                        <SelectField
                            label="成品出材"
                            options={outputRateOptions}
                            onSelect={onSelect}
                            value={selectedOutputRateOption}
                            required={true}
                            tip="请选择在搜索荒料时要如何匹配对应的成品出材率，如果想搜索无成品出材率的荒料，请选择“荒料无成品板出材率”。如果选择“荒料有成品板出材率”，则必须填写成品板长、宽，以及出材率范围。"
                        />
                        {hideFilterControls ? null : (
                            <BlockTilePlanningLengthSourceField
                                source={data.planningTileLengthSource}
                                onChange={onSourceChange}
                            />
                        )}
                        {hideFilterControls ? null : (
                            <BlockTilePlanningSizesField
                                data={data}
                                tileSizes={tileSizes}
                                onChange={onSizesChange}
                            />
                        )}
                        {hideFilterControls ? null : (
                            <BlockTilePlanningOutputRatesField
                                data={data}
                                onChange={onOutputRatesChange}
                            />
                        )}
                        <ButtonGroup
                            onGoBack={onClearClick}
                            onSubmit={onPickerSubmitClick}
                            goBackButtonLabel="清除"
                        />
                    </Group.List>
                </Group>
            </Picker>
        </Field>
    );
}

export default PlanningRecordFilterField;
