import * as React from "react";
import { NoticeBar } from "saltui";
import Util from "../Util";

export interface IESNoticeBarProps {
    message: string;
    type: string;
    closable: boolean;
    className: string;
}

class ESNoticeBar extends React.Component<IESNoticeBarProps, null> {
    public static defaultProps: IESNoticeBarProps;
    constructor(props: IESNoticeBarProps) {
        super(props);
    }

    public render() {
        const t = this;
        const { message, type, closable, className } = t.props;
        if (!Util.isNotNullAndNotEmpty(message)) {
            return null;
        }

        return (
            <NoticeBar
                className={className}
                message={message}
                closable={closable}
                type={type}
            />
        );
    }
}

ESNoticeBar.defaultProps = {
    message: "",
    type: "info",
    closable: false,
    className: ""
};

export default ESNoticeBar;
