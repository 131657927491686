import React from "react";
import { Boxs } from "saltui";
import { IWarehouseDTO } from "../../app/WebAPIClients";
import Consts from "../Consts";
import StockingAreaUtil from "../StockingAreaUtil";
import Util from "../Util";

const { HBox, Box } = Boxs;

declare interface IStockingAreaItemProps {
    stockingAreaId: number;
}

declare interface IStockingAreaItemState {
    saList?: IIdNameItem[]; // 仓库区域列表
    warehouses?: IIdNameItem[]; // 仓库列表
    factories?: IIdNameItem[]; // 工厂列表
}

class StockingAreaItem extends React.PureComponent<
    IStockingAreaItemProps,
    IStockingAreaItemState
> {
    public static defaultProps: IStockingAreaItemProps;
    constructor(props) {
        super(props);
        this.state = {};
    }

    public async componentDidMount() {
        const factories = await Consts.Factories.getFactories(); // 工厂列表
        const warehouses: IWarehouseDTO[] = await Consts.Warehouses.getWarehouses(); // 仓库列表3.
        const psaList = await Consts.ProductStockingAreas.getStockingAreas();
        const bsaList = await Consts.BlockStockingAreas.getStockingAreas();

        this.setState({
            saList: [...psaList, ...bsaList],
            factories,
            warehouses
        });
    }

    public render() {
        const t = this;
        const s = t.state;

        const { stockingAreaId } = t.props;

        if (!Util.isDefinedAndNotNull(stockingAreaId)) {
            return null;
        }

        return (
            <HBox flex={1}>
                <Box>
                    <span className="unitStockingText">
                        {StockingAreaUtil.showStockingAreaText(
                            stockingAreaId,
                            s.saList,
                            s.factories,
                            s.warehouses
                        )}
                    </span>
                </Box>
            </HBox>
        );
    }
}

StockingAreaItem.defaultProps = {
    stockingAreaId: null
};

export default StockingAreaItem;
