import Util from "./Util";

class DateUtil {
    // 格式化日期：yyyy-MM-dd
    public static formatDate(date: Date): string {
        if (!Util.isDefinedAndNotNull(date)) {
            return null;
        }
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return year + "-" + this.completion(month) + "-" + this.completion(day);
    }

    // 格式化日期：yyyy-MM-dd HH:mm:ss
    public static formatDateTime(
        date: Date,
        showSecond: boolean = true
    ): string {
        if (!Util.isDefinedAndNotNull(date)) {
            return null;
        }

        const dateArray: string[] = [
            this.completion(date.getHours()),
            this.completion(date.getMinutes())
        ];

        if (showSecond) {
            dateArray.push(this.completion(date.getSeconds()));
        }

        return this.formatDate(date) + " " + dateArray.join(":");
    }

    // 获得指定季度的开始月份
    public static getQuarterStartMonth(month: number): number {
        return Math.floor(month / 3) * 3;
    }

    // 获得今日对应的星期的开始日期
    public static getCurrentWeekStartDate(): string {
        const today = new Date();
        return this.getWeekStartDate(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        );
    }

    // 获得今日对应的星期的结束日期
    public static getCurrentWeekEndDate(): string {
        const today = new Date();
        return this.getWeekEndDate(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        );
    }

    // 获得今日对应的月份的开始日期
    public static getCurrentMonthStartDate(): string {
        const today = new Date();
        return this.getMonthStartDate(today.getFullYear(), today.getMonth());
    }

    // 获得今日对应的月份的结束日期
    public static getCurrentMonthEndDate(): string {
        const today = new Date();
        return this.getMonthEndDate(today.getFullYear(), today.getMonth());
    }

    // 获得指定月的开始日期
    public static getMonthStartDate(year: number, month: number): string {
        const monthStartDate = new Date(year, month, 1);
        return this.formatDate(monthStartDate);
    }

    // 获得指定月的结束日期
    public static getMonthEndDate(year: number, month: number): string {
        const monthEndDate = new Date(year, month + 1, 0);
        return this.formatDate(monthEndDate);
    }

    // 获得当前月对应的季度的开始日期
    public static getCurrentQuarterStartDate(): string {
        const today = new Date();
        return this.getQuarterStartDate(today.getFullYear(), today.getMonth());
    }

    // 获得当前月对应的季度的结束日期
    public static getCurrentQuarterEndDate(): string {
        const today = new Date();
        return this.getQuarterEndDate(today.getFullYear(), today.getMonth());
    }

    // 获得指定月对应的季度的开始日期
    public static getQuarterStartDate(year: number, month: number): string {
        const quarterStartDate = new Date(
            year,
            this.getQuarterStartMonth(month),
            1
        );
        return this.formatDate(quarterStartDate);
    }

    // 获得指定月对应的季度的结束日期
    public static getQuarterEndDate(year: number, month: number): string {
        const quarterEndMonth = this.getQuarterStartMonth(month) + 2;
        const quarterStartDate = new Date(year, quarterEndMonth + 1, 0);
        return this.formatDate(quarterStartDate);
    }

    // 获得当前月对应的年度的开始日期
    public static getCurrentYearStartDate(): string {
        const today = new Date();
        return this.getYearStartOrEndDate(today.getFullYear(), 0, 1);
    }

    // 获得当前月对应的年度的结束日期
    public static getCurrentYearEndDate(): string {
        const today = new Date();
        return this.getYearStartOrEndDate(today.getFullYear(), 11, 31);
    }

    // 获得指定月对应的年度的开始日期
    public static getYearStartOrEndDate(
        year: number,
        month: number,
        day: number
    ): string {
        const date = new Date(year, month, day);
        return this.formatDate(date);
    }

    public static getValidDate(value: string | number): Date | undefined {
        if (!Util.isDefinedAndNotNull(value)) {
            return undefined;
        }

        const date = new Date(value);
        return date instanceof Date && !isNaN(date.getTime())
            ? date
            : undefined;
    }

    // 获得指定日期对应的周的开始日期
    private static getWeekStartDate(
        year: number,
        month: number,
        day: number
    ): string {
        const iDate = new Date(year, month, day);
        let iDayOfWeek = iDate.getDay();
        if (iDayOfWeek === 0) {
            iDayOfWeek = 7; // 把周日当作星期的最后一天来计算星期一，所以周日的dayOfWeek固定设置为7
        }

        const weekStartDate = new Date(year, month, day - iDayOfWeek + 1);
        return this.formatDate(weekStartDate);
    }

    // 获得指定日期对应的周的结束日期
    private static getWeekEndDate(
        year: number,
        month: number,
        day: number
    ): string {
        const iDate = new Date(year, month, day);
        let iDayOfWeek = iDate.getDay();
        if (iDayOfWeek === 0) {
            iDayOfWeek = 7; // 把周日当作星期的最后一天来计算星期一，所以周日的dayOfWeek固定设置为7
        }

        const weekEndDate = new Date(year, month, day + (7 - iDayOfWeek));
        return this.formatDate(weekEndDate);
    }

    private static completion(value: number): string {
        if (value < 10) {
            return "0" + value.toString();
        }
        return value.toString();
    }
}

export default DateUtil;
