import nattyStorage from "natty-storage";
import Util from "../Util";
import { SortInfo } from "../../app/WebAPIClients";

class BundleListSortingStore {
    private static cacheKey: string = "BundleListSortingFieldName";
    private static storage: any = null;

    private static getStorage() {
        if (this.storage === null) {
            this.storage = nattyStorage(
                Util.getCacheOption("BundleListSortingFieldName")
            );
        }
        return this.storage;
    }

    public static getSortingField(): BundleListSortingField {
        const cache = this.getStorage();
        const field = cache.get(this.cacheKey);
        return field !== "StockingArea.Name" ? null : "StockingArea.Name";
    }

    public static setSortingField(field: BundleListSortingField) {
        const cache = this.getStorage();
        const value: BundleListSortingField =
            field !== "StockingArea.Name" ? null : "StockingArea.Name";
        cache.set(this.cacheKey, value);
    }

    public static getSortInfoForAPICall(): SortInfo[] | undefined {
        const sortingFieldName = this.getSortingField();
        return sortingFieldName === null
            ? undefined
            : [SortInfo.fromJS({ field: sortingFieldName })];
    }
}

export default BundleListSortingStore;
