import React from "react";
import Perm from "../../app/Perm";
import { IBlockForListDTO } from "../../app/WebAPIClients";
import BlockUtil from "../BlockUtil";
import Consts from "../Consts";
import ListItem from "../ListItem";
import PermCtl from "../PermCtrl";
import ReferencePriceUtil from "../ReferencePriceUtil";
import Settings from "../SettingInfo";
import SettingsDefinition from "../SettingsDefinition";
import Util from "../Util";
const { StoneCategories, StoneGrades } = Consts;

export interface IBlockListItemProps {
    block: IBlockForListDTO;
    onClick?: (block: IBlockForListDTO) => void;
    onSelect?: (block: IBlockForListDTO) => void;
    allowSelection?: boolean;
    selected?: boolean;
    showReferencePrice?: boolean;
}

export interface IBlockListItemState {
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
    enableReferencePrice: boolean;
}

class BlockListItem extends React.PureComponent<
    IBlockListItemProps,
    IBlockListItemState
> {
    public static defaultProps: IBlockListItemProps;
    constructor(props) {
        super(props);
        this.state = {
            enableReferencePrice: Settings.getBoolValue(
                SettingsDefinition.EnableReferencePriceKey
            )
        };
    }

    public async componentDidMount() {
        const categoryList = await StoneCategories.getCategories();
        const gradeList = await StoneGrades.getGrades();
        this.setState({ categoryList, gradeList });
    }

    public render() {
        const t = this;
        const s = t.state;

        const {
            block,
            allowSelection,
            selected,
            showReferencePrice,
            onClick,
            onSelect
        } = t.props;

        const { categoryList } = s;

        const canViewAmount = PermCtl.isAuthorized(Perm.B_RP_R);
        const title = (
            <span>
                {BlockUtil.getBlockTitle(block, categoryList)}
                {!(
                    canViewAmount &&
                    s.enableReferencePrice &&
                    showReferencePrice
                )
                    ? " | "
                    : null}
                {!(
                    canViewAmount &&
                    s.enableReferencePrice &&
                    showReferencePrice
                )
                    ? BlockUtil.getBlockStatus(block.status)
                    : null}
            </span>
        );

        const description = ReferencePriceUtil.getDescriptionWithPriceForBlock(
            block,
            s.categoryList,
            s.gradeList,
            canViewAmount && s.enableReferencePrice && showReferencePrice
        );
        const avatarTitle = Util.getPreAvatarText(block.blockNumber);

        return (
            <ListItem
                key={block.id}
                item={block}
                avatarTitle={avatarTitle}
                title={title}
                description={description}
                onClick={onClick}
                allowSelection={allowSelection}
                selected={selected}
                onSelect={onSelect}
            />
        );
    }
}

BlockListItem.defaultProps = {
    block: null,
    onClick: (block: IBlockForListDTO): void => {},
    onSelect: (block: IBlockForListDTO): void => {},
    allowSelection: false,
    selected: false,
    showReferencePrice: true
};

export default BlockListItem;
