import nattyStorage from "natty-storage";
import ClientFactory from "../app/WebAPIClientFactory";
import {
  CustomerForListDTO,
  IAppliedPriceTypeDto,
  IBankAccountDto,
  ICustomerForListDTO,
  ICustomerGradeDTO,
  ICustomerTypeDTO,
  IFactoryDTO,
  IMachineryDTO,
  IQuarryDTO,
  IStockingAreaDTO,
  IStoneCategoryDTO,
  IStoneGradeDTO,
  IWarehouseDTO,
  MachineryUse,
  PaymentOrderStatus,
  WarehouseType,
} from "../app/WebAPIClients";
import Settings from "./SettingInfo";
import SettingsDefinition from "./SettingsDefinition";
import tenantInfo from "./TenantInfo";
import Util from "./Util";

class Helper {
  public static getTenantKey(suffix: string): string {
    return `${tenantInfo.getAbbreviationCode()}-${suffix}`;
  }
}

class WorkOrderType {
  public data: IOption[];

  constructor() {
    this.data = [
      {
        value: 10,
        text: "荒料",
      },
      {
        value: 20,
        text: "光板",
      },
      {
        value: 30,
        text: "毛板",
      },
      {
        value: 40,
        text: "工程板",
      },
    ];
  }

  public getOrderType(value: number) {
    return this.data.find((wot) => wot.value === value);
  }
}

class SalesOrderStatus {
  public data: IOption[];
  public approvalData: IOption[];
  public changeData: IOption[];
  constructor() {
    this.data = [
      {
        value: 5,
        text: "草稿",
      },
      {
        value: 10,
        text: "审批中",
      },
      {
        value: 20,
        text: "已批准",
      },
      {
        value: 30,
        text: "未通过审批",
      },
      {
        value: 40,
        text: "已完成",
      },
      {
        value: 50,
        text: "已取消",
      },
    ];
    this.approvalData = [
      {
        value: 20,
        text: "已批准",
      },
      {
        value: 30,
        text: "未通过审批",
      },
      {
        value: 40,
        text: "已完成",
      },
    ];
    this.changeData = [
      {
        value: 20,
        text: "已批准",
      },
      {
        value: 40,
        text: "已完成",
      },
    ];
  }

  public getStatus(value: number) {
    return this.data.find((sos) => sos.value === value);
  }

  public getChangeStatus(value: number) {
    return this.changeData.find((sos) => sos.value === value);
  }
}

class ShippingOrderStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "新建",
      },
      {
        value: 20,
        text: "等待财务核查",
      },
      {
        value: 30,
        text: "等待出厂核查",
      },
      {
        value: 40,
        text: "已出厂",
      },
      {
        value: 50,
        text: "已取消",
      },
    ];
  }

  public getStatus(value) {
    return this.data.find((sos) => sos.value === value);
  }
}

class SlabType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "毛板",
      },
      {
        value: 20,
        text: "光板",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}

class SlabBundleStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "在生产",
      },
      {
        value: 20,
        text: "在库存",
      },
      {
        value: 30,
        text: "被领出",
      },
      {
        value: 40,
        text: "已售出",
      },
      {
        value: 50,
        text: "销售冻结",
      },
      {
        value: 51,
        text: "领料冻结",
      },
      {
        value: 52,
        text: "退料冻结",
      },
      {
        value: 60,
        text: "已报废",
      },
    ];
  }

  public getStatus(value: number) {
    return this.data.find((sbs) => sbs.value === value);
  }
}

class BlockStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 5,
        text: "已收料",
      },
      {
        value: 10,
        text: "在库存",
      },
      {
        value: 20,
        text: "生产中",
      },
      {
        value: 30,
        text: "生产完成",
      },
      {
        value: 40,
        text: "已售出",
      },
      {
        value: 50,
        text: "冻结",
      },
      {
        value: 100,
        text: "已报废",
      },
    ];
  }

  public getStatus(value) {
    return this.data.find((b) => b.value === value);
  }
}

class SalesOrderType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "库存荒料",
      },
      // {
      //     value: 20,
      //     text: '无库存荒料'
      // },
      {
        value: 30,
        text: "库存大板",
      },
      {
        value: 40,
        text: "无库存大板",
      },
      // {
      //     value: 50,
      //     text: '无库存毛板'
      // }
      {
        value: 60,
        text: "工程板",
      },
      // {
      //     value: 70,
      //     text: '外来料纯加工'
      // },
      {
        value: 80,
        text: "销售荒料代加工",
      },
    ];
  }

  public getOrderType(value) {
    return this.data.find((sot) => sot.value === value);
  }
}

class ManufacturingState {
  public data: IOption[];
  public filterData: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "等待领料",
      },
      {
        value: 15,
        text: "领料审批",
      },
      {
        value: 20,
        text: "等待修边",
      },
      {
        value: 25,
        text: "等待修边质检",
      },
      {
        value: 30,
        text: "等待大切",
      },
      {
        value: 35,
        text: "等待大切分扎",
      },
      {
        value: 40,
        text: "等待决定工序",
      },
      {
        value: 43,
        text: "等待补胶",
      },
      {
        value: 45,
        text: "等待补胶质检",
      },
      {
        value: 50,
        text: "等待磨抛质检",
      },
      {
        value: 55,
        text: "等待磨抛确认",
      },
      {
        value: 70,
        text: "工程板生产完成",
      },
      {
        value: 80,
        text: "已取消",
      },
      {
        value: 90,
        text: "已完成",
      },
    ];
    this.filterData = [
      {
        value: 10,
        text: "等待领料",
      },
      {
        value: 15,
        text: "领料审批",
      },
      {
        value: 20,
        text: "等待修边",
      },
      {
        value: 25,
        text: "等待修边质检",
      },
      {
        value: 30,
        text: "等待大切",
      },
      {
        value: 35,
        text: "等待大切分扎",
      },
      {
        value: 40,
        text: "等待决定工序",
      },
      {
        value: 43,
        text: "等待补胶",
      },
      {
        value: 45,
        text: "等待补胶质检",
      },
      {
        value: 50,
        text: "等待磨抛质检",
      },
      {
        value: 55,
        text: "等待磨抛确认",
      },
      {
        value: 80,
        text: "已取消",
      },
      {
        value: 90,
        text: "已完成",
      },
    ];
  }

  public getState(value) {
    return this.data.find((ms) => ms.value === value);
  }
}
class ApprovalStatus {
  public data: IOption[];
  public approvalData: IOption[];
  public pendingApprovalData: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "审批中",
      },
      {
        value: 20,
        text: "审批不通过",
      },
      {
        value: 30,
        text: "已完成",
      },
      {
        value: 40,
        text: "已取消",
      },
    ];
    this.pendingApprovalData = [
      {
        value: 10,
        text: "审批中",
      },
    ];
    this.approvalData = [
      {
        value: 20,
        text: "审批不通过",
      },
      {
        value: 30,
        text: "已完成",
      },
      {
        value: 40,
        text: "已取消",
      },
    ];
  }

  public getState(value) {
    return this.data.find((ms) => ms.value === value);
  }
}
class TilePackingCaseStatus {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "规划完成",
      },
      {
        value: 20,
        text: "生产完成",
      },
      {
        value: 30,
        text: "在库存",
      },
      {
        value: 40,
        text: "冻结",
      },
      {
        value: 50,
        text: "已售出",
      },
      {
        value: 60,
        text: "已废弃",
      },
    ];
  }

  public getStatus(value) {
    return this.data.find((ms) => ms.value === value);
  }
}

class PriorityOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 1,
        text: "正常",
      },
      {
        value: 2,
        text: "加急",
      },
      {
        value: 3,
        text: "特急",
      },
    ];
  }

  public getPriority(value) {
    return this.data.find((st) => st.value === value);
  }
}

class ManufacturingMethod {
  public combinationData: IOption[];
  public processingData: IOption[];
  private data: IOption[];
  constructor() {
    this.data = [
      {
        value: 0,
        text: "无补无磨",
      },
      {
        value: 1,
        text: "正常加工",
      },
      {
        value: 2,
        text: "正反加工",
      },
      {
        value: 4,
        text: "补胶",
      },
      {
        value: 8,
        text: "磨抛",
      },
    ];

    this.combinationData = [
      {
        value: 0,
        text: "无补无磨",
      },
      {
        value: 5,
        text: "正常补",
      },
      {
        value: 6,
        text: "正反补",
      },
      {
        value: 9,
        text: "正常磨",
      },
      {
        value: 10,
        text: "正反磨",
      },
      {
        value: 13,
        text: "正常补磨",
      },
      {
        value: 14,
        text: "正反补磨",
      },
    ];

    this.processingData = [
      {
        value: 1,
        text: "正常加工",
      },
      {
        value: 2,
        text: "正反加工",
      },
    ];
  }

  public getManufacturingMethod(value) {
    const data = [...this.data, ...this.combinationData];
    return data.find((st) => st.value === value);
  }
}

class ProcessingType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "大切工艺",
      },
      {
        value: 20,
        text: "补胶工艺",
      },
      {
        value: 30,
        text: "磨抛工艺",
      },
      {
        value: 40,
        text: "工程板工艺",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}

class PolishingType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "粗磨 + 抛光",
      },
      {
        value: 20,
        text: "磨光",
      },
      {
        value: 30,
        text: "亚光",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}

class InvoiceType {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "增值税专用发票",
      },
      {
        value: 20,
        text: "增值税普通发票",
      },
      {
        value: 30,
        text: "其他发票",
      },
    ];
  }

  public getType(value) {
    return this.data.find((it) => it.value === value);
  }
}

class StoneCategories {
  private categoryCacheKey: string;
  private storage: any;
  constructor() {
    this.categoryCacheKey = Helper.getTenantKey("categories");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(Helper.getTenantKey("categoryCache"), 10),
      );
    }
    return this.storage;
  }

  public async getCategory(id: number): Promise<IStoneCategoryDTO> {
    const cateList = await this.getCategories();
    return cateList.find((c) => c.id === id);
  }

  public async ensureCategories() {
    const cache = this.getStorage();
    const result = cache.has(this.categoryCacheKey);
    if (!result.has) {
      const client = ClientFactory.getStoneCategoryClient();
      const response = await client.getAll(
        undefined,
        1,
        1000,
        true,
        true,
        undefined,
        undefined,
      );
      cache.set(this.categoryCacheKey, response.result);
    }
  }

  public async getCategories(): Promise<IStoneCategoryDTO[]> {
    await this.ensureCategories();
    const cache = this.getStorage();
    return cache.get(this.categoryCacheKey);
  }
}

class BankAccounts {
  private bankAccountCacheKey: string;
  private storage: any;
  constructor() {
    this.bankAccountCacheKey = Helper.getTenantKey("bankAccounts");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(Helper.getTenantKey("bankAccountCache"), 10),
      );
    }
    return this.storage;
  }

  public async getBankAccount(id: number): Promise<IBankAccountDto> {
    const accList = await this.getBankAccounts();
    return accList.find((c) => c.id === id);
  }

  public async ensureBankAccounts() {
    const cache = this.getStorage();
    const result = cache.has(this.bankAccountCacheKey);
    if (!result.has) {
      const client = ClientFactory.getBankAccountClient();
      const accList = await client.getAll();
      cache.set(this.bankAccountCacheKey, accList);
    }
  }

  public async getBankAccounts(): Promise<IBankAccountDto[]> {
    await this.ensureBankAccounts();
    const cache = this.getStorage();
    return cache.get(this.bankAccountCacheKey);
  }
}

class AppliedPriceTypes {
  private appliedPriceTypeCacheKey: string;
  private storage: any;
  constructor() {
    this.appliedPriceTypeCacheKey = Helper.getTenantKey("appliedPriceTypes");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(
          Helper.getTenantKey("appliedPriceTypeCache"),
          10,
        ),
      );
    }
    return this.storage;
  }

  public async getAppliedPriceType(id: number): Promise<IAppliedPriceTypeDto> {
    const aptList = await this.getAppliedPriceTypes();
    return aptList.find((c) => c.id === id);
  }

  public async ensureAppliedPriceTypes() {
    const cache = this.getStorage();
    const result = cache.has(this.appliedPriceTypeCacheKey);
    if (!result.has) {
      const client = ClientFactory.getAppliedPriceTypeClient();
      const aptList = await client.getAll();
      cache.set(this.appliedPriceTypeCacheKey, aptList);
    }
  }

  public async getAppliedPriceTypes(): Promise<IAppliedPriceTypeDto[]> {
    await this.ensureAppliedPriceTypes();
    const cache = this.getStorage();
    return cache.get(this.appliedPriceTypeCacheKey);
  }
}

class Customers {
  private customerCacheKey: string;
  private storage: any;
  constructor() {
    this.customerCacheKey = Helper.getTenantKey("customers");
    this.storage = null;
  }

  public async getCustomer(id: number): Promise<ICustomerForListDTO> {
    const customerList = await this.getCustomers();
    return customerList.find((c) => c.id === id);
  }

  public async getCustomers(): Promise<ICustomerForListDTO[]> {
    await this.ensureCustomers();
    const cache = this.getStorage();
    return cache.get(this.customerCacheKey);
  }

  public clearCache() {
    const cache = this.getStorage();
    cache.remove(this.customerCacheKey);
  }

  private getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(Helper.getTenantKey("customerCache"), 20),
      );
    }
    return this.storage;
  }

  private async ensureCustomers() {
    const cache = this.getStorage();
    const result = cache.has(this.customerCacheKey);
    if (!result.has) {
      let customerList: CustomerForListDTO[] = [];
      let noMoreData = false;
      let pageNo: number = 1;
      const client = ClientFactory.getCustomerClient();
      // 若客户数量超出1000，则多次调用，获取全部客户列表 修复bug 3164 by hxy 20210907
      while (!noMoreData) {
        const response = await client.getAll(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          pageNo,
          1000,
          true,
          true,
        );

        noMoreData = response.pagingInfo.noMoreData;
        pageNo += 1;
        customerList = customerList.concat(response.result);
      }

      cache.set(this.customerCacheKey, customerList);
    }
  }
}

class StoneGrades {
  private gradeCacheKey: string;
  private storage: any;
  constructor() {
    this.gradeCacheKey = Helper.getTenantKey("grades");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(Helper.getTenantKey("gradeCache"), 10),
      );
    }
    return this.storage;
  }

  public async getGrade(id: number): Promise<IStoneGradeDTO> {
    const gradeList = await this.getGrades();
    return gradeList.find((c) => c.id === id);
  }

  public async ensureGrades() {
    const cache = this.getStorage();
    const result = cache.has(this.gradeCacheKey);
    if (!result.has) {
      const client = ClientFactory.getStoneGradeClient();
      const response = await client.getAll(undefined, 1, 1000, true, true);
      cache.set(this.gradeCacheKey, response.result);
    }
  }

  public async getGrades(): Promise<IStoneGradeDTO[]> {
    await this.ensureGrades();
    const cache = this.getStorage();
    return cache.get(this.gradeCacheKey);
  }
}

class Warehouses {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;

  constructor() {
    this.storageKey = Helper.getTenantKey("warehousesStorage");
    this.cacheKey = Helper.getTenantKey("warehousesCache");
    this.storage = null;
  }

  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getWarehouse(id: number): Promise<IWarehouseDTO> {
    const warehouseList = await this.getWarehouses();
    return warehouseList.find((c) => c.id === id);
  }

  public async getWarehouses(): Promise<IWarehouseDTO[]> {
    await this.ensureWarehouses();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }

  public async ensureWarehouses() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getWarehouseClient();
      const list = await client.getAll();
      cache.set(this.cacheKey, list);
    }
  }
}

class BlockStockingAreas {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("blockStockingAreaStorage");
    this.cacheKey = Helper.getTenantKey("blockStockingAreaCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getStockingArea(id: number): Promise<IStockingAreaDTO> {
    const bsaList = await this.getStockingAreas();
    return bsaList.find((c) => c.id === id);
  }

  public async ensureBSAs() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getStockingAreaClient();
      const response = await client.getAll(
        undefined,
        undefined,
        undefined,
        [WarehouseType.Block],
        1000,
        1,
        undefined,
        undefined,
      );
      cache.set(this.cacheKey, response.result);
    }
  }

  public async getStockingAreas(): Promise<IStockingAreaDTO[]> {
    await this.ensureBSAs();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class DistrictData {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("districtDataStorage");
    this.cacheKey = Helper.getTenantKey("districtDataCache");
    this.storage = null;
  }

  public async getDistrictData(): Promise<ICityData[]> {
    await this.ensureDDs();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }

  private getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getCacheOption(this.storageKey, 10080), // 设置城市信息缓存时间为七天
      );
    }
    return this.storage;
  }

  private async ensureDDs() {
    const cache = this.getStorage();

    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const cityData = await import(
        /* webpackChunkName: "citydata" */ "../app/cityData.json"
      );
      cache.set(this.cacheKey, cityData.default);
    }
  }
}

class CustomerGrades {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("customerGradeStorage");
    this.cacheKey = Helper.getTenantKey("customerGradeCache");
    this.storage = null;
  }
  public async getGrade(id: number): Promise<ICustomerGradeDTO> {
    const gradeList = await this.getGrades();
    return gradeList.find((c) => c.id === id);
  }

  public async getGrades(): Promise<ICustomerGradeDTO[]> {
    await this.ensureCGs();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }

  public clearCache() {
    const cache = this.getStorage();
    cache.remove(this.cacheKey);
  }

  private getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 20),
      );
    }
    return this.storage;
  }

  private async ensureCGs() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getCustomerGradeClient();
      const response = await client.getAll(
        undefined,
        undefined,
        1,
        100,
        true,
        true,
      );
      cache.set(this.cacheKey, response.result);
    }
  }
}

class CustomerTypes {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("customerTypeStorage");
    this.cacheKey = Helper.getTenantKey("customerTypeCache");
    this.storage = null;
  }

  public async getType(id: number): Promise<ICustomerTypeDTO> {
    const typeList = await this.getTypes();
    return typeList.find((c) => c.id === id);
  }

  public async getTypes(): Promise<ICustomerTypeDTO[]> {
    await this.ensureCTs();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }

  public clearCache() {
    const cache = this.getStorage();
    cache.remove(this.cacheKey);
  }

  private async ensureCTs() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getCustomerTypeClient();
      const response = await client.getAll(
        undefined,
        undefined,
        1,
        100,
        true,
        true,
      );
      cache.set(this.cacheKey, response.result);
    }
  }

  private getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 20),
      );
    }
    return this.storage;
  }
}

class ProductStockingAreas {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("productStockingAreaStorage");
    this.cacheKey = Helper.getTenantKey("productStockingAreaCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getStockingArea(id: number): Promise<IStockingAreaDTO> {
    const gradeList = await this.getStockingAreas();
    return gradeList.find((c) => c.id === id);
  }

  public async ensurePSAs() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getStockingAreaClient();
      const response = await client.getAll(
        undefined,
        undefined,
        undefined,
        [WarehouseType.Product],
        1000,
        1,
        undefined,
        undefined,
      );
      cache.set(this.cacheKey, response.result);
    }
  }

  public async getStockingAreas(): Promise<IStockingAreaDTO[]> {
    await this.ensurePSAs();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class Quarry {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("quarryStorage");
    this.cacheKey = Helper.getTenantKey("quarryCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getQuarry(id: number): Promise<IQuarryDTO> {
    const quarryList = await this.getQuarries();
    return quarryList.find((c) => c.id === id);
  }

  public async ensureQuarries() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getQuarryClient();
      const response = await client.getAll(undefined, true, true, 1000, 1);
      cache.set(this.cacheKey, response.result);
    }
  }

  public async getQuarries(): Promise<IQuarryDTO[]> {
    await this.ensureQuarries();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class Factories {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("factoryStorage");
    this.cacheKey = Helper.getTenantKey("factoryCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getFactory(id: number): Promise<IFactoryDTO> {
    const factoryList = await this.getFactories();
    return factoryList.find((f) => f.id === id);
  }

  public async ensureFactories() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getFactoryClient();
      const list = await client.getAll();
      cache.set(this.cacheKey, list);
    }
  }

  public async getFactories(): Promise<IFactoryDTO[]> {
    await this.ensureFactories();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class Machineries {
  private storageKey: string;
  private cacheKey: string;
  private storage: any;
  constructor() {
    this.storageKey = Helper.getTenantKey("machineryStorage");
    this.cacheKey = Helper.getTenantKey("machineryCache");
    this.storage = null;
  }
  public getStorage() {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, 60),
      );
    }
    return this.storage;
  }

  public async getMachinery(id: number): Promise<IMachineryDTO> {
    const machineries = await this.getMachineries();
    return machineries.find((m) => m.id === id);
  }

  public async ensureMachineries() {
    const cache = this.getStorage();
    const result = cache.has(this.cacheKey);
    if (!result.has) {
      const client = ClientFactory.getMachineryClient();
      const response = await client.getAll(
        undefined,
        undefined,
        undefined,
        undefined,
        1,
        1000,
        true,
        true,
      );
      cache.set(this.cacheKey, response.result);
    }
  }

  public async getMachineries(): Promise<IMachineryDTO[]> {
    await this.ensureMachineries();
    const cache = this.getStorage();
    return cache.get(this.cacheKey);
  }
}

class PaymentType {
  public data: IOption[];

  constructor() {
    this.data = [
      {
        value: 10,
        text: "荒料",
      },
      {
        value: 20,
        text: "大板",
      },
      {
        value: 30,
        text: "工程板",
      },
      {
        value: 40,
        text: "其它收费",
      },
    ];
  }

  public getType(value: number) {
    return this.data.find((st) => st.value === value);
  }
}

class TagTypeOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 1,
        text: "销售订单",
      },
    ];
  }

  public getTagTypes(value) {
    return this.data.find((st) => st.value === value);
  }
}

class TagColorOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: "magentaTag",
        text: "紫红色",
      },
      {
        value: "volcanoTag",
        text: "火焰红",
      },
      {
        value: "redTag",
        text: "红色",
      },
      {
        value: "goldTag",
        text: "金色",
      },
      {
        value: "greenTag",
        text: "绿色",
      },
      {
        value: "cyanTag",
        text: "青色",
      },
      {
        value: "blueTag",
        text: "蓝色",
      },
      {
        value: "geekblueTag",
        text: "蓝绿色",
      },
      {
        value: "purpleTag",
        text: "紫色",
      },
      {
        value: "orangeTag",
        text: "橙色",
      },
    ];
  }

  public getTagColor(value) {
    return this.data.find((st) => st.value === value);
  }
}

class ImageTypes {
  public customerfollowUpRecordSheet: string = "CustomerFollowUpRecordSheet";
  public normal: string = "Normal";
  public discarded: string = "Discarded";
  public manufacturingTrackingSheet: string = "ManufacturingTrackingSheet";
  public rawSlabDataSheet: string = "RawSlabDataSheet";
  public polishedSlabDataSheet: string = "PolishedSlabDataSheet";
  public other: string = "Other";
  public blockDataSheet: string = "BlockDataSheet";
  public salesOrderDataSheet: string = "SalesOrderDataSheet";
  public imagingDeviceProvided: string = "ImagingDeviceProvided";
  public depositIncurredItemDataSheet: string = "DepositIncurredItemDataSheet";
  public paymentOrderDataSheet: string = "PaymentOrderDataSheet";
  public transferOrderDataSheet: string = "TransferOrderDataSheet";
  public attachment: string = "Attachment";
}

class ProcessConst {
  public trimmingProcessName: string = "对剖";
  public trimmingProcessUnitName: string = "刀";
  public trimmingConventionalCutsName: string = "平常刀";
  public trimmingDilatedCutsName: string = "加宽";
  public trimmingThickenCutsName: string = "加厚";
  public trimmingCreviceHeadCutsName: string = "隙头";
  public trimmingCreviceBrimCutsName: string = "隙边";
}

class PaymentOrderStatusOptions {
  public data: IOption[];
  public dataWithApproval: IOption[];
  public waitingData: IOption[];
  public waitingDataWithApproval: IOption[];
  constructor() {
    this.data = [
      {
        value: PaymentOrderStatus.New,
        text: "新建",
      },
      {
        value: PaymentOrderStatus.PaymentReceived,
        text: "完成结算",
      },
      {
        value: PaymentOrderStatus.Cancelled,
        text: "已取消",
      },
    ];
    this.dataWithApproval = [
      {
        value: PaymentOrderStatus.New,
        text: "审批中",
      },
      {
        value: PaymentOrderStatus.Approved,
        text: "等待完成结算",
      },
      {
        value: PaymentOrderStatus.Rejected,
        text: "审批不通过",
      },
      {
        value: PaymentOrderStatus.PaymentReceived,
        text: "完成结算",
      },
      {
        value: PaymentOrderStatus.Cancelled,
        text: "已取消",
      },
    ];
    this.waitingData = [
      {
        value: PaymentOrderStatus.New,
        text: "新建",
      },
    ];
    this.waitingDataWithApproval = [
      {
        value: PaymentOrderStatus.Approved,
        text: "等待完成结算",
      },
    ];
  }

  public getStatus(value: number) {
    return this.data.find((sos) => sos.value === value);
  }

  public getStatusWithApproval(value: number) {
    return this.dataWithApproval.find((sos) => sos.value === value);
  }
}

class WarehouseTypeOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: WarehouseType.Block,
        text: "荒料",
      },
      {
        value: WarehouseType.Product,
        text: "成品",
      },
      {
        value: WarehouseType.Supplementary,
        text: "辅料",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}

class MachineryUseOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: MachineryUse.Trimming,
        text: "修边机械",
      },
      {
        value: MachineryUse.Sawing,
        text: "大锯机械",
      },
      {
        value: MachineryUse.Filling,
        text: "补胶机械",
      },
      {
        value: MachineryUse.Polishing,
        text: "磨抛机械",
      },
    ];
  }

  public getUse(value) {
    return this.data.find((st) => st.value === value);
  }
}

class StoneArtifactTypeOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 10,
        text: "荒料",
      },
      {
        value: 20,
        text: "扎",
      },
      {
        value: 25,
        text: "散板",
      },
      {
        value: 30,
        text: "工程板",
      },
    ];
  }

  public getType(value: number) {
    return this.data.find((st) => st.value === value);
  }
}

class SalesOrderReservationTypeOptions {
  public data: IOption[];
  constructor() {
    this.data = [
      {
        value: 0,
        text: "未启用订单冻结",
      },
      {
        value: 10,
        text: "未付订金",
      },
      {
        value: 20,
        text: "订金冻结",
      },
      {
        value: 30,
        text: "永久冻结",
      },
    ];
  }

  public getType(value) {
    return this.data.find((st) => st.value === value);
  }
}

class BlockSalesType {
  public selling: number = 10;
  public sellingAndManufacturing: number = 20;
}

class Consts {
  public static WorkOrderType = new WorkOrderType();
  public static SalesOrderType = new SalesOrderType();
  public static SlabTypeOptions = new SlabType();
  public static SlabBundleStatus = new SlabBundleStatus();
  public static ManufacturingState = new ManufacturingState();
  public static SalesOrderStatusOptions = new SalesOrderStatus();
  public static BankAccounts = new BankAccounts();
  public static StoneGrades = new StoneGrades();
  public static StoneCategories = new StoneCategories();
  public static ProductStockingAreas = new ProductStockingAreas();
  public static BlockStockingAreas = new BlockStockingAreas();
  public static ShippingOrderStatus = new ShippingOrderStatus();
  public static BlockStatus = new BlockStatus();
  public static ApprovalStatus = new ApprovalStatus();
  public static TilePackingCaseStatus = new TilePackingCaseStatus();
  public static PriorityOptions = new PriorityOptions();
  public static ProcessingType = new ProcessingType();
  public static ManufacturingMethod = new ManufacturingMethod();
  public static Quarry = new Quarry();
  public static ImageTypes = new ImageTypes();
  public static PolishingType = new PolishingType();
  public static InvoiceType = new InvoiceType();
  public static DistrictData = new DistrictData();
  public static CustomerGrades = new CustomerGrades();
  public static CustomerTypes = new CustomerTypes();
  public static Customers = new Customers();
  public static PaymentOrderStatusOptions = new PaymentOrderStatusOptions();
  public static PaymentType = new PaymentType();
  public static AppliedPriceTypes = new AppliedPriceTypes();
  public static Factories = new Factories();
  public static Warehouses = new Warehouses();
  public static WarehouseTypeOptions = new WarehouseTypeOptions();
  public static MachineryUseOptions = new MachineryUseOptions();
  public static Machineries = new Machineries();
  public static StoneArtifactTypeOptions = new StoneArtifactTypeOptions();
  public static ProcessConst = new ProcessConst();
  public static BlockSalesType = new BlockSalesType();
  public static TagTypeOptions = new TagTypeOptions();
  public static TagColorOptions = new TagColorOptions();
  public static SalesOrderReservationTypeOptions =
    new SalesOrderReservationTypeOptions();
}

export default Consts;
