import React from "react";
import {
  IStoneCategoryDTO,
  IStoneGradeDTO,
  IUntrackedStoneArtifactItemDTO,
  IUntrackedStoneArtifactItemViewModel,
} from "../app/WebAPIClients";
import Consts from "./Consts";
import Util from "./Util";
const { SlabTypeOptions } = Consts;

class UntrackedContentUtil {
  public getUntrackedContentTitle(
    untrackedContent:
      | IUntrackedStoneArtifactItemViewModel
      | IUntrackedStoneArtifactItemDTO,
  ) {
    if (!untrackedContent) {
      return "";
    }
    let title = untrackedContent.name + " | 厚：" + untrackedContent.thickness;
    if (untrackedContent.slabCount > 0) {
      title += " | " + untrackedContent.slabCount + " 片";
    }

    title += " | " + untrackedContent.area + " 平方";

    return title;
  }

  public getUntrackedContentText(
    untrackedContent:
      | IUntrackedStoneArtifactItemViewModel
      | IUntrackedStoneArtifactItemDTO,
    categoryList: IStoneCategoryDTO[] | IIdNameItem[],
    gradeList: IStoneGradeDTO[] | IIdNameItem[],
  ) {
    const cateName = Util.getItemName(
      untrackedContent.categoryId,
      categoryList,
    );
    const gradeName = Util.getItemName(untrackedContent.gradeId, gradeList);
    const slabType = SlabTypeOptions.getType(untrackedContent.slabType);
    let text = cateName;
    if (Util.isDefinedAndNotNull(slabType)) {
      text += " | " + slabType.text;
    }
    if (Util.isDefinedAndNotNull(gradeName)) {
      text += " | " + gradeName;
    }
    if (Util.isNotNullAndNotEmpty(untrackedContent.dimension)) {
      text += " | 规格：" + untrackedContent.dimension;
    }

    return Util.isNotNullAndNotEmpty(untrackedContent.notes) ? (
      <div>
        {text} <div>{"备注：" + untrackedContent.notes}</div>
      </div>
    ) : (
      text
    );
  }

  // 用于计算其它物品量的面积和片数
  public getUntrackedContentsAreaAndCount(
    untrackedContents:
      | IUntrackedStoneArtifactItemDTO[]
      | IUntrackedStoneArtifactItemViewModel[],
  ): IUntrackedContentInfo {
    let area = 0;
    let slabCount = 0;

    if (
      Util.isDefinedAndNotNull(untrackedContents) &&
      untrackedContents.length > 0
    ) {
      untrackedContents.forEach((uc) => {
        slabCount += uc.slabCount;
        area += uc.area;
      });
    }

    return { area, slabCount };
  }
}

export default new UntrackedContentUtil();
