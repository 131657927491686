import * as React from "react";
import { Group } from "saltui";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ESPickerField from "../ESPickerField";
import ESTextField from "../ESTextField";
import FilterButtonGroup from "../FilterButtonGroup";
import Util from "../Util";
const { ApprovalStatus } = Consts;

declare interface ISGCRListFilterProps
  extends IFilterProps<ISGCRListFilterData> {}

export interface ISGCRListFilterData {
  requestNumber?: string;
  slabSequenceNumber?: number;
  bundlePrefix?: string;
  requestStatus?: IOption[];
}

declare interface ISGCRListFilterState extends ISGCRListFilterData {
  statusPhoneticOptions: IOptionWithPhonetic[];
}

class SGCRListFilter extends React.Component<
  ISGCRListFilterProps,
  ISGCRListFilterState
> {
  public static defaultProps: ISGCRListFilterProps;
  constructor(props: ISGCRListFilterProps) {
    super(props);
    this.state = {
      statusPhoneticOptions: Util.getOptionsWithPhoneticByOptionArr(
        props.showStatusSelector
          ? props.statusSelectorOptions ?? ApprovalStatus.data
          : ApprovalStatus.data,
      ),
      ...props.filterData,
    };
  }

  public componentWillReceiveProps(nextProps: ISGCRListFilterProps) {
    if (nextProps.filterData !== this.props.filterData) {
      this.setState({ ...nextProps.filterData });
    }
  }

  public async componentDidMount() {}

  public render() {
    const t = this;
    const s = t.state;
    const { requestNumber, slabSequenceNumber, bundlePrefix, requestStatus } =
      s;
    const { onExpansionChanged, expanded, showStatusSelector, ...other } =
      t.props;

    return (
      <Collapsible
        label={"筛选条件"}
        onExpansionChanged={onExpansionChanged}
        expanded={expanded}
      >
        <Group.List itemIndent={[15, 15]}>
          <ESTextField
            label="变更单号"
            value={requestNumber}
            onChange={t.handleRequestNumberChange}
            placeholder="请输入变更单号"
          />
          <ESTextField
            label="荒料号"
            value={bundlePrefix}
            onChange={t.handleBundlePrefixChange}
            placeholder="请输入荒料号"
          />
          <ESTextField
            label="大板序号"
            type="number"
            pattern="\d*"
            value={slabSequenceNumber}
            placeholder="请输入大板序号"
            onChange={t.handleSlabSequenceNumberChange}
          />
          {showStatusSelector ? (
            <ESPickerField
              label="状态"
              options={s.statusPhoneticOptions}
              onSelect={t.handleRequestStatusChange}
              value={requestStatus}
              placeholder="请选择状态"
              multiple={true}
            />
          ) : null}
          <FilterButtonGroup
            onSearch={t.handleSearch}
            onClearData={t.handleClearData}
          />
        </Group.List>
      </Collapsible>
    );
  }

  private handleRequestNumberChange = (value: string) => {
    this.setState({ requestNumber: value });
  };

  private handleBundlePrefixChange = (value: string) => {
    this.setState({ bundlePrefix: value });
  };

  private handleSlabSequenceNumberChange = (value: string) => {
    let seqNo: number | null = Util.parseToInt(value);
    if (seqNo !== null && seqNo <= 0) {
      seqNo = null;
    }
    this.setState({ slabSequenceNumber: seqNo });
  };

  private handleRequestStatusChange = (value: IOption[]) => {
    this.setState({ requestStatus: value });
  };

  private handleSearch = () => {
    const t = this;
    const s = t.state;
    const p: ISGCRListFilterData = {
      requestStatus: s.requestStatus,
      requestNumber: s.requestNumber,
      slabSequenceNumber: s.slabSequenceNumber,
      bundlePrefix: s.bundlePrefix,
    };

    const { onSearch } = t.props;
    if (onSearch) {
      onSearch(p);
    }
  };

  private handleClearData = () => {
    const t = this;
    const p: ISGCRListFilterData = {
      requestStatus: [],
      requestNumber: undefined,
      slabSequenceNumber: undefined,
      bundlePrefix: undefined,
    };

    t.setState(p);

    const { onClear } = t.props;
    if (onClear) {
      onClear(p);
    }
  };
}

export default SGCRListFilter;
