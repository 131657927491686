import { observer } from "mobx-react";

import React from "react";

// tslint:disable-next-line:no-implicit-dependencies
import {
    IBundleForListDTO,
    IBundleForSODTO,
    SlabChargeableStructureDTO
} from "../../app/WebAPIClients";
import ReferencePrice from "../ReferencePrice";
import SlabChargeableStructure from "../SlabChargeableStructure";
import { ReferencePriceSize } from "../type";
import Util from "../Util";
import Store from "./store";

export interface IReferencePriceInfoProps {
    bundle?: IBundleForListDTO | IBundleForSODTO;
    categoryList: IIdNameItem[];
    gradeList: IIdNameItem[];
    showPrice: boolean;
    referencePrice?: number | null;
    size?: ReferencePriceSize;
    showIcon?: boolean;
    lineThrough?: boolean;
    noReferencePriceText?: string;
    salesOrderId?: number;
    customerId?: number;
}

export interface IReferencePriceInfoState {
    visible: boolean;
}
@observer
class ReferencePriceInfo extends React.Component<
    IReferencePriceInfoProps,
    IReferencePriceInfoState
> {
    public static defaultProps: IReferencePriceInfoProps;
    constructor(props: IReferencePriceInfoProps) {
        super(props);
        this.state = { visible: false };
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const {
            bundle,
            categoryList,
            gradeList,
            showPrice,
            referencePrice,
            size,
            showIcon,
            lineThrough,
            noReferencePriceText
        } = t.props;
        return (
            <span>
                {showPrice ? (
                    <ReferencePrice
                        referencePrice={referencePrice}
                        size={size}
                        onClick={showIcon ? t.handleUnitPriceClick : null}
                        lineThrough={lineThrough}
                        showIcon={showIcon}
                        noReferencePriceText={noReferencePriceText}
                    />
                ) : null}
                {s.visible && showPrice && Util.isDefinedAndNotNull(bundle) ? (
                    <SlabChargeableStructure
                        slabChargeableStructures={
                            Store.slabChargeableStructures
                        }
                        bundle={bundle}
                        categoryList={categoryList}
                        gradeList={gradeList}
                        visible={s.visible}
                        onClose={t.handleCloseChange}
                    />
                ) : null}
            </span>
        );
    }

    private handleCloseChange = (visible: boolean) => {
        this.setState({ visible });
    };

    private handleUnitPriceClick = (
        event: React.MouseEvent<HTMLSpanElement>
    ): void => {
        const t = this;
        const { bundle } = t.props;
        if (!Util.isDefinedAndNotNull(bundle)) {
            return;
        }

        if (
            !Util.isDefinedAndNotNull(bundle) ||
            !Util.isDefinedAndNotNull(bundle.unitPrice)
        ) {
            return;
        }

        t.loadBundleSalesPriceItems();

        if (event) {
            event.stopPropagation();
        }
    };

    private async loadBundleSalesPriceItems() {
        const { bundle, salesOrderId, customerId } = this.props;
        if (
            !Util.isDefinedAndNotNull(bundle) ||
            !Util.isDefinedAndNotNull(bundle.id)
        ) {
            return null;
        }

        const p = { bundleId: bundle.id, customerId, salesOrderId };
        await Store.onLoadBundleSalesPriceItems(p);

        if (!Store.hasError) {
            const slabChargeableStructures: SlabChargeableStructureDTO[] =
                Store.slabChargeableStructures;
            if (Util.isNotNullAndNotEmptyArray(slabChargeableStructures)) {
                this.setState({ visible: true });
            }
        }
    }
}

ReferencePriceInfo.defaultProps = {
    bundle: null,
    categoryList: [],
    gradeList: [],
    showPrice: false,
    referencePrice: null,
    size: ReferencePriceSize.Normal,
    showIcon: false,
    lineThrough: false,
    noReferencePriceText: null,
    salesOrderId: null,
    customerId: null
};

export default ReferencePriceInfo;
