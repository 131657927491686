import React from "react";
import Consts from "./Consts";
const { TilePackingCaseStatus: TilePackingCaseStatusOptions } = Consts;
import {
  IBundleWithSlabsForListDTO,
  IPublicTilePackingCaseDTO,
  ISlabForListDTO,
  ITilePackingCaseForListDTO,
  TilePackingCaseStatus,
} from "../app/WebAPIClients";
import SlabUtil from "./SlabUtil";
import StockingAreaUtil from "./StockingAreaUtil";
import StoneBundleUtil from "./StoneBundleUtil";
import Util from "./Util";
import { Boxs } from "saltui";
import ReferencePrice from "./ReferencePrice";
const HBox = Boxs.HBox;
const Box = Boxs.Box;

class TileUtil {
  // 获取显示工程板信息的title
  public getTileTitle(tile): JSX.Element {
    if (!tile) {
      return null;
    }
    let title = "";
    if (tile.tileNumber) {
      title = tile.tileNumber;
    }
    if (tile.position) {
      title += " | " + tile.position;
    }

    return (
      <div>
        <span>{title + " | "}</span>
        {this.getTileStatus(tile)}
      </div>
    );
  }

  // 获取显示工程板信息的描述
  public getTileTitleDescription(tile): string {
    if (!tile) {
      return null;
    }
    let desc = "";
    desc = tile.length + " x " + tile.width + " | " + tile.area + " 平方";

    return desc;
  }

  // 获取显示工程板装箱的信息
  public getTilePackingCaseInfo(tilePackingCase): JSX.Element {
    if (!tilePackingCase) {
      return null;
    }
    return (
      <div>
        <span>{"箱号：" + tilePackingCase.caseNumber + "  "}</span>
        {this.getTileStatus(tilePackingCase)}
      </div>
    );
  }

  // 获取显示工程板装箱的title
  public getTilePackingCaseTitle(tilePackingCase, salesOrder): string {
    if (!tilePackingCase || !salesOrder) {
      return null;
    }
    let title = "";
    title += "箱号：" + tilePackingCase.caseNumber;
    return title;
  }

  // 获取显示工程板装箱的title
  // [James] 9/30/2007
  public getTileCaseTitle(tileCase): JSX.Element {
    if (!tileCase) {
      return null;
    }
    return (
      <div>
        <span>
          {tileCase.projectName + " | 箱号：" + tileCase.caseNumber + " | "}
        </span>
        {this.getTileStatus(tileCase)}
      </div>
    );
  }

  public getTileCaseTitleForShippingOrder(
    tileCase: ITilePackingCaseForListDTO,
  ): string {
    if (!tileCase) {
      return null;
    }
    let title = "";
    if (tileCase.projectName) {
      title += tileCase.projectName;
    }

    if (tileCase.caseNumber) {
      title +=
        (Util.isNotNullAndNotEmpty(title) ? " | " : "") +
        "箱号：" +
        tileCase.caseNumber;
    }

    return title;
  }

  // 获取显示工程板装箱的描述
  // [James] 9/30/2007
  public getTileCaseDescription(
    tileCase: ITilePackingCaseForListDTO | IPublicTilePackingCaseDTO,
    showOrderNumber: boolean,
    showStockingArea: boolean = true,
  ): JSX.Element {
    if (!tileCase) {
      return null;
    }
    let desc = "";
    if (showOrderNumber) {
      desc += tileCase.orderNumber + " | ";
    }

    desc +=
      "共 " +
      tileCase.totalTileCount +
      " 片" +
      " | " +
      tileCase.totalArea +
      " 平方";
    showStockingArea =
      [TilePackingCaseStatus.InStock, TilePackingCaseStatus.Reserved].includes(
        tileCase.status as TilePackingCaseStatus,
      ) && showStockingArea;

    return (
      <div>
        <div>{desc}</div>
        {Util.isDefinedAndNotNull(
          (tileCase as ITilePackingCaseForListDTO).stockingAreaId,
        ) && showStockingArea
          ? StockingAreaUtil.showStockingArea(
              showStockingArea,
              (tileCase as ITilePackingCaseForListDTO).stockingAreaId,
            )
          : null}
      </div>
    );
  }

  public getTileListTitle(tileSlab): JSX.Element {
    if (!tileSlab) {
      return null;
    }
    return (
      <div>
        <span>
          {tileSlab.customerName +
            " | " +
            tileSlab.tileNumber +
            " | 厚：" +
            tileSlab.thickness +
            " | "}
        </span>
        {this.getTileStatus(tileSlab)}
      </div>
    );
  }

  public getTileListDescription(tileSlab, categoryList): string {
    const cateName = Util.getItemName(tileSlab.categoryId, categoryList);
    return (
      cateName +
      " | " +
      tileSlab.length +
      " x " +
      tileSlab.width +
      " | " +
      tileSlab.area +
      "平方"
    );
  }

  // 获取工程板状态
  public getTileStatus(item): JSX.Element {
    let tileStatusName = "";
    tileStatusName = TilePackingCaseStatusOptions.getStatus(item.status).text;
    let className = "";
    switch (item.status) {
      case 10:
        className = "tilePlanningCompleted";
        break;
      case 20:
        className = "tileManufactureCompleted";
        break;
      case 30:
        className = "tileInStock";
        break;
      case 40:
        className = "tileReserved";
        break;
      case 50:
        className = "tileSold";
        break;
      case 60:
        className = "tileDiscarded";
        break;
    }
    return <span className={className}>{tileStatusName}</span>;
  }

  public getTilePackingCasesInfoForList(
    tilePackingCase,
    showOrderNumber,
  ): IListItem {
    if (!tilePackingCase) {
      return Util.getEmptyListItemData();
    }

    return {
      title: this.getTileCaseTitleForShippingOrder(tilePackingCase),
      text: this.getTileCaseDescription(tilePackingCase, showOrderNumber),
    };
  }

  public getTilePackingCasesInfoForShippingOrder(
    tilePackingCase: ITilePackingCaseForListDTO,
  ): IListItem {
    if (!tilePackingCase) {
      return Util.getEmptyListItemData();
    }

    return {
      title: this.getTileCaseTitleForShippingOrder(tilePackingCase),
      text: this.getTileCaseDescription(tilePackingCase, false, false),
    };
  }

  // 计算工程板装箱的总面积
  // 传入参数为工程板列表
  public calculateTotalAreaForTilePackingCase(tiles: any[]): number {
    if (!tiles) {
      return null;
    }
    const totalArea = tiles.reduce((area, tile) => {
      return area + tile.area;
    }, 0);
    return Util.round(totalArea, 3);
  }

  // 传入参数为工程板列表,将工程板按areaName分组
  public getTilesGroupbyAreaName(tiles: any[]): any[] {
    const tilesGroupbyAreaName = [];
    if (typeof tiles === "undefined" || tiles === null) {
      return tilesGroupbyAreaName;
    }
    const areaNameList = [];
    tiles.forEach((tile) => {
      if (!areaNameList.some((a) => a === tile.areaName)) {
        areaNameList.push(tile.areaName);
      }
    });
    areaNameList.forEach((an) => {
      const tileList = tiles.filter((t) => {
        return t.areaName === an;
      });

      const totalArea = this.calculateTotalAreaForTilePackingCase(tileList);
      const totalTileCount = tileList.length;
      tilesGroupbyAreaName.push({
        areaName: an,
        totalArea,
        totalTileCount,
        tiles: tileList,
      });
    });

    return tilesGroupbyAreaName;
  }

  // 获取显示未装箱工程板信息的描述
  public getNoPackedTileText(item): JSX.Element {
    if (!item) {
      return null;
    }
    return (
      <div>
        <span>
          {"共 " + item.totalTileCount + " 片 | " + item.totalArea + " 平方"}
        </span>
      </div>
    );
  }

  // 每一箱工程板按工程板编号排序大板
  public getTilesGroupBy(tiles: any[]): any[] {
    return tiles.slice().sort((t1, t2) => {
      const sortOne = t1.tileNumber;
      const sortTwo = t2.tileNumber;
      return sortOne - sortTwo;
    });
  }

  // 用于计算工程板领料单实际领出的大板的总面积
  // bundles：工程板领料单中只包含实际领出整扎大板信息
  // scatteredSlabs：工程板领料单中只包含实际领出零散大板信息
  // 返回 - 面积area
  public calculateTotalAreaForMatrerialReq(
    bundles: IBundleWithSlabsForListDTO[],
    scatteredSlabs: Array<{ slabList: ISlabForListDTO[] }>,
  ): number {
    let area = 0;
    let bundlesArea = 0;
    let slabsArea = 0;

    if (
      (typeof bundles === "undefined" ||
        bundles === null ||
        !bundles.length ||
        bundles.length === 0) &&
      (typeof scatteredSlabs === "undefined" ||
        scatteredSlabs === null ||
        !scatteredSlabs.length ||
        scatteredSlabs.length === 0)
    ) {
      return area;
    }

    bundlesArea = StoneBundleUtil.calculateAreaForBundleList(bundles);

    scatteredSlabs.forEach((s) => {
      s.slabList.forEach((slab) => {
        slabsArea += SlabUtil.calculateAreaForSlab(slab);
      });
    });
    area = bundlesArea + Util.round(slabsArea, 3);
    return Util.round(area, 3);
  }

  // 整理出领料单中领出的散板title和text
  public getScatteredSlabsTitle(
    scatteredSlabs,
    categoryList: IIdNameItem[],
    gradeList: IIdNameItem[],
    showBundleGrades: boolean = true,
    showUnitPrice: boolean = false,
  ): IListItem {
    let title = "";
    let text: string | JSX.Element = "";

    if (
      !(
        scatteredSlabs ||
        scatteredSlabs.slabList ||
        scatteredSlabs.slabList.length ||
        scatteredSlabs.slabList.length === 0
      )
    ) {
      return Util.getEmptyListItemData();
    }

    const slabs = scatteredSlabs.slabList;
    const bundlePrefix = scatteredSlabs.bundlePrefix;
    let thickness = "";
    let cateName = "";
    let gradeName = "";
    let area = 0;

    title += bundlePrefix + " ";

    let totalPrice = 0;

    slabs.forEach((s) => {
      thickness = s.thickness;
      cateName = Util.getItemName(s.categoryId, categoryList);
      gradeName = Util.getItemName(s.gradeId, gradeList);
      const slabArea = SlabUtil.calculateAreaForSlab(s);
      area += slabArea;
      totalPrice += showUnitPrice ? s.unitPrice * slabArea : 0;
    });

    const bundleUnitPrice = showUnitPrice
      ? Util.round(totalPrice / area, 0)
      : 0;

    area = Util.round(area, 3);

    text =
      cateName +
      (showBundleGrades ? " | " + gradeName : "") +
      " | " +
      area +
      "平方";

    text = showUnitPrice ? (
      <div>
        <div>{text}</div>
        <div className="referencePriceArea">
          <HBox>
            <Box className="referencePriceLabel">单价</Box>
            <Box>
              <ReferencePrice referencePrice={bundleUnitPrice} />
            </Box>
          </HBox>
        </div>
      </div>
    ) : (
      text
    );

    title += " | 厚：" + thickness + " | " + slabs.length + "片";

    return { title, text };
  }
}

export default new TileUtil();
