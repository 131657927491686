import React, { useEffect, useState } from "react";
import { Boxs } from "saltui";
import {
  IBundleForListDTO,
  IFactoryDTO,
  IStockingAreaDTO,
  IWarehouseDTO,
  SlabBundleStatus,
} from "../../app/WebAPIClients";
import Consts from "../Consts";
import Util from "../Util";

const { SlabBundleStatus: SlabBundleStatusConsts } = Consts;
const { HBox, Box } = Boxs;

interface IBundleStatusProps {
  currentBundle: IBundleForListDTO;
  bundlesWithSamePrefixes: IBundleForListDTO[];
  salesOrderDetails?: Array<{ bundleId: number }>;
  showStatusInColor?: boolean;
  showPrice?: boolean;
}

function BundleStatus(props: IBundleStatusProps) {
  const {
    currentBundle,
    bundlesWithSamePrefixes,
    salesOrderDetails,
    showStatusInColor,
    showPrice,
  } = props;

  const [warehouses, setWarehouses] = useState<IWarehouseDTO[]>([]);
  const [factories, setFactories] = useState<IFactoryDTO[]>([]);
  const [stockingAreas, setStockingAreas] = useState<IStockingAreaDTO[]>([]);

  useEffect(() => {
    const loadSupportData = async () => {
      const factoryList = await Consts.Factories.getFactories(); // 工厂列表
      const warehouseList = await Consts.Warehouses.getWarehouses(); // 仓库列表
      const productStockingAreaList =
        await Consts.ProductStockingAreas.getStockingAreas();
      setFactories(factoryList);
      setWarehouses(warehouseList);
      setStockingAreas(productStockingAreaList);
    };

    loadSupportData();
  }, []);

  const getFactoryInfo: (
    stockingAreaId: number,
  ) => { id: number; name: string } | null = (stockingAreaId) => {
    const stockingArea = stockingAreas.find((sa) => sa.id === stockingAreaId);
    if (Util.isUndefinedOrNull(stockingArea)) {
      return null;
    }

    const warehouse = warehouses.find((h) => h.id === stockingArea.warehouseId);
    if (Util.isUndefinedOrNull(warehouse)) {
      return null;
    }

    const factory = factories.find((f) => f.id === warehouse.factoryId);
    if (Util.isUndefinedOrNull(factory)) {
      return null;
    }

    return { id: factory.id, name: factory.name };
  };

  const isBundleInSameOrder: (id: number) => boolean = (id) => {
    // if there is no sales order details in the props, then we don't need to
    // show if the bundle with the id are in the same sales order with other bundles
    // with same prefixes
    // in this case, only show the status of the bundle
    var showInSameOrder = Util.isDefinedAndNotNull(salesOrderDetails);
    return showInSameOrder && salesOrderDetails.some((d) => d.bundleId === id);
  };

  const isBundleInSameFactory: (stockingAreaId: number) => boolean = (
    stockingAreaId,
  ) => {
    const currentBundleFactoryInfo = getFactoryInfo(
      currentBundle.stockingAreaId,
    );
    const factory = getFactoryInfo(stockingAreaId);

    return Util.isDefinedAndNotNull(currentBundleFactoryInfo) &&
      Util.isDefinedAndNotNull(factory)
      ? currentBundleFactoryInfo.id === factory.id
      : false;
  };

  const getStatusText: (bundle: { id: number; status: number }) => string = ({
    id,
    status,
  }) => {
    return isBundleInSameOrder(id)
      ? "在此单中"
      : SlabBundleStatusConsts.getStatus(status).text;
  };

  const getStatusClassName: (bundle: {
    id: number;
    stockingAreaId: number;
    status: number;
  }) => string = ({ id, stockingAreaId, status }) => {
    const inSameFactory = isBundleInSameFactory(stockingAreaId);
    return isBundleInSameOrder(id)
      ? "bundleWithSamePrefixStatusOK"
      : showStatusInColor
      ? inSameFactory && [10, 20].includes(status)
        ? "bundleWithSamePrefixStatusWarning"
        : "bundleWithSamePrefixStatusOK"
      : "";
  };

  const getAveragePriceInBlockText: () => string = () => {
    const allBundlesInBlock = bundlesWithSamePrefixes.filter(
      (b) =>
        b.prefix === currentBundle.prefix &&
        Util.isDefinedAndNotNull(b.unitPrice),
    );

    // For bundles in sales order details (salesOrderDetails in the props), we
    // calculate the average price by considering only bundles in the same sales
    // order
    // For bundles in the product list (salesOrderDetails in the props is undefined)
    // we calculate the average price by considering all bundles in the same block
    // (with the same prefix) and with status of in stock
    const forSOAvgPrice = Util.isDefinedAndNotNull(salesOrderDetails);
    const bundlesToCalculateAveragePrice = forSOAvgPrice
      ? allBundlesInBlock.filter((b) => isBundleInSameOrder(b.id))
      : allBundlesInBlock.filter((b) => b.status == SlabBundleStatus.InStock);

    const averagePriceInBlockLabel = forSOAvgPrice
      ? "订单中整颗料的扎均价："
      : "可售的整颗料的扎均价：";
    let averagePriceText = "无";
    if (showPrice && bundlesToCalculateAveragePrice.length > 0) {
      const totalBundleAreaInBlock = bundlesToCalculateAveragePrice.reduce(
        (sum, bundle) => sum + bundle.area,
        0,
      );
      const totalPriceInBlock = bundlesToCalculateAveragePrice.reduce(
        (sum, bundle) => sum + bundle.area * bundle.unitPrice,
        0,
      );
      const averagePriceInBlock = Util.round(
        totalPriceInBlock / totalBundleAreaInBlock,
        0,
      );
      averagePriceText = "¥" + averagePriceInBlock.toString();
    }
    return `${averagePriceInBlockLabel}${averagePriceText}`;
  };

  const otherBundles = bundlesWithSamePrefixes.filter(
    (b) => b.id !== currentBundle.id && b.prefix === currentBundle.prefix,
  );

  return (
    <HBox flex={1} className="unitPriceArea">
      <Box className="unitPriceLabel" />
      <Box className="listItemContentArea" flex={1}>
        {otherBundles.length === 0 ? (
          <div className="demo3-t-list-text">同颗料无其它扎</div>
        ) : (
          <div>
            <ul>
              {otherBundles.map((b) => {
                const statusText = getStatusText(b);
                const unitPriceText = showPrice
                  ? Util.isDefinedAndNotNull(b.unitPrice)
                    ? " | ¥" + b.unitPrice.toString()
                    : " | 无单价"
                  : "";
                const factory = getFactoryInfo(b.stockingAreaId);

                // If the displaying bundle is stored in the same factory as the referencing bundle
                // (currentBundle in the props), and its status is either manufacturing or in stock,
                // the user then needs to pay attention, according to the business rule of XSL, in its
                // new factory in Anlong, all in-stock bundles under the same block should be sold in
                // the same sales order
                const className = getStatusClassName(b);
                return (
                  <li
                    key={currentBundle.id.toString() + "_" + b.id.toString()}
                    className="demo3-t-list-text"
                  >
                    #{b.bundleNumber} | {b.area}平方{unitPriceText} |{" "}
                    {Util.isDefinedAndNotNull(factory)
                      ? `${factory.name} | `
                      : ""}
                    <span className={className}>{statusText}</span>
                  </li>
                );
              })}
            </ul>
            {showPrice ? (
              <div className="demo3-t-list-text">
                {getAveragePriceInBlockText()}
              </div>
            ) : null}
          </div>
        )}
      </Box>
      <Box />
    </HBox>
  );
}

export default BundleStatus;
