import {
  BlockStatus,
  SlabBundleStatus,
  StoneArtifactType,
  TilePackingCaseStatus,
} from "../app/WebAPIClients";
import BlockUtil from "./BlockUtil";
import StoneBundleUtil from "./StoneBundleUtil";
import TileUtil from "./TileUtil";
import { ISalesOrderDetailForUI } from "./type";
import Util from "./Util";

class StoneArtifactUtil {
  /**
   *
   * @param item 石材物品对象
   * @param categoryList 石材种类列表，如果不传，则在返回的对象中不包含石材种类信息
   * @param gradeList 石材等级列表，如果不传，则在返回的对象中不包含石材等级信息
   * @param showBundleGrades 是否显示大扎等级，默认显示
   */
  public getStoneArtifactInfoForList(
    item: ISalesOrderDetailForUI,
    categoryList: IIdNameItem[],
    gradeList: IIdNameItem[],
    showBundleGrades: boolean = true,
  ): IListItem {
    let listItem = Util.getEmptyListItemData();
    if (typeof item === "undefined" || item === null) {
      return listItem;
    }

    switch (item.type) {
      case StoneArtifactType.Block: // 库存荒料
        listItem = BlockUtil.getBlockInfoForList(
          item.block,
          categoryList,
          gradeList,
        );
        break;
      case StoneArtifactType.StoneBundle: // 扎
        listItem = StoneBundleUtil.getBundleInfoForList(
          item.bundle,
          categoryList,
          gradeList,
          showBundleGrades,
        );
        break;
      case StoneArtifactType.Tile: // 工程板
        listItem = TileUtil.getTilePackingCasesInfoForShippingOrder(
          item.tilePackingCase,
        );
        break;
    }

    return listItem;
  }

  public getStoneArtifactAvatarText(item: ISalesOrderDetailForUI): string {
    let text = "";
    if (!item) {
      return text;
    }
    switch (item.type) {
      case StoneArtifactType.Block:
        text = "荒料";
        break;
      case StoneArtifactType.StoneBundle:
        if (typeof item.bundle !== "undefined" && item.bundle !== null) {
          text = item.bundle.type === 10 ? "毛板" : "光板";
        }
        break;
      case StoneArtifactType.Tile:
        text = "工程";
        break;
    }
    return text;
  }

  /**
   * 将石材物品对象整理为向服务端提交数据的格式
   * @param item 石材物品对象
   */
  public getStoneArtifactItemForSubmitting(
    item: ISalesOrderDetailForUI,
  ): ISalesOrderDetailForUI {
    return Util.pick(
      item,
      "id",
      "blockId",
      "bundleId",
      "tilePackingCaseId",
      "type",
    );
  }

  /**
   * 从一个石材物品列表中获取指定的石材物品实例
   * @param items 石材物品列表
   * @param item 石材物品对象
   */
  public getStoneArtifactItem(
    items: ISalesOrderDetailForUI[],
    item: ISalesOrderDetailForUI,
  ): ISalesOrderDetailForUI {
    if (items.includes(item)) {
      return item;
    }

    const filterItems = items.filter((i) => {
      if (item.type !== i.type) {
        return false;
      }
      switch (item.type) {
        case StoneArtifactType.Block:
          return item.blockId === i.blockId;
          break;
        case StoneArtifactType.StoneBundle:
          return item.bundleId === i.bundleId;
          break;
        case StoneArtifactType.Tile:
          return item.tilePackingCaseId === i.tilePackingCaseId;
          break;
      }
      return false;
    });

    if (filterItems.length >= 1) {
      return filterItems[0];
    } else {
      return null;
    }
  }

  /**
   * 根据一个石材物品的状态和区域id得到是否显示其区域
   * @param item 石材物品对象
   */
  public getShowStockingArea(item: ISalesOrderDetailForUI): boolean {
    if (!Util.isDefinedAndNotNull(item)) {
      return false;
    }

    switch (item.type) {
      case StoneArtifactType.Block:
        return (
          Util.isDefinedAndNotNull(item.block.stockingAreaId) &&
          [
            BlockStatus.InStock,
            BlockStatus.Purchased,
            BlockStatus.Reserved,
            BlockStatus.Sold,
          ].includes(item.block.status) &&
          item.hasShipped !== true
        );
        break;
      case StoneArtifactType.StoneBundle:
        return (
          Util.isDefinedAndNotNull(item.bundle.stockingAreaId) &&
          [
            SlabBundleStatus.InStock,
            SlabBundleStatus.ReservedBySalesOrder,
            SlabBundleStatus.ReservedBySlabCheckOutRequest,
            SlabBundleStatus.Sold,
          ].includes(item.bundle.status) &&
          item.hasShipped !== true
        );
        break;
      case StoneArtifactType.Tile:
        return (
          Util.isDefinedAndNotNull(item.tilePackingCase.stockingAreaId) &&
          [
            TilePackingCaseStatus.InStock,
            TilePackingCaseStatus.Reserved,
            TilePackingCaseStatus.Sold,
          ].includes(item.tilePackingCase.status) &&
          item.hasShipped !== true
        );
        break;
    }
  }

  /**
   * 获取指定种类的石材物品
   * @param items 石材物品列表
   * @param artifactType 石材物品种类
   */
  public getStoneArtifactItems(
    items: ISalesOrderDetailForUI[],
    artifactType: StoneArtifactType,
  ) {
    return items.filter((i) => i.type === artifactType);
  }

  /**
   * 获取石材物品的物品Id，例如：如果石材物品是荒料，则获取该对象中的BlockId
   * @param item 石材物品对象
   */
  public getStoneArtifactItemId(item: ISalesOrderDetailForUI): number {
    if (!item) {
      return null;
    }

    switch (item.type) {
      case StoneArtifactType.Block:
        return item.blockId;
        break;
      case StoneArtifactType.StoneBundle:
        return item.bundleId;
        break;
      case StoneArtifactType.Tile:
        return item.tilePackingCaseId;
        break;
    }
    return null;
  }

  public getStoneArtifactItemIds(items: ISalesOrderDetailForUI[]): number[] {
    return items.map((i) => i.id);
  }

  public getStoneArtifactItemIdName(item: ISalesOrderDetailForUI): string {
    if (!item) {
      return null;
    }

    let itemName = "";

    switch (item.type) {
      case 10:
        itemName = "blockId";
        break;
      case 20:
        itemName = "bundleId";
        break;
      case 30:
        itemName = "tilePackingCaseId";
        break;
      default:
        return;
    }

    return itemName;
  }

  public getBlockItems<T extends ISalesOrderDetailForUI>(items: T[]): T[] {
    return items.filter((d) => d.type === StoneArtifactType.Block);
  }

  public getBundleItems<T extends ISalesOrderDetailForUI>(items: T[]): T[] {
    return items.filter((d) => d.type === StoneArtifactType.StoneBundle);
  }

  public getTilePackingCaseItems<T extends ISalesOrderDetailForUI>(
    items: T[],
  ): T[] {
    return items.filter((d) => d.type === StoneArtifactType.Tile);
  }
}

export default new StoneArtifactUtil();
