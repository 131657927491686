import * as React from "react";
import { Badge, Boxs } from "saltui";
import ESAvatar from "../ESAvatar";
import ESIcon from "../ESIcon";
import Util from "../Util";
const { HBox, Box, VBox } = Boxs;

export declare interface IListItemBaseProps {
    item?: object;
    avatarTitle?: string;
    title?: string | JSX.Element;
    className?: string;
    description?: string | JSX.Element;
    allowDeletion?: boolean;
    allowSelection?: boolean;
    showBadge?: boolean;
    badgeText?: string;
    badgeCorner?: string;
    badgeStyle?: string | React.CSSProperties;
    expanded?: boolean;
    selected?: SelectionStatus;
    onClick?: (item: object) => void;
    onDelete?: (item: object) => void;
    onSelect?: (item: object) => void;
}

declare interface IListItemProps extends IListItemBaseProps {
    allowExpansion?: boolean;
    avatarSize?: string;
    extraInfo?: JSX.Element;
    icon?: JSX.Element;
    onActionIconClick?: (item: object) => void;
    onExpansionChange?: (item: object) => void;
}

class ListItem extends React.PureComponent<IListItemProps> {
    public static defaultProps: IListItemProps;
    constructor(props) {
        super(props);
    }

    public render() {
        const t = this;

        const {
            avatarTitle,
            title,
            description,
            extraInfo,
            icon,
            allowDeletion,
            allowSelection,
            allowExpansion,
            expanded,
            avatarSize,
            className,
            showBadge,
            badgeText,
            badgeStyle,
            badgeCorner,
            ...other
        } = t.props;

        const avaSize = avatarSize !== null ? avatarSize : "40";
        const listClassName =
            className !== null ? className : "list-item t-FBH t-FBAC";

        let iconToShow = null;
        if (allowDeletion) {
            iconToShow = (
                <ESIcon
                    name={"icon-delete"}
                    className="icon-is iconField"
                    height={20}
                    width={20}
                />
            );
        } else if (allowSelection) {
            const fillingColor = t.getSelectionColor();
            iconToShow = (
                <ESIcon
                    name={"check-round"}
                    className="icon-is iconField"
                    fill={fillingColor}
                    height={20}
                    width={20}
                />
            );
        }
        if (iconToShow !== null) {
            iconToShow = (
                <div>
                    <div onClick={t.onIconClick}>{iconToShow}</div>
                    <div style={{ marginTop: "15px" }}>{icon}</div>
                </div>
            );
        } else {
            iconToShow = <div onClick={t.onIconClick}>{icon}</div>;
        }

        let expandedIcon = null;
        let iconName = null;
        iconName = expanded ? "icon-uparrow" : "icon-downarrow";
        if (allowExpansion) {
            const expandedIconClassName = allowSelection
                ? "icon-is iconField expandedIconStyl "
                : "icon-is iconField";
            expandedIcon = (
                <ESIcon
                    name={iconName}
                    className={expandedIconClassName}
                    fill="#1296db"
                    height={25}
                    width={25}
                />
            );
        }

        return (
            <div className={listClassName} onClick={t.onListItemClick}>
                {showBadge ? (
                    <Badge
                        text={badgeText}
                        corner={
                            Util.isNotNullAndNotEmpty(badgeCorner)
                                ? badgeCorner
                                : "lt"
                        }
                        style={badgeStyle}
                    />
                ) : null}
                <VBox flex={1}>
                    <HBox flex={1}>
                        <Box>
                            <ESAvatar
                                size={avaSize}
                                name={avatarTitle}
                                className="demo3-t-list-img"
                            />
                        </Box>
                        <Box className="listItemContentArea" flex={1}>
                            {title !== null && title !== "" ? (
                                <div className="demo3-t-list-title">
                                    {title}
                                </div>
                            ) : null}
                            {description !== null && description !== "" ? (
                                <div className="demo3-t-list-text">
                                    {description}
                                </div>
                            ) : null}
                        </Box>
                        {allowExpansion ? (
                            <Box onClick={t.onChangeExpanded}>
                                {expandedIcon}
                            </Box>
                        ) : null}
                        {iconToShow ? <Box>{iconToShow}</Box> : null}
                    </HBox>
                    {extraInfo}
                </VBox>
            </div>
        );
    }

    private onListItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const t = this;

        const { onClick, item } = t.props;

        if (onClick) {
            onClick(item);
        }

        if (e) {
            e.stopPropagation();
        }
    };

    private onIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const t = this;

        const { allowDeletion, allowSelection, item } = t.props;

        let iconClickEvent = null;

        if (allowDeletion) {
            iconClickEvent = t.props.onDelete;
        } else if (allowSelection) {
            iconClickEvent = t.props.onSelect;
        } else {
            iconClickEvent = t.props.onActionIconClick;
        }

        if (iconClickEvent) {
            iconClickEvent(item);
        }
        if (e) {
            e.stopPropagation();
        }
    };

    private onChangeExpanded = (e: React.MouseEvent<HTMLDivElement>) => {
        const t = this;
        const { allowExpansion, onExpansionChange, item } = t.props;

        if (allowExpansion) {
            if (onExpansionChange) {
                onExpansionChange(item);
            }

            if (e) {
                e.stopPropagation();
            }
        }
    };

    private getSelectionColor() {
        const t = this;
        const { selected, allowSelection } = t.props;
        if (!allowSelection) {
            return "";
        }

        let fillingColor = "";

        // 选择图标的颜色支持传入两种数据类型：
        // （1）bool类型：true———选中、false———未选中，用于只有一级选择的界面，比如blockselector界面
        // （2）string类型："all"———全选、"partially"———部分选中，、"none"———全部未选,用于级联选择的界面，比如slabcheckoutrequestselector界面
        if (typeof selected === "boolean") {
            fillingColor = selected ? "#00CC00" : "#999999";
            return fillingColor;
        } else if (typeof selected === "string") {
            switch (selected) {
                case "all":
                    fillingColor = "#00CC00";
                    break;
                case "partially":
                    fillingColor = "#4876FF";
                    break;
                case "none":
                    fillingColor = "#999999";
                    break;
                default:
                    break;
            }
            return fillingColor;
        }
    }
}

ListItem.defaultProps = {
    avatarTitle: "",
    avatarSize: null,
    title: null,
    description: null,
    extraInfo: null,
    icon: null,
    allowDeletion: false,
    allowSelection: false,
    allowExpansion: false,
    showBadge: false,
    badgeText: "",
    badgeStyle: null,
    badgeCorner: null,
    expanded: false,
    selected: false,
    className: null,
    item: null,
    onClick: item => {},
    onDelete: item => {},
    onSelect: item => {},
    onActionIconClick: item => {},
    onExpansionChange: item => {}
};

export default ListItem;
