import * as React from "react";
import { Field, Group } from "saltui";
import {
    IBlockForListDTO,
    IBlockTrimmingRecordDTO,
    IMachineryDTO
} from "../../app/WebAPIClients";
import SettingsDefinition from "../../components/SettingsDefinition";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import Settings from "../SettingInfo";
import TrimmingDetailInfo from "../TrimmingRecordInfo";
import Util from "../Util";
const { BlockStatus } = Consts;

export interface ICollapsibleTrimmingInfoProps {
    block: IBlockForListDTO;
    blockTrimmingRecords: IBlockTrimmingRecordDTO[];
    label?: string;
    showBlockInfo?: boolean;
    expanded?: boolean;
    allowDeletion?: boolean;
    factoryOptions: IOption[];
    machineries: IMachineryDTO[];
    categoryName?: string;
    className?: string;
    onDelete?: (item: IBlockTrimmingRecordDTO) => void;
}

export interface ICollapsibleTrimmingInfoState {
    enableBlockNumberFromQuarry: boolean;
}

class CollapsibleTrimmingInfo extends React.Component<
    ICollapsibleTrimmingInfoProps,
    ICollapsibleTrimmingInfoState
> {
    public static defaultProps: ICollapsibleTrimmingInfoProps;
    constructor(props: ICollapsibleTrimmingInfoProps) {
        super(props);
        this.state = {
            enableBlockNumberFromQuarry: Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            )
        };
    }

    public render() {
        const t = this;
        const s = t.state;

        const {
            block,
            blockTrimmingRecords,
            label,
            showBlockInfo,
            factoryOptions,
            machineries,
            className,
            categoryName,
            expanded,
            onDelete,
            allowDeletion
        } = t.props;
        const { blockNumber, blockNumberFromQuarry } = block;

        return (
            <Collapsible label={label} expanded={expanded}>
                <Group>
                    <Group.List lineIndent={15}>
                        {blockNumber === null || !showBlockInfo ? null : (
                            <Field label="荒料编号">
                                <span className="importantInfo">
                                    {blockNumber}
                                </span>
                            </Field>
                        )}
                        {categoryName === null || !showBlockInfo ? null : (
                            <Field label="石材种类">
                                <span className="importantInfo">
                                    {categoryName}
                                </span>
                            </Field>
                        )}
                        {!block.status || !showBlockInfo ? null : (
                            <Field label="荒料状态">
                                <span className="importantInfo">
                                    {BlockStatus.getStatus(block.status).text}
                                </span>
                            </Field>
                        )}
                        {s.enableBlockNumberFromQuarry &&
                        showBlockInfo &&
                        Util.isNotNullAndNotEmpty(blockNumberFromQuarry) ? (
                            <Field label="矿山荒料号">
                                <span className="importantInfo">
                                    {blockNumberFromQuarry}
                                </span>
                            </Field>
                        ) : null}
                        <TrimmingDetailInfo
                            blockTrimmingRecords={blockTrimmingRecords}
                            factoryOptions={factoryOptions}
                            machineries={machineries}
                            className={className}
                            allowDeletion={allowDeletion}
                            onDelete={onDelete}
                        />
                    </Group.List>
                </Group>
            </Collapsible>
        );
    }
}

CollapsibleTrimmingInfo.defaultProps = {
    block: null,
    blockTrimmingRecords: [],
    label: "历史修边信息",
    showBlockInfo: true,
    expanded: true,
    factoryOptions: [],
    machineries: [],
    className: "trimmingSelectorTimeLineStyl",
    categoryName: null,
    onDelete: item => {},
    allowDeletion: false
};

export default CollapsibleTrimmingInfo;
