import React from "react";
import { SelectField } from "saltui";
import { BlockTilePlanningLengthSource } from "../../app/WebAPIClients";

interface IBlockTilePlanningLengthSourceFieldProps {
    source?: BlockTilePlanningLengthSource;
    onChange?: (source?: BlockTilePlanningLengthSource) => void;
}

function BlockTilePlanningLengthSourceField(
    props: IBlockTilePlanningLengthSourceFieldProps
) {
    const { source, onChange } = props;
    const options: IOption[] = [
        { value: 0, text: "不指定长度适配来源" },
        {
            value: BlockTilePlanningLengthSource.BlockLength,
            text: "荒料长度适配规格板长度"
        },
        {
            value: BlockTilePlanningLengthSource.BlockWidth,
            text: "荒料宽度适配规格板长度"
        }
    ];

    const selectedOption =
        source === null ? null : options.find(o => o.value === source);

    const onSelect: (value: IOption) => void = value => {
        const selectedSource: number | null =
            value === null || value.value === 0
                ? null
                : (value.value as number);
        if (onChange) {
            onChange(selectedSource);
        }
    };

    return (
        <SelectField
            label="长度来源"
            options={options}
            onSelect={onSelect}
            value={selectedOption}
            placeholder="请选择长度适配来源"
        />
    );
}

export default BlockTilePlanningLengthSourceField;
