import React from "react";

interface IListFooterProps {
    totalRecordCount?: number;
    className?: string;
    content?: string | JSX.Element | JSX.Element[];
    unit?: string;
}

class ListFooter extends React.PureComponent<IListFooterProps> {
    public static defaultProps: IListFooterProps;

    public render(): JSX.Element {
        const t = this;
        const { totalRecordCount, className, content, unit } = t.props;

        const footerClassName =
            "footerInfo" + (className ? " " + className : "");
        const footerContent = content ? (
            content
        ) : (
            <div>
                {"共："}
                <span className="unitPriceText">{totalRecordCount}</span>
                {" " + unit}
            </div>
        );
        return <div className={footerClassName}>{footerContent}</div>;
    }
}

ListFooter.defaultProps = {
    totalRecordCount: 0,
    className: null,
    content: null,
    unit: "条"
};

export default ListFooter;
