import React, { useEffect, useState } from "react";
import { Table } from "saltui";
import Util from "../Util";
import {
    IBlockSlabPlanningItemDTO,
    IStoneGradeDTO
} from "../../app/WebAPIClients";
import Consts from "../Consts";
import PercentageInput from "./PercentageInput";
import {
    IPlanningOutputRatesForBackend,
    ISlabPlanningItemForUI
} from "./interfaces";
import PlanningRecordUtil from "./PlanningRecordUtil";

const { StoneGrades } = Consts;

interface ISlabPlanningItemTableProps {
    allowEdit?: boolean;
    items: IBlockSlabPlanningItemDTO[];
    onChange?: (outputRates: IPlanningOutputRatesForBackend) => void;
}

function SlabPlanningItemTable(props: ISlabPlanningItemTableProps) {
    const { items, allowEdit, onChange } = props;
    const [grades, setGrades] = useState<IStoneGradeDTO[]>([]);
    const [planningItems, setPlanningItems] = useState<
        ISlabPlanningItemForUI[]
    >([]);

    useEffect(
        () => {
            const loadGrades = async () => {
                const result = await StoneGrades.getGrades();
                const sortedGrades = [...result];
                sortedGrades.sort((a, b) => {
                    return a.id - b.id;
                });

                setGrades(sortedGrades);

                if (allowEdit) {
                    const allItems = sortedGrades.map<ISlabPlanningItemForUI>(
                        g => {
                            const item = items.find(i => i.gradeId === g.id);
                            if (item) {
                                return item;
                            }
                            return { gradeId: g.id, outputRate: null };
                        }
                    );
                    setPlanningItems(allItems);
                } else {
                    if (Util.isDefinedAndNotNull(items)) {
                        items.sort((a, b) => {
                            return a.gradeId - b.gradeId;
                        });
                    }
                    setPlanningItems(items);
                }
            };

            loadGrades();
        },
        [items]
    );

    if (planningItems.length === 0) {
        return <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">无数据</div>;
    }

    const changePlanningItem: (
        item: ISlabPlanningItemForUI,
        changeFunc: (item: ISlabPlanningItemForUI) => void
    ) => void = (oldItem, changeFunc) => {
        const newItems = [...planningItems];
        const idx = newItems.indexOf(oldItem);
        console.log("idx:", idx);
        if (idx === -1) {
            return;
        }
        const newItem = { ...oldItem };
        changeFunc(newItem);
        newItems.splice(idx, 1, newItem);
        setPlanningItems(newItems);
        if (onChange) {
            const outputRates = PlanningRecordUtil.convertSlabPlanningItemsToOutputRates(
                newItems
            );
            onChange(outputRates);
        }
    };

    const onRateChange: (
        item: ISlabPlanningItemForUI,
        newRate: number | null
    ) => void = (item, newRate) => {
        changePlanningItem(item, newItem => (newItem.outputRate = newRate));
    };

    const columns = [
        {
            dataKey: "gradeId",
            title: "等级",
            width: 0.5,
            align: "center",
            render: (cellData, rowData: ISlabPlanningItemForUI) => {
                var sg = grades.find(c => c.id === rowData.gradeId);
                return Util.isDefinedAndNotNull(sg)
                    ? sg.name
                    : `未知（Id: ${rowData.gradeId}`;
            }
        },
        {
            dataKey: "outputRate",
            title: "出材率",
            width: 0.5,
            align: "center",
            render: (cellData, rowData: ISlabPlanningItemForUI) => {
                return !allowEdit ? (
                    Util.toPercent(rowData.outputRate)
                ) : (
                    <PercentageInput
                        item={rowData}
                        rate={rowData.outputRate}
                        onChange={onRateChange}
                    />
                );
            }
        }
    ];

    return <Table columns={columns} data={{ data: planningItems }} />;
}

export default SlabPlanningItemTable;
