import * as React from "react";
import { ISlabGradeChangeRequestForListDTO } from "../../app/WebAPIClients";
import ApprovalStatusUtil from "../ApprovalStatusUtil";
import Util from "../Util";
import ListItem from "../ListItem";

export default class SGCRHelper {
  public static sortSlabsOrContents = <
    T extends { bundlePrefix: string; sequenceNumber: number },
  >(
    slabsOrContents: T[],
  ): T[] => {
    if (!Util.isDefinedAndNotNull(slabsOrContents)) {
      return [];
    }

    return slabsOrContents.sort((a, b) => {
      if (a.bundlePrefix < b.bundlePrefix) {
        return -1;
      }
      if (a.bundlePrefix > b.bundlePrefix) {
        return 1;
      }
      if (a.sequenceNumber < b.sequenceNumber) {
        return -1;
      }
      if (a.sequenceNumber > b.sequenceNumber) {
        return 1;
      }
      return 0;
    });
  };

  public static getSGCRListItem(
    request: ISlabGradeChangeRequestForListDTO,
    onClick: (item: ISlabGradeChangeRequestForListDTO) => void,
  ): JSX.Element {
    const title = this.getListItemTitle(request);
    const description = this.getListItemDescription(request);

    return (
      <ListItem
        key={request.id}
        item={request}
        avatarTitle={request.orderNumber}
        title={title}
        description={description}
        onClick={onClick}
      />
    );
  }

  public static getListItemTitle(
    request: ISlabGradeChangeRequestForListDTO,
  ): JSX.Element {
    return (
      <span>
        {request.orderNumber}
        {" | "}
        {ApprovalStatusUtil.getStatusJSX(request.status)}
      </span>
    );
  }

  public static getListItemDescription(
    request: ISlabGradeChangeRequestForListDTO,
  ): JSX.Element {
    return (
      <span>
        {"总片数："}
        {request.slabCount}
      </span>
    );
  }
}
