import * as React from "react";
import { Boxs, Group } from "saltui";
import ESIcon from "../ESIcon";
import Util from "../Util";

const HBox = Boxs.HBox;
const Box = Boxs.Box;

export interface IESHeaderProps extends React.Props<ESHeader> {
    label: string;
    onCreateClick?: () => void;
    onEditClick?: () => void;
    extraIcons?: JSX.Element;
    allowCreation?: boolean;
    allowEdit?: boolean;
    fontSize?: string;
}

class ESHeader extends React.Component<IESHeaderProps, null> {
    public static defaultProps: IESHeaderProps;
    constructor(props: IESHeaderProps) {
        super(props);
    }

    public render() {
        const t = this;

        const {
            label,
            extraIcons,
            allowCreation,
            allowEdit,
            fontSize
        } = t.props;
        return (
            <div>
                <Group.Head>
                    <HBox vAlign="center">
                        <Box flex={1}>
                            {Util.isDefinedAndNotNull(fontSize) ? (
                                <span style={{ fontSize: fontSize }}>
                                    {label}
                                </span>
                            ) : (
                                label
                            )}
                        </Box>
                        {extraIcons}
                        {allowCreation ? (
                            <Box className={"scanStyle"}>
                                <span onClick={t.handleOnCreateClick}>
                                    <ESIcon
                                        name="icon-jiahao"
                                        fill="#1296db"
                                        height={25}
                                        width={25}
                                    />
                                </span>
                            </Box>
                        ) : null}
                        {allowEdit ? (
                            <Box className={"scanStyle"}>
                                <span onClick={t.handleOnEditClick}>
                                    <ESIcon
                                        name="icon-edit"
                                        fill="#1296db"
                                        height={25}
                                        width={25}
                                    />
                                </span>
                            </Box>
                        ) : null}
                    </HBox>
                </Group.Head>
            </div>
        );
    }

    private handleOnCreateClick = e => {
        const t = this;
        const { onCreateClick } = t.props;

        if (onCreateClick) {
            onCreateClick();
        }

        if (e) {
            e.stopPropagation();
        }
    };

    private handleOnEditClick = e => {
        const t = this;
        const { onEditClick } = t.props;

        if (onEditClick) {
            onEditClick();
        }

        if (e) {
            e.stopPropagation();
        }
    };
}

ESHeader.defaultProps = {
    label: null,
    extraIcons: null,
    allowCreation: false,
    allowEdit: false,
    onCreateClick: () => {},
    onEditClick: () => {}
};

export default ESHeader;
