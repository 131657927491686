import React from "react";
import { Group } from "saltui";
import { ISlabGradeChangeRequestForListDTO } from "../../app/WebAPIClients";
import ESHeader from "../ESHeader";
import SGCRHelper from "./SGCRHelper";

declare interface ISGCRListProps {
  requests: ISlabGradeChangeRequestForListDTO[];
  showCreateItemIcon?: boolean;
  showHeader?: boolean;
  onCreateItemClick?: () => void;
  onItemClick: (request: ISlabGradeChangeRequestForListDTO) => void;
}

class SGCRList extends React.Component<ISGCRListProps, {}> {
  public static defaultProps: ISGCRListProps;
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      requests,
      showCreateItemIcon,
      showHeader,
      onCreateItemClick,
      onItemClick,
    } = this.props;

    return (
      <div>
        <Group>
          {!showHeader ? null : (
            <ESHeader
              label={"等级变更单列表"}
              allowCreation={showCreateItemIcon}
              onCreateClick={onCreateItemClick}
            />
          )}
          <Group.List lineIndent={15} itemIndent={[15, 15]}>
            {requests && requests.length && requests.length > 0 ? (
              requests.map((req) =>
                SGCRHelper.getSGCRListItem(req, onItemClick),
              )
            ) : (
              <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">没有数据</div>
            )}
          </Group.List>
        </Group>
      </div>
    );
  }
}
SGCRList.defaultProps = {
  requests: null,
  showCreateItemIcon: true,
  showHeader: true,
  onItemClick: () => {},
  onCreateItemClick: () => {},
};

export default SGCRList;
