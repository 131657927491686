import React, { MouseEventHandler, useState } from "react";
import { Group, RadioField } from "saltui";
import ESIcon from "../ESIcon";
import Picker from "../Picker/Picker";
import ButtonGroup from "../ButtonGroup/ButtonGroup";

interface IBundleListSortingSelectorIconProps {
    sortingField: BundleListSortingField;
    onChange?: (sortingField: BundleListSortingField) => void;
}

function BundleListSortingSelectorIcon(
    props: IBundleListSortingSelectorIconProps
) {
    const { sortingField, onChange } = props;
    const [pickerVisible, setPickerVisible] = useState(false);
    const [field, setField] = useState<BundleListSortingField>(sortingField);

    const onPickerVisibleChange: (visible: boolean) => void = visible => {
        setPickerVisible(visible);
    };

    const onShowPickerIconClick: MouseEventHandler<HTMLElement> = event => {
        setPickerVisible(true);
        event.stopPropagation();
    };

    const onPickerGoBackClick: () => void = () => {
        window.history.back();
    };

    const onPickerSubmitClick: () => void = () => {
        if (onChange) {
            onChange(field);
        }
        window.history.back();
    };

    const onFieldChange: (value: string) => void = value => {
        let fieldName: BundleListSortingField =
            value !== "StockingArea.Name" ? null : "StockingArea.Name";
        setField(fieldName);
    };

    const fieldOptionData = [
        {
            value: "Default",
            checked: field !== "StockingArea.Name",
            content: "默认排序",
            disable: false
        },
        {
            value: "StockingArea.Name",
            checked: field === "StockingArea.Name",
            content: "按存储区域排序",
            disable: false
        }
    ];

    return (
        <div>
            <ESIcon
                name="icon-sorting"
                fill="#1296db"
                onClick={onShowPickerIconClick}
                height={25}
                width={25}
            />
            <Picker
                visible={pickerVisible}
                onVisibleChange={onPickerVisibleChange}
                className="t-picker-searchpanel-inner"
            >
                <Group>
                    <RadioField
                        label="扎列表排序字段"
                        iconPosition="right"
                        data={fieldOptionData}
                        onChange={onFieldChange}
                    />
                    <Group.List>
                        <ButtonGroup
                            onGoBack={onPickerGoBackClick}
                            onSubmit={onPickerSubmitClick}
                        />
                    </Group.List>
                </Group>
            </Picker>
        </div>
    );
}

export default BundleListSortingSelectorIcon;
