import * as React from "react";
import { Field, SwitchField } from "saltui";
import {
    IAppliedProcessDTO,
    ProcessDisplayType
} from "../../app/WebAPIClients";

declare interface IAppliedProcessInfoProps
    extends React.Props<AppliedProcessInfo> {
    appliedProcess: IAppliedProcessDTO;
}

class AppliedProcessInfo extends React.Component<IAppliedProcessInfoProps, {}> {
    public static defaultProps: IAppliedProcessInfoProps;

    public render() {
        const t = this;

        const { appliedProcess } = t.props;
        if (!appliedProcess) {
            return;
        }

        return (
            <div>
                <Field label={appliedProcess.name}>
                    {appliedProcess.quantity + " " + appliedProcess.unit}
                </Field>
            </div>
        );
    }
}

AppliedProcessInfo.defaultProps = {
    appliedProcess: null
};

export default AppliedProcessInfo;
