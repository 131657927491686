import React from "react";
import { Table } from "saltui";
import Util from "../Util";
import {
    BlockTilePlanningLengthSource,
    IBlockTilePlanningItemDTO
} from "../../app/WebAPIClients";

interface ITilePlanningItemTableProps {
    items: IBlockTilePlanningItemDTO[];
}

function TilePlanningItemTable(props: ITilePlanningItemTableProps) {
    const { items } = props;
    const noDataComponent = (
        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">无数据</div>
    );

    if (items.length === 0) {
        return noDataComponent;
    }

    const sortedItems = [...items];

    sortedItems.sort((a, b) => {
        if (a.source !== b.source) {
            return a.source - b.source;
        }

        if (a.tileLength !== b.tileLength) {
            return a.tileLength - b.tileLength;
        }

        return a.tileWidth - b.tileWidth;
    });

    const columns = [
        {
            dataKey: "tileLength",
            title: "长度",
            align: "center"
        },
        {
            dataKey: "tileWidth",
            title: "宽度",
            align: "center"
        },
        {
            dataKey: "outputRate",
            title: "出材率",
            align: "center",
            render: (cellData, rowData: IBlockTilePlanningItemDTO) => {
                return `${Util.round(rowData.outputRate * 100, 0)}%`;
            }
        },
        {
            dataKey: "source",
            title: "长度来源",
            align: "center",
            render: (cellData, rowData: IBlockTilePlanningItemDTO) => {
                return rowData.source ===
                    BlockTilePlanningLengthSource.BlockLength
                    ? "荒料长"
                    : "荒料宽";
            }
        }
    ];

    return <Table columns={columns} data={{ data: sortedItems }} />;
}

export default TilePlanningItemTable;
