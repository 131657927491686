import React from "react";
import { Boxs, Mask } from "saltui";
import "whatwg-fetch";
import { IImagingDeviceConnectionInfoDTO } from "../../app/WebAPIClients";
import ESIcon from "../ESIcon";
import "./MJPEGVideoPreviewer.styl";
const { HBox, Box } = Boxs;

export interface IMJPEGVideoPreviewerProps
    extends React.Props<MJPEGVideoPreviewer> {
    visible: boolean;
    cameraConnectionInfo: IImagingDeviceConnectionInfoDTO;
    onClose: () => void;
    onPhotoShot?: (slabInfo: ISlabTileInfoForVideoPreviewer) => void;
    slabTileInfo: ISlabTileInfoForVideoPreviewer;
}

export interface IMJPEGVideoPreviewerState {
    visible?: boolean;
    loggedIn?: boolean;
    loginMsg?: string;
    liveStreaming?: boolean;
    imageCapturing?: boolean;
    imageCaptured?: boolean;
    imageConfirmed?: boolean;
    slabTileInfo?: ISlabTileInfoForVideoPreviewer;
}

class MJPEGVideoPreviewer extends React.Component<
    IMJPEGVideoPreviewerProps,
    IMJPEGVideoPreviewerState
> {
    public static defaultProps: IMJPEGVideoPreviewerProps;
    private frameRate: number;
    private img: HTMLImageElement;
    constructor(props: IMJPEGVideoPreviewerProps) {
        super(props);

        // 设置到 state 作为渲染的依据
        this.state = {
            visible: props.visible,
            loggedIn: false,
            loginMsg: "正在登录图像采集仪...",
            liveStreaming: false, // 正在预览实时视频
            imageCapturing: false, // 正在抓拍
            imageCaptured: false, // 完成抓拍
            imageConfirmed: false // 确认抓拍图片
        };

        this.frameRate = 10;
    }

    // 属性变化时把响应状态设置到 state
    public async componentWillReceiveProps(nextProps) {
        const s = this.state;

        if (s.visible === nextProps.visible) {
            return;
        }

        let changeState: {
            visible?: boolean;
            slabInfo?: ISlabTileInfoForVideoPreviewer;
            imageCapturing?: boolean;
            imageCaptured?: boolean;
            imageConfirmed?: boolean;
        } = {};
        changeState.visible = nextProps.visible;
        changeState.slabInfo = nextProps.slabInfo;
        if (nextProps.visible) {
            changeState = {
                ...changeState,
                imageCapturing: false,
                imageCaptured: false,
                imageConfirmed: false
            };
        }
        this.setState(changeState);
        if (changeState.visible) {
            if (!this.state.loggedIn) {
                await this.logIntoCamera();
            } else if (!this.state.liveStreaming) {
                this.setState({ liveStreaming: true });
            }
        }
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;

        const { loggedIn, visible, liveStreaming, imageCaptured } = s;

        let img = null; // 图片控件，用于：1、进行MJPEG视频实时预览，2、抓拍完成后显示大板预览图
        const buttons = []; // 按钮数组，用于根据条件显示：1、抓拍按钮，2、重拍按钮，3、确认按钮
        if (loggedIn) {
            const imgUrl = liveStreaming
                ? this.getLiveStreamImageSrc()
                : imageCaptured
                    ? this.getPreviewImageSrc()
                    : null;
            let imgProps = {};
            if (liveStreaming) {
                imgProps = { onLoad: t.onImageLoad };
            }
            img = (
                <img
                    className="videoPreviewerImg"
                    ref={i => {
                        this.img = i;
                    }}
                    src={imgUrl}
                    {...imgProps}
                />
            );

            const iconProps: {
                width: number;
                height: number;
                onClick?: () => {};
            } = { width: 60, height: 60 };

            if (imageCaptured) {
                // 如果已经抓拍了图片，则显示重新抓拍和确认两个按钮
                buttons.push(
                    <Box key={1} flex={1}>
                        <ESIcon
                            name="icon-retakePhoto"
                            fill="#FFFFFF"
                            onClick={t.retakePhoto}
                            {...iconProps}
                        />
                    </Box>
                );
                buttons.push(<Box key={2} flex={1} />);
                buttons.push(
                    <Box key={3} flex={1}>
                        <ESIcon
                            name="icon-confirmPhoto"
                            fill="#FFFFFF"
                            onClick={t.confirmPhoto}
                            {...iconProps}
                        />
                    </Box>
                );
            } else {
                // 如果没有完成抓拍，则显示抓拍按钮，如果没有登录或者正在抓拍，仍然显示抓拍按钮，不过将其显示为灰色并不附加点击事件
                const fillColor = liveStreaming ? "#FFFFFF" : "#999999";
                if (liveStreaming) {
                    iconProps.onClick = t.captureImage;
                }
                const icon = (
                    <ESIcon
                        name="icon-photoshot"
                        fill={fillColor}
                        {...iconProps}
                    />
                );
                buttons.push(
                    <Box key={4} flex={1}>
                        {icon}
                    </Box>
                );
            }
        }

        return (
            <div>
                <Mask
                    visible={visible}
                    opacity={0.85}
                    className="videoPreviewerMask"
                />
                {!visible ? null : (
                    <div className="videoPreviewerContainer">
                        <div
                            className="videoPreviewerCloseIconArea"
                            onClick={t.onClose}
                        >
                            <ESIcon
                                name="icon-close"
                                width={40}
                                height={40}
                                fill="#d81e06"
                            />
                        </div>
                        {!s.loggedIn ? (
                            <div className="videoPreviewerImgPlaceHolder">
                                {s.loginMsg}
                            </div>
                        ) : (
                            <div className="videoPreviewerImgContainer">
                                {img}
                            </div>
                        )}
                        <div className="videoPreviewerShotIconArea">
                            <HBox className="videoPreviewerIcon">
                                {buttons}
                            </HBox>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private isItemSlab(slabTileInfo: ISlabTileInfoForVideoPreviewer) {
        return (
            typeof slabTileInfo.slabId !== "undefined" &&
            slabTileInfo.slabId !== null
        );
    }

    private getItemId(slabTileInfo: ISlabTileInfoForVideoPreviewer): number {
        const isSlab = this.isItemSlab(slabTileInfo);
        if (isSlab) {
            return slabTileInfo.slabId;
        }
        return slabTileInfo.tileId;
    }

    private getCameraWebAPIBaseUrl() {
        const t = this;
        const connInfo = t.props.cameraConnectionInfo;

        return `http://${connInfo.ipAddress}:${connInfo.webAPIPort}/api`;
    }

    private getCameraWebAPIUrl(relativeUrl) {
        let baseUrl = this.getCameraWebAPIBaseUrl();
        if (!baseUrl.endsWith("/")) {
            baseUrl += "/";
        }

        return baseUrl + relativeUrl;
    }

    private getPreviewImageSrc() {
        const { slabTileInfo } = this.props;

        const isSlab = this.isItemSlab(slabTileInfo);
        const itemId = this.getItemId(slabTileInfo);
        return this.getCameraWebAPIUrl(
            `${isSlab ? "Slabs" : "Tiles"}/${itemId}/Image/Preview`
        );
    }

    private getLiveStreamImageSrc() {
        return this.getCameraWebAPIUrl(
            "Images/LatestPreviewImage?time=" + new Date().getTime()
        );
    }

    private getCaptureImageUrl() {
        const { slabTileInfo } = this.props;

        const isSlab = this.isItemSlab(slabTileInfo);
        const queryString = this.getItemQueryString(slabTileInfo);
        return this.getCameraWebAPIUrl(
            `Images/Capture${isSlab ? "Slab" : "Tile"}Image${queryString}`
        );
    }

    private getConfirmImageUrl() {
        const { slabTileInfo } = this.props;

        const isSlab = this.isItemSlab(slabTileInfo);
        const itemId = this.getItemId(slabTileInfo);
        return this.getCameraWebAPIUrl(
            `${isSlab ? "Slabs" : "Tiles"}/${itemId}/Image/UploadQueueItem`
        );
    }

    private getItemQueryString(
        slabTileInfo: ISlabTileInfoForVideoPreviewer
    ): string {
        const isSlab = this.isItemSlab(slabTileInfo);
        const itemId = this.getItemId(slabTileInfo);
        return (
            `?id=${itemId}` +
            (isSlab
                ? `&blockNumber=${encodeURIComponent(
                      slabTileInfo.blockNumber
                  )}&sequenceNumber=${slabTileInfo.slabSequenceNumber}`
                : `&tileNumber=${encodeURIComponent(slabTileInfo.tileNumber)}`)
        );
    }

    private onClose = () => {
        const onPreviewerClose = this.props.onClose;
        this.setState({ visible: false });
        if (onPreviewerClose) {
            onPreviewerClose();
        }
    };

    private onImageLoad = () => {
        const t = this;
        const s = t.state;

        if (!s.visible || !s.liveStreaming) {
            return;
        }

        const frameRate = this.frameRate;
        const timeout = 1000 / frameRate;
        window.setTimeout(() => {
            this.img.src = this.getLiveStreamImageSrc();
        }, timeout);
    };

    private async logIntoCamera() {
        const t = this;
        const s = t.state;

        if (s.loggedIn) {
            return;
        }

        const { slabTileInfo, cameraConnectionInfo } = t.props;

        if (cameraConnectionInfo === null) {
            t.setState({
                loggedIn: false,
                loginMsg: "图像采集仪连接信息为空，请联系管理员"
            });
            return;
        }

        if (
            slabTileInfo === null ||
            ((typeof slabTileInfo.slabId === "undefined" ||
                slabTileInfo.slabId === null) &&
                (typeof slabTileInfo.tileId === "undefined" ||
                    slabTileInfo.tileId === null))
        ) {
            t.setState({
                loggedIn: false,
                loginMsg: "未设置大板或工程板信息，请联系管理员"
            });
            return;
        }

        const { userName, password } = t.props.cameraConnectionInfo;
        const params = `userName=${encodeURIComponent(
            userName
        )}&password=${encodeURIComponent(password)}`;
        const loginUrl = t.getCameraWebAPIUrl(
            `Security/LoginByPassword?${params}`
        );
        try {
            const response = await fetch(loginUrl, { credentials: "include" });
            if (response.status >= 200 && response.status < 300) {
                t.setState({
                    loggedIn: true,
                    liveStreaming: true,
                    loginMsg: "登录成功"
                });
            } else {
                throw new FetchError(response);
            }
        } catch (e) {
            const err = e as Error;
            t.setState({
                loggedIn: false,
                loginMsg: `登录失败，错误消息：${err.message}`
            });
        }
    }

    private captureImage = async () => {
        const t = this;
        const s = t.state;

        if (!s.loggedIn) {
            return;
        }

        t.setState({ liveStreaming: false, imageCapturing: true });

        const url = t.getCaptureImageUrl();
        try {
            const response = await fetch(url, {
                credentials: "include",
                method: "GET",
                mode: "cors"
            });

            if (response.status >= 200 && response.status < 300) {
                t.setState({
                    liveStreaming: false,
                    imageCaptured: true,
                    imageCapturing: false
                });
            } else {
                throw new FetchError(response);
            }
        } catch (e) {
            const err = e as Error;
            t.setState({
                liveStreaming: true,
                imageCaptured: false,
                imageCapturing: false,
                loginMsg: `拍照失败，错误消息：${err.message}`
            });
        }
    };

    private retakePhoto = () => {
        this.setState({
            liveStreaming: true,
            imageCaptured: false,
            imageCapturing: false,
            imageConfirmed: false
        });
    };

    private confirmPhoto = async () => {
        const t = this;
        const s = t.state;

        if (!s.loggedIn) {
            return;
        }

        const url = t.getConfirmImageUrl();
        const data = [{ op: "replace", path: "/status", value: 20 }];
        try {
            const response = await fetch(url, {
                body: JSON.stringify(data),
                credentials: "include",
                headers: { "content-type": "application/json" },
                method: "PATCH",
                mode: "cors"
            });

            if (response.status >= 200 && response.status < 300) {
                t.setState({ imageConfirmed: true });
                const onPhotoShot = t.props.onPhotoShot;
                if (onPhotoShot) {
                    onPhotoShot(t.props.slabTileInfo);
                }
                t.onClose();
            } else {
                throw new FetchError(response);
            }
        } catch (e) {
            const err = e as Error;
            t.setState({
                loginMsg: `确认照片失败，请重试，错误消息：${err.message}`
            });
        }
    };
}

MJPEGVideoPreviewer.defaultProps = {
    cameraConnectionInfo: null,
    slabTileInfo: null,
    visible: false,
    onPhotoShot: slabInfo => {},
    onClose: () => {}
};

export default MJPEGVideoPreviewer;
