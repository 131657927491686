import Perm from "../../app/Perm";
import {
  IBundleForListDTO,
  SalesOrderType,
  SlabBundleStatus,
} from "../../app/WebAPIClients";
import ProductStockingAreaStore from "../../store/ProductStockingAreaStore";
import PermCtrl from "../PermCtrl";
import Settings from "../SettingInfo";
import SettingsDefinition from "../SettingsDefinition";
import Util from "../Util";

export default class BundlesWithSamePrefixHelper {
  /**
   * Get a list of bundles with the same prefix and in stock in the same factory of the specified bundle
   * @param bundle The bundle to match the prefix and the factory Id to get the result
   * @param bundlesWithSamePrefixes A list containing all bundles with the same prefixes as the resource to get the result
   * @returns A list of bundles with the same prefix and in stock in the same factory
   */
  public static async getBundlesShouldBeSoldTogether(
    bundle: IBundleForListDTO,
    bundlesWithSamePrefixes: IBundleForListDTO[],
    salesOrderDetails: Array<{ bundleId: number | null }>,
  ): Promise<IBundleForListDTO[]> {
    if (!Util.isDefinedAndNotNull(bundle)) {
      return [];
    }

    if (
      !Util.isDefinedAndNotNull(bundlesWithSamePrefixes) ||
      bundlesWithSamePrefixes.length === 0
    ) {
      return [];
    }

    const bundleFactoryId =
      await ProductStockingAreaStore.getFactoryIdByStockingAreaId(
        bundle.stockingAreaId,
      );

    if (!Util.isDefinedAndNotNull(bundleFactoryId)) {
      return [];
    }

    let bundlesShouldBeSoldTogether = [];
    for (let i = 0; i < bundlesWithSamePrefixes.length; i++) {
      const b = bundlesWithSamePrefixes[i];
      if (b.prefix !== bundle.prefix) {
        continue;
      }
      if (
        !(
          b.status === SlabBundleStatus.InStock ||
          (b.status === SlabBundleStatus.ReservedBySalesOrder &&
            salesOrderDetails.some((s) => s.bundleId === b.id)) ||
          (b.status === SlabBundleStatus.Sold &&
            salesOrderDetails.some((s) => s.bundleId === b.id))
        )
      ) {
        continue;
      }
      const factoryId =
        await ProductStockingAreaStore.getFactoryIdByStockingAreaId(
          b.stockingAreaId,
        );
      if (factoryId === bundleFactoryId) {
        bundlesShouldBeSoldTogether.push(b);
      }
    }

    return bundlesShouldBeSoldTogether;
  }

  /**
   * Determine if a stocking area is in a factory which is configured to sell bundles by whole block
   * @param stockingAreaId The stocking area id
   * @returns true if the stocking area is in a factory which was configured to sell bundles by whole block, otherwise false
   */
  public static async isStockingAreaInFactoryConfiguredToSellBundlesByWholeBlock(
    stockingAreaId: number | null,
  ) {
    if (!Util.isDefinedAndNotNull(stockingAreaId)) {
      return false;
    }

    const factoryId =
      await ProductStockingAreaStore.getFactoryIdByStockingAreaId(
        stockingAreaId,
      );

    return this.isFactoryConfiguredToSellBundlesByWholeBlock(factoryId);
  }

  /**
   * Determine if a factory is configured to sell bundles by whole block
   * @param factoryId The factory id
   * @returns true if the factory was configured to sell bundles by whole block, otherwise false
   */
  public static isFactoryConfiguredToSellBundlesByWholeBlock(
    factoryId: number | null,
  ) {
    if (!Util.isDefinedAndNotNull(factoryId)) {
      return false;
    }

    const factoryIdsForSellingBundlesByWholeBlock = Settings.getValue(
      SettingsDefinition.FactoryIdsForSellingBundlesByWholeBlockKey,
    );
    if (!Util.isNotNullAndNotEmpty(factoryIdsForSellingBundlesByWholeBlock)) {
      return false;
    }

    const factoryIds = factoryIdsForSellingBundlesByWholeBlock
      .split(",")
      .map((f) => parseInt(f));
    return factoryIds.some((f) => f === factoryId);
  }

  /**
   * Check if the current user or the given sales order can ignore the selling by whole block rule
   * @param salesOrder The sales order to check the creation date and order type
   * @returns true if the current user or the given sales order can ignore the selling by whole block rule, otherwise false
   */
  public static canIgnoreSellingByWholeBlockRule(salesOrder: {
    id: number;
    orderType: SalesOrderType;
    createdTime: Date;
  }) {
    if (PermCtrl.isAuthorized(Perm.SO_IWR)) {
      return true;
    }
    if (!salesOrder) {
      return false;
    }
    return (
      salesOrder.orderType === SalesOrderType.PolishedSlabInStock &&
      salesOrder.id > 0 &&
      salesOrder.createdTime < new Date(2023, 10, 16)
    );
  }
}
