import React, { useState } from "react";
import { Table } from "saltui";
import { ICostPriceSpec } from "../type";
import AmountInput from "./AmountInput";
import ESIcon from "../ESIcon";

interface ICostPriceSpecsTableProps {
    allowEdit?: boolean;
    costPriceSpecs: ICostPriceSpec[];
    onChange?: (specs: ICostPriceSpec[]) => void;
}

const CostPriceSpecsTable = (props: ICostPriceSpecsTableProps) => {
    const { costPriceSpecs, onChange, allowEdit = false } = props;

    if (!(costPriceSpecs && costPriceSpecs.length)) {
        return null;
    }

    const sortedSpecs = [...costPriceSpecs];

    sortedSpecs.sort((a, b) => {
        if (a.thickness === null && b.thickness === null) {
            return 0;
        } else if (a.thickness === null && b.thickness !== null) {
            return 1;
        } else if (a.thickness !== null && b.thickness === null) {
            return -1;
        } else {
            return a.thickness - b.thickness;
        }
    });

    const [specs, setSpecs] = useState(sortedSpecs);

    const changeSpec: (
        spec: ICostPriceSpec,
        changeFunc: (spec: ICostPriceSpec) => void
    ) => void = (oldSpec, changeFunc) => {
        const newSpecs = [...specs];
        const idx = newSpecs.indexOf(oldSpec);
        console.log("idx:", idx);
        if (idx === -1) {
            return;
        }
        const newSpec = { ...oldSpec };
        changeFunc(newSpec);
        newSpecs.splice(idx, 1, newSpec);
        setSpecs(newSpecs);
        if (onChange) {
            onChange(newSpecs);
        }
    };

    const deleteSpec: (spec: ICostPriceSpec) => void = spec => {
        let newSpecs = [...specs];
        newSpecs = newSpecs.filter(s => s !== spec);
        setSpecs(newSpecs);
        if (onChange) {
            onChange(newSpecs);
        }
    };

    const onThicknessChange: (
        item: ICostPriceSpec,
        value: number | null
    ) => void = (item, thickness) => {
        changeSpec(item, spec => (spec.thickness = thickness));
    };

    const onUnitCostChange: (item: ICostPriceSpec, value: number) => void = (
        item,
        unitCost
    ) => {
        changeSpec(item, spec => (spec.unitCost = unitCost));
    };

    const onUnitPriceChange: (item: ICostPriceSpec, value: number) => void = (
        item,
        unitPrice
    ) => {
        changeSpec(item, spec => (spec.unitPrice = unitPrice));
    };

    const addSpec = () => {
        const newSpecs = [
            ...specs,
            { thickness: null, unitCost: 0, unitPrice: 0 }
        ];
        setSpecs(newSpecs);
        if (onChange) {
            onChange(newSpecs);
        }
    };

    const columns = [
        {
            dataKey: "thickness",
            title: "厚度",
            align: "center",
            render: (cellData, rowData: ICostPriceSpec) => {
                if (!allowEdit) {
                    if (cellData === null) {
                        return "空";
                    }
                    return cellData;
                }

                return (
                    <AmountInput
                        item={rowData}
                        value={rowData.thickness}
                        onChange={onThicknessChange}
                    />
                );
            }
        },
        {
            dataKey: "unitCost",
            title: "成本",
            align: "center",
            render: (cellData, rowData: ICostPriceSpec) => {
                if (!allowEdit) {
                    return cellData;
                }

                return (
                    <AmountInput
                        item={rowData}
                        value={rowData.unitCost}
                        onChange={onUnitCostChange}
                    />
                );
            }
        },
        {
            dataKey: "unitPrice",
            title: "价格",
            align: "center",
            render: (cellData, rowData: ICostPriceSpec) => {
                if (!allowEdit) {
                    return cellData;
                }

                return (
                    <AmountInput
                        item={rowData}
                        value={rowData.unitPrice}
                        onChange={onUnitPriceChange}
                    />
                );
            }
        }
    ];

    if (allowEdit) {
        columns.push({
            dataKey: "thickness",
            title: "操作",
            align: "center",
            render: (_, rowData: ICostPriceSpec) => {
                const deleteIcon =
                    specs.length <= 1 ? null : (
                        <ESIcon
                            name="icon-delete"
                            height={20}
                            width={20}
                            className="ESIcon-icon-smallfont"
                            onClick={() => {
                                deleteSpec(rowData);
                            }}
                        />
                    );

                const addIcon =
                    rowData !== specs[specs.length - 1] ? null : (
                        <ESIcon
                            name="icon-jiahao"
                            height={20}
                            width={20}
                            className="ESIcon-icon-smallfont"
                            onClick={addSpec}
                        />
                    );

                if (deleteIcon !== null && addIcon !== null) {
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            {deleteIcon}
                            <span style={{ marginLeft: 5 }} />
                            {addIcon}
                        </div>
                    );
                }

                return deleteIcon !== null ? deleteIcon : addIcon;
            }
        });
    }

    return <Table columns={columns} data={{ data: specs }} />;
};

export default CostPriceSpecsTable;
