import { Toast, Dialog } from "saltui";

class Validator {
    public static initializeShowError(showErr: boolean): boolean {
        return showErr === null || showErr === true;
    }
    public static showAlert(msg: string, showAsDialog: boolean = false): void {
        if (!showAsDialog) {
            Toast.show({ content: msg });
            return;
        }

        Dialog.alert({ content: msg });
    }
    public static isEmptyOrNull(value: any): boolean {
        return (
            typeof value === "undefined" ||
            value === null ||
            value === "" ||
            value.length <= 0
        );
    }
    public static isNumber(value: string): boolean {
        return !isNaN(parseFloat(value));
    }
    public static isDate(value: any): boolean {
        if (typeof value === "undefined" || value === null) {
            return false;
        }
        return typeof value.getMonth === "function";
    }
    // 验证输入值中间是否包含空格
    // 包含空格则验证不通过，返回false,否则返回true
    // value [string]: 要验证的值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    public static containSpace(
        value: string,
        fieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        if (typeof value === "string") {
            value = value.trim();
        }
        const containSpace = value.includes(" ");
        if (containSpace) {
            if (showErr) {
                this.showAlert(
                    fieldName + "不能包含空格，请重新输入！",
                    showAsDialog
                );
            }
            return false;
        }
        return true;
    }

    // 验证是否为空
    // value [object]: 要验证的值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    public static require(
        value: any,
        fieldName: string,
        showErr: boolean = true,
        action: string = "输入",
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);

        if (typeof value === "string") {
            value = value.trim();
        }
        const isEmpty = this.isEmptyOrNull(value);
        if (isEmpty && showErr) {
            this.showAlert(`请${action}${fieldName}`, showAsDialog);
        }
        return !isEmpty;
    }

    public static requireUpload(
        value: any,
        fieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);

        if (typeof value === "string") {
            value = value.trim();
        }
        const isEmpty = this.isEmptyOrNull(value);
        if (isEmpty && showErr) {
            this.showAlert("请上传" + fieldName, showAsDialog);
        }
        return !isEmpty;
    }
    // 验证SelectField的选择结果是否为空
    // value [object]: 要验证的值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    public static requireSelect(
        value: IOption,
        fieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const errMsg = showErr ? "请选择" + fieldName : "";
        let isEmpty = this.isEmptyOrNull(value);
        if (isEmpty) {
            if (showErr) {
                this.showAlert(errMsg, showAsDialog);
            }
            return false;
        }
        const v = value.value;
        const t = value.text;
        isEmpty = this.isEmptyOrNull(v) || this.isEmptyOrNull(t);
        if (isEmpty && showErr) {
            this.showAlert(errMsg, showAsDialog);
        }
        return !isEmpty;
    }
    // 验证两个Date对象，确保startTime不能晚于endTime
    // startTime [Date]: 要验证的开始日期
    // endTime [Date]: 要验证的结束日期
    // startTimeFieldName [string]: 提示里面的开始日期字段名称
    // endTimeFieldName [string]: 提示里面的结束日期字段名称
    // showErr [bool]: 是否需要提示
    // 如果startTime或者endTime传入值任何一个为空，则返回true，所以如果需要检查项目必填，须在调用此函数之前单独调用require函数
    public static dateTimeCompare(
        startTime: Date,
        endTime: Date,
        startTimeFieldName: string,
        endTimeFieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const isStartEmpty = this.isEmptyOrNull(startTime);
        const isEndEmpty = this.isEmptyOrNull(endTime);
        if (isStartEmpty || isEndEmpty) {
            return true;
        }
        if (!this.isDate(startTime)) {
            if (showErr) {
                this.showAlert(
                    startTimeFieldName + "不是有效的日期类型",
                    showAsDialog
                );
            }
            return false;
        }
        if (!this.isDate(endTime)) {
            if (showErr) {
                this.showAlert(
                    endTimeFieldName + "不是有效的日期类型",
                    showAsDialog
                );
            }
            return false;
        }
        const valid = startTime.getTime() <= endTime.getTime();
        if (!valid && showErr) {
            this.showAlert(
                startTimeFieldName + "不能晚于" + endTimeFieldName,
                showAsDialog
            );
        }
        return valid;
    }
    // 验证两个DatetimeField的选择结果，确保startTime不能晚于endTime
    // startTime [int]: 要验证的开始日期（从DatetimeField.value中获取）
    // endTime [int]: 要验证的结束日期（从DatetimeField.value中获取）
    // startTimeFieldName [string]: 提示里面的开始日期字段名称
    // endTimeFieldName [string]: 提示里面的结束日期字段名称
    // showErr [bool]: 是否需要提示
    // 如果startTime或者endTime传入值任何一个为空，则返回true，所以如果需要检查项目必填，须在调用此函数之前单独调用require函数
    public static dateTimeFieldCompare(
        startTime: number,
        endTime: number,
        startTimeFieldName: string,
        endTimeFieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const isStartEmpty = this.isEmptyOrNull(startTime);
        const isEndEmpty = this.isEmptyOrNull(endTime);
        if (isStartEmpty || isEndEmpty) {
            return true;
        }
        const valid = startTime <= endTime;
        if (!valid && showErr) {
            this.showAlert(
                startTimeFieldName + "不能晚于" + endTimeFieldName,
                showAsDialog
            );
        }
        return valid;
    }
    // 验证两个数字，确保number1必须小于number2
    // number1 [number]: 要验证的数字1
    // number2 [number]: 要验证的数字2
    // number1FieldName [string]: 提示里面的数字1字段名称
    // number2FieldName [string]: 提示里面的数字2字段名称
    // showErr [bool]: 是否需要提示
    // 如果number1或者number2传入值任何一个为空，则返回true，所以如果需要检查项目必填，须在调用此函数之前单独调用require函数
    public static numberCompare(
        number1: number,
        number2: number,
        number1FieldName: string,
        number2FieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const isNumber1Empty = this.isEmptyOrNull(number1);
        const isNumber2Empty = this.isEmptyOrNull(number2);
        if (isNumber1Empty || isNumber2Empty) {
            return true;
        }
        let valid =
            this.numeric(number1, number1FieldName, showErr, showAsDialog) &&
            this.numeric(number2, number2FieldName, showErr, showAsDialog);
        if (!valid) {
            return valid;
        }
        valid = number1 < number2;
        if (!valid && showErr) {
            this.showAlert(
                number1FieldName + "必须小于" + number2FieldName,
                showAsDialog
            );
        }
        return valid;
    }
    // 验证扣尺长度和宽度，确保扣尺长度和扣尺宽度同时都有值或同时都没有值才可提交
    // deductedLength [number]：要验证的扣尺长度
    // deductedWidth [number]：要验证的扣尺宽度
    // deductedLengthFiledName [string]:提示里面的扣尺长度字段的名称
    // deductedWidthFiledName [string]:提示里面的扣尺宽度字段的名称
    // showErr [bool]:是否需要提示
    // 如果dedutedLength或者deductedWidth传入值任何一个为空，则返回true,所以如果需要检查项目必填，须在调用函数之前单独调用require函数
    public static verifyDeductedSize(
        deductedLength: number,
        deductedWidth: number,
        deductedLengthFieldName: string,
        deductedWidthFieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const deductedLengthIsEmptyOrNull = this.isEmptyOrNull(deductedLength);
        const deductedWidthIsEmptyOrNull = this.isEmptyOrNull(deductedWidth);
        if (deductedWidthIsEmptyOrNull && deductedLengthIsEmptyOrNull) {
            return true;
        }
        const valid =
            this.numeric(
                deductedLength,
                deductedLengthFieldName,
                showErr,
                showAsDialog
            ) &&
            this.numeric(
                deductedWidth,
                deductedWidthFieldName,
                showErr,
                showAsDialog
            );
        if (!valid) {
            return valid;
        }
        if (deductedLength === 0 && deductedWidth === 0) {
            return true;
        } else if (deductedLength === 0) {
            if (showErr) {
                this.showAlert(
                    "请输入" + deductedLengthFieldName,
                    showAsDialog
                );
            }
            return false;
        } else if (deductedWidth === 0) {
            if (showErr) {
                this.showAlert("请输入" + deductedWidthFieldName, showAsDialog);
            }
            return false;
        } else {
            return true;
        }
    }
    // 验证传入的值是否是数字
    // value [object]: 要验证的值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    // 如果传入值为空，则返回true，所以如果需要检查项目必填，须在调用此函数之前单独调用require函数
    public static numeric(
        value: any,
        fieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const isEmpty = this.isEmptyOrNull(value);
        if (isEmpty) {
            return true;
        }
        const valid = this.isNumber(value);
        if (!valid && showErr) {
            this.showAlert(fieldName + "必须是数字", showAsDialog);
        }
        return valid;
    }
    // 验证文本的长度是否在指定范围之内
    // value [string]: 要验证的文本
    // minLength [int]: 最小长度，如果传null则不验证最小长度
    // maxLength [int]: 最da长度，如果传null则不验证最大长度
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    public static textLength(
        value: string,
        minLength: number,
        maxLength: number,
        fieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        value = this.isEmptyOrNull(value) ? "" : value;
        if (typeof minLength !== "undefined" && minLength !== null) {
            if (value.length < minLength) {
                if (showErr) {
                    this.showAlert(
                        fieldName + "的长度不能小于" + minLength + "字符",
                        showAsDialog
                    );
                }
                return false;
            }
        }
        if (typeof maxLength !== "undefined" && maxLength !== null) {
            if (value.length > maxLength) {
                if (showErr) {
                    this.showAlert(
                        fieldName + "的长度不能多于" + maxLength + "字符",
                        showAsDialog
                    );
                }
                return false;
            }
        }
        return true;
    }
    // 验证两次输入的密码值是否一致
    // password [string]: 第一次输入的密码
    // repeatPassword [string]: 第二次输入的密码
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    public static verifyPasswords(
        password: string,
        repeatPassword: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        let valid = true;

        valid = this.require(password, "密码", showErr, "输入", showAsDialog);
        if (!valid) {
            return false;
        }

        valid = this.require(
            repeatPassword,
            "重复密码",
            showErr,
            "输入",
            showAsDialog
        );
        if (!valid) {
            return false;
        }

        if (password === repeatPassword) {
            return true;
        } else {
            if (showErr) {
                this.showAlert("两次输入的密码不一致", showAsDialog);
            }
            return false;
        }
    }
    // 验证传入的值是否为数字以及是否在指定范围之内
    // value [string]: 要验证的值
    // min [int]: 最小值，如果传null则不验证最小值
    // max [int]: 最大值，如果传null则不验证最大值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    // 如果传入值为空，则返回true，所以如果需要检查项目必填，须在调用此函数之前单独调用require函数
    public static range(
        value: any,
        min: number,
        max: number,
        fieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const isEmpty = this.isEmptyOrNull(value);
        if (isEmpty) {
            return true;
        }
        const valid = this.numeric(value, fieldName, showErr, showAsDialog);
        if (!valid) {
            return valid;
        }
        const floatVal = parseFloat(value);
        if (typeof min !== "undefined" && min !== null) {
            if (floatVal < min) {
                if (showErr) {
                    this.showAlert(
                        fieldName + "必须大于或等于" + min,
                        showAsDialog
                    );
                }
                return false;
            }
        }
        if (typeof max !== "undefined" && max !== null) {
            if (floatVal > max) {
                if (showErr) {
                    this.showAlert(
                        fieldName + "必须小于或等于" + max,
                        showAsDialog
                    );
                }
                return false;
            }
        }
        return true;
    }
    // 验证传入的时间为小时和分钟是为正确值
    // hours [int]: 小时数
    // minutes [int]: 分钟数
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    public static requireTime(
        hours: number,
        minutes: number,
        fieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        let valid = true;

        valid = this.require(hours, "小时", showErr, "输入", showAsDialog);
        if (!valid) {
            return false;
        }

        valid = this.require(minutes, "分钟", showErr, "输入", showAsDialog);
        if (!valid) {
            return false;
        }

        valid = this.numeric(hours, "小时", showErr, showAsDialog);
        if (!valid) {
            return false;
        }

        valid = this.numeric(minutes, "分钟", showErr, showAsDialog);
        if (!valid) {
            return false;
        }

        if (hours === 0 && minutes === 0) {
            if (showErr) {
                this.showAlert("请输入" + fieldName, showAsDialog);
            }
            return false;
        }
        let validHours = -1;
        let validMinutes = -1;
        if (hours !== 0) {
            validHours = hours.toString().indexOf(".");
        }

        if (minutes !== 0) {
            validMinutes = minutes.toString().indexOf(".");
        }

        if (validHours !== -1 || validMinutes !== -1) {
            if (showErr) {
                this.showAlert(fieldName + "不能输入小数", showAsDialog);
            }
            return false;
        }
        valid = this.range(hours, 0, 10000, "小时", true, showAsDialog);
        if (!valid) {
            return false;
        }
        valid = this.range(minutes, 0, 59, "分钟", true, showAsDialog);
        return valid;
    }

    // 验证传入的时间传入的开始时间和结束时间是否小于耗时的时间
    // startTime [int]: 开始时间
    // endTime [int]: 结束时间
    // hours [int]: 耗时小时数
    // minutes [int]: 耗时分钟数
    // showErr [bool]: 是否需要提示
    public static compareTimeConsuming(
        startTime: number,
        endTime: number,
        hours: number,
        minutes: number,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        if (this.isEmptyOrNull(hours)) {
            hours = 0;
        }
        if (this.isEmptyOrNull(minutes)) {
            minutes = 0;
        }
        showErr = this.initializeShowError(showErr);
        let time1 = endTime - startTime;
        time1 = time1 / (60 * 1000);
        const time2 = hours * 60 + minutes;
        if (time1 < time2) {
            if (showErr) {
                this.showAlert("开始和结束时间之差不能小于耗时", showAsDialog);
            }
            return false;
        }
        return true;
    }

    // 验证传入的值是否为数字以及是否等于0
    // value [string]: 要验证的值
    // fieldName [string]: 提示里面的字段名称
    // showErr [bool]: 是否需要提示
    // 如果传入值为空，则返回true，所以如果需要检查项目必填，须在调用此函数之前单独调用require函数
    public static noZero(
        value: any,
        fieldName: string,
        showErr: boolean = true,
        showAsDialog: boolean = false
    ): boolean {
        showErr = this.initializeShowError(showErr);
        const isEmpty = this.isEmptyOrNull(value);
        if (isEmpty) {
            return true;
        }
        const valid = this.numeric(value, fieldName, showErr);
        if (!valid) {
            return valid;
        }
        const floatVal = parseFloat(value);
        if (floatVal === 0) {
            if (showErr) {
                this.showAlert(fieldName + "不能等于0", showAsDialog);
            }
            return false;
        }
        return true;
    }
}

export default Validator;
