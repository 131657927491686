import React from "react";
import pinyin from "simple-pinyin";
import {
  IBundleForListDTO,
  IBundleForSODTO,
  IBundleWithSlabsForListDTO,
  IImageFileInfo,
  IMachineryDTO,
  IMaterialSpecDTOBase,
  IPublicSalesOrderProcessingSpecDTO,
  ISalesOrderMaterialSpecDTO,
  ISlabCheckOutContentCreationViewModel,
  ISlabDTO,
  ISlabForListDTO,
  ISlabRequestStatisticsDTO,
  IStructureOfScatteredSlabDTO,
  ITagDTO,
  ITagInfo,
  ITileMaterialStatisticsDTO,
  IWorkOrderForListDTO,
  SalesOrderType,
  TagTypes,
} from "../app/WebAPIClients";
import PermCtl from "./PermCtrl";
import {
  ISalesOrderMaterialSpecSelector,
  ISOSelectedProcessSelector,
} from "./type";
import validator from "./Validator";

import * as H from "history";
import Perm from "../app/Perm";

class Util {
  // 通过Id获取IdNameItem，适用于所有带有Id和Name属性的列表项，包括石材种类/石材等级/存储区域等
  public getIdNameItem(itemId: number, itemList: IIdNameItem[]): IIdNameItem {
    if (typeof itemId === "undefined" || !this.isDefinedAndNotNull(itemList)) {
      return null;
    }

    return itemList.find((i) => i.id === itemId);
  }

  // 通过Id获取名称，适用于所有带有Id和Name属性的列表项，包括石材种类/石材等级/存储区域等
  public getItemName(itemId: number, itemList: IIdNameItem[]): string {
    let iName = null;
    const item = this.getIdNameItem(itemId, itemList);
    if (item) {
      iName = item.name;
    }
    return iName;
  }

  public isNumber(value: string): boolean {
    return !isNaN(parseFloat(value));
  }

  public getMaterialSpecTitle(
    materialSpec:
      | IMaterialSpecDTOBase
      | ISalesOrderMaterialSpecSelector
      | ISalesOrderMaterialSpecDTO,
    categoryList: IIdNameItem[],
    gradeList: IIdNameItem[],
    slabTypeOptions: IOption[],
  ): string {
    const cate = categoryList.find((c) => c.id === materialSpec.categoryId);
    const grade = gradeList.find((g) => g.id === materialSpec.gradeId);
    const slabType = slabTypeOptions.find((t) => t.value === materialSpec.type);

    const cateName = this.isDefinedAndNotNull(cate) ? cate.name : "";
    const gradeName = this.isDefinedAndNotNull(grade) ? grade.name : "";
    const type = this.isDefinedAndNotNull(slabType) ? slabType.text : "";

    let title = "";
    if (this.isNotNullAndNotEmpty(type)) {
      title = type;
    }
    if (this.isNotNullAndNotEmpty(cateName)) {
      title += " | " + cateName;
    }
    if (this.isNotNullAndNotEmpty(gradeName)) {
      title += " | " + gradeName;
    }
    if (this.isDefinedAndNotNull(materialSpec.thickness)) {
      title += " | 厚： " + materialSpec.thickness;
    }

    return title;
  }

  public getMaterialSpecAvatarTitle(
    materialSpec:
      | IMaterialSpecDTOBase
      | ISalesOrderMaterialSpecSelector
      | ISalesOrderMaterialSpecDTO,
    categoryList: IIdNameItem[],
  ): string {
    if (!this.isDefinedAndNotNull(materialSpec.categoryId)) {
      return "";
    }

    return this.getPreAvatarText(
      this.getItemName(materialSpec.categoryId, categoryList),
      2,
    );
  }

  public getMaterialSpecText(
    materialSpec: IMaterialSpecDTOBase,
    area?: number,
  ): string {
    let text = "";
    if (this.isDefinedAndNotNull(area)) {
      text += "总计：" + area + " 平方 ";
    }

    if (area !== null && this.isNotNullAndNotEmpty(materialSpec.notes)) {
      text += " | ";
    }

    if (this.isNotNullAndNotEmpty(materialSpec.notes)) {
      text += "备注：" + materialSpec.notes;
    }

    return text;
  }

  public getProcessingSpecInfoForList(
    ps: ISOSelectedProcessSelector | IPublicSalesOrderProcessingSpecDTO,
  ): string {
    let title = "";

    if (!this.isDefinedAndNotNull(ps)) {
      return title;
    }

    title += ps.name + " | " + ps.unit;

    return title;
  }

  public getSubTotalForMaterialSpec(
    materialSpec: ISalesOrderMaterialSpecSelector | ISalesOrderMaterialSpecDTO,
  ): number {
    let subTotal = 0;
    if (
      typeof materialSpec === "undefined" ||
      materialSpec === null ||
      typeof materialSpec.area === "undefined" ||
      materialSpec.area === null
    ) {
      return subTotal;
    }

    subTotal = this.round(materialSpec.price * materialSpec.area, 2);

    return subTotal;
  }

  public getEmptyListItemData(): IListItem {
    return { title: "", text: "" };
  }

  // 用于获取React.Component的props.location.state的属性值
  // 主要用在页面初始化state的时候
  // obj - React.Component对象，在constructor中可以传this
  // name - 属性的名字
  // defaultValue - 如果属性不存在时使用的默认值，如果不传此参数，则默认为null
  public getLocState<T>(
    obj: React.Component<any, any>,
    name: string,
    defaultValue?: T,
  ): T {
    if (typeof defaultValue === "undefined") {
      defaultValue = null;
    }

    if (!(obj && obj.props && obj.props.location && obj.props.location.state)) {
      return defaultValue;
    }

    let returnVal = defaultValue;
    const stateObj = obj.props.location.state;
    if (stateObj && typeof stateObj[name] !== "undefined") {
      returnVal = stateObj[name];
    }
    return returnVal;
  }

  public getAreaSpecText(
    length: number,
    width: number,
    deductedLength: number,
    deductedWidth: number,
  ): string {
    const area = this.calculateArea(length, width);
    const deductedArea = this.calculateArea(deductedLength, deductedWidth);
    let actualArea = area - deductedArea;
    actualArea = this.round(actualArea, 3);
    let deducted = "";
    if (deductedLength > 0 && deductedWidth > 0) {
      deducted = " | " + this.getSpecText(deductedLength, deductedWidth);
    }
    return (
      this.getSpecText(length, width) + deducted + " | " + actualArea + "平方"
    );
  }

  public getSpecText(length: number, width: number, height?: number): string {
    let text = length.toString();
    if (typeof height !== "undefined" && height !== null) {
      text += " x " + height;
    }
    text += " x " + width;
    return text;
  }

  // 计算体积
  // length - Number, 长度，单位为毫米
  // width - Number, 宽度，单位为毫米
  // height - Number, 高度，单位为毫米
  // 返回 - string，体积，单位为立方米，保留小数点后三位，第四位四舍五入
  public calculateVolume(
    length: number,
    width: number,
    height: number,
  ): number {
    let v = 0;

    if (
      typeof length === "undefined" ||
      typeof width === "undefined" ||
      typeof height === "undefined" ||
      length === null ||
      width === null ||
      height === null
    ) {
      return v;
    }

    if (length > 0 && width > 0 && height > 0) {
      v = this.round((length * width * height) / 1000000000, 3);
    }

    return v;
  }

  public calculateSubTotal(
    quantity: number,
    deductedQuantity: number,
    unitPrice: number,
  ): number {
    const subTotal = 0;
    if (
      typeof quantity === "undefined" ||
      typeof unitPrice === "undefined" ||
      quantity === null ||
      unitPrice === null
    ) {
      return subTotal;
    }

    if (typeof deductedQuantity === "undefined" || deductedQuantity === null) {
      deductedQuantity = 0;
    }

    return this.round((quantity - deductedQuantity) * unitPrice, 2);
  }

  // 计算面积
  // length - Number, 长度，单位为毫米
  // width - Number, 宽度，单位为毫米
  // 返回 - string，面积，单位为平方米，保留小数点后三位，第四位四舍五入
  public calculateArea(length: number, width: number): number {
    let a = 0;

    if (
      typeof length === "undefined" ||
      typeof width === "undefined" ||
      length === null ||
      width === null
    ) {
      return a;
    }

    if (length > 0 && width > 0) {
      a = this.round((length * width) / 1000000, 3);
    }

    return a;
  }

  public getCacheOption(
    key: string,
    durationInMin?: number,
  ): { type: "localStorage"; key: string; duration?: number } {
    if (this.isUndefinedOrNull(durationInMin)) {
      return {
        type: "localStorage", // 缓存方式, 默认为'localStorage'
        key, // !!! 唯一必选的参数, 用于内部存储 !!!
      };
    }
    return {
      type: "localStorage", // 缓存方式, 默认为'localStorage'
      key, // !!! 唯一必选的参数, 用于内部存储 !!!
      duration: 1000 * 60 * durationInMin, // 缓存的有效期长, 以毫秒数指定
    };
  }

  public getSessionCacheOption(
    key: string,
    durationInMin: number,
  ): { type: "sessionStorage"; key: string; duration?: number } {
    return {
      type: "sessionStorage",
      key,
    };
  }

  public getMargin(d1: string, d2: string): string {
    if (
      typeof d1 === "undefined" ||
      typeof d2 === "undefined" ||
      d1 === null ||
      d2 === null
    ) {
      return "";
    }

    const date1 = Date.parse(d1);
    const date2 = Date.parse(d2);
    return this.getCompletetime(date1, date2);
  }
  public getCompletetime(date1: number, date2: number): string {
    if (
      typeof date1 === "undefined" ||
      typeof date2 === "undefined" ||
      date1 === null ||
      date2 === null
    ) {
      return "";
    }

    const date3 = date2 - date1; // 时间差的毫秒数
    // 计算出相差天数
    const days = Math.floor(date3 / (24 * 3600 * 1000));

    // 计算出小时数
    const leave1 = date3 % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
    const hours = Math.floor(leave1 / (3600 * 1000));
    // 计算相差分钟数
    const leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
    const minutes = Math.floor(leave2 / (60 * 1000));

    return days + "天" + hours + "小时" + minutes + "分";
  }

  // 用于从后端返回的所有bundleList中提取工程板领料单实际领出的大板信息
  // 返回 - StoneBundleList
  public getBundlesForSlabRequest(
    materialInfo: ISlabCheckOutContentCreationViewModel[],
    bundles: IBundleWithSlabsForListDTO[],
  ): IBundleWithSlabsForListDTO[] {
    let bundleList: IBundleWithSlabsForListDTO[] = [];

    if (
      typeof materialInfo === "undefined" ||
      typeof bundles === "undefined" ||
      materialInfo === null ||
      bundles === null ||
      materialInfo.length === 0 ||
      bundles.length === 0
    ) {
      return bundleList;
    }

    // 过滤出整扎被全部领出的扎
    bundleList = bundles.filter((b) => {
      const mrInfo = materialInfo.find((m) => {
        return m.bundleId === b.id;
      });
      return mrInfo.allSlabSelected;
    });

    bundleList.forEach((b) => {
      const mrInfo = materialInfo.find((m) => {
        return m.bundleId === b.id;
      });
      b.slabs = b.slabs.filter((s) => {
        return mrInfo.slabIds.some((id) => {
          return id === s.id;
        });
      });
    });

    return bundleList;
  }

  // 用于从后端返回的所有bundleList中提取工程板退料单实际领出的散板
  // 返回 - slabList
  public getScatteredSlabsForBundle(
    materialInfo: ISlabCheckOutContentCreationViewModel[],
    bundles: IBundleWithSlabsForListDTO[],
  ): ISlabForListDTO[] {
    const slabList: ISlabForListDTO[] = [];
    if (
      typeof materialInfo === "undefined" ||
      typeof bundles === "undefined" ||
      materialInfo === null ||
      bundles === null ||
      materialInfo.length === 0 ||
      bundles.length === 0
    ) {
      return slabList;
    }

    // 过滤出被领出部分大板的扎
    bundles = bundles.filter((b) => {
      const mrInfo = materialInfo.find((m) => {
        return m.bundleId === b.id;
      });
      return !mrInfo.allSlabSelected;
    });

    bundles.forEach((b) => {
      const mrInfo = materialInfo.find((m) => {
        return m.bundleId === b.id;
      });
      b.slabs.forEach((s) => {
        const slabSelected = mrInfo.slabIds.some((id) => {
          return id === s.id;
        });
        if (slabSelected) {
          slabList.push(s);
        }
      });
    });

    return slabList;
  }

  // 获取工单的大板生产面积
  public getWOArea(wo: IWorkOrderForListDTO): number {
    let area = 0;
    if (!wo) {
      return area;
    }

    // 工单生产状态为90，即工单完成，根据wo.endState取工单生产出来的大板面积
    // 工单生产状态不等于90，即工单生产进行中，根据wo.manufacturingState取工单生产出来的大板面积
    area =
      wo.manufacturingState === 90
        ? this.getWOAreaByState(wo, wo.endState)
        : this.getWOAreaByState(wo, wo.manufacturingState);

    return area;
  }

  // 用于计算工程板领料单实际领出整扎数
  // 返回 - 片数count
  public getTotalBundleCountForSlabRequest(
    materialInfo: ISlabCheckOutContentCreationViewModel[],
  ): number {
    let count = 0;
    const bundleIdsInMR: number[] = [];

    if (
      typeof materialInfo === "undefined" ||
      materialInfo === null ||
      materialInfo.length === 0
    ) {
      return count;
    }

    materialInfo.map((item) => {
      if (item.allSlabSelected) {
        bundleIdsInMR.push(item.bundleId);
      }
    });

    if (bundleIdsInMR.length > 0) {
      count = bundleIdsInMR.length;
    }

    return count;
  }

  // 用于整理工程板领料单实际领出散板区的板，按荒料编号为单位显示
  // 返回 slabsForBundlePrefix
  public getScatteredSlabsInfo(
    slabList: ISlabForListDTO[],
  ): IStructureOfScatteredSlabDTO[] {
    const slabsForBundlePrefix = [];
    const bundlePrefixList = [];

    if (
      typeof slabList === "undefined" ||
      slabList === null ||
      !slabList.length ||
      slabList.length === 0
    ) {
      return slabsForBundlePrefix;
    }

    slabList.forEach((slab) => {
      const bundlePrefixIsExist = bundlePrefixList.some((b) => {
        return b === slab.bundlePrefix;
      });
      if (!bundlePrefixIsExist) {
        bundlePrefixList.push(slab.bundlePrefix);
      }
    });
    bundlePrefixList.forEach((b) => {
      const slabs = slabList.filter((s) => {
        return s.bundlePrefix === b;
      });
      if (slabs.length > 0) {
        const slabForBundlePrefix = {
          bundlePrefix: b,
          slabList: slabs,
        };
        slabsForBundlePrefix.push(slabForBundlePrefix);
      }
    });

    return slabsForBundlePrefix;
  }

  // 每一扎大板按大板序列号排序大板
  public getSlabGroupBy(
    slabs: Array<ISlabForListDTO | ISlabDTO>,
  ): Array<ISlabForListDTO | ISlabDTO> {
    return slabs.slice().sort((b1, b2) => {
      const sortOne = b1.sequenceNumber;
      const sortTwo = b2.sequenceNumber;
      return sortOne - sortTwo;
    });
  }

  // 获取领料单的text
  public getSlabRequestDescription(
    item: { totalSlabCount: number; area: number },
    type: string,
  ): string {
    let desc = "";
    if (
      typeof item !== "undefined" &&
      item !== null &&
      typeof type !== "undefined" &&
      type !== null
    ) {
      desc = type === "slabCheckOut" ? "领料：" : "退料：";
      if (this.isNotNullAndNorthOfZero(item.totalSlabCount)) {
        desc = desc + item.totalSlabCount + " 片 |";
      }

      desc = desc + " 共 " + item.area + " 平方";
    }

    return desc;
  }

  public getWOAreaByState(wo: IWorkOrderForListDTO, state: number): number {
    let area = 0;
    if (
      typeof state === "undefined" ||
      state === null ||
      typeof wo === "undefined" ||
      wo === null
    ) {
      return area;
    }

    switch (state) {
      case 40:
      case 43:
      case 45:
      case 50:
        area = wo.areaAfterSawing;
        break;
      case 55:
      case 60:
      case 90:
        area = wo.areaAfterPolishing;
        break;
    }
    return area;
  }

  public getOptionText(optionValue: number, optionList: IOption[]): string {
    if (
      typeof optionValue === "undefined" ||
      optionValue === null ||
      !(optionList && optionList.length && optionList.length > 0)
    ) {
      return "";
    }
    const option = optionList.find((o) => {
      return o.value === optionValue;
    });
    const optionText =
      typeof option === "undefined" || option === null ? "" : option.text;
    return optionText;
  }

  public convertItemsToOptions(itemList: IIdNameItem[]): IOption[] {
    let optionList: IOption[] = [];
    if (itemList && itemList.length && itemList.length > 0) {
      optionList = itemList.map((item) => {
        return { value: item.id, text: item.name };
      });
    }

    return optionList;
  }

  public getTimeConsuming(minutes: number): string {
    if (typeof minutes === "undefined" || minutes === null || minutes < 0) {
      return "";
    }

    const hours = Math.floor(minutes / 60);
    const minute = minutes % 60;
    if (hours === 0) {
      return minute + "分";
    }
    if (minute === 0) {
      return hours + "小时";
    }
    return hours + "小时" + minute + "分";
  }

  // 对传入的数字进行四舍五入
  // num: 数字
  // precision: 要保留的小数位数
  public round(num: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(num * factor) / factor;
  }

  public toPercent(num: number): string {
    return this.round(num * 100, 0) + "%";
  }

  // 对于在输入框中输入的数字只保留两位小数
  public toFixedTowDecimal(value: any) {
    return value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
  }

  public getPreAvatarText(title: string, length: number = 2): string {
    let text = "";
    if (title) {
      text = title.substring(0, length);
    }
    return text;
  }

  public getSalesOrderTypeAvatarText(salesOrderType: number): string {
    let text = "";
    switch (salesOrderType) {
      case 10:
        text = "荒料";
        break;
      case 30:
        text = "大板";
        break;
      case 40:
        text = "生产";
        break;
      case 60:
        text = "工程";
        break;
      case 80:
        text = "加工";
        break;
    }
    return text;
  }

  public getBundleAvatarText(item: IBundleForListDTO): string {
    let text = "";
    switch (item.type) {
      case 10:
        text = "毛板";
        break;
      case 20:
        text = "光板";
        break;
    }
    return text;
  }

  public getMaterialSpecInfo(
    materialSpec: ITileMaterialStatisticsDTO | ISlabRequestStatisticsDTO,
    categoryList: IIdNameItem[],
  ): { title: string; category: string } {
    const cate = categoryList.find((c) => c.id === materialSpec.categoryId);

    const cateName =
      typeof cate !== "undefined" && cate !== null ? cate.name : "";
    let title = "";
    if (
      typeof cateName !== "undefined" &&
      cateName !== null &&
      cateName !== ""
    ) {
      title += cateName;
    }
    if (
      typeof materialSpec.thickness !== "undefined" &&
      materialSpec.thickness !== null
    ) {
      title += " | 厚： " + materialSpec.thickness;
    }

    return { title, category: this.getPreAvatarText(cateName, 2) };
  }

  public parseToInt(value: string): number | null {
    const valid = validator.isNumber(value);
    if (!valid) {
      return null;
    }

    return value === null || value === "" ? null : parseInt(value);
  }

  public parseToFloat(value: string): number | null {
    const valid = validator.isNumber(value);
    if (!valid) {
      return null;
    }

    return value === null || value === "" ? null : parseFloat(value);
  }

  public parseToFloatForFilter(value: string): number | null {
    const valid = validator.isNumber(value);
    if (!valid) {
      return undefined;
    }

    return parseFloat(value);
  }

  public parseToString(value: string): string {
    if (typeof value === "string") {
      value = value.trim();
    }
    value = value === null || value === "" ? null : value;
    return value;
  }

  public isInRoles(roleNames: string[], userRoles: string[]): boolean {
    let existRole = false;
    roleNames.forEach((rn) => {
      if (userRoles.some((r) => r === rn)) {
        existRole = true;
      }
    });
    return existRole;
  }

  public getImageFileNamesByType(
    imageFiles: IImageFileInfo[],
    type: string,
  ): string | null {
    return this.getImageFileNamesByTypes(imageFiles, [type]);
  }

  public getImageFileNamesByTypes(
    imageFiles: IImageFileInfo[],
    types: string[],
  ): string | null {
    if (!this.isDefinedAndNotNull(imageFiles)) {
      return null;
    }
    const filteredImages = imageFiles.filter((f) => types.includes(f.type));
    if (filteredImages.length && filteredImages.length > 0) {
      return filteredImages.map((file) => file.fileName).join(",");
    } else {
      return null;
    }
  }

  public getTagOptionsByTypes(
    tagInfos: ITagInfo[],
    types: TagTypes[],
    tagList: ITagDTO[],
  ): IOption[] {
    if (!this.isDefinedAndNotNull(tagInfos)) {
      return null;
    }

    const tagOptions: IOption[] = [];
    const filteredNames = tagInfos.filter((i) => types.includes(i.type));
    if (this.isNotNullAndNotEmptyArray(filteredNames)) {
      filteredNames.forEach((n) => {
        const to = tagList.find(
          (tag) => tag.name === n.name && types.includes(tag.type),
        );
        if (this.isDefinedAndNotNull(to)) {
          tagOptions.push({ value: to.id, text: to.name });
        } else {
          // 如果标签在tagList中不存在，也将其添加，以处理标签在添加到销售订单后，又被改名的情况
          const tagOption = tagOptions.find(
            (ta) => ta.value === n.name && ta.text === n.name,
          );
          if (!this.isDefinedAndNotNull(tagOption)) {
            tagOptions.push({ value: n.name, text: n.name });
          }
        }
      });
    }

    return tagOptions;
  }

  public GetUrlParam(url: string, name: string): string {
    try {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const result = url.split("?")[1].match(reg);
      if (result != null) {
        return result[2];
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  public isUndefinedOrNull(item: any): boolean {
    return item === undefined || item === null;
  }

  public isDefinedAndNotNull(item: any): boolean {
    return typeof item !== "undefined" && item !== null;
  }

  public isNotNullAndNotEmpty(item: any): boolean {
    return this.isDefinedAndNotNull(item) && item !== "";
  }

  public isNotNullAndNorthOfZero(value?: number): boolean {
    return value !== null && value > 0;
  }

  public isNotNullAndNotNaN(value?: number): boolean {
    return this.isDefinedAndNotNull(value) && !isNaN(value);
  }

  public isNotNullAndNotEmptyArray(item: any): boolean {
    return (
      this.isDefinedAndNotNull(item) &&
      this.isDefinedAndNotNull(item.length) &&
      item.length > 0
    );
  }

  public verifySizeIsNotNullAndNorthOfZero(
    length?: number,
    width?: number,
  ): boolean {
    return (
      this.isNotNullAndNorthOfZero(length) &&
      this.isNotNullAndNorthOfZero(width)
    );
  }

  /**
   * 通过传入的value获取数组中对应的选项
   * @param options 要查找的IOption数组
   * @param value 要匹配的value
   */
  public getOption(
    options: IOption[],
    value: string | number | boolean,
  ): IOption {
    let option: IOption = null;
    if (this.isDefinedAndNotNull(options) && this.isDefinedAndNotNull(value)) {
      const opt = options.find((o) => o.value === value);
      // 如果对应value的对象没找到，find返回undefined，需要把undefined转换为null返回
      option = typeof opt === "undefined" ? null : opt;
    }
    return option;
  }

  /**
   * 通过传入的text获取数组中对应的选项
   * @param options 要查找的IOption数组
   * @param valtexte 要匹配的text
   */
  public getOptionByText(
    options: IOption[],
    text: string | number | boolean,
  ): IOption {
    let option: IOption = null;
    if (this.isDefinedAndNotNull(options) && this.isDefinedAndNotNull(text)) {
      const opt = options.find((o) => o.text === text);
      // 如果对应text的对象没找到，find返回undefined，需要把undefined转换为null返回
      option = typeof opt === "undefined" ? null : opt;
    }
    return option;
  }

  /**
   * 通过后台返回的带有Id和Name属性的数组，获得界面控件要用的数组，Id映射到value，Name映射到text
   * @param idNameItems 带有Id和Name属性的数组
   * @param filterFn 可选，过滤条件，使用方法参考Array.filter
   */
  public getOptionsForUI<T extends IIdNameItem>(
    idNameItems: T[],
    filterFn: (value: T, index: number, array: T[]) => any = null,
  ): IOption[] {
    let options: IOption[] = [];
    const items: T[] =
      idNameItems !== null && filterFn !== null
        ? idNameItems.filter(filterFn)
        : idNameItems;

    if (items && items.length > 0) {
      options = items.map((item) => ({
        value: item.id,
        text: item.name,
        // phonetic: this.getPhonetic(item.name)
      }));
    }
    return options;
  }

  /**
   * 通过传入的IOption数组，转换成IOptionWithPhonetic数组
   * @param optionArr IOption数组
   * @param filterFn 可选，过滤条件，使用方法参考Array.filter
   */
  public getOptionsWithPhoneticByOptionArr<T extends IOption>(
    optionArr: T[],
    filterFn: (value: T, index: number, array: T[]) => any = null,
  ): IOptionWithPhonetic[] {
    let options: IOptionWithPhonetic[] = [];
    const items: T[] =
      optionArr !== null && filterFn !== null
        ? optionArr.filter(filterFn)
        : optionArr;

    if (items && items.length > 0) {
      options = items.map((item) => ({
        value: item.value,
        text: item.text,
        phonetic: this.getPhonetic(item.text),
      }));
    }
    return options;
  }

  /**
   * 通过后台返回的带有Id和Name属性的数组，获得界面控件要用的数组，Id映射到value，Name映射到text, this.getPhonetic(item.name)映射到phonetic
   * @param idNameItems 带有Id和Name属性的数组
   * @param filterFn 可选，过滤条件，使用方法参考Array.filter
   */
  public getOptionsWithPhoneticForUI<T extends IIdNameItem>(
    idNameItems: T[],
    filterFn: (value: T, index: number, array: T[]) => any = null,
  ): IOptionWithPhonetic[] {
    let options: IOptionWithPhonetic[] = [];
    const items: T[] =
      idNameItems !== null && filterFn !== null
        ? idNameItems.filter(filterFn)
        : idNameItems;

    if (items && items.length > 0) {
      options = items.map((item) => ({
        value: item.id,
        text: item.name,
        phonetic: this.getPhonetic(item.name),
      }));
    }
    return options;
  }

  /**
   * 通过一组IOption数组，获得其value数组
   * @param options IOption数组
   */
  public getValuesByOptions(options: IOption[]) {
    const values = this.isNotNullAndNotEmptyArray(options)
      ? options.map((i) => i.value as number)
      : [];

    return values;
  }

  /**
   * 通过一组string数组，获得界面控件要用的数组
   * @param items string数组
   */
  public getOptions(items: string[]): IOption[] {
    const options: IOption[] = [];

    if (items && items.length > 0) {
      items.map((item) => {
        if (!options.some((o) => o.value === item)) {
          options.push({
            value: item,
            text: item,
          });
        }
      });
    }
    return options;
  }

  public canCreateOrUpdateShippingOrder(soType: number): boolean {
    // 对于荒料销售订单，需要有建立荒料装车单权限，对于其他销售订单，需要有建立大板和工程板装车单权限
    if (!this.isDefinedAndNotNull(soType)) {
      return true;
    }

    return (
      ([
        SalesOrderType.BlockInStock,
        SalesOrderType.SellingAndManufacturing,
      ].includes(soType) &&
        PermCtl.isAnyAuthorized([Perm.SPO_B_C, Perm.SPO_B_U])) ||
      ([
        SalesOrderType.PolishedSlabInStock,
        SalesOrderType.Tile,
        SalesOrderType.SellingAndManufacturing,
        SalesOrderType.SlabNotInStock,
      ].includes(soType) &&
        PermCtl.isAnyAuthorized([
          Perm.SPO_S_C,
          Perm.SPO_T_C,
          Perm.SPO_S_U,
          Perm.SPO_T_U,
        ]))
    );
  }

  public canCreateShippingOrder(soType: number): boolean {
    // 对于荒料销售订单，需要有建立荒料装车单权限，对于其他销售订单，需要有建立大板和工程板装车单权限
    if (!this.isDefinedAndNotNull(soType)) {
      return (
        true &&
        PermCtl.isAnyAuthorized([Perm.SPO_S_C, Perm.SPO_T_C, Perm.SPO_B_C])
      );
    }

    return (
      ([
        SalesOrderType.BlockInStock,
        SalesOrderType.SellingAndManufacturing,
      ].includes(soType) &&
        PermCtl.isAnyAuthorized([Perm.SPO_B_C])) ||
      ([
        SalesOrderType.PolishedSlabInStock,
        SalesOrderType.Tile,
        SalesOrderType.SellingAndManufacturing,
        SalesOrderType.SlabNotInStock,
      ].includes(soType) &&
        PermCtl.isAnyAuthorized([Perm.SPO_S_C, Perm.SPO_T_C]))
    );
  }

  public canUpdateShippingOrder(soType: number): boolean {
    // 对于荒料销售订单，需要有建立荒料装车单权限，对于其他销售订单，需要有建立大板和工程板装车单权限
    if (!this.isDefinedAndNotNull(soType)) {
      return (
        true &&
        PermCtl.isAnyAuthorized([Perm.SPO_S_U, Perm.SPO_T_U, Perm.SPO_B_U])
      );
    }

    return (
      ([
        SalesOrderType.BlockInStock,
        SalesOrderType.SellingAndManufacturing,
      ].includes(soType) &&
        PermCtl.isAnyAuthorized([Perm.SPO_B_U])) ||
      ([
        SalesOrderType.PolishedSlabInStock,
        SalesOrderType.Tile,
        SalesOrderType.SellingAndManufacturing,
        SalesOrderType.SlabNotInStock,
      ].includes(soType) &&
        PermCtl.isAnyAuthorized([Perm.SPO_S_U, Perm.SPO_T_U]))
    );
  }

  public getMaterialSpecKeyForList(
    ms: ISalesOrderMaterialSpecSelector | ISalesOrderMaterialSpecDTO,
  ): string {
    return (
      ms.categoryId.toString() +
      (ms.gradeId != null ? ms.gradeId.toString() : null) +
      ms.type.toString() +
      ms.thickness.toString()
    );
  }

  /**
   * 将一个对象的选定属性拷贝到新的对象
   * @param obj 源对象
   * @param props 需要拷贝到新对象的属性字符数组
   */
  public pick(obj: object, ...props: string[]) {
    return Object.assign(
      {},
      ...props.map((prop) => {
        if (obj[prop]) {
          return { [prop]: obj[prop] };
        }
      }),
    );
  }

  // 获取加工机器的级联选择options结构
  public getMachineryCascadData(
    machineries: IMachineryDTO[],
  ): ITwoStageCascade[] {
    const options = [];

    machineries.forEach((element) => {
      const typeOption = options.find((w) => w.value === element.typeId);
      if (typeOption) {
        typeOption.children.push({
          value: element.id,
          label: element.name,
        });
      } else {
        options.push({
          value: element.typeId,
          label: element.typeName,
          children: [
            {
              value: element.id,
              label: element.name,
            },
          ],
        });
      }
    });

    return options;
  }

  public getValidDate(value: string | number | Date): Date {
    if (!this.isDefinedAndNotNull(value)) {
      return undefined;
    }

    const date = new Date(value);
    return date instanceof Date && !isNaN(date.getTime()) ? date : undefined;
  }

  public getFileNameWithoutExtension(fileName: string): string {
    if (!fileName || !fileName.length || fileName.length === 0) {
      return "";
    }

    if (!fileName.includes(".")) {
      return fileName;
    }

    return fileName.substr(0, fileName.lastIndexOf("."));
  }

  public getFileExtension(fileName: string): string {
    if (!fileName || !fileName.length || fileName.length === 0) {
      return "";
    }

    if (!fileName.includes(".")) {
      return "";
    }

    return fileName.substr(fileName.lastIndexOf(".") + 1);
  }

  public produce<T>(
    array: T[],
    filterFunc: (item: T) => boolean,
    replace: (item: T) => T,
  ) {
    const newArray = [...array];
    const indexof = newArray.findIndex(filterFunc);
    newArray[indexof] = replace(array[indexof]);
    return newArray;
  }

  public historyGoBackForExternalLinks(
    history: H.History,
    maxLength: number = 1,
  ): void {
    // 修复bug #2985 by hxy 2020-11-24 具体原因可查看PageTOApproval.tsx文件中调用此函数的地方
    if (history.length <= maxLength) {
      window.location.reload();
    } else {
      history.goBack();
    }
  }

  public getBalanceColor(balance: number): string {
    return balance < 0 ? "unitPriceText" : "balanceText";
  }

  private getPhonetic(text: string) {
    return pinyin(text, { pinyinOnly: false, matchFullText: "original" });
  }
}

export default new Util();
