import userInfo from "./UserInfo";

class DingtalkClientSVC {
    public static isJSAPIAvailable(): boolean {
        return !(
            typeof dd === "undefined" ||
            dd === null ||
            typeof dd.version === "undefined" ||
            dd.version === null
        );
    }

    // 获取钉钉临时授权码，用于在WebAPI的Get方法中
    public static getDDAuthCodeForMessageAsync(): Promise<string> {
        return new Promise((f, r) => {
            if (!this.isJSAPIAvailable()) {
                f(null); // 如果客户端钉钉对象不可用（可能是浏览器)，则直接返回成功
            } else {
                const ddConfig = userInfo.getDDConfig();
                this.getTempAuthCodeAsync(ddConfig.corpId, ddConfig.agentId)
                    .then(authCode => {
                        f(authCode);
                    })
                    .catch(err => {
                        f(null);
                    });
            }
        });
    }

    // 将授权码添加到需要被发送到服务端的数据中，临时授权码保存在authCode属性中
    public static addAuthCodeForMessageAsync(postData): Promise<void> {
        return new Promise((f, r) => {
            if (!this.isJSAPIAvailable()) {
                f(); // 如果客户端钉钉对象不可用（可能是浏览器)，则直接返回成功
            } else {
                const ddConfig = userInfo.getDDConfig();
                this.getTempAuthCodeAsync(ddConfig.corpId, ddConfig.agentId)
                    .then(authCode => {
                        if (
                            !(
                                typeof authCode === "undefined" ||
                                authCode === null
                            )
                        ) {
                            postData.authCode = authCode;
                        }
                        f();
                    })
                    .catch(err => {
                        f();
                    });
            }
        });
    }

    // 通过corpId和agentId从dd.runtime中拿到临时授权码，用于发送消息
    public static getTempAuthCodeAsync(corpId, agentId): Promise<string> {
        return new Promise((f, r) => {
            dd.runtime.permission.requestOperateAuthCode({
                agentId,
                corpId,
                onFail: err => {
                    r(err);
                },
                onSuccess: result => {
                    f(result.code);
                }
            });
        });
    }

    // 调用钉钉客户端扫码功能进行扫码，返回二维码包含的文本信息
    public static extractQRCode(): Promise<string> {
        return new Promise((f, r) => {
            if (!this.isJSAPIAvailable()) {
                const err = { message: "当前不在钉钉客户端，无法使用此功能！" };
                r(err);
            }

            dd.biz.util.scan({
                onFail: err => {
                    r(err);
                },
                onSuccess: data => {
                    f(data.text);
                },
                tips: "扫码",
                type: "qrCode" // type 为 all、qrCode、barCode，默认是all
            });
        });
    }
}

export default DingtalkClientSVC;
