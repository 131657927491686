import * as React from "react";
import { SwitchField } from "saltui";
import {
  IAppliedProcessViewModel,
  IProcessDTO,
  ProcessDisplayType,
} from "../../app/WebAPIClients";
import ESTextField from "../ESTextField";
import Util from "../Util";

declare interface IAppliedProcessItemProps
  extends React.Props<AppliedProcessItem> {
  process: IProcessDTO;
  onChange: (appliedProecss: IAppliedProcessViewModel) => void;
  quantity?: number;
}

declare interface IAppliedProcessItemState {
  quantity?: number;
  quantityText: string;
  onSwitch: boolean;
}

class AppliedProcessItem extends React.Component<
  IAppliedProcessItemProps,
  IAppliedProcessItemState
> {
  public static defaultProps: IAppliedProcessItemProps;
  constructor(props) {
    super(props);
    this.state = {
      quantity: props.quantity,
      quantityText: Util.isDefinedAndNotNull(props.quantity)
        ? props.quantity.toString()
        : "",
      onSwitch: false,
    };
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.quantity !== this.props.quantity) {
      this.setState({
        quantity: nextProps.quantity,
        quantityText: Util.isDefinedAndNotNull(nextProps.quantity)
          ? nextProps.quantity.toString()
          : "",
      });
    }
  }

  public render() {
    const t = this;
    const s = t.state;

    const { process } = t.props;
    if (!process) {
      return;
    }

    return (
      <div>
        {process.displayType === ProcessDisplayType.IntegerNumber ? (
          <ESTextField
            label={process.name}
            placeholder={`请输入${process.name}数量`}
            type="number"
            pattern="\d*"
            value={s.quantityText}
            onChange={t.handleIntegerNumberChange}
          />
        ) : null}
        {process.displayType === ProcessDisplayType.RealNumber ? (
          <ESTextField
            label={process.name}
            placeholder={`请输入${process.name}数量`}
            type="number"
            pattern="\d*"
            value={s.quantityText}
            onChange={t.handleRealNumberChange}
          />
        ) : null}
        {process.displayType === ProcessDisplayType.Switch ? (
          <SwitchField
            label={process.name}
            on={s.onSwitch}
            onChange={t.handleSwitchFieldChange}
          />
        ) : null}
      </div>
    );
  }

  private handleSwitchFieldChange = (value: boolean) => {
    this.setState({
      onSwitch: value,
    });
    const { process, onChange } = this.props;

    if (onChange) {
      const appliedProecss: IAppliedProcessViewModel = {
        processId: process.id,
        quantity: null,
        onSwitch: value,
      };
      onChange(appliedProecss);
    }
  };

  private handleIntegerNumberChange = (value: string) => {
    const integerNum = Util.parseToInt(value);
    this.setState({
      quantity: integerNum,
      quantityText: value,
    });
    const { process, onChange } = this.props;

    if (onChange) {
      const appliedProecss: IAppliedProcessViewModel = {
        processId: process.id,
        quantity: integerNum,
        onSwitch: null,
      };
      onChange(appliedProecss);
    }
  };

  private handleRealNumberChange = (value: string) => {
    const realNum = Util.parseToFloat(value);
    this.setState({
      quantity: realNum,
      quantityText: value,
    });
    const { process, onChange } = this.props;

    if (onChange) {
      const appliedProecss: IAppliedProcessViewModel = {
        processId: process.id,
        quantity: realNum,
        onSwitch: null,
      };
      onChange(appliedProecss);
    }
  };
}

AppliedProcessItem.defaultProps = {
  onChange: (appliedProecss: IAppliedProcessViewModel) => {},
  process: null,
};

export default AppliedProcessItem;
