class ReorganizedOptions {
    public data: IOption[];

    constructor() {
        this.data = [
            {
                value: true,
                text: "是"
            },
            {
                value: false,
                text: "否"
            }
        ];
    }

    public getOption(value: boolean | string) {
        return this.data.find(v => v.value === value);
    }
}

class OptionConsts {
    public static ReorganizedOptions = new ReorganizedOptions();
}

export default OptionConsts;
