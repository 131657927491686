import React from "react";
import { CascadeSelectField, Field, Group } from "saltui";
import { MachineryUse } from "../../app/WebAPIClients";
import Consts from "../Consts";
import Util from "../Util";

const { Factories, Machineries } = Consts;

declare interface IMachinerySelectorProps
    extends React.Props<MachinerySelector> {
    placeholder?: string;
    factoryId?: number;
    machineryName: string;
    machineryId?: number;
    machineryUse: MachineryUse;
    onSelect: (value: number) => void;
    readOnly?: boolean;
}

declare interface IMachinerySelectorState {
    factories: any; // 工厂列表
    machineryOptions: ITwoStageCascade[];
    columns: string[];
    selectorMachinery: string[];
}

class MachinerySelector extends React.Component<
    IMachinerySelectorProps,
    IMachinerySelectorState
> {
    public static defaultProps: IMachinerySelectorProps;
    constructor(props) {
        super(props);
        this.state = {
            factories: [],
            columns: ["类型", "机台号"],
            machineryOptions: [],
            selectorMachinery: []
        };
    }

    public async componentWillReceiveProps(nextProps) {
        if (nextProps.factoryId !== this.props.factoryId) {
            const machineryOptions = await this.getMachineryOptions(
                nextProps.factoryId
            );
            this.setState({ machineryOptions });
        }
    }

    public async componentDidMount() {
        const t = this;
        const { factoryId, onSelect } = t.props;
        let { machineryId } = t.props;
        const factories = await Factories.getFactories(); // 工厂列表
        const machineryOptions = await t.getMachineryOptions(factoryId);

        // 若factoryId对应的工厂下只有一种机械类型的机器，则machineryId默认设置为第一个
        machineryId =
            machineryId !== null
                ? machineryId
                : machineryOptions.length === 1
                    ? (machineryOptions[0].children[0].value as number)
                    : null;

        t.setState({
            machineryOptions,
            factories
        });

        // 当设置默认的machineryId后，同步刷新调用界面的machineryId
        if (onSelect) {
            onSelect(machineryId);
        }
    }

    public render(): JSX.Element {
        const t = this;
        const s = this.state;
        const { machineryName, placeholder, factoryId, readOnly } = t.props;
        const optionValue = t.getMachinery(s.machineryOptions);

        return (
            <div>
                {s.machineryOptions.length > 0 ? (
                    <CascadeSelectField
                        label="机台号"
                        onSelect={t.handleSelectChange}
                        options={s.machineryOptions}
                        value={optionValue}
                        placeholder={placeholder}
                        columns={s.columns}
                        required={true}
                        readOnly={readOnly}
                    />
                ) : (
                    <Field label="机台号" required={true}>
                        {factoryId
                            ? `${Util.getItemName(
                                  factoryId,
                                  s.factories
                              )}不存在${machineryName}机械信息，请联系管理员配置`
                            : null}
                    </Field>
                )}
            </div>
        );
    }

    private getMachinery(machineryOptions: ITwoStageCascade[]) {
        const t = this;
        let optionValues = [];
        const { machineryId } = t.props;

        // 获取对应的机台号Value
        machineryOptions.forEach(m => {
            const op = m.children.find(c => c.value === machineryId);
            if (op) {
                optionValues = [m.value, op.value];
                return optionValues;
            }
        });

        return optionValues;
    }

    private handleSelectChange = (
        value: Array<{ value: number; text: string }>
    ) => {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect(value[1].value);
        }
    };

    private async getMachineryOptions(
        factoryId?: number
    ): Promise<ITwoStageCascade[]> {
        const { machineryUse } = this.props;
        let machineries = await Machineries.getMachineries();
        if (Util.isDefinedAndNotNull(factoryId)) {
            machineries = machineries.filter(
                m =>
                    m.use === machineryUse &&
                    m.disabled === false &&
                    m.factoryId === factoryId
            );
        } else {
            machineries = machineries.filter(
                m => m.use === machineryUse && m.disabled === false
            );
        }

        const machineryOptions = Util.getMachineryCascadData(machineries);

        return machineryOptions;
    }
}

MachinerySelector.defaultProps = {
    machineryName: "",
    machineryUse: MachineryUse.Trimming,
    onSelect: value => {},
    machineryId: null,
    placeholder: null,
    factoryId: null,
    readOnly: false
};

export default MachinerySelector;
