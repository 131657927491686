import React from "react";
import { PickerField } from "saltui";
import Util from "../Util";

declare interface IESPickerFieldProps {
    options: IOptionWithPhonetic[] | IOption[];
    value: IOptionWithPhonetic | IOption | IOption[] | IOptionWithPhonetic[];
    label: string;
    onSelect: (value: IOption | IOption[]) => void;
    readOnly?: boolean;
    multiple?: boolean;
    grouping?: boolean;
    groupingIndicator?: boolean;
    required?: boolean;
    placeholder?: string;
    className?: string;
}

class ESPickerField extends React.Component<IESPickerFieldProps, {}> {
    public static defaultProps: IESPickerFieldProps;

    public render(): JSX.Element {
        const t = this;

        const {
            label,
            value,
            options,
            onSelect,
            multiple,
            groupingIndicator,
            grouping,
            required,
            className,
            ...other
        } = t.props;

        const fieldStyle = Util.isNotNullAndNotEmpty(className)
            ? className + " packerFieldSty"
            : "packerFieldSty";

        return (
            <PickerField
                label={label}
                value={value}
                options={options}
                required={required}
                onSelect={onSelect}
                multiple={multiple}
                groupingIndicator={groupingIndicator}
                grouping={grouping}
                className={fieldStyle}
                {...other}
            />
        );
    }
}

ESPickerField.defaultProps = {
    label: "",
    value: null,
    options: [],
    onSelect: (value: IOption | IOption[]) => {},
    readOnly: false,
    multiple: false,
    required: false,
    placeholder: null,
    grouping: true,
    groupingIndicator: true,
    className: null
};

export default ESPickerField;
