import React from "react";
import { Boxs } from "saltui";
import Perm from "../../app/Perm";
import { IWarehouseDTO, StoneArtifactType } from "../../app/WebAPIClients";
import Consts from "../Consts";
import ListItem from "../ListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";
import PermCtrl from "../PermCtrl";
import ReferencePriceInfo from "../ReferencePriceInfo";
import SalesOrderUtil from "../SalesOrderUtil";
import Settings from "../SettingInfo";
import SettingsDefinition from "../SettingsDefinition";
import ShippingOrderUtil from "../ShippingOrderUtil";
import StockingAreaUtil from "../StockingAreaUtil";
import StoneArtifactUtil from "../StoneArtifactUtil";
import { ISalesOrderDetailForUI, ReferencePriceSize } from "../type";
import Util from "../Util";

const { HBox, Box } = Boxs;

declare interface IShippingOrderDetailListItemProps extends IListItemBaseProps {
    item?: ISalesOrderDetailForUI;
    showPrice?: boolean;
    showStockingArea?: boolean;
    customShowReferencePrice?: boolean;
    allowEditing?: boolean;
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
    showIconStatus?: ShowIconStatus;
    onUnitPriceChange?: (value: string, item: ISalesOrderDetailForUI) => void;
    onDeductedQuantityChange?: (
        value: string,
        item: ISalesOrderDetailForUI
    ) => void;
    onPaymentIconClick?: (item: ISalesOrderDetailForUI) => void;
    onShipmentIconClick?: (item: ISalesOrderDetailForUI) => void;
}

declare interface IShippingOrderDetailListItemState {
    saList?: IIdNameItem[]; // 仓库区域列表
    warehouses?: IIdNameItem[]; // 仓库列表
    factories?: IIdNameItem[]; // 工厂列表
    enableReferencePrice: boolean;
    showBundleGrades: boolean;
}

class ShippingOrderDetailListItem extends React.PureComponent<
    IShippingOrderDetailListItemProps,
    IShippingOrderDetailListItemState
> {
    public static defaultProps: IShippingOrderDetailListItemProps;
    constructor(props) {
        super(props);
        this.state = {
            enableReferencePrice: Settings.getBoolValue(
                SettingsDefinition.EnableReferencePriceKey
            ),
            showBundleGrades: PermCtrl.isAuthorized(Perm.SB_G_R)
        };
    }

    public async componentDidMount() {
        const factories = await Consts.Factories.getFactories(); // 工厂列表
        const warehouses: IWarehouseDTO[] = await Consts.Warehouses.getWarehouses(); // 仓库列表3.
        const psaList = await Consts.ProductStockingAreas.getStockingAreas();
        const bsaList = await Consts.BlockStockingAreas.getStockingAreas();

        this.setState({
            saList: [...psaList, ...bsaList],
            factories,
            warehouses
        });
    }

    public render() {
        const t = this;
        const s = t.state;

        const {
            item,
            showPrice,
            showStockingArea,
            allowEditing,
            allowDeletion,
            categoryList,
            gradeList,
            allowSelection,
            showIconStatus,
            selected,
            onDelete,
            onClick,
            onSelect,
            ...other
        } = t.props;

        const spoItemInfo = ShippingOrderUtil.getShippingItemInfoForList(
            item,
            categoryList,
            gradeList,
            s.showBundleGrades
        );

        const description = (
            <div>
                {spoItemInfo.text}
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    StockingAreaUtil.getStockingAreaId(item)
                )}
            </div>
        );
        let extraInfo = null;
        const avatarTitle = StoneArtifactUtil.getStoneArtifactAvatarText(item);
        const showDeductedQuantity = item.type !== StoneArtifactType.Tile;
        extraInfo = (
            <div>
                {showPrice ? (
                    allowEditing ? (
                        <HBox flex={1}>
                            <Box className="unitPriceArea unitPriceLabel">
                                单价
                            </Box>
                            <Box className="unitPriceArea">
                                <div>
                                    <input
                                        type="number"
                                        min="0"
                                        step="0.1"
                                        name="unitPrice"
                                        onChange={t.handleUnitPriceChange}
                                        className={"unitPriceTextBox"}
                                        value={item.price}
                                    />
                                    {t.getReferencePriceInfo()}
                                </div>
                            </Box>
                            <Box className="unitPriceArea deductedReasonLabel">
                                扣方
                            </Box>
                            <Box className="unitPriceArea" flex={1}>
                                <input
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    name="deductedQuantity"
                                    onChange={t.handleDeductedQuantityChange}
                                    className={
                                        "unitPriceTextBox unitPriceTextPosition"
                                    }
                                    value={item.deductedQuantity}
                                />
                            </Box>
                            <Box className="unitPriceArea">
                                <p className="demo3-t-list-title">
                                    <span className="unitPriceText">
                                        ￥{Util.calculateSubTotal(
                                            item.quantity,
                                            item.deductedQuantity,
                                            item.price
                                        )}
                                    </span>
                                </p>
                            </Box>
                        </HBox>
                    ) : (
                        <HBox flex={1}>
                            <Box className="unitPriceArea unitPriceLabel">
                                单价
                            </Box>
                            <Box className="unitPriceArea">
                                <div>
                                    <span className="unitPriceText">
                                        ￥{item.price}
                                    </span>
                                    {t.getReferencePriceInfo()}
                                </div>
                            </Box>
                            <Box className="unitPriceArea deductedSizeLabel">
                                {showDeductedQuantity &&
                                item.deductedQuantity > 0
                                    ? "扣方"
                                    : null}
                            </Box>
                            <Box className="unitPriceArea" flex={1}>
                                <span className="unitPriceText deductedSizeText">
                                    {showDeductedQuantity &&
                                    item.deductedQuantity > 0
                                        ? item.deductedQuantity
                                        : null}
                                </span>
                            </Box>
                            <Box className="unitPriceArea">
                                <p className="demo3-t-list-title">
                                    <span className="unitPriceText subTotalStyl">
                                        ￥{item.subTotal}
                                    </span>
                                </p>
                            </Box>
                        </HBox>
                    )
                ) : null}
            </div>
        );

        const icon =
            showIconStatus === true
                ? SalesOrderUtil.showIcon(
                      item,
                      t.handlePaymentIconClick,
                      t.handleShipmentIconClick
                  )
                : showIconStatus === "onlyShowShipped"
                    ? SalesOrderUtil.getShipmentIcon(
                          item,
                          t.handleShipmentIconClick
                      )
                    : showIconStatus === "onlyShowPaid"
                        ? SalesOrderUtil.getPaidIcon(
                              item,
                              t.handlePaymentIconClick
                          )
                        : null;

        return (
            <ListItem
                avatarTitle={avatarTitle}
                item={item}
                title={spoItemInfo.title}
                description={description}
                extraInfo={extraInfo}
                allowDeletion={allowDeletion}
                allowSelection={allowSelection}
                selected={selected}
                onDelete={onDelete}
                onClick={onClick}
                onSelect={onSelect}
                icon={icon}
            />
        );
    }

    private handleUnitPriceChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const t = this;
        const { onUnitPriceChange, item } = t.props;
        if (onUnitPriceChange) {
            const value = event.target.value;
            onUnitPriceChange(value, item);
        }
    };

    private handleDeductedQuantityChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const t = this;
        const { onDeductedQuantityChange, item } = t.props;
        if (onDeductedQuantityChange) {
            const value = event.target.value;
            onDeductedQuantityChange(value, item);
        }
    };

    private handlePaymentIconClick = (
        event: React.MouseEvent<HTMLDivElement>
    ) => {
        const t = this;
        const { item, onPaymentIconClick } = t.props;
        if (onPaymentIconClick) {
            onPaymentIconClick(item);
        }

        if (event) {
            event.stopPropagation();
        }
    };

    private handleShipmentIconClick = (
        event: React.MouseEvent<HTMLDivElement>
    ) => {
        const t = this;
        const { item, onShipmentIconClick } = t.props;
        if (onShipmentIconClick) {
            onShipmentIconClick(item);
        }

        if (event) {
            event.stopPropagation();
        }
    };

    private getReferencePriceInfo = () => {
        const t = this;
        const s = t.state;
        const {
            item,
            customShowReferencePrice,
            categoryList,
            gradeList
        } = t.props;

        const unitPrice = SalesOrderUtil.getPriceInfo(item);
        const showReferencePrice = [
            StoneArtifactType.Block,
            StoneArtifactType.StoneBundle
        ].includes(item.type);
        return s.enableReferencePrice &&
            showReferencePrice &&
            customShowReferencePrice ? (
            <ReferencePriceInfo
                bundle={item.bundle}
                categoryList={categoryList}
                gradeList={gradeList}
                showPrice={
                    s.enableReferencePrice &&
                    showReferencePrice &&
                    customShowReferencePrice
                }
                referencePrice={unitPrice}
                lineThrough={unitPrice !== null && unitPrice !== item.price}
                showIcon={false}
                size={ReferencePriceSize.Small}
            />
        ) : null;
    };
}

ShippingOrderDetailListItem.defaultProps = {
    item: null,
    onClick: item => {},
    onSelect: item => {},
    onDelete: item => {},
    onUnitPriceChange: () => {},
    onDeductedQuantityChange: () => {},
    showPrice: false,
    allowEditing: false,
    allowDeletion: false,
    allowSelection: false,
    showStockingArea: false,
    selected: false,
    categoryList: [],
    gradeList: [],
    showIconStatus: true,
    onPaymentIconClick: null,
    onShipmentIconClick: null,
    customShowReferencePrice: false
};

export default ShippingOrderDetailListItem;
