import React from "react";
import { Group } from "saltui";
import ListItem from "../ListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";

declare interface IExpandableContentProps extends IListItemBaseProps {
    item?: object & { id: number };
}

declare interface IExpandableListItemProps extends IListItemBaseProps {
    content?: IExpandableContentProps[];
    customContent?: JSX.Element;
    onExpansionChange?: (item: object, expanded: boolean) => void;
    onSelect?: (item: object) => void;
}

declare interface IExpandableListItemState {
    expanded: boolean;
}

class ExpandableListItem extends React.PureComponent<
    IExpandableListItemProps,
    IExpandableListItemState
> {
    public static defaultProps: IExpandableListItemProps;
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    public componentDidUpdate() {
        if (
            "expanded" in this.props &&
            this.state.expanded !== this.props.expanded
        ) {
            this.setState({
                expanded: this.props.expanded
            });
        }
    }

    public render() {
        const t = this;
        const s = t.state;
        const {
            item,
            avatarTitle,
            title,
            description,
            content,
            allowSelection,
            selected,
            customContent,
            ...other
        } = t.props;

        // 该控件暂时不支持删除明细
        return (
            <div>
                <ListItem
                    item={item}
                    avatarTitle={avatarTitle}
                    title={title}
                    description={description}
                    allowSelection={allowSelection}
                    selected={selected}
                    allowExpansion={true}
                    onClick={t.props.onClick}
                    onSelect={t.props.onSelect}
                    onExpansionChange={t.handleExpansionChange}
                    expanded={s.expanded}
                />
                {s.expanded ? (
                    <div>
                        {customContent ? (
                            customContent
                        ) : content && content.length && content.length > 0 ? (
                            <Group.List lineIndent={15} itemIndent={[15, 0]}>
                                {content.map(child => {
                                    return (
                                        <ListItem
                                            key={child.item.id}
                                            item={child.item}
                                            avatarTitle={child.avatarTitle}
                                            avatarSize="34"
                                            title={child.title}
                                            className="slab-item t-FBH t-FBAC"
                                            description={child.description}
                                            allowSelection={
                                                child.allowSelection
                                            }
                                            selected={child.selected}
                                            onClick={child.onClick}
                                            onSelect={child.onSelect}
                                        />
                                    );
                                })}
                            </Group.List>
                        ) : (
                            <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                                没有数据
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        );
    }

    private handleExpansionChange = (item: object) => {
        const t = this;
        const s = t.state;
        const { onExpansionChange } = t.props;
        const expanded = !s.expanded;

        if ("expanded" in this.props === false) {
            t.setState({ expanded });
        }

        if (onExpansionChange) {
            onExpansionChange(item, expanded);
        }
    };
}

ExpandableListItem.defaultProps = {
    item: null,
    avatarTitle: "",
    title: null,
    description: null,
    allowSelection: false,
    onClick: item => {},
    onSelect: item => {},
    onExpansionChange: item => {},
    selected: false,
    content: [],
    customContent: null
};

export default ExpandableListItem;
