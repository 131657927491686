import React, { useEffect, useState } from "react";

import { Boxs, RadioField, Field } from "saltui";
import ReferencePrice from "../ReferencePrice";
import { ISlabInfoForGradeChange } from "./interfaces";
import ProductStockingAreaStore from "../../store/ProductStockingAreaStore";
import StoneGradeStore from "../../store/StoneGradeStore";
import { IStoneGradeDTO } from "../../app/WebAPIClients";
import Util from "../Util";
import Picker from "../Picker";
import SlabGradeUnitPriceStore from "./SlabGradeUnitPriceStore";
import ESIcon from "../ESIcon";
const { VBox, HBox, Box } = Boxs;

declare interface ISlabGradeSelectorProps {
  slab: ISlabInfoForGradeChange;
  toGradeId?: number;
  onGradeChange?: (slab: ISlabInfoForGradeChange, toGradeId: number) => void;
  showUnitPrice?: boolean;
}

function SlabGradeSelector(props: ISlabGradeSelectorProps) {
  const { slab, toGradeId, onGradeChange, showUnitPrice } = props;
  const [grades, setGrades] = useState<IStoneGradeDTO[]>([]);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [unitPrice, setUnitPrice] = useState<number | null>(null);

  useEffect(() => {
    const loadGrades = async () => {
      const result = await StoneGradeStore.getGrades();
      setGrades(result);
    };

    loadGrades();
  }, []);

  if (showUnitPrice) {
    useEffect(() => {
      const getUnitPrice = async () => {
        if (toGradeId !== null) {
          const stockingAreaId = slab.stockingAreaId;
          const factoryId =
            await ProductStockingAreaStore.getFactoryIdByStockingAreaId(
              stockingAreaId,
            );

          const unitPrice = await SlabGradeUnitPriceStore.getUnitPrice(
            factoryId,
            slab.categoryId,
            toGradeId,
            slab.thickness,
            slab.type,
          );
          setUnitPrice(unitPrice);
        }
      };

      getUnitPrice();
    }, [toGradeId]);
  }

  const onPickerVisibleChange: (visible: boolean) => void = (visible) => {
    setPickerVisible(visible);
  };

  const onGradeRadioOptionChange = (value: number) => {
    if (onGradeChange) {
      onGradeChange(slab, value);
    }
    window.history.back();
  };

  const gradesOptions = grades
    .filter((g) => g.id !== slab.gradeId)
    .map((g) => {
      return {
        value: g.id,
        checked: g.id === toGradeId,
        content: g.name,
      };
    });

  const toGrade: IStoneGradeDTO | null = Util.isDefinedAndNotNull(toGradeId)
    ? grades.find((g) => g.id === toGradeId)
    : null;

  const toGradeName = toGrade?.name ?? "请选择等级";

  const onShowPickerIconClick: () => void = () => {
    setPickerVisible(true);
  };

  const icon: JSX.Element = (
    <ESIcon
      name="icon-right"
      fill="rgba(31,56,88,.4)"
      onClick={onShowPickerIconClick}
      height={25}
      width={25}
    />
  );

  let gradeNameCss: string = "t-FBAC slabGradeSelectorInput";

  if (!Util.isDefinedAndNotNull(toGradeId)) {
    gradeNameCss = "t-picker-field-placeholder " + gradeNameCss;
  }

  return (
    <HBox onClick={onShowPickerIconClick}>
      <Box>
        <VBox>
          <Box className={gradeNameCss}>{toGradeName}</Box>
          <Box className="t-FBAC slabGradeCentered">
            {showUnitPrice && Util.isDefinedAndNotNull(unitPrice) ? (
              <ReferencePrice referencePrice={unitPrice} />
            ) : null}
          </Box>
        </VBox>
        <Picker
          visible={pickerVisible}
          onVisibleChange={onPickerVisibleChange}
          className="t-picker-searchpanel-inner"
        >
          <RadioField
            label="请选择要变更的等级"
            iconPosition="right"
            data={gradesOptions}
            onChange={onGradeRadioOptionChange}
          />
        </Picker>
      </Box>
      <Box>{icon}</Box>
    </HBox>
  );
}

export default SlabGradeSelector;
