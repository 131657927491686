import React from "react";
import Perm from "../../app/Perm";
import {
    ISlabForListDTO,
    IStructureOfScatteredSlabDTO
} from "../../app/WebAPIClients";
import ExpandableListItem from "../ExpandableListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";
import PermCtrl from "../PermCtrl";
import SlabUtil from "../SlabUtil";
import TileUtil from "../TileUtil";
import Util from "../Util";

declare interface IScatteredSlabListItemProps extends IListItemBaseProps {
    scatteredSlab?: IStructureOfScatteredSlabDTO;
    scatteredDetails?: IScatteredSlabSelection[];
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
    onExpansionChange?: (item: object, expanded: boolean) => void;
    showUnitPrice?: boolean;
}

declare interface IScatteredSlabListItemState {
    showBundleGrades: boolean;
}

class ScatteredSlabListItem extends React.PureComponent<
    IScatteredSlabListItemProps,
    IScatteredSlabListItemState
> {
    constructor(props) {
        super(props);
        this.state = {
            showBundleGrades: PermCtrl.isAuthorized(Perm.SB_G_R)
        };
    }
    public static defaultProps: IScatteredSlabListItemProps;
    public render() {
        const t = this;

        const {
            scatteredSlab,
            scatteredDetails,
            onClick,
            onSelect,
            categoryList,
            gradeList,
            allowSelection,
            showUnitPrice,
            ...other
        } = t.props;

        const { showBundleGrades } = t.state;

        let selectionClickEvent = onSelect;
        let onClickFun = onClick;
        let selected: SelectionStatus = false;
        if (allowSelection) {
            selectionClickEvent = t.handleScatteredSlabsInBundlePrefixSelection;
            onClickFun = t.handleScatteredSlabsInBundlePrefixSelection;
            selected = t.getScatteredSlabSelection(scatteredSlab);
        }

        const ss = TileUtil.getScatteredSlabsTitle(
            scatteredSlab,
            categoryList,
            gradeList,
            showBundleGrades,
            showUnitPrice
        );
        const avatarTitle = Util.getPreAvatarText(scatteredSlab.bundlePrefix);

        return (
            <ExpandableListItem
                item={scatteredSlab}
                avatarTitle={avatarTitle}
                title={ss.title}
                description={ss.text}
                onClick={onClickFun}
                content={t.getChildren()}
                onSelect={selectionClickEvent}
                selected={selected}
                allowSelection={allowSelection}
                {...other}
            />
        );
    }

    private getSelectedSlabState(slabItem: ISlabForListDTO): boolean {
        const t = this;
        let slabSelected: boolean = false;
        const { scatteredDetails } = t.props;
        if (
            typeof scatteredDetails === "undefined" ||
            scatteredDetails === null
        ) {
            return slabSelected;
        }
        const detail = scatteredDetails.find(b => {
            return b.bundlePrefix === slabItem.bundlePrefix;
        });

        if (detail) {
            slabSelected = detail.slabIds.some(d => {
                return d === slabItem.id;
            });
        }

        return slabSelected;
    }

    // 选择散板
    private handleScatteredSlabSelection = (slab: ISlabForListDTO) => {
        const { scatteredSlab: scatteredItem } = this.props;

        const slabId = slab.id;
        if (!slabId) {
            return;
        }

        const t = this;
        const { scatteredDetails, onSelect } = t.props;
        let newScatteredDetails = [...scatteredDetails];

        // 该片板所在slabOfBundlePrefix是否在被选中
        const scatteredSlabSelected = scatteredDetails.some(s => {
            return s.bundlePrefix === slab.bundlePrefix;
        });

        // 如果没被选中
        if (!scatteredSlabSelected) {
            // 判断该slabOfBundlePrefix是否只有一片板，如果只有一片板，allSlabSelected=true。
            const allSlabSelected =
                scatteredItem.slabList.length > 1 ? false : true;
            const detail = {
                bundlePrefix: scatteredItem.bundlePrefix,
                allSlabSelected,
                slabIds: [slabId]
            };
            newScatteredDetails.push(detail);
        }
        // 如果slabOfBundlePrefix被选中
        else {
            const scatteredDetail = newScatteredDetails.find(s => {
                return s.bundlePrefix === slab.bundlePrefix;
            });
            // 判断该块板是否被选中
            const slabSelected = scatteredDetail.slabIds.some(s => {
                return s === slabId;
            });
            if (slabSelected) {
                if (scatteredDetail.slabIds.length > 1) {
                    // 如果该扎选中有多片板，将该片板移除
                    scatteredDetail.allSlabSelected = false;
                    const slabIds = scatteredDetail.slabIds.filter(id => {
                        return id !== slabId;
                    });
                    scatteredDetail.slabIds = slabIds;
                } else {
                    // 如果只有一片板，将该扎移除
                    newScatteredDetails = scatteredDetails.filter(s => {
                        return s.bundlePrefix !== slab.bundlePrefix;
                    });
                }
            } else {
                // 如果没被选中，添加该片板
                scatteredDetail.slabIds.push(slabId);
                // 如果该扎下的所有在库存状态板被选中，如果完全选中，把allSlabSeleted设为true
                if (
                    scatteredDetail.slabIds.length ===
                    scatteredItem.slabList.length
                ) {
                    scatteredDetail.allSlabSelected = true;
                }
            }
        }

        if (onSelect) {
            onSelect(newScatteredDetails);
        }

        if (event) {
            event.stopPropagation();
        }
    };

    // 以荒料为单位选择散板
    private handleScatteredSlabsInBundlePrefixSelection = (
        item: IStructureOfScatteredSlabDTO
    ) => {
        const bundlePrefix = item.bundlePrefix;
        if (!bundlePrefix) {
            return;
        }

        const t = this;
        const { scatteredDetails, onSelect } = t.props;
        let newScatteredDetails = [...scatteredDetails];

        const scatteredSlabSelected = scatteredDetails.some(s => {
            return s.bundlePrefix === bundlePrefix;
        });

        if (!scatteredSlabSelected) {
            // 没有被选中，添加该扎编号下的所有散板
            newScatteredDetails = t.addScatteredSlabsDetail(
                item,
                newScatteredDetails
            );
        } else {
            // 已经被选中
            const detail = scatteredDetails.find(d => {
                return d.bundlePrefix === bundlePrefix;
            });
            newScatteredDetails = scatteredDetails.filter(d => {
                return d.bundlePrefix !== bundlePrefix;
            });
            // 如果该扎下的大板没有被全选，添加整扎
            if (!detail.allSlabSelected) {
                newScatteredDetails = t.addScatteredSlabsDetail(
                    item,
                    newScatteredDetails
                );
            }
        }

        if (onSelect) {
            onSelect(newScatteredDetails);
        }

        if (event) {
            event.stopPropagation();
        }
    };

    // 添加散板
    private addScatteredSlabsDetail(
        item: IStructureOfScatteredSlabDTO,
        newScatteredDetails: IScatteredSlabSelection[]
    ) {
        const t = this;
        const s = t.state;
        const slabIds = [];
        const detail = {
            bundlePrefix: null,
            allSlabSelected: null,
            slabIds: []
        };

        detail.bundlePrefix = item.bundlePrefix;
        detail.allSlabSelected = true;
        item.slabList.map(slabItme => {
            slabIds.push(slabItme.id);
        });

        detail.slabIds = slabIds;
        newScatteredDetails.push(detail);
        return newScatteredDetails;
    }

    // 获取扎的是否选中颜色
    private getScatteredSlabSelection(
        item: IStructureOfScatteredSlabDTO
    ): SelectionStatus {
        const t = this;
        let selectedFlag: SelectionStatus = false;
        let detail = null;
        let slabs = [];

        const { scatteredDetails } = t.props;

        if (
            typeof scatteredDetails === "undefined" ||
            scatteredDetails === null
        ) {
            return selectedFlag;
        }
        if (item.bundlePrefix) {
            detail = scatteredDetails.find(b => {
                return b.bundlePrefix === item.bundlePrefix;
            });
            slabs = item.slabList;
        }

        // 扎下面的大板被全部选中
        if (detail && detail.allSlabSelected) {
            selectedFlag = "all";
        }
        // 扎下面的大板被部分选中
        else if (
            detail &&
            detail.slabIds.length > 0 &&
            detail.slabIds.length < slabs.length
        ) {
            selectedFlag = "partially";
        }
        // 扎下面的大板全部没有选中
        else {
            selectedFlag = "none";
        }

        return selectedFlag;
    }

    private getChildren() {
        const t = this;
        const children = [];

        const {
            scatteredSlab,
            allowSelection,
            categoryList,
            gradeList,
            showUnitPrice
        } = t.props;

        if (scatteredSlab.slabList && scatteredSlab.slabList.length > 0) {
            Util.getSlabGroupBy(scatteredSlab.slabList).forEach(slab => {
                let newChild: IListItemBaseProps = {
                    item: slab,
                    avatarTitle: slab.sequenceNumber.toString(),
                    title: SlabUtil.getSlabTitle(slab, showUnitPrice),
                    description:
                        slab.bundlePrefix === slab.blockNumber
                            ? SlabUtil.getSlabSpecWithShowStockingArea(
                                  slab,
                                  gradeList
                              )
                            : SlabUtil.getSlabInfoSpecWithShowStockingArea(
                                  slab,
                                  categoryList,
                                  gradeList
                              ),
                    allowSelection
                };

                if (allowSelection) {
                    newChild = {
                        ...newChild,
                        selected: t.getSelectedSlabState(slab),
                        onSelect: t.handleScatteredSlabSelection,
                        onClick: t.handleScatteredSlabSelection
                    };
                } else {
                    newChild = {
                        ...newChild,
                        selected: false,
                        onSelect: () => {},
                        onClick: () => {}
                    };
                }

                children.push(newChild);
            });
        }
        return children;
    }
}

ScatteredSlabListItem.defaultProps = {
    scatteredSlab: null,
    scatteredDetails: [],
    onClick: () => {},
    onSelect: () => {},
    onExpansionChange: () => {},
    categoryList: [],
    gradeList: [],
    allowSelection: false,
    showUnitPrice: false
};

export default ScatteredSlabListItem;
