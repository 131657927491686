import React from "react";
import { Boxs, Group } from "saltui";
import { IDepositIncurredItemDTO } from "../../app/WebAPIClients";
import DateUtil from "../DateUtil";
import ESHeader from "../ESHeader";
import ListItem from "../ListItem";
import Util from "../Util";

const { HBox, Box } = Boxs;

declare interface IDepositIncurredListProps
    extends React.Props<DepositIncurredList> {
    items: IDepositIncurredItemDTO[];
    onCreateItemClick?: () => void;
    onClick: (depositIncurred: IDepositIncurredItemDTO) => void;
    showCreateItemIcon?: boolean;
}

class DepositIncurredList extends React.Component<
    IDepositIncurredListProps,
    {}
> {
    public static defaultProps: IDepositIncurredListProps;
    constructor(props) {
        super(props);
    }

    public render() {
        const {
            items,
            onCreateItemClick,
            showCreateItemIcon,
            onClick
        } = this.props;

        return (
            <div>
                <ESHeader
                    allowCreation={showCreateItemIcon}
                    onCreateClick={onCreateItemClick}
                    label="存入扣减款列表"
                />
                <Group.List lineIndent={15} itemIndent={[15, 15]}>
                    {items && items.map && items.length > 0 ? (
                        items.map(di => {
                            const title = `${di.number} | 摘要：${di.summary}`;
                            const description = `来源：${
                                di.source
                            } | 日期：${DateUtil.formatDate(
                                di.transactionDate
                            )}`;
                            const extraInfo = this.getExtraInfo(di);
                            return (
                                <ListItem
                                    key={di.id}
                                    item={di}
                                    title={title}
                                    avatarTitle={this.getPreAvatarText(di)}
                                    description={description}
                                    onClick={onClick}
                                    extraInfo={extraInfo}
                                />
                            );
                        })
                    ) : (
                        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                            没有数据
                        </div>
                    )}
                </Group.List>
            </div>
        );
    }

    private getPreAvatarText(depositIncurred: IDepositIncurredItemDTO) {
        return depositIncurred.deductedAmount !== 0 ? "扣减" : "存入";
    }

    private getExtraInfo(item: IDepositIncurredItemDTO) {
        const amount = Util.round(
            item.deductedAmount !== 0
                ? item.deductedAmount
                : item.depositAmount,
            2
        );
        return (
            <div className="materialSpecArea">
                <HBox className="demo3-t-list-text" flex={1}>
                    <Box className="separator">交易额：</Box>
                    <Box className="separator" flex={1}>
                        <span className={Util.getBalanceColor(amount)}>
                            ￥{amount.toLocaleString()}
                        </span>
                    </Box>
                    <Box className="separator">
                        <p className="demo3-t-list-title">
                            <span className="demo3-t-list-text">余额：</span>
                            <span
                                className={Util.getBalanceColor(item.balance)}
                            >
                                ￥{Util.round(item.balance, 2).toLocaleString()}
                            </span>
                        </p>
                    </Box>
                    <div className="materialSpecIconArea">
                        <div className="placeholderArea" />
                    </div>
                </HBox>
            </div>
        );
    }
}

DepositIncurredList.defaultProps = {
    items: null,
    onClick: () => {},
    onCreateItemClick: () => {},
    showCreateItemIcon: false
};

export default DepositIncurredList;
