import { Dialog, Toast } from "saltui";

class ESDialog {
    private static dialog: ESDialog = null;
    private isSubmitted: boolean = false;

    constructor() {
        if (ESDialog.dialog === null) {
            ESDialog.dialog = this;
        }
        return ESDialog.dialog;
    }

    public DialogConfirm(
        title: string | JSX.Element,
        content: string | JSX.Element,
        onConfirmFun: () => void
    ) {
        Dialog.confirm({
            title,
            content,
            onConfirm() {
                if (ESDialog.dialog.isSubmitted) {
                    Toast.show({
                        type: "fail",
                        content: "正在提交数据，请耐心等待！"
                    });
                } else {
                    ESDialog.dialog.isSubmitted = true;
                    onConfirmFun();
                    Dialog.hide();
                }
            },
            onCancel() {}
        });
    }

    public Reset() {
        ESDialog.dialog.isSubmitted = false;
    }
}

export default new ESDialog();
