import * as React from "react";
import { Boxs, Group } from "saltui";
import ESHeader from "../ESHeader";
import ESIcon from "../ESIcon";

const HBox = Boxs.HBox;
const Box = Boxs.Box;

export interface ICollapsibleProps extends React.Props<Collapsible> {
    label: string;
    onExpansionChanged?: (expanded: boolean) => void;
    onCreateClick?: () => void;
    expanded?: boolean;
    extraIcons?: JSX.Element;
    allowCreation?: boolean;
}

export interface ICollapsibleState {
    expanded: boolean;
}

class Collapsible extends React.Component<
    ICollapsibleProps,
    ICollapsibleState
> {
    public static defaultProps: ICollapsibleProps;
    constructor(props: ICollapsibleProps) {
        super(props);
        this.state = {
            expanded: props.expanded
        };
    }

    public componentWillReceiveProps(nextProps) {
        if (nextProps.expanded !== this.props.expanded) {
            this.setState({ expanded: nextProps.expanded });
        }
    }

    public render() {
        const t = this;
        const s = t.state;

        const { children, label, ...other } = t.props;

        return (
            <div>
                {t.getInfoHeaderJSX(label)}
                {s.expanded ? children : null}
            </div>
        );
    }

    private handleInfoHeaderClick = e => {
        const t = this;
        const s = t.state;
        const { onExpansionChanged } = t.props;
        const expandedState = !s.expanded;
        t.setState({ expanded: expandedState });

        if (onExpansionChanged) {
            onExpansionChanged(expandedState);
        }

        if (e) {
            e.stopPropagation();
        }
    };

    private handleOnCreateClick = e => {
        const t = this;
        const { onCreateClick } = t.props;

        if (onCreateClick) {
            onCreateClick();
        }

        if (e) {
            e.stopPropagation();
        }
    };

    private getInfoHeaderJSX(headerTitle: string): JSX.Element {
        const t = this;
        const s = t.state;
        const { extraIcons, allowCreation } = t.props;
        const iconName = s.expanded ? "icon-uparrow" : "icon-downarrow";
        const extraJsx = (
            <HBox>
                {extraIcons}
                {allowCreation ? (
                    <Box className={"scanStyle"}>
                        <span onClick={t.handleOnCreateClick}>
                            <ESIcon
                                name="icon-jiahao"
                                fill="#1296db"
                                height={25}
                                width={25}
                            />
                        </span>
                    </Box>
                ) : null}
                <Box className={"scanStyle"}>
                    <ESIcon
                        name={iconName}
                        fill="#1296db"
                        height={25}
                        width={25}
                    />
                </Box>
            </HBox>
        );
        return (
            <Group>
                <div
                    onClick={t.handleInfoHeaderClick}
                    className={"getTopStatus"}
                >
                    <ESHeader label={headerTitle} extraIcons={extraJsx} />
                </div>
            </Group>
        );
    }
}

Collapsible.defaultProps = {
    label: null,
    onExpansionChanged: expanded => {},
    expanded: false,
    extraIcons: null,
    allowCreation: false,
    onCreateClick: () => {}
};

export default Collapsible;
