import nattyStorage from "natty-storage";
import { ISettingDTO } from "../app/WebAPIClients";
import Util from "./Util";

class Settings {
    public static getBoolValue(key: string): boolean {
        const settingVal = this.getValue(key);
        return settingVal.toLocaleLowerCase() === "true" ? true : false;
    }

    public static getNumberValue(key: string): number {
        const settingVal = this.getValue(key);
        return Util.parseToInt(settingVal);
    }

    public static getValue(key: string): string {
        const cache = this.getStorage();
        const si: ISettingDTO[] = cache.get(this.cacheKey);
        return si.find(s => s.key === key).value;
    }

    public static setSettings(settings: ISettingDTO[]): void {
        if (!settings) {
            return;
        }
        const cache = this.getStorage();
        cache.set(this.cacheKey, settings);
    }

    private static storage: any = null;
    private static storageKey: string = "settingsStorage";
    private static cacheKey: string = "settings";
    private static cacheDurationInMin: number = 10;

    private static getStorage(): any {
        if (this.storage === null) {
            this.storage = nattyStorage(
                Util.getSessionCacheOption(
                    this.storageKey,
                    this.cacheDurationInMin
                )
            );
        }
        return this.storage;
    }
}
export default Settings;
