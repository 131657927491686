import * as React from "react";
import { Dialog } from "saltui";
import logger from "./ESLogger";
import Util from "./Util";

class ErrorHandler {
    private static handler: ErrorHandler = null;

    constructor() {
        if (ErrorHandler.handler === null) {
            ErrorHandler.handler = this;
        }

        return ErrorHandler.handler;
    }

    public handleErr(msg: string, err: { message: string; code: number }) {
        let errDetail = null;
        if (err) {
            errDetail = (
                <span>
                    {Util.isDefinedAndNotNull(err.code) ? (
                        <div>
                            {"错误代码："}
                            <span>{err.code}</span>
                        </div>
                    ) : null}
                    {Util.isDefinedAndNotNull(err.message) ? (
                        <div>
                            {"错误信息："}
                            <span>{JSON.stringify(err.message)}</span>
                        </div>
                    ) : null}
                </span>
            );
        }

        logger.e(msg + errDetail);

        Dialog.alert({
            title: msg,
            content: errDetail
        });
    }
}

export default new ErrorHandler();
