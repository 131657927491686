import * as React from "react";
import { Group } from "saltui";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ShippingOrderDetailListItem from "../ShippingOrderDetailListItem";
import StoneArtifactUtil from "../StoneArtifactUtil";
import { ISalesOrderDetailForUI } from "../type";

declare interface IStoneArtifactItemGroupProps {
    items: ISalesOrderDetailForUI[];
    showPrice?: boolean;
    showStockingArea?: boolean;
    allowDeletion?: boolean;
    isPaymentOrder?: boolean;
    showIconStatus?: ShowIconStatus;
    onDelete?: (item) => void;
    onPaymentIconClick?: (item: ISalesOrderDetailForUI) => void;
    onShipmentIconClick?: (item: ISalesOrderDetailForUI) => void;
    customShowReferencePrice?: boolean;
}

declare interface IStoneArtifactItemGroupState {
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
}

class StoneArtifactItemGroup extends React.Component<
    IStoneArtifactItemGroupProps,
    IStoneArtifactItemGroupState
> {
    public static defaultProps: IStoneArtifactItemGroupProps;
    constructor(props) {
        super(props);
        this.state = {};
    }

    public async componentDidMount() {
        const categoryList = await Consts.StoneCategories.getCategories();
        const gradeList = await Consts.StoneGrades.getGrades();
        this.setState({
            categoryList,
            gradeList
        });
    }

    public render() {
        const t = this;

        const { items, ...other } = t.props;
        const blockItems = StoneArtifactUtil.getBlockItems(items);
        const bundleItems = StoneArtifactUtil.getBundleItems(items);
        const tileItems = StoneArtifactUtil.getTilePackingCaseItems(items);
        const showBlockItems =
            blockItems && blockItems.length && blockItems.length > 0;
        const showBundleItems =
            bundleItems && bundleItems.length && bundleItems.length > 0;
        const showTPCItems =
            tileItems && tileItems.length && tileItems.length > 0;

        return (
            <Group>
                {showBlockItems
                    ? this.getStoneArtifactIdItemJSX("荒料列表", blockItems)
                    : null}
                {showBundleItems
                    ? this.getStoneArtifactIdItemJSX("大板列表", bundleItems)
                    : null}
                {showTPCItems
                    ? this.getStoneArtifactIdItemJSX("工程板列表", tileItems)
                    : null}
                {!(showBlockItems || showBundleItems || showTPCItems) ? (
                    <Group.List lineIndent={15} itemIndent={[15, 15]}>
                        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                            没有数据
                        </div>
                    </Group.List>
                ) : null}
            </Group>
        );
    }

    private getStoneArtifactIdItemJSX(
        lable: string,
        items: ISalesOrderDetailForUI[]
    ): JSX.Element {
        const t = this;
        const {
            showPrice,
            showStockingArea,
            onDelete,
            isPaymentOrder,
            showIconStatus,
            onPaymentIconClick,
            onShipmentIconClick,
            customShowReferencePrice,
            ...other
        } = t.props;

        const { allowDeletion } = t.props;
        const { categoryList, gradeList } = t.state;

        return (
            <Collapsible label={lable} expanded={true}>
                <Group>
                    <Group.List lineIndent={15} itemIndent={[15, 15]}>
                        {items && items.length && items.length > 0 ? (
                            items.map((item, index) => {
                                const itemKey =
                                    item.type.toString() +
                                    "|" +
                                    StoneArtifactUtil.getStoneArtifactItemId(
                                        item
                                    ).toString();

                                // 处理场景：更新装车单或结算单界面，若货物明细对应的销售订单不是已批准，则不允许删除此条明细
                                const allowDeletionItem =
                                    allowDeletion && item.orderStatus === 20;
                                return (
                                    <ShippingOrderDetailListItem
                                        key={itemKey}
                                        item={item}
                                        showPrice={showPrice}
                                        showStockingArea={showStockingArea}
                                        categoryList={categoryList}
                                        gradeList={gradeList}
                                        allowDeletion={allowDeletionItem}
                                        onDelete={onDelete}
                                        showIconStatus={showIconStatus}
                                        onPaymentIconClick={onPaymentIconClick}
                                        onShipmentIconClick={
                                            onShipmentIconClick
                                        }
                                        customShowReferencePrice={
                                            customShowReferencePrice
                                        }
                                    />
                                );
                            })
                        ) : (
                            <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                                没有数据
                            </div>
                        )}
                    </Group.List>
                </Group>
            </Collapsible>
        );
    }
}

StoneArtifactItemGroup.defaultProps = {
    items: [],
    showPrice: false,
    showIconStatus: true,
    showStockingArea: false,
    allowDeletion: false,
    isPaymentOrder: false,
    onDelete: item => {},
    onPaymentIconClick: null,
    onShipmentIconClick: null,
    customShowReferencePrice: false
};

export default StoneArtifactItemGroup;
