import React from "react";
import { Boxs, Field, Group, SwitchField } from "saltui";
import { IBlockPlanningRecordDTO } from "../../app/WebAPIClients";
import Collapsible from "../Collapsible";
import ESHeader from "../ESHeader";
import DateUtil from "../DateUtil";
import SlabPlanningItemTable from "./SlabPlanningItemTable";
import TilePlanningItemTable from "./TilePlanningItemTable";
import Util from "../Util";
import ESIcon from "../ESIcon";
import ESPhotoField from "../ESPhotoField";
import Consts from "../Consts";

const { ImageTypes } = Consts;

const { Box } = Boxs;

interface IBlockPlanningRecordInfoProps {
    allowEdit: boolean;
    record: IBlockPlanningRecordDTO;
    onEditClick?: () => void;
}

function BlockPlanningRecordInfo(props: IBlockPlanningRecordInfoProps) {
    const { allowEdit, record, onEditClick } = props;

    const fontSize = "12px";

    const onEdit: React.MouseEventHandler<HTMLSpanElement> = e => {
        if (onEditClick) {
            onEditClick();
        }

        if (e) {
            e.stopPropagation();
        }
    };

    const editIcon = allowEdit ? (
        <Box className={"scanStyle"}>
            <span onClick={onEdit}>
                <ESIcon
                    name="icon-edit"
                    fill="#1296db"
                    height={25}
                    width={25}
                />
            </span>
        </Box>
    ) : null;

    const imageNames = record
        ? Util.getImageFileNamesByType(record.imageFiles, ImageTypes.attachment)
        : null;

    return (
        <Collapsible label="规划方案" expanded={false} extraIcons={editIcon}>
            {Util.isDefinedAndNotNull(record) ? (
                <div>
                    <Group.List lineIndent={15}>
                        <Field label="裂缝/裂痕">
                            {record.cracksExist ? "有裂" : "无裂"}
                        </Field>
                        <Field label="价值评估">
                            {Util.isUndefinedOrNull(record.assessedValue)
                                ? "无"
                                : `¥${record.assessedValue}元`}
                        </Field>
                        <Field label="评估人">{record.assessor}</Field>
                        <Field label="评估日期">
                            {DateUtil.formatDate(record.assessmentDate)}
                        </Field>
                        <ESHeader label={"大板规划方案"} fontSize={fontSize} />
                        <SlabPlanningItemTable
                            items={record.slabPlanningItems}
                        />
                        <ESHeader label="成品板规划方案" fontSize={fontSize} />
                        {record.noTileOutputRates ? (
                            <SwitchField
                                label="无出材率"
                                on={record.noTileOutputRates}
                                readOnly={true}
                                tip="荒料无成品板出材率"
                            />
                        ) : (
                            <TilePlanningItemTable
                                items={record.tilePlanningItems}
                            />
                        )}
                        {Util.isNotNullAndNotEmpty(imageNames) ? (
                            <ESPhotoField
                                label="规划方案附件"
                                readOnly={true}
                                imageNames={imageNames}
                            />
                        ) : null}
                    </Group.List>
                </div>
            ) : (
                <Group.List lineIndent={15} itemIndent={[15, 15]}>
                    <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                        没有数据
                    </div>
                </Group.List>
            )}
        </Collapsible>
    );
}

export default BlockPlanningRecordInfo;
