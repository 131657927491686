import {
    SlabChargeableItemDTO,
    SlabChargeableStructureDTO
} from "../app/WebAPIClients";
import Util from "./Util";

class ChargeableItemUtil {
    public getUnitPrice(slabChargeableItems: SlabChargeableItemDTO[]) {
        if (!Util.isDefinedAndNotNull(slabChargeableItems)) {
            return null;
        }

        let unitPrice = 0;
        slabChargeableItems.forEach(aci => {
            unitPrice += aci.unitPrice;
        });

        return Util.round(unitPrice, 2);
    }

    public getUnitPriceInfo(slabChargeableItems: SlabChargeableItemDTO[]) {
        if (!Util.isDefinedAndNotNull(slabChargeableItems)) {
            return null;
        }

        let unitPriceInfo = "";
        slabChargeableItems.forEach(aci => {
            const unitPriceText = aci.unitPrice.toString();
            unitPriceInfo +=
                aci.unitPrice < 0
                    ? unitPriceText
                    : Util.isNotNullAndNotEmpty(unitPriceInfo)
                        ? "+" + unitPriceText
                        : unitPriceText;
        });

        return slabChargeableItems.length > 1
            ? "(" + unitPriceInfo + ")"
            : unitPriceInfo;
    }

    public getChargeableItemNotes(
        slabChargeableItems: SlabChargeableItemDTO[]
    ) {
        let notes = "";
        if (!Util.isNotNullAndNotEmptyArray(slabChargeableItems)) {
            return notes;
        }

        slabChargeableItems.forEach(aci => {
            if (Util.isNotNullAndNotEmpty(aci.notes)) {
                notes += " " + aci.notes;
            }
        });

        return notes;
    }

    public calculateTotalPrice(
        slabChargeableStructure: SlabChargeableStructureDTO
    ) {
        if (
            !Util.isDefinedAndNotNull(slabChargeableStructure) ||
            !Util.isNotNullAndNotEmptyArray(
                slabChargeableStructure.chargeableItems
            ) ||
            !Util.isDefinedAndNotNull(slabChargeableStructure.slab)
        ) {
            return null;
        }

        let unitPrice = 0;
        slabChargeableStructure.chargeableItems.forEach(aci => {
            unitPrice += aci.unitPrice;
        });

        return Util.round(unitPrice * slabChargeableStructure.slab.area, 2);
    }
}

export default new ChargeableItemUtil();
