import * as React from "react";
import { ICustomerForListDTO } from "../app/WebAPIClients";
import ListItem from "./ListItem";
import Util from "./Util";

class CustomerUtil {
    public getCustomerListItem(
        customer: ICustomerForListDTO,
        customerTypes: IIdNameItem[],
        customerGrades: IIdNameItem[],
        onClick: (item: ICustomerForListDTO) => void,
        allowSelection: boolean = false,
        onSelect: (item: ICustomerForListDTO) => void = item => {},
        selected: boolean = false
    ): JSX.Element {
        const grade = Util.getItemName(customer.gradeId, customerGrades);
        const type = Util.getItemName(customer.typeId, customerTypes);
        const title = (
            <span className={customer.disabled ? "disabledItem" : ""}>
                {customer.name}
                {grade ? " | " + grade : null}
            </span>
        );
        let description = customer.abbreviation + " | " + customer.number;
        description += type ? " | " + type : "";
        return (
            <ListItem
                key={customer.id}
                item={customer}
                title={title}
                avatarTitle={Util.getPreAvatarText(customer.name)}
                description={description}
                onClick={onClick}
                allowSelection={allowSelection}
                onSelect={onSelect}
                selected={selected}
            />
        );
    }
}

export default new CustomerUtil();
