import { Buffer } from "buffer";
import crypto from "crypto-js";
import nattyStorage from "natty-storage";
import { v4 as uuidv4 } from "uuid";
import ClientFactory from "../app/WebAPIClientFactory";
import { ICloudStorageInfoDTO } from "../app/WebAPIClients";
import settings from "../components/Settings";
import tenantInfo from "./TenantInfo";
import { ImageSize } from "./type";
import userInfo from "./UserInfo";
import Util from "./Util";

class CloudStorage {
    public static async getCloudStorageInfo(): Promise<ICloudStorageInfoDTO> {
        const cache = this.getStorage();
        let cloudStorageInfo = cache.get(
            this.cloudStorageInfoCacheKey
        ) as ICloudStorageInfoDTO;

        const expirationTime =
            cloudStorageInfo &&
            cloudStorageInfo.credential &&
            cloudStorageInfo.credential.expiration
                ? new Date(cloudStorageInfo.credential.expiration)
                : new Date(1970, 1, 1);

        // 从后台获取云存储信息的条件
        // 1. 如果云存储信息不存在，或者
        // 2. 云存储信息已经过期或者不到5分钟过期（避免服务器端和客户端的时间差）
        const acquireFromBackend =
            expirationTime.getTime() - Date.now() < 5 * 60 * 1000;
        if (acquireFromBackend) {
            const client = ClientFactory.getSecurityClient();
            cloudStorageInfo = await client.getCloudStorageInfo();
            cache.set(this.cloudStorageInfoCacheKey, cloudStorageInfo);
        }
        return cloudStorageInfo;
    }

    public static async getCloudStorageAccessUrl(
        fileName: string,
        imageSize: ImageSize = ImageSize.Thumbnail
    ): Promise<string> {
        const urls = await this.getCloudStorageAccessUrls(
            [fileName],
            imageSize
        );
        return urls[0];
    }

    public static async getCloudStorageAccessUrls(
        fileNames: string[],
        imageSize: ImageSize = ImageSize.Thumbnail
    ): Promise<string[]> {
        const si = await this.getCloudStorageInfo();
        return this.getCloudStorageAccessUrlsWithStorageInfo(
            fileNames,
            si,
            imageSize
        );
    }

    public static getCloudStorageAccessUrlsWithStorageInfo(
        fileNames: string[],
        storageInfo: ICloudStorageInfoDTO,
        imageSize: ImageSize = ImageSize.Thumbnail
    ): string[] {
        if (!fileNames || !fileNames.length || fileNames.length === 0) {
            return [];
        }

        if (!storageInfo) {
            return [];
        }

        const urls: string[] = [];

        for (const fileName of fileNames) {
            const valid = Util.isNotNullAndNotEmpty(fileName);
            if (!valid) {
                urls.push("");
                continue;
            }
            if (fileName.length < 2) {
                urls.push(fileName);
                continue;
            }
            const fileNameWithoutExt = Util.getFileNameWithoutExtension(
                fileName
            );
            const fileExt = Util.getFileExtension(fileName);
            const objectPath = this.getObjectPath(
                fileNameWithoutExt,
                fileExt,
                imageSize
            );
            urls.push(this.getSignedUrl(objectPath, storageInfo));
        }

        return urls;
    }

    public static getObjectPath(
        fileNameWithoutExt: string,
        fileExt: string,
        imageSize: ImageSize = ImageSize.FullSize
    ) {
        const objectName = this.getObjectName(
            fileNameWithoutExt,
            fileExt,
            imageSize
        );
        return `${tenantInfo.getAbbreviationCode()}/${fileNameWithoutExt[0]}/${
            fileNameWithoutExt[1]
        }/${objectName}`;
    }

    public static getObjectName(
        fileNameWithoutExt: string,
        fileExt: string,
        imageSize: ImageSize = ImageSize.FullSize
    ) {
        const sizeSuffix = this.getImageSizeSuffix(imageSize);
        return `${fileNameWithoutExt}${sizeSuffix}.${fileExt}`;
    }

    /**
     * 为上传文件到OSS设置UploadCore的FileRequest对象的Url和POST的其他参数
     * 调用该函数应该在UploadCore的FILE_UPLOAD_PREPARING事件中使用
     * @param request UploadCore的FileRequest对象
     */
    public static async setFileRequestParams(request: any): Promise<void> {
        const isDevEnv = settings.isDevEnv;
        const storageInfo = await this.getCloudStorageInfo();
        const uuid = uuidv4();
        const file = request.getFile();
        const fileExt = file.ext;
        const objectPath = this.getObjectPath(
            uuid,
            fileExt,
            ImageSize.FullSize
        );
        const objectName = this.getObjectName(
            uuid,
            fileExt,
            ImageSize.FullSize
        );
        const expiration = new Date(Date.now() + 60 * 1000).toISOString();

        const callbackUrl = `${
            settings.webApiUrlForOSSCallback
        }CloudStorageProcessing`;

        // 开发环境没有回调信息
        const callback = isDevEnv
            ? ""
            : {
                  // 回调服务器地址，系统回调地址是WebAPI中的CloudStorageProcessingController
                  callbackUrl,
                  // 回调请求的内容，这些内容将由CloudStorageProcessingController处理
                  callbackBody:
                      // tslint:disable-next-line: no-invalid-template-strings
                      '{"bucketName":${bucket},"objectPath":${object},"objectSize":${size},"imageFormat":${imageInfo.format},"mimeType":${mimeType},"imageHeight":"${imageInfo.height}","imageWidth":"${imageInfo.width}","uploader":${x:uploader}}',
                  callbackBodyType: "application/json"
              };

        const callbackBase64 = Buffer.from(JSON.stringify(callback)).toString(
            "base64"
        );

        // 开发环境没有回调信息
        const policyText = isDevEnv
            ? `{"expiration":"${expiration}","conditions":[{"key":"${objectPath}"}]}`
            : `{"expiration":"${expiration}","conditions":[{"key":"${objectPath}"},{"callback":"${callbackBase64}"}]}`;
        const policyBase64 = Buffer.from(policyText).toString("base64");
        const signature = this.getSignature(
            policyBase64,
            storageInfo.credential.accountKey
        );

        request.setUrl(storageInfo.cloudStorageEndPoint);
        request.setParam("OSSAccessKeyId", storageInfo.credential.accountId);
        request.setParam("policy", policyBase64);
        request.setParam("signature", signature);
        request.setParam("key", objectPath);
        request.getFile().name = objectName;
        request.setParam("success_action_status", "200");
        request.setParam(
            "x-oss-security-token",
            storageInfo.credential.securityToken
        );
        request.setParam("x:uploader", userInfo.getName());

        // 开发环境没有回调信息
        if (!isDevEnv) {
            request.setParam("callback", callbackBase64);
        }
    }

    private static storage: any = null;
    private static storageKey: string = "cloudStorageInfoStorage";
    private static cloudStorageInfoCacheKey: string = "cloudStorageInfo_Cache";
    private static cacheDurationInMin: number = 10;

    private static getStorage(): any {
        if (this.storage === null) {
            this.storage = nattyStorage(
                Util.getSessionCacheOption(
                    this.storageKey,
                    this.cacheDurationInMin
                )
            );
        }
        return this.storage;
    }

    private static getImageSizeSuffix(imageSize: ImageSize): string {
        // 如果是开发环境，因为OSS不能进行回调（没有外网IP），所以没有后台服务处理图片（小图和略缩图）
        // 所以上传的图片只有一张原图，在这里无论要获取什么尺寸的图片，都使用原图
        if (settings.isDevEnv) {
            return "";
        }

        return imageSize === ImageSize.Thumbnail
            ? "_TN"
            : imageSize === ImageSize.SmallSize
                ? "_SS"
                : "";
    }

    private static getSignedUrl(
        objectPath: string,
        storageInfo: ICloudStorageInfoDTO
    ): string {
        if (!storageInfo) {
            return null;
        }

        const keyId = storageInfo.credential.accountId;
        const keySecret = storageInfo.credential.accountKey;
        const endPointUrl = storageInfo.cloudStorageEndPoint;
        const bucket = storageInfo.cloudStorageAccountName;
        const stsToken = storageInfo.credential.securityToken;

        objectPath = objectPath.replace(/^\/+/, "");
        const method = "GET";
        const expires = Math.round(Date.now() / 1000) + 1800; // expiration time is 1800 seconds (30 minutes) from now
        const resource = `/${bucket}/${objectPath}?security-token=${stsToken}`;
        const contentToSign = `${method}\n\n\n${expires.toString()}\n${resource}`;
        const signature = this.getSignature(contentToSign, keySecret);
        return `${endPointUrl}/${objectPath}?OSSAccessKeyId=${encodeURIComponent(
            keyId
        )}&Expires=${encodeURIComponent(
            expires.toString()
        )}&Signature=${encodeURIComponent(
            signature
        )}&security-token=${encodeURIComponent(stsToken)}`;
    }

    private static getSignature(
        contentToSign: string,
        keySecret: string
    ): string {
        const hmac = crypto.HmacSHA1(contentToSign, keySecret);
        return hmac.toString(crypto.enc.Base64);
    }
}
export default CloudStorage;
