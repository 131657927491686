import React from "react";
import { Boxs, Group } from "saltui";
import { IPaymentOrderForListDTO } from "../../app/WebAPIClients";
import ESHeader from "../ESHeader";
import ESIcon from "../ESIcon";
import PaymentOrderUtil from "../PaymentOrderUtil";
const { HBox, Box } = Boxs;

declare interface IPOListProps {
    paymentOrders: IPaymentOrderForListDTO[];
    showCreateItemIcon?: boolean;
    showHeader?: boolean;
    onCreateItemClick?: () => void;
    onItemClick: (paymentOrder: IPaymentOrderForListDTO) => void;
    showScan?: boolean;
    onScanQrCodeClick?: () => void;
}

class POList extends React.Component<IPOListProps, {}> {
    public static defaultProps: IPOListProps;
    constructor(props) {
        super(props);
    }

    public render() {
        const {
            paymentOrders: poList,
            showCreateItemIcon,
            showHeader,
            onCreateItemClick,
            showScan,
            onScanQrCodeClick,
            onItemClick
        } = this.props;

        const extraIcons = showScan ? (
            <Box>
                <span onClick={onScanQrCodeClick}>
                    <ESIcon
                        name="icon-scan"
                        fill="#1296db"
                        height={25}
                        width={25}
                    />
                </span>
            </Box>
        ) : null;

        return (
            <div>
                <Group>
                    {!showHeader ? null : (
                        <ESHeader
                            label={"结算单列表"}
                            extraIcons={extraIcons}
                            allowCreation={showCreateItemIcon}
                            onCreateClick={onCreateItemClick}
                        />
                    )}
                    <Group.List lineIndent={15} itemIndent={[15, 15]}>
                        {poList && poList.length && poList.length > 0 ? (
                            poList.map(po =>
                                PaymentOrderUtil.getPOListItem(po, onItemClick)
                            )
                        ) : (
                            <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                                没有数据
                            </div>
                        )}
                    </Group.List>
                </Group>
            </div>
        );
    }
}
POList.defaultProps = {
    paymentOrders: null,
    showCreateItemIcon: true,
    showHeader: true,
    onItemClick: () => {},
    onCreateItemClick: () => {},
    onScanQrCodeClick: () => {},
    showScan: false
};

export default POList;
