import React from "react";
import { Boxs } from "saltui";
import ListItem from "../ListItem";
import { ISOSelectedProcessSelector } from "../type";
import Util from "../Util";
import CostPriceSpecsTable from "./CostPriceSpecsTable";

const HBox = Boxs.HBox;
const Box = Boxs.Box;

declare interface ISelectedProcessListItemProps {
    process: ISOSelectedProcessSelector;
    showPrice: boolean;
    allowDeletion?: boolean;
    onDelete?: (item: ISOSelectedProcessSelector) => void;
    onClick?: (item: ISOSelectedProcessSelector) => void;
}

class SelectedProcessListItem extends React.Component<
    ISelectedProcessListItemProps
> {
    public static defaultProps: ISelectedProcessListItemProps;
    public static displayName: string;
    public static propTypes: any;

    public render() {
        const t = this;

        const {
            process: processingSpec,
            showPrice,
            allowDeletion,
            onClick,
            onDelete
        } = t.props;

        if (processingSpec === null) {
            return null;
        }

        const pstitle = Util.getProcessingSpecInfoForList(processingSpec);
        const avatarTitle = Util.getPreAvatarText(pstitle);
        const className =
            allowDeletion || showPrice
                ? "materialSpecSeparatorWithIcon"
                : "materialSpecSeparator";

        const costPriceSpecsTable =
            showPrice &&
            processingSpec &&
            processingSpec.costPriceSpecs &&
            processingSpec.costPriceSpecs.length ? (
                <CostPriceSpecsTable
                    costPriceSpecs={processingSpec.costPriceSpecs}
                    allowEdit={false}
                />
            ) : null;

        const title = (
            <HBox flex={1}>
                <Box className="listItemContentArea" flex={1}>
                    <p className="demo3-t-list-title">{pstitle}</p>
                    {processingSpec.notes !== null &&
                    processingSpec.notes !== "" ? (
                        <p className={className}>
                            备注：{processingSpec.notes}
                        </p>
                    ) : null}
                    <div className={className}>{costPriceSpecsTable}</div>
                </Box>
            </HBox>
        );

        return (
            <ListItem
                avatarTitle={avatarTitle}
                title={title}
                item={processingSpec}
                allowDeletion={allowDeletion}
                onDelete={onDelete}
                onClick={onClick}
            />
        );
    }
}
SelectedProcessListItem.defaultProps = {
    process: null,
    showPrice: false,
    allowDeletion: false,
    onClick: (item: ISOSelectedProcessSelector) => {},
    onDelete: (item: ISOSelectedProcessSelector) => {}
};

SelectedProcessListItem.displayName = "ProcessingSpecListItem";

export default SelectedProcessListItem;
