import * as React from "react";
import { Boxs, Field, Group } from "saltui";
import {
    ISlabDTO,
    ISlabForListDTO,
    ISlabWithBundleDTO,
    SlabBundleStatus
} from "../app/WebAPIClients";
import DateUtil from "./DateUtil";
import ReferencePrice from "./ReferencePrice";
import StockingAreaUtil from "./StockingAreaUtil";
import StoneBundleUtil from "./StoneBundleUtil";
import { ReferencePriceSize } from "./type";
import Util from "./Util";
const HBox = Boxs.HBox;
const Box = Boxs.Box;

class SlabUtil {
    // 显示大板的大切尺寸
    public showSawingSize(slab: ISlabDTO | ISlabWithBundleDTO): JSX.Element {
        return (
            <div>
                {this.showSize(
                    "大切尺寸",
                    slab.lengthAfterSawing,
                    slab.widthAfterSawing
                )}
                {this.showSize(
                    "大切扣尺",
                    slab.deductedLengthAfterSawing,
                    slab.deductedWidthAfterSawing
                )}
            </div>
        );
    }

    // 显示大板的补胶尺寸
    public showFillingSize(slab: ISlabDTO | ISlabWithBundleDTO): JSX.Element {
        return (
            <div>
                {this.showSize(
                    "补胶尺寸",
                    slab.lengthAfterFilling,
                    slab.widthAfterFilling
                )}
                {this.showSize(
                    "补胶扣尺",
                    slab.deductedLengthAfterFilling,
                    slab.deductedWidthAfterFilling
                )}
            </div>
        );
    }

    // 显示大板的磨抛尺寸
    public showPolishingSize(slab: ISlabDTO | ISlabWithBundleDTO): JSX.Element {
        return (
            <div>
                {this.showSize(
                    "磨抛尺寸",
                    slab.lengthAfterPolishing,
                    slab.widthAfterPolishing
                )}
                {this.showSize(
                    "磨抛扣尺",
                    slab.deductedLengthAfterPolishing,
                    slab.deductedWidthAfterPolishing
                )}
            </div>
        );
    }

    // 显示大板的扣尺信息
    public showSize(
        text: string,
        length?: number,
        width?: number
    ): JSX.Element {
        return Util.verifySizeIsNotNullAndNorthOfZero(length, width) ? (
            <Field label={text}>{length + " x " + width}</Field>
        ) : null;
    }

    // 显示大板的其它信息
    public showSlabExtraInfo(slab: ISlabDTO | ISlabWithBundleDTO): JSX.Element {
        return (
            <Group.List lineIndent={15}>
                {slab.sawingQE ? (
                    <Field label="大切质检员">{slab.sawingQE}</Field>
                ) : null}
                {slab.sawingTime ? (
                    <Field label="大切时间">
                        {DateUtil.formatDateTime(slab.sawingTime)}
                    </Field>
                ) : null}
                {slab.sawingNote ? (
                    <Field label="大切备注">{slab.sawingNote}</Field>
                ) : null}
                {slab.fillingQE ? (
                    <Field label="补胶质检员">{slab.fillingQE}</Field>
                ) : null}
                {slab.fillingTime ? (
                    <Field label="补胶时间">
                        {DateUtil.formatDateTime(slab.fillingTime)}
                    </Field>
                ) : null}
                {slab.fillingNote ? (
                    <Field label="补胶备注">{slab.fillingNote}</Field>
                ) : null}
                {slab.polishingQE ? (
                    <Field label="磨抛质检员">{slab.polishingQE}</Field>
                ) : null}
                {slab.polishingTime ? (
                    <Field label="磨抛时间">
                        {DateUtil.formatDateTime(slab.polishingTime)}
                    </Field>
                ) : null}
                {slab.polishingNote ? (
                    <Field label="磨抛备注">{slab.polishingNote}</Field>
                ) : null}
                {slab.status === 60 && slab.discardedReason ? (
                    <Field label="报废原因">{slab.discardedReason}</Field>
                ) : null}
            </Group.List>
        );
    }

    // 获取大板的长宽和扣尺
    public getSlabSize(
        slab: ISlabDTO | ISlabWithBundleDTO | ISlabForListDTO
    ): ISlabSize {
        let length = 0;
        let width = 0;
        let deductedLength = 0;
        let deductedWidth = 0;
        if (
            slab.lengthAfterPolishing != null &&
            slab.widthAfterPolishing != null
        ) {
            length = slab.lengthAfterPolishing;
            width = slab.widthAfterPolishing;
            deductedLength = slab.deductedLengthAfterPolishing;
            deductedWidth = slab.deductedWidthAfterPolishing;
        } else if (
            slab.lengthAfterFilling != null &&
            slab.widthAfterFilling != null
        ) {
            length = slab.lengthAfterFilling;
            width = slab.widthAfterFilling;
            deductedLength = slab.deductedLengthAfterFilling;
            deductedWidth = slab.deductedWidthAfterFilling;
        } else {
            length = slab.lengthAfterSawing;
            width = slab.widthAfterSawing;
            deductedLength = slab.deductedLengthAfterSawing;
            deductedWidth = slab.deductedWidthAfterSawing;
        }
        return { length, width, deductedLength, deductedWidth };
    }

    public getSlabInfoForList(
        slab: ISlabDTO | ISlabWithBundleDTO | ISlabForListDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ): IListItem {
        if (!slab) {
            return Util.getEmptyListItemData();
        }

        let title = "";
        if (
            typeof slab.bundlePrefix !== "undefined" &&
            slab.bundlePrefix !== null
        ) {
            title += slab.bundlePrefix;
        }

        title += " | #" + slab.sequenceNumber;

        title += " | " + "厚：" + slab.thickness;

        return {
            title,
            text: this.getSlabInfoSpec(slab, categoryList, gradeList)
        };
    }

    public getSlabTitle(
        slab: ISlabDTO | ISlabWithBundleDTO | ISlabForListDTO,
        showPrice: boolean = false
    ): string | JSX.Element {
        if (!slab) {
            return "";
        }

        let title = "";

        if (slab.blockNumber !== slab.bundlePrefix) {
            if (slab.sequenceNumber !== slab.originalSequenceNumber) {
                title =
                    slab.sequenceNumber.toString() +
                    " | " +
                    slab.blockNumber +
                    " #" +
                    slab.originalSequenceNumber;
            } else {
                title =
                    slab.sequenceNumber.toString() + " | " + slab.blockNumber;
            }
        } else {
            title = slab.sequenceNumber.toString();
        }

        if (!showPrice) {
            return title;
        }

        return (
            <div>
                {title} | <ReferencePrice referencePrice={slab.unitPrice} />
            </div>
        );
    }

    public getSlabSpec(
        slab: ISlabDTO | ISlabWithBundleDTO | ISlabForListDTO,
        gradeList: IIdNameItem[]
    ): string {
        if (!slab) {
            return "";
        }
        const slabSize: ISlabSize = this.getSlabSize(slab);

        return (
            Util.getItemName(slab.gradeId, gradeList) +
            " | " +
            Util.getAreaSpecText(
                slabSize.length,
                slabSize.width,
                slabSize.deductedLength,
                slabSize.deductedWidth
            )
        );
    }

    public getSlabSpecWithShowStockingArea(
        slab: ISlabDTO | ISlabWithBundleDTO | ISlabForListDTO,
        gradeList: IIdNameItem[]
    ): JSX.Element {
        if (!slab) {
            return <span />;
        }
        const slabSize: ISlabSize = this.getSlabSize(slab);
        const showStockingArea = [
            SlabBundleStatus.InStock,
            SlabBundleStatus.ReservedBySalesOrder,
            SlabBundleStatus.ReservedBySlabCheckOutRequest
        ].includes(slab.status);
        return (
            <div>
                <div>
                    {Util.getItemName(slab.gradeId, gradeList) +
                        " | " +
                        Util.getAreaSpecText(
                            slabSize.length,
                            slabSize.width,
                            slabSize.deductedLength,
                            slabSize.deductedWidth
                        )}
                </div>
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    slab.stockingAreaId
                )}
            </div>
        );
    }

    public getSlabInfoSpec(
        slab: ISlabDTO | ISlabWithBundleDTO | ISlabForListDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ): string {
        if (!slab) {
            return "";
        }

        let title = "";
        let cateName = "";

        if (
            typeof slab.categoryId !== "undefined" &&
            slab.categoryId !== null
        ) {
            cateName = Util.getItemName(slab.categoryId, categoryList);
            title += cateName;
        }
        if (cateName !== "") {
            title += " | ";
        }

        title += slab.thickness + " | ";
        title += this.getSlabSpec(slab, gradeList);

        return title;
    }

    public getSlabInfoSpecWithShowStockingArea(
        slab: ISlabDTO | ISlabWithBundleDTO | ISlabForListDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ): JSX.Element {
        const showStockingArea = [
            SlabBundleStatus.InStock,
            SlabBundleStatus.ReservedBySalesOrder,
            SlabBundleStatus.ReservedBySlabCheckOutRequest
        ].includes(slab.status);
        return (
            <div>
                <div>{this.getSlabInfoSpec(slab, categoryList, gradeList)}</div>
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    slab.stockingAreaId
                )}
            </div>
        );
    }

    public getSlabInfoSpecWithShowStockingAreaAndUnitPrice(
        slab: ISlabDTO | ISlabWithBundleDTO | ISlabForListDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[],
        showPrice: boolean = false
    ): JSX.Element {
        const showStockingArea = [
            SlabBundleStatus.InStock,
            SlabBundleStatus.ReservedBySalesOrder,
            SlabBundleStatus.ReservedBySlabCheckOutRequest
        ].includes(slab.status);
        return (
            <div>
                <div>{this.getSlabInfoSpec(slab, categoryList, gradeList)}</div>
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    slab.stockingAreaId
                )}
                {showPrice
                    ? this.showUnitPriceInfo(
                          slab.unitPrice,
                          StoneBundleUtil.getStoneBundleStatus(
                              slab.status,
                              slab.manufacturingState
                          ),
                          ReferencePriceSize.Normal
                      )
                    : null}
            </div>
        );
    }

    // 计算大板面积
    // 传入slab
    // 返回 - string，面积，单位为平方米，保留小数点后三位，第四位四舍五入
    public calculateAreaForSlab(slab: ISlabForListDTO): number {
        let area = 0;
        let deductedArea = 0;

        if (typeof slab === "undefined" || slab === null) {
            return area;
        }

        const slabSize: ISlabSize = this.getSlabSize(slab);
        const length: number = slabSize.length;
        const width: number = slabSize.width;
        const deductedLength: number = slabSize.deductedLength;
        const deductedWidth: number = slabSize.deductedWidth;

        if (length > 0 && width > 0) {
            area = Util.round((length * width) / 1000000, 3);
        }

        if (
            typeof deductedLength === "undefined" ||
            typeof deductedWidth === "undefined" ||
            deductedLength === null ||
            deductedWidth === null
        ) {
            return area;
        }

        if (deductedLength > 0 && deductedWidth > 0) {
            deductedArea = Util.round(
                (deductedLength * deductedWidth) / 1000000,
                3
            );
        }

        return Util.round(area - deductedArea, 3);
    }

    // 获取大板的备注信息
    public getSlabNotes(slab: ISlabDTO | ISlabForListDTO): string {
        let notes: string = "";
        if (Util.isNotNullAndNotEmpty(slab.sawingNote)) {
            notes = slab.sawingNote;
        }
        if (Util.isNotNullAndNotEmpty(slab.fillingNote)) {
            notes += " " + slab.fillingNote;
        }
        if (Util.isNotNullAndNotEmpty(slab.polishingNote)) {
            notes += " " + slab.polishingNote;
        }

        return notes;
    }

    // 获取大板的尺寸信息
    public showSlabSizeInfo(slab: ISlabDTO | ISlabForListDTO): string {
        const slabSize: ISlabSize = this.getSlabSize(slab);
        const length: number = slabSize.length;
        const width: number = slabSize.width;
        const deductedLength: number = slabSize.deductedLength;
        const deductedWidth: number = slabSize.deductedWidth;
        let sizeInfo = Util.verifySizeIsNotNullAndNorthOfZero(length, width)
            ? length + "x" + width
            : "";

        if (
            Util.verifySizeIsNotNullAndNorthOfZero(
                deductedLength,
                deductedWidth
            )
        ) {
            sizeInfo += " | " + deductedLength + "x" + deductedWidth;
        }

        return sizeInfo;
    }

    public showUnitPriceInfo(
        referencePrice: number | null,
        status: JSX.Element | string | null,
        size: ReferencePriceSize = ReferencePriceSize.Normal,
        showIcon: boolean = false
    ): JSX.Element {
        return (
            <div className="referencePriceArea">
                <HBox>
                    <Box className="referencePriceLabel">单价</Box>
                    <Box>
                        <ReferencePrice
                            referencePrice={referencePrice}
                            size={size}
                            showIcon={showIcon}
                            noReferencePriceText={"无"}
                        />
                    </Box>
                    <Box className="referencePriceOperatorLabel">状态</Box>
                    <Box>
                        <span className="referenceOperatorStyl">{status}</span>
                    </Box>
                </HBox>
            </div>
        );
    }
}

export default new SlabUtil();
