import * as React from "react";
import {
    IBlockForListDTO,
    IBundleForListDTO,
    ISlabForListDTO,
    ITilePackingCaseForListDTO,
    ITransferOrderForListDTO,
    StoneArtifactType
} from "../app/WebAPIClients";
import BlockUtil from "./BlockUtil";
import Consts from "./Consts";
import DateUtil from "./DateUtil";
import ListItem from "./ListItem";
import SlabUtil from "./SlabUtil";
import StockingAreaUtil from "./StockingAreaUtil";
import StoneBundleUtil from "./StoneBundleUtil";
import TileUtil from "./TileUtil";
import { ITransferDetailForUI } from "./type";
import Util from "./Util";

class TransferOrderUtil {
    public getTransferDetailIdName(type: StoneArtifactType) {
        if (!type) {
            return null;
        }

        let idString = "";
        let nameString = "";

        switch (type) {
            case StoneArtifactType.Block:
                idString = "blockId";
                nameString = "block";
                break;
            case StoneArtifactType.StoneBundle:
                idString = "bundleId";
                nameString = "bundle";
                break;
            case StoneArtifactType.Slab:
                idString = "slabId";
                nameString = "slab";
                break;
            case StoneArtifactType.Tile:
                idString = "tilePackingCaseId";
                nameString = "tilePackingCase";
                break;
        }

        return { idString, nameString };
    }

    public getGoodesTypeName(typeCode: number): string {
        let typeName = "";
        switch (typeCode) {
            case StoneArtifactType.Block:
                typeName = "荒料";
                break;
            case StoneArtifactType.StoneBundle:
                typeName = "扎";
                break;
            case StoneArtifactType.Slab:
                typeName = "散板";
                break;
            case StoneArtifactType.Tile:
                typeName = "工程板";
                break;
        }

        return typeName;
    }

    public getTransferOrderDetailItemTitle(
        transferDetail: ITransferDetailForUI,
        categories: IIdNameItem[],
        grades: IIdNameItem[],
        showBundleGrades: boolean = true
    ) {
        let listItem = Util.getEmptyListItemData();
        if (typeof transferDetail === "undefined" || transferDetail === null) {
            return listItem;
        }
        switch (transferDetail.type) {
            case StoneArtifactType.Block:
                listItem = this.getTODetailItemInfo(
                    transferDetail.block,
                    StoneArtifactType.Block,
                    categories,
                    grades
                );
                break;
            case StoneArtifactType.StoneBundle:
                listItem = this.getTODetailItemInfo(
                    transferDetail.bundle as IBundleForListDTO,
                    StoneArtifactType.StoneBundle,
                    categories,
                    grades,
                    showBundleGrades
                );
                break;
            case StoneArtifactType.Tile:
                listItem = this.getTODetailItemInfo(
                    transferDetail.tilePackingCase,
                    StoneArtifactType.Tile,
                    categories,
                    grades
                );
                break;
            case StoneArtifactType.Slab:
                listItem = this.getTODetailItemInfo(
                    transferDetail.slab,
                    StoneArtifactType.Slab,
                    categories,
                    grades
                );
                break;
        }

        return listItem;
    }

    public getTODetailItemInfo(
        item:
            | IBlockForListDTO
            | IBundleForListDTO
            | ITilePackingCaseForListDTO
            | ISlabForListDTO,
        type: StoneArtifactType,
        categories: IIdNameItem[],
        grades: IIdNameItem[],
        showBundleGrades: boolean = true
    ): IListItem & {
        avatarTitle: string;
    } {
        let title = null;
        let text = null;
        let avatarTitle: string = null;
        switch (type) {
            case StoneArtifactType.Block:
                title = (
                    <span>
                        {BlockUtil.getBlockTitle(
                            item as IBlockForListDTO,
                            categories
                        ) + " | "}
                        {BlockUtil.getBlockStatus(item.status)}
                    </span>
                );
                text = BlockUtil.getBlockSpec(
                    item as IBlockForListDTO,
                    categories,
                    grades
                );
                break;
            case StoneArtifactType.StoneBundle:
                item = item as IBundleForListDTO;
                title = (
                    <div>
                        {StoneBundleUtil.getBundleTitle(item) + " | "}
                        {StoneBundleUtil.getStoneBundleStatus(
                            item.status,
                            item.manufacturingState
                        )}
                    </div>
                );
                text = StoneBundleUtil.getBundleSpec(
                    item,
                    categories,
                    grades,
                    showBundleGrades
                );
                avatarTitle = Util.getBundleAvatarText(item);
                break;
            case StoneArtifactType.Tile:
                item = item as ITilePackingCaseForListDTO;
                avatarTitle = item.caseNumber;
                title = TileUtil.getTileCaseTitle(item);
                text = TileUtil.getTileCaseDescription(item, true, false);
                break;
            case StoneArtifactType.Slab:
                item = item as ISlabForListDTO;
                const slabInfo = SlabUtil.getSlabInfoForList(
                    item,
                    categories,
                    grades
                );
                title = (
                    <div>
                        {slabInfo.title + " | "}
                        {StoneBundleUtil.getStoneBundleStatus(
                            item.status,
                            item.manufacturingState
                        )}
                    </div>
                );
                text = slabInfo.text;
                avatarTitle = item.sequenceNumber.toString();
                break;
            default:
                break;
        }

        return { title, text, avatarTitle };
    }

    public getTransferDetailItem(
        items: ITransferDetailForUI[],
        item: any
    ): ITransferDetailForUI {
        if (items.includes(item)) {
            return item;
        }

        const filterItems = items.filter(i => {
            switch (i.type) {
                case StoneArtifactType.Block:
                    return item.id === i.blockId;
                    break;
                case StoneArtifactType.StoneBundle:
                    return item.id === i.bundleId;
                    break;
                case StoneArtifactType.Tile:
                    return item.id === i.tilePackingCaseId;
                    break;
                case StoneArtifactType.Slab:
                    return item.id === i.slabId;
                    break;
            }
            return false;
        });

        if (filterItems.length >= 1) {
            return filterItems[0];
        } else {
            return null;
        }
    }

    public getApprovalStatusState(status: number): JSX.Element {
        const mrStateName = this.getStatusText(status);
        let className = "";
        switch (status) {
            case 10:
                className = "notStartedWO";
                break;
            case 20:
                className = "sawingWO";
                break;
            case 30:
                className = "trimmingWO";
                break;
            case 40:
                className = "abnormalSO";
                break;
        }
        return <span className={className}>{mrStateName}</span>;
    }

    public getStatusText(status: number) {
        return Consts.ApprovalStatus.getState(status).text;
    }

    public getUnit(type: StoneArtifactType) {
        let unit = "";
        switch (type) {
            case StoneArtifactType.Block:
                unit = "颗";
                break;
            case StoneArtifactType.StoneBundle:
                unit = "扎";
                break;
            case StoneArtifactType.Slab:
                unit = "片";
                break;
            case StoneArtifactType.Tile:
                unit = "箱";
                break;
        }
        return unit;
    }

    public getTOListItem(
        item: ITransferOrderForListDTO,
        factories: IIdNameItem[],
        warehouses: IIdNameItem[],
        onClickFunc: (item: ITransferOrderForListDTO) => void
    ) {
        const fromWarehousesName = StockingAreaUtil.getWarehouseName(
            item.fromWarehouseId,
            factories,
            warehouses
        );
        const toWarehouseName = StockingAreaUtil.getWarehouseName(
            item.toWarehouseId,
            factories,
            warehouses
        );
        const description = (
            <div>
                <div>调出：{fromWarehousesName}</div>
                <div>调入：{toWarehouseName}</div>
                <div>调拨日期：{DateUtil.formatDate(item.transferDate)}</div>
            </div>
        );

        const title = (
            <span>
                {item.orderNumber + " | "}
                {this.getApprovalStatusState(item.status)}
            </span>
        );

        return (
            <ListItem
                key={item.id}
                item={item}
                title={title}
                avatarTitle={this.getGoodesTypeName(item.type)}
                description={description}
                onClick={onClickFunc}
            />
        );
    }
}

export default new TransferOrderUtil();
