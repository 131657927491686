import * as React from "react";
import { Avatar } from "saltui";

export interface IESAvatarProps extends React.Props<ESAvatar> {
    [propertyName: string]: any;
}

class ESAvatar extends React.Component<IESAvatarProps, {}> {
    public render() {
        const t = this;

        const { ...other } = t.props;

        return (
            <Avatar
                // tslint:disable-next-line:jsx-no-lambda
                filter={prevName => prevName.replace(/[()（）]/g, "")}
                {...other}
            />
        );
    }
}

export default ESAvatar;
