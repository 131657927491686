import * as React from "react";
import "./ESIcon.styl";

import svg4everybody from "./svg4everybody";
const webkitUA = /\bAppleWebKit\/(\d+)\b/;

declare interface IESIconProps extends React.Props<SVGSVGElement> {
    className?: string;
    name: string;
    width?: number;
    height?: number;
    fill?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

class ESIcon extends React.Component<IESIconProps, {}> {
    public static defaultProps: IESIconProps;

    constructor(props: IESIconProps) {
        super(props);
    }

    public render(): JSX.Element {
        const t = this;

        const { className, width, height, fill, onClick, ...other } = t.props;

        return (
            <div
                onClick={onClick}
                className={"ESIcon-icon " + t.props.className}
            >
                <svg
                    className="ESIcon-icon-svg"
                    fill={fill}
                    width={width}
                    height={height}
                    {...other}
                >
                    <use xlinkHref={"#" + t.props.name} />
                </svg>
                <div className="ESIcon-icon-mask" />
            </div>
        );
    }

    public componentDidMount(): void {
        const webkitUAVersion = (navigator.userAgent.match(webkitUA) || [])[1];
        svg4everybody({
            polyfill:
                webkitUAVersion === "600" || parseInt(webkitUAVersion) < 537
        });
    }
}

ESIcon.defaultProps = {
    className: "",
    name: "",
    width: 32,
    height: 32,
    fill: "#000",
    onClick: () => {}
};

export default ESIcon;
