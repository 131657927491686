import * as React from "react";
import { Group, Timeline } from "saltui";
import {
    IStockInAndOutRecordDTO,
    StockInAndOutRecordType,
    StoneArtifactType
} from "../app/WebAPIClients";
import Collapsible from "./Collapsible";
import DateUtil from "./DateUtil";
import StockingAreaUtil from "./StockingAreaUtil";

class StockInAndOutRecordUtil {
    public ShowStockInAndOutRecords(
        sioRecords: IStockInAndOutRecordDTO[],
        psaList,
        factories,
        warehouses
    ): JSX.Element {
        return (
            <Collapsible label="出入库记录" expanded={false}>
                <Group>
                    <Group.List lineIndent={15}>
                        <Timeline className="timeLineStyl">
                            {sioRecords.map((item, index) => {
                                const psa = StockingAreaUtil.showStockingAreaText(
                                    item.stockingAreaId,
                                    psaList,
                                    factories,
                                    warehouses
                                );

                                const title =
                                    this.getStockInAndOutRecordTypeText(item) +
                                    " | " +
                                    DateUtil.formatDateTime(
                                        item.transactionDate
                                    ) +
                                    " | " +
                                    item.source;
                                const unit = this.getStockInAndOutRecordUnit(
                                    item
                                );
                                if (item !== null) {
                                    return (
                                        <Timeline.Item
                                            key={item.id}
                                            active={index === 0}
                                            description={
                                                <div>
                                                    <div>
                                                        {"操作人：" +
                                                            item.operator +
                                                            " | 区域：" +
                                                            psa}
                                                    </div>
                                                    {item.title +
                                                        " | " +
                                                        item.spec +
                                                        " | " +
                                                        item.quantity +
                                                        unit}
                                                </div>
                                            }
                                        >
                                            {title}
                                        </Timeline.Item>
                                    );
                                }
                            })}
                        </Timeline>
                    </Group.List>
                </Group>
            </Collapsible>
        );
    }

    public getStockInAndOutRecordTypeText(
        item: IStockInAndOutRecordDTO
    ): string {
        let text = "";
        if (!item) {
            return text;
        }
        switch (item.recordType) {
            case StockInAndOutRecordType.StockingIn:
                text = "入库";
                break;
            case StockInAndOutRecordType.StockingOut:
                text = "出库";
                break;
        }
        return text;
    }

    public getStockInAndOutRecordUnit(item: IStockInAndOutRecordDTO): string {
        let unit = "";
        if (!item) {
            return unit;
        }
        switch (item.type) {
            case StoneArtifactType.Block:
                unit = "立方";
                break;
            case StoneArtifactType.Slab:
            case StoneArtifactType.StoneBundle:
            case StoneArtifactType.Tile:
                unit = "平方";
                break;
        }
        return unit;
    }
}

export default new StockInAndOutRecordUtil();
