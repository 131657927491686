import React from "react";
import {
  IStoneCategoryDTO,
  IStoneGradeDTO,
  IUntrackedStoneArtifactItemDTO,
  IUntrackedStoneArtifactItemViewModel,
} from "../../app/WebAPIClients";
import ListItem from "../ListItem";
import UntrackedContentUtil from "../UntrackedContentUtil";

export interface IUntrackedContentListItemProps
  extends React.Props<UntrackedContentListItem> {
  untrackedContent:
    | IUntrackedStoneArtifactItemViewModel
    | IUntrackedStoneArtifactItemDTO;
  onDelete?: (
    untrackedContent:
      | IUntrackedStoneArtifactItemViewModel
      | IUntrackedStoneArtifactItemDTO,
  ) => void;
  onClick?: (
    untrackedContent:
      | IUntrackedStoneArtifactItemViewModel
      | IUntrackedStoneArtifactItemDTO,
  ) => void;
  allowDeletion?: boolean;
  categoryList: IStoneCategoryDTO[] | IIdNameItem[];
  gradeList: IStoneGradeDTO[] | IIdNameItem[];
}

export interface IUntrackedContentListItemState {
  expanded: boolean;
}

class UntrackedContentListItem extends React.Component<
  IUntrackedContentListItemProps,
  IUntrackedContentListItemState
> {
  public static defaultProps: IUntrackedContentListItemProps;

  public render() {
    const t = this;

    const { untrackedContent, allowDeletion, categoryList, gradeList } =
      t.props;

    const title =
      UntrackedContentUtil.getUntrackedContentTitle(untrackedContent);
    const description = UntrackedContentUtil.getUntrackedContentText(
      untrackedContent,
      categoryList,
      gradeList,
    );

    const avatarTitle = untrackedContent.name;

    return (
      <ListItem
        avatarTitle={avatarTitle}
        title={title}
        description={description}
        allowDeletion={allowDeletion}
        onDelete={this.onDelete}
        onClick={this.onClick}
      />
    );
  }

  private onDelete = () => {
    this.props.onDelete(this.props.untrackedContent);
  };

  private onClick = () => {
    this.props.onClick(this.props.untrackedContent);
  };
}

UntrackedContentListItem.defaultProps = {
  untrackedContent: null,
  onDelete: (): void => {},
  onClick: (): void => {},
  allowDeletion: false,
  categoryList: [],
  gradeList: [],
};

export default UntrackedContentListItem;
