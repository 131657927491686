import React from "react";
import { Boxs, Field } from "saltui";
import PercentageInput from "./PercentageInput";

const { Box, HBox } = Boxs;

interface IBlockTilePlanningOutputRates {
    planningTileMinOutputRate?: number;
    planningTileMaxOutputRate?: number;
}

interface IBlockTilePlanningOutputRatesFieldProps {
    data: IBlockTilePlanningOutputRates;
    onChange?: (outputRateData: IBlockTilePlanningOutputRates) => void;
}

function BlockTilePlanningOutputRatesField(
    props: IBlockTilePlanningOutputRatesFieldProps
) {
    const { data, onChange } = props;

    let minOutputRate: number | undefined | null = null;
    let maxOutputRate: number | undefined | null = null;

    if (data !== null) {
        minOutputRate = data.planningTileMinOutputRate;
        maxOutputRate = data.planningTileMaxOutputRate;
    }

    const onDataChange: (
        minRate: number | undefined | null,
        maxRate: number | undefined | null
    ) => void = (minRate, maxRate) => {
        if (onChange) {
            onChange({
                planningTileMinOutputRate: minRate,
                planningTileMaxOutputRate: maxRate
            });
        }
    };

    const onMinRateChange: (item: any, rate: number | null) => void = (
        _,
        rate
    ) => {
        onDataChange(rate, maxOutputRate);
    };

    const onMaxRateChange: (item: any, rate: number | null) => void = (
        _,
        rate
    ) => {
        onDataChange(minOutputRate, rate);
    };

    return (
        <Field
            label="出材范围"
            required={true}
            tip="可以只提供其中一个值，或者同时提供两个值"
        >
            <HBox>
                <Box>
                    <PercentageInput
                        item={null}
                        rate={minOutputRate}
                        onChange={onMinRateChange}
                        className="outputRatesPercentageInput"
                        placeholder="最小值"
                    />
                </Box>
                <Box className="outputRatesRangeSymbol">~</Box>
                <Box>
                    <PercentageInput
                        item={null}
                        rate={maxOutputRate}
                        onChange={onMaxRateChange}
                        className="outputRatesPercentageInput"
                        placeholder="最大值"
                    />
                </Box>
            </HBox>
        </Field>
    );
}

export default BlockTilePlanningOutputRatesField;
