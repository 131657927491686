import React from "react";
import { Boxs } from "saltui";
import ESButton from "../ESButton";
const HBox = Boxs.HBox;
const Box = Boxs.Box;

export interface IButtonGroupProps {
    className?: string;
    submitButtonLable?: string;
    submitButtonType?: string;
    goBackButtonLabel?: string;
    extraButtonLable?: string;
    extraButtonType?: string;
    extraButtonClassName?: string;
    showSubmitButton?: boolean;
    showGoBackButton?: boolean;
    showExtraButton?: boolean;
    onSubmit: () => void;
    onGoBack?: () => void;
    onExtraButtonClick?: () => void;
}

class ButtonGroup extends React.Component<IButtonGroupProps, {}> {
    public static defaultProps: IButtonGroupProps;
    constructor(props: IButtonGroupProps) {
        super(props);
    }

    public render(): JSX.Element {
        const t = this;
        const {
            className,
            onGoBack,
            onSubmit,
            onExtraButtonClick,
            showSubmitButton,
            showGoBackButton,
            showExtraButton
        } = t.props;

        let {
            submitButtonLable,
            goBackButtonLabel,
            extraButtonLable,
            extraButtonType,
            extraButtonClassName,
            submitButtonType
        } = t.props;

        const divClassName = className ? className : "actionBtnContainer";

        extraButtonLable = extraButtonLable ? extraButtonLable : "批准";
        extraButtonType = extraButtonType ? extraButtonType : "primary";
        extraButtonClassName = extraButtonClassName ? extraButtonClassName : "";
        submitButtonLable = submitButtonLable ? submitButtonLable : "提交";
        submitButtonType = submitButtonType ? submitButtonType : "primary";
        goBackButtonLabel = goBackButtonLabel ? goBackButtonLabel : "返回";

        return (
            <div className={divClassName}>
                <HBox>
                    {showExtraButton ? (
                        <Box flex={1} className="t-PR8">
                            <ESButton
                                type={extraButtonType}
                                onClick={onExtraButtonClick}
                                className={extraButtonClassName}
                            >
                                {extraButtonLable}
                            </ESButton>
                        </Box>
                    ) : null}
                    {showSubmitButton ? (
                        <Box flex={1} className="t-PR8">
                            <ESButton
                                onClick={onSubmit}
                                type={submitButtonType}
                            >
                                {submitButtonLable}
                            </ESButton>
                        </Box>
                    ) : null}
                    {showGoBackButton ? (
                        <Box flex={1} className="t-PR8">
                            <ESButton onClick={onGoBack}>
                                {goBackButtonLabel}
                            </ESButton>
                        </Box>
                    ) : null}
                </HBox>
            </div>
        );
    }
}

ButtonGroup.defaultProps = {
    className: "",
    showSubmitButton: true,
    showGoBackButton: true,
    showExtraButton: false,
    onSubmit: () => {},
    onGoBack: () => {},
    onExtraButtonClick: () => {},
    submitButtonLable: null,
    extraButtonLable: null,
    extraButtonType: null,
    extraButtonClassName: null,
    submitButtonType: null
};

export default ButtonGroup;
