import React, { SFC } from "react";
import { Group, Tab } from "saltui";
import { IPagingInfo, StoneArtifactType } from "../../app/WebAPIClients";
import ShippingOrderDetailListItem from "../../components/ShippingOrderDetailListItem";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ESScroller from "../ESScroller";
import ESTextField from "../ESTextField";
import FilterButtonGroup from "../FilterButtonGroup";
import ListFooter from "../ListFooter";
import StoneArtifactUtil from "../StoneArtifactUtil";
import { ISalesOrderDetailForUI } from "../type";

interface IFilterAreaButtonsProps {
    onSearch: () => void;
    onClearFilter: () => void;
}

const FilterAreaButtons: SFC<IFilterAreaButtonsProps> = ({
    onSearch,
    onClearFilter
}) => <FilterButtonGroup onSearch={onSearch} onClearData={onClearFilter} />;

declare interface IStoneArtifactItemSelectorProps
    extends React.Props<StoneArtifactItemSelector> {
    items: ISalesOrderDetailForUI[];
    selectedItems?: ISalesOrderDetailForUI[];
    currentArtifactType?: StoneArtifactType;
    currentPagingInfo?: IPagingInfo;
    showStockingArea?: boolean;
    showPrice?: boolean;
    showIconStatus?: ShowIconStatus;
    blockFilterData?: IStoneArtifactSelectorBlockFilterData;
    bundleFilterData?: IStoneArtifactSelectorBundleFilterData;
    tpcFilterData?: IStoneArtifactSelectorTileFilterData;
    onSelectionChange?: (items: ISalesOrderDetailForUI[]) => void;
    onArtifactTypeChange?: (artifactType: StoneArtifactType) => void;
    onSearch?: (
        artifactType: StoneArtifactType,
        filterData: StoneArtifactSelectorFilterData
    ) => void;
    onClearFilter?: (
        artifactType: StoneArtifactType,
        filterData: StoneArtifactSelectorFilterData
    ) => void;

    onPaymentIconClick?: (item: ISalesOrderDetailForUI) => void;
    onShipmentIconClick?: (item: ISalesOrderDetailForUI) => void;
    parameter?: any;
    error?: boolean;
    loadMoreDataForBlock?: any;
    loadMoreDataForBundle?: any;
    loadMoreDataForTPC?: any;
    customShowReferencePrice?: boolean;
}

declare interface IStoneArtifactItemSelectorState
    extends IStoneArtifactSelectorBlockFilterData,
        IStoneArtifactSelectorBundleFilterData,
        IStoneArtifactSelectorTileFilterData {
    gradeList?: IIdNameItem[];
    categoryList?: IIdNameItem[];
    blockFilterExpanded?: boolean;
    bundleFilterExpanded?: boolean;
    tpcFilterExpanded?: boolean;
    noMoreData?: boolean;
}

class StoneArtifactItemSelector extends React.Component<
    IStoneArtifactItemSelectorProps,
    IStoneArtifactItemSelectorState
> {
    public static defaultProps: IStoneArtifactItemSelectorProps;
    constructor(props: IStoneArtifactItemSelectorProps) {
        super(props);
        this.state = {
            blockFilterExpanded: false,
            bundleFilterExpanded: false,
            tpcFilterExpanded: false,
            blockNumber: props.blockFilterData.blockNumber,
            blockOrderNumber: props.blockFilterData.blockOrderNumber,
            bundlePrefix: props.bundleFilterData.bundlePrefix,
            bundleOrderNumber: props.bundleFilterData.bundleOrderNumber,
            tpcCaseNumber: props.tpcFilterData.tpcCaseNumber,
            tpcOrderNumber: props.tpcFilterData.tpcOrderNumber,
            tpcProjectName: props.tpcFilterData.tpcProjectName
        };
    }

    public async componentDidMount() {
        const gradeList = await Consts.StoneGrades.getGrades();
        const categoryList = await Consts.StoneCategories.getCategories();
        this.setState({
            gradeList,
            categoryList
        });
    }

    public render() {
        const t = this;

        const { currentArtifactType: type } = t.props;
        const tabArr = [];
        tabArr.push(t.getBlockTabJSX());
        tabArr.push(t.getBundleTabJSX());
        tabArr.push(t.getTilePackCaseTabJSX());

        return (
            <div>
                <Tab
                    onChange={t.handleTabChange}
                    activeKey={type.toString()}
                    animated={false}
                >
                    {tabArr}
                </Tab>
            </div>
        );
    }

    private getBlockTabJSX(): JSX.Element {
        const t = this;
        const s = t.state;
        const {
            items,
            currentPagingInfo: pi,
            parameter,
            error,
            loadMoreDataForBlock
        } = t.props;
        let blockItems: ISalesOrderDetailForUI[] = [];

        if (items && items.length) {
            blockItems = StoneArtifactUtil.getStoneArtifactItems(
                items,
                StoneArtifactType.Block
            );
        }

        return (
            <Tab.Item title="选择荒料" key={"10"}>
                <Group>
                    <Collapsible
                        label={"筛选条件"}
                        onExpansionChanged={t.handleBlockExpandedClick}
                        expanded={s.blockFilterExpanded}
                    >
                        <Group.List itemIndent={[15, 15]}>
                            <ESTextField
                                label="荒料编号"
                                value={s.blockNumber}
                                onChange={t.handleBlockNumberChange}
                                placeholder={"请输入荒料编号"}
                            />
                            <ESTextField
                                label="订单编号"
                                value={s.blockOrderNumber}
                                onChange={t.handleBlockOrderNumberChange}
                                placeholder={"请输入订单编号"}
                            />
                            <FilterAreaButtons
                                onSearch={t.handleSearch}
                                onClearFilter={t.handleClearData}
                            />
                        </Group.List>
                    </Collapsible>
                </Group>
                <ESScroller
                    className={
                        "scrollerBottom " +
                        (s.blockFilterExpanded
                            ? "filterForTwoWithTabExpanded"
                            : "filterCollapsedWithTab")
                    }
                    noMoreData={pi && pi.noMoreData}
                    dataType="荒料"
                    loadMoreDataActionFun={loadMoreDataForBlock}
                    error={error}
                    parameter={parameter}
                    showDataLoadingMessage={
                        blockItems && blockItems.length && blockItems.length > 0
                    }
                >
                    {t.getPendingItemJSX(blockItems)}
                </ESScroller>
                {!s.blockFilterExpanded &&
                blockItems &&
                blockItems.length &&
                blockItems.length > 0 &&
                pi ? (
                    <ListFooter
                        totalRecordCount={pi.totalRecordCount}
                        className="footerAboveActionButton"
                    />
                ) : null}
            </Tab.Item>
        );
    }

    private getBundleTabJSX(): JSX.Element {
        const t = this;
        const s = t.state;
        const {
            items,
            currentPagingInfo: pi,
            parameter,
            error,
            loadMoreDataForBundle
        } = t.props;
        let bundleItems: ISalesOrderDetailForUI[] = [];

        if (items && items.length) {
            bundleItems = StoneArtifactUtil.getStoneArtifactItems(
                items,
                StoneArtifactType.StoneBundle
            );
        }

        return (
            <Tab.Item title="选择大板" key={"20"}>
                <Collapsible
                    label={"筛选条件"}
                    onExpansionChanged={t.handleBunldeExpandedClick}
                    expanded={s.bundleFilterExpanded}
                >
                    <Group.List itemIndent={[15, 15]}>
                        <ESTextField
                            label="荒料编号"
                            value={s.bundlePrefix}
                            onChange={t.handleBundlePrefixChange}
                            placeholder={"请输入荒料编号"}
                        />
                        <ESTextField
                            label="订单编号"
                            value={s.bundleOrderNumber}
                            onChange={t.handleBundleOrderNumberChange}
                            placeholder={"请输入订单编号"}
                        />
                        <FilterAreaButtons
                            onSearch={t.handleSearch}
                            onClearFilter={t.handleClearData}
                        />
                    </Group.List>
                </Collapsible>
                <ESScroller
                    className={
                        "scrollerBottom " +
                        (s.bundleFilterExpanded
                            ? "filterForTwoWithTabExpanded"
                            : "filterCollapsedWithTab")
                    }
                    noMoreData={pi && pi.noMoreData}
                    dataType="大板"
                    loadMoreDataActionFun={loadMoreDataForBundle}
                    parameter={parameter}
                    error={error}
                    showDataLoadingMessage={
                        bundleItems &&
                        bundleItems.length &&
                        bundleItems.length > 0
                    }
                >
                    {t.getPendingItemJSX(bundleItems)}
                </ESScroller>
                {!s.bundleFilterExpanded &&
                bundleItems &&
                bundleItems.length &&
                bundleItems.length > 0 ? (
                    <ListFooter
                        totalRecordCount={pi.totalRecordCount}
                        className="footerAboveActionButton"
                    />
                ) : null}
            </Tab.Item>
        );
    }

    private getTilePackCaseTabJSX() {
        const t = this;
        const s = t.state;
        const {
            items,
            currentPagingInfo: pi,
            parameter,
            error,
            loadMoreDataForTPC
        } = t.props;
        let tpcItems: ISalesOrderDetailForUI[] = [];

        if (items && items.length) {
            tpcItems = StoneArtifactUtil.getStoneArtifactItems(
                items,
                StoneArtifactType.Tile
            );
        }

        return (
            <Tab.Item title="选择工程板" key={"30"}>
                <Collapsible
                    label={"筛选条件"}
                    onExpansionChanged={t.handleTPCExpandedClick}
                    expanded={s.tpcFilterExpanded}
                >
                    <Group.List itemIndent={[15, 15]}>
                        <ESTextField
                            label="箱号"
                            value={s.tpcCaseNumber}
                            placeholder={"请输入箱号"}
                            onChange={t.handleCaseNumberChange}
                        />
                        <ESTextField
                            label="订单编号"
                            value={s.tpcOrderNumber}
                            onChange={t.handleTPCOrderNumberChange}
                            placeholder={"请输入订单编号"}
                        />
                        <ESTextField
                            label="工程名称"
                            value={s.tpcProjectName}
                            onChange={t.handleProjectNameChange}
                            placeholder={"请输入工程名称"}
                        />
                        <FilterAreaButtons
                            onSearch={t.handleSearch}
                            onClearFilter={t.handleClearData}
                        />
                    </Group.List>
                </Collapsible>
                <ESScroller
                    className={
                        "scrollerBottom " +
                        (s.tpcFilterExpanded
                            ? "filterForThreeWithTabExpanded"
                            : "filterCollapsedWithTab")
                    }
                    noMoreData={pi && pi.noMoreData}
                    loadMoreDataActionFun={loadMoreDataForTPC}
                    parameter={parameter}
                    dataType="工程板装箱"
                    error={error}
                    showDataLoadingMessage={
                        tpcItems && tpcItems.length && tpcItems.length > 0
                    }
                >
                    {t.getPendingItemJSX(tpcItems)}
                </ESScroller>
                {!s.tpcFilterExpanded &&
                tpcItems &&
                tpcItems.length &&
                tpcItems.length > 0 ? (
                    <ListFooter
                        totalRecordCount={pi.totalRecordCount}
                        className="footerAboveActionButton"
                    />
                ) : null}
            </Tab.Item>
        );
    }

    private getPendingItemJSX(items: ISalesOrderDetailForUI[]): JSX.Element {
        const t = this;
        const s = t.state;

        const {
            selectedItems,
            showStockingArea,
            showPrice,
            showIconStatus,
            onPaymentIconClick,
            onShipmentIconClick,
            customShowReferencePrice
        } = t.props;
        return (
            <div>
                <Group>
                    <Group.List lineIndent={15} itemIndent={[15, 15]}>
                        {items && items.length && items.length > 0 ? (
                            items.map((item, index) => {
                                const selected =
                                    StoneArtifactUtil.getStoneArtifactItem(
                                        selectedItems,
                                        item
                                    ) !== null;
                                return (
                                    <ShippingOrderDetailListItem
                                        key={index}
                                        item={item}
                                        showStockingArea={
                                            showStockingArea &&
                                            StoneArtifactUtil.getShowStockingArea(
                                                item
                                            )
                                        }
                                        showPrice={showPrice}
                                        categoryList={s.categoryList}
                                        gradeList={s.gradeList}
                                        allowSelection={true}
                                        selected={selected}
                                        onClick={t.handleItemSelect}
                                        onSelect={t.handleItemSelect}
                                        showIconStatus={showIconStatus}
                                        onPaymentIconClick={onPaymentIconClick}
                                        onShipmentIconClick={
                                            onShipmentIconClick
                                        }
                                        customShowReferencePrice={
                                            customShowReferencePrice
                                        }
                                    />
                                );
                            })
                        ) : (
                            <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                                没有数据
                            </div>
                        )}
                    </Group.List>
                </Group>
            </div>
        );
    }

    private getFilterData(
        isClearAction: boolean
    ): StoneArtifactSelectorFilterData {
        const s = this.state;
        const { currentArtifactType } = this.props;

        let filterData: StoneArtifactSelectorFilterData;
        switch (currentArtifactType) {
            case StoneArtifactType.Block:
                const blockNumber = isClearAction ? "" : s.blockNumber;
                const blockOrderNumber = isClearAction
                    ? ""
                    : s.blockOrderNumber;
                filterData = { blockNumber, blockOrderNumber };
                break;
            case StoneArtifactType.StoneBundle:
                const bundlePrefix = isClearAction ? "" : s.bundlePrefix;
                const bundleOrderNumber = isClearAction
                    ? ""
                    : s.bundleOrderNumber;
                filterData = {
                    bundlePrefix,
                    bundleOrderNumber
                };
                break;
            case StoneArtifactType.Tile:
                const tpcCaseNumber = isClearAction ? "" : s.tpcCaseNumber;
                const tpcOrderNumber = isClearAction ? "" : s.tpcOrderNumber;
                const tpcProjectName = isClearAction ? "" : s.tpcProjectName;
                filterData = {
                    tpcCaseNumber,
                    tpcOrderNumber,
                    tpcProjectName
                };
                break;
        }
        return filterData;
    }

    private handleBlockExpandedClick = expanded => {
        this.setState({ blockFilterExpanded: expanded });
    };

    private handleBlockOrderNumberChange = value => {
        this.setState({ blockOrderNumber: value });
    };

    private handleBlockNumberChange = value => {
        this.setState({ blockNumber: value });
    };

    private handleBunldeExpandedClick = expanded => {
        this.setState({ bundleFilterExpanded: expanded });
    };

    private handleBundleOrderNumberChange = value => {
        this.setState({ bundleOrderNumber: value });
    };

    private handleBundlePrefixChange = value => {
        this.setState({ bundlePrefix: value });
    };

    private handleTPCExpandedClick = expanded => {
        this.setState({ tpcFilterExpanded: expanded });
    };

    private handleTPCOrderNumberChange = value => {
        this.setState({ tpcOrderNumber: value });
    };

    private handleCaseNumberChange = value => {
        this.setState({ tpcCaseNumber: value });
    };

    private handleProjectNameChange = value => {
        this.setState({ tpcProjectName: value });
    };

    private handleSearch = () => {
        const { onSearch, currentArtifactType } = this.props;

        let newState = null;
        const filterData = this.getFilterData(false);

        // 当点击搜索按钮后,将筛选条件折叠
        switch (currentArtifactType) {
            case StoneArtifactType.Block:
                newState = { blockFilterExpanded: false };
                break;
            case StoneArtifactType.StoneBundle:
                newState = { bundleFilterExpanded: false };
                break;
            case StoneArtifactType.Tile:
                newState = { tpcFilterExpanded: false };
                break;
        }

        this.setState(newState);

        if (onSearch) {
            onSearch(currentArtifactType, filterData);
        }
    };

    private handleClearData = () => {
        const { onClearFilter, currentArtifactType } = this.props;

        const filterData = this.getFilterData(true);
        this.setState(filterData);
        if (onClearFilter) {
            onClearFilter(currentArtifactType, filterData);
        }
    };

    private handleItemSelect = item => {
        if (!item) {
            return;
        }

        const t = this;

        const { selectedItems, onSelectionChange } = t.props;
        let newItems = [...selectedItems];
        const selectedItem = StoneArtifactUtil.getStoneArtifactItem(
            newItems,
            item
        );
        if (selectedItem !== null) {
            newItems = newItems.filter(i => i !== selectedItem);
        } else {
            newItems.push(item);
        }

        if (onSelectionChange) {
            onSelectionChange(newItems);
        }
    };

    private handleTabChange = (tabObj): void => {
        if (!(tabObj && tabObj.activeKey)) {
            return;
        }

        const t = this;
        const { currentArtifactType: type, onArtifactTypeChange } = t.props;

        const activeKey = tabObj.activeKey;
        if (type.toString() === activeKey) {
            return;
        }

        if (onArtifactTypeChange) {
            onArtifactTypeChange(parseInt(activeKey));
        }
    };
}

StoneArtifactItemSelector.defaultProps = {
    items: null,
    selectedItems: null,
    showIconStatus: true,
    currentArtifactType: StoneArtifactType.Block,
    currentPagingInfo: null,
    onSelectionChange: () => {},
    onArtifactTypeChange: () => {},
    onSearch: () => {},
    onClearFilter: () => {},
    onPaymentIconClick: null,
    onShipmentIconClick: null,
    parameter: null,
    error: false,
    loadMoreDataForBlock: null,
    loadMoreDataForBundle: null,
    loadMoreDataForTPC: null,
    showStockingArea: false,
    customShowReferencePrice: false
};

export default StoneArtifactItemSelector;
