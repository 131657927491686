import * as React from "react";
import { Group } from "saltui";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ESPickerField from "../ESPickerField";
import ESTextField from "../ESTextField";
import FilterButtonGroup from "../FilterButtonGroup";
import Util from "../Util";
const { Customers, ApprovalStatus } = Consts;

declare interface ISlabRequestListFilterProps
    extends IFilterProps<ISlabRequestListFilter> {}

export interface ISlabRequestListFilter {
    requestNumber?: string;
    selectSOOrderNumber?: string;
    selectProjectName?: string;
    selectCustomerOptions?: IOption[];
    selectRequestStatusOptions?: IOption[];
}

declare interface ISlabRequestListFilterState extends ISlabRequestListFilter {
    statusOptions: IOptionWithPhonetic[];
    customerOptions: IOptionWithPhonetic[];
}

class SlabRequestListFilter extends React.Component<
    ISlabRequestListFilterProps,
    ISlabRequestListFilterState
> {
    public static defaultProps: ISlabRequestListFilterProps;
    constructor(props: ISlabRequestListFilterProps) {
        super(props);
        this.state = {
            statusOptions: Util.getOptionsWithPhoneticByOptionArr(
                ApprovalStatus.approvalData
            ),
            customerOptions: [],
            ...props.filterData
        };
    }

    public componentWillReceiveProps(nextProps: ISlabRequestListFilterProps) {
        if (nextProps.filterData !== this.props.filterData) {
            this.setState({ ...nextProps.filterData });
        }
    }

    public async componentDidMount() {
        const customers = await Customers.getCustomers();
        const customerOptions = Util.getOptionsWithPhoneticForUI(customers);
        this.setState({ customerOptions });
    }

    public render() {
        const t = this;
        const s = t.state;
        const {
            requestNumber,
            selectSOOrderNumber,
            selectProjectName,
            selectCustomerOptions,
            selectRequestStatusOptions
        } = s;
        const { onExpansionChanged, expanded, ...other } = t.props;

        return (
            <Collapsible
                label={"筛选条件"}
                onExpansionChanged={onExpansionChanged}
                expanded={expanded}
            >
                <Group.List itemIndent={[15, 15]}>
                    <ESTextField
                        label="单号"
                        value={requestNumber}
                        onChange={t.handleRequestNumberChange}
                        placeholder="请输入单号"
                    />
                    <ESTextField
                        label="订单编号"
                        value={selectSOOrderNumber}
                        onChange={t.handleSelectSOOrderNumberChange}
                        placeholder="请输入订单编号"
                    />
                    <ESTextField
                        label="工程名称"
                        onChange={t.handleSelectProjectNameChange}
                        value={selectProjectName}
                        placeholder="请输入工程名称"
                    />
                    <ESPickerField
                        label="客户名称"
                        options={s.customerOptions}
                        onSelect={t.handleSelectCustomerChange}
                        value={selectCustomerOptions}
                        placeholder="请选择客户名称"
                        multiple={true}
                    />
                    <ESPickerField
                        label="状态"
                        options={s.statusOptions}
                        onSelect={t.handleSelectRequestStatusChange}
                        value={selectRequestStatusOptions}
                        placeholder="请选择状态"
                        multiple={true}
                    />
                    <FilterButtonGroup
                        onSearch={t.handleSearch}
                        onClearData={t.handleClearData}
                    />
                </Group.List>
            </Collapsible>
        );
    }

    private handleRequestNumberChange = (value: string) => {
        this.setState({ requestNumber: value });
    };

    private handleSelectSOOrderNumberChange = (value: string) => {
        this.setState({ selectSOOrderNumber: value });
    };

    private handleSelectProjectNameChange = (value: string) => {
        this.setState({ selectProjectName: value });
    };

    private handleSelectRequestStatusChange = (value: IOption[]) => {
        this.setState({ selectRequestStatusOptions: value });
    };
    private handleSelectCustomerChange = (value: IOption[]) => {
        this.setState({ selectCustomerOptions: value });
    };

    private handleSearch = () => {
        const t = this;
        const s = t.state;
        const p: ISlabRequestListFilter = {
            selectRequestStatusOptions: s.selectRequestStatusOptions,
            requestNumber: s.requestNumber,
            selectSOOrderNumber: s.selectSOOrderNumber,
            selectCustomerOptions: s.selectCustomerOptions,
            selectProjectName: s.selectProjectName
        };

        const { onSearch } = t.props;
        if (onSearch) {
            onSearch(p);
        }
    };

    private handleClearData = () => {
        const t = this;
        const p: ISlabRequestListFilter = {
            selectRequestStatusOptions: [],
            requestNumber: undefined,
            selectSOOrderNumber: undefined,
            selectCustomerOptions: [],
            selectProjectName: undefined
        };

        t.setState(p);

        const { onClear } = t.props;
        if (onClear) {
            onClear(p);
        }
    };
}

export default SlabRequestListFilter;
