import { observer } from "mobx-react";
import React from "react";
import { Scroller } from "saltui";
import DataLoadingMessage from "../DataLoadingMessage";
import Store from "./store";

export interface IESScrollerProps {
    className?: string;
    error?: boolean;
    noMoreData: boolean;
    loadMoreDataActionFun: any;
    dataType: string;
    children: JSX.Element;
    parameter?: any;
    showDataLoadingMessage?: boolean;
}

export interface IESScrollerState {}

@observer
class ESScroller extends React.Component<IESScrollerProps, IESScrollerState> {
    public static defaultProps: IESScrollerProps;
    private sc: IScroller;

    public async componentDidMount() {
        setTimeout(() => {
            const sc = this.sc as IScroller;
            if (sc && sc.scroller) {
                sc.scroller.refresh();
            }
        }, 100);
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const {
            className,
            error,
            noMoreData,
            showDataLoadingMessage,
            children
        } = t.props;

        return (
            <Scroller
                ref={sc => {
                    t.sc = sc;
                }}
                className={className}
                disablePointer={true}
                mouseWheel={true}
                scrollBar={true}
                click={true}
                onScrollEnd={t.handleScrollEnd}
            >
                {error ? (
                    <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                        加载数据错误
                    </div>
                ) : (
                    children
                )}
                {showDataLoadingMessage ? (
                    <DataLoadingMessage noMoreData={noMoreData} />
                ) : null}
            </Scroller>
        );
    }

    private handleScrollEnd = async () => {
        const s = this.sc.scroller;
        const { noMoreData, loadMoreDataActionFun, parameter } = this.props;
        const { scrollerDisabled } = Store;

        // 没有拉到最后或者没有数据、或当前载入数据请求未执行完，不执行任何操作
        if (s.y > s.maxScrollY || noMoreData || scrollerDisabled) {
            return;
        }

        await loadMoreDataActionFun(parameter);
    };
}

ESScroller.defaultProps = {
    className: "",
    error: false,
    noMoreData: false,
    loadMoreDataActionFun: null,
    dataType: "",
    children: null,
    showDataLoadingMessage: true
};

export default ESScroller;
