import React from "react";
import { Field } from "saltui";
import {
    IBlockDTO,
    IBlockForListDTO,
    IBlockWithBundlesSlabsDTO
} from "../../app/WebAPIClients";
import BlockUtil from "../BlockUtil";
import { BlockDimensionType } from "../type";
import Util from "../Util";

export interface IBlockDimensionFieldProps {
    block: IBlockDTO | IBlockWithBundlesSlabsDTO | IBlockForListDTO;
    dimensionType: BlockDimensionType;
    labelPrefix?: string;
}

class BlockDimensionField extends React.PureComponent<
    IBlockDimensionFieldProps,
    {}
> {
    public static defaultProps: IBlockDimensionFieldProps;

    public render() {
        const t = this;

        const { block, dimensionType, labelPrefix } = t.props;

        if (!block) {
            return null;
        }

        const prefix: string = Util.isNotNullAndNotEmpty(labelPrefix)
            ? labelPrefix
            : dimensionType.toString();
        const title = prefix + BlockUtil.getBlockDimensionTitle();
        const dimensionText = BlockUtil.getBlockDimensionByType(
            block,
            dimensionType
        );

        return <Field label={title}>{dimensionText}</Field>;
    }
}

BlockDimensionField.defaultProps = {
    block: null,
    dimensionType: BlockDimensionType.QuarryReported,
    labelPrefix: null
};

export default BlockDimensionField;
