import React from "react";
import { Button, Popup, Table } from "saltui";
import {
    IBundleForListDTO,
    SlabBundleStatus,
    SlabChargeableStructureDTO
} from "../../app/WebAPIClients";
import ChargeableItemUtil from "../ChargeableItemUtil";
import Consts from "../Consts";
import ESButton from "../ESButton";
import SlabUtil from "../SlabUtil";
import Util from "../Util";

const { SlabBundleStatus: SlabBundleStatusOptions } = Consts;
interface ISlabChargeableStructureProps {
    slabChargeableStructures: SlabChargeableStructureDTO[];
    bundle?: IBundleForListDTO;
    categoryList: IIdNameItem[];
    gradeList: IIdNameItem[];
    visible?: boolean;
    onClose: (visible: boolean) => void | null;
}

declare interface ISlabChargeableStructureState {
    pageNo: number;
    currentSlabChargeableStructures: SlabChargeableStructureDTO[];
    visible: boolean;
}

class SlabChargeableStructure extends React.Component<
    ISlabChargeableStructureProps,
    ISlabChargeableStructureState
> {
    public static defaultProps: ISlabChargeableStructureProps;
    public pageSize: number = 8;
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            currentSlabChargeableStructures: [],
            visible: false
        };
    }

    public componentDidMount() {
        const { slabChargeableStructures } = this.props;
        if (Util.isNotNullAndNotEmptyArray(slabChargeableStructures)) {
            const currentSlabChargeableStructures: SlabChargeableStructureDTO[] = this.getCurrentSlabChargeableStructures(
                1
            );
            this.setState({
                currentSlabChargeableStructures
            });
        }
    }

    public componentDidUpdate() {
        if (
            "visible" in this.props &&
            this.state.visible !== this.props.visible
        ) {
            this.setState({
                visible: this.props.visible
            });
        }
    }

    public render(): JSX.Element {
        const t = this;
        const {
            slabChargeableStructures,
            bundle,
            categoryList,
            gradeList
        } = t.props;

        return (
            <Popup
                key={bundle.id}
                content={this.getContent(
                    slabChargeableStructures,
                    bundle,
                    categoryList,
                    gradeList
                )}
                // tslint:disable-next-line:jsx-no-lambda
                onMaskClick={t.handleCloseChange}
                visible={this.state.visible}
            >
                {null}
            </Popup>
        );
    }

    private getContent(
        slabChargeableStructures: SlabChargeableStructureDTO[],
        bundle: IBundleForListDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ) {
        const s = this.state;
        if (!Util.isNotNullAndNotEmptyArray(slabChargeableStructures)) {
            return null;
        }

        const slabChargeableStructureColumns = this.getSlabChargeableStructureColumns(
            categoryList,
            gradeList
        );
        const bundleStatusText = SlabBundleStatusOptions.getStatus(
            bundle.status
        ).text;
        let title = "共" + bundle.totalSlabCount + "片 ";
        title +=
            bundle.status === SlabBundleStatus.InStock
                ? `${bundleStatusText} 参考价使用最新价格表实时计算`
                : `${bundleStatusText} 使用扎和大板存储的参考价`;
        return (
            <div>
                <p
                    style={{
                        lineHeight: "42px",
                        textAlign: "center",
                        color: "#1890ff",
                        fontSize: "18px"
                    }}
                >
                    {bundle.prefix +
                        " #" +
                        bundle.totalBundleCount +
                        "-" +
                        bundle.bundleNo +
                        " 参考价: " +
                        "￥" +
                        bundle.unitPrice +
                        "元"}
                </p>
                <p
                    style={{
                        textAlign: "center",
                        marginBottom: "10px",
                        fontSize: "16px"
                    }}
                >
                    {title}
                </p>
                <Table
                    columns={slabChargeableStructureColumns}
                    data={{
                        data: s.currentSlabChargeableStructures,
                        currentPage: 1,
                        totalCount: slabChargeableStructures.length
                    }}
                    leftFixed={2}
                    pageSize={this.pageSize}
                    onPagerChange={this.handlePagerChange}
                />
                <div className="actionBtnContainer">
                    <ESButton onClick={this.handleCloseChange}>关闭</ESButton>
                </div>
            </div>
        );
    }

    private handlePagerChange = noop => {
        const t = this;
        const { slabChargeableStructures } = this.props;
        let currentSlabChargeableStructures: SlabChargeableStructureDTO[] = [];
        if (Util.isNotNullAndNotEmptyArray(slabChargeableStructures)) {
            currentSlabChargeableStructures = this.getCurrentSlabChargeableStructures(
                noop
            );
        }

        t.setState({ pageNo: noop, currentSlabChargeableStructures });
    };

    private getSlabChargeableStructureColumns(
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[]
    ) {
        const { bundle } = this.props;
        let slabChargeableStructureColumns: ITableColumnInfo[] = [
            {
                dataKey: "sequenceNumber",
                title: "大板序号",
                align: "center",
                width: 0.15,
                render: (cellData, rowData) =>
                    rowData.slab.sequenceNumber.toString()
            },
            {
                dataKey: "unitPrice",
                title: "参考价",
                align: "center",
                width: 0.2,
                render: (cellData, rowData) =>
                    this.getAmountJSX(
                        ChargeableItemUtil.getUnitPrice(rowData.chargeableItems)
                    )
            },
            {
                dataKey: "category",
                title: "品名",
                align: "center",
                render: (cellData, rowData) =>
                    Util.getItemName(rowData.slab.categoryId, categoryList)
            },
            {
                dataKey: "thickness",
                title: "厚度",
                align: "center",
                width: 0.15,
                render: (cellData, rowData) => rowData.slab.thickness
            },
            {
                dataKey: "grade",
                title: "等级",
                width: 0.15,
                align: "center",
                render: (cellData, rowData) =>
                    Util.getItemName(rowData.slab.gradeId, gradeList)
            },
            {
                dataKey: "subTotal",
                title: "参考总价",
                align: "center",
                render: (cellData, rowData) =>
                    this.getAmountJSX(
                        ChargeableItemUtil.calculateTotalPrice(rowData)
                    )
            }
        ];

        // 扎的状态是在库存状态时，才显示参考价组成，因为是实时计算的
        if (bundle.status === SlabBundleStatus.InStock) {
            slabChargeableStructureColumns.push({
                dataKey: "priceComponent",
                title: "参考价组成",
                align: "center",
                width: 0.3,
                render: (cellData, rowData) =>
                    this.getAmountJSX(
                        ChargeableItemUtil.getUnitPriceInfo(
                            rowData.chargeableItems
                        )
                    )
            });
        }

        slabChargeableStructureColumns = [
            ...slabChargeableStructureColumns,

            {
                dataKey: "size",
                title: "大板尺寸",
                align: "center",
                width: 0.4,
                render: (cellData, rowData) =>
                    SlabUtil.showSlabSizeInfo(rowData.slab)
            },
            {
                dataKey: "area",
                title: "面积",
                align: "center",
                width: 0.2,
                render: (cellData, rowData) => rowData.slab.area
            },
            {
                dataKey: "appliedProcesses",
                title: "工艺",
                align: "center",
                width: 0.4,
                render: (cellData, rowData) =>
                    rowData.slab.appliedProcessesString
            },
            {
                dataKey: "notes",
                title: "备注",
                align: "center",
                width: bundle.status === SlabBundleStatus.InStock ? 0.3 : 0.6,
                render: (cellData, rowData) =>
                    ChargeableItemUtil.getChargeableItemNotes(
                        rowData.chargeableItems
                    )
            }
        ];

        return slabChargeableStructureColumns;
    }

    private getCurrentSlabChargeableStructures(currentPage: number = 1) {
        let { slabChargeableStructures } = this.props;
        let currentSlabChargeableStructures: SlabChargeableStructureDTO[] = [];
        if (!Util.isNotNullAndNotEmptyArray(slabChargeableStructures)) {
            return currentSlabChargeableStructures;
        }

        // 如果传入的页码大于当前数据条数最大页码，则返回空数组
        if (
            Math.ceil(slabChargeableStructures.length / this.pageSize) <
            currentPage
        ) {
            return currentSlabChargeableStructures;
        }

        slabChargeableStructures = this.getSlabChargeableStructureGroupBy(
            slabChargeableStructures
        );
        if (
            currentPage === 1 &&
            this.pageSize >= slabChargeableStructures.length
        ) {
            currentSlabChargeableStructures = slabChargeableStructures;
        } else {
            currentSlabChargeableStructures = slabChargeableStructures.slice(
                (currentPage - 1) * this.pageSize,
                this.pageSize * currentPage
            );
        }

        return currentSlabChargeableStructures;
    }

    private getSlabChargeableStructureGroupBy(
        slabChargeableStructures: SlabChargeableStructureDTO[]
    ): SlabChargeableStructureDTO[] {
        return slabChargeableStructures.slice().sort((sc1, sc2) => {
            const sortOne = sc1.slab.sequenceNumber;
            const sortTwo = sc2.slab.sequenceNumber;
            return sortOne - sortTwo;
        });
    }

    private handleCloseChange = () => {
        const { onClose } = this.props;
        this.setState({ visible: false });
        if (onClose) {
            onClose(false);
        }
    };

    private getAmountJSX = (value: string | number | JSX.Element) => {
        return <span style={{ color: "#1890FF" }}>{"￥" + value}</span>;
    };
}

SlabChargeableStructure.defaultProps = {
    slabChargeableStructures: [],
    bundle: null,
    categoryList: [],
    gradeList: [],
    onClose: null
};

export default SlabChargeableStructure;
