import * as React from "react";
import { Group } from "saltui";
import { IShippingOrderForListDTO } from "../../app/WebAPIClients";
import ESHeader from "../ESHeader";
import ListItem from "../ListItem";
import ShippingOrderUtil from "../ShippingOrderUtil";
import Util from "../Util";

declare interface IShippingOrderListProps {
    shippingOrders: IShippingOrderForListDTO[];
    showStatus?: boolean;
    showCreateItemIcon?: boolean;
    showHeader?: boolean;
    onClick?: (item: IShippingOrderForListDTO) => void;
    onCreateItemClick?: () => void;
}

class ShippingOrderList extends React.PureComponent<
    IShippingOrderListProps,
    {}
> {
    public static defaultProps: IShippingOrderListProps;

    public render() {
        const t = this;
        const {
            shippingOrders,
            showStatus,
            showHeader,
            onClick,
            showCreateItemIcon,
            onCreateItemClick
        } = t.props;
        return (
            <div>
                <Group>
                    {!showHeader ? null : (
                        <ESHeader
                            label={"装车单列表"}
                            allowCreation={showCreateItemIcon}
                            onCreateClick={onCreateItemClick}
                        />
                    )}
                    <Group.List lineIndent={15} itemIndent={[15, 15]}>
                        {shippingOrders &&
                        shippingOrders.length &&
                        shippingOrders.length > 0 ? (
                            shippingOrders.map(item => {
                                const title = showStatus ? (
                                    <span>
                                        {item.customerName}
                                        {" | "}
                                        {item.licensePlateNumber + " | "}
                                        {ShippingOrderUtil.getSPOStatus(
                                            item.status
                                        )}
                                    </span>
                                ) : (
                                    item.customerName +
                                    " | " +
                                    item.licensePlateNumber
                                );

                                let description: string = item.orderNumber;
                                const avatarTitle = Util.getPreAvatarText(
                                    item.licensePlateNumber
                                );
                                if (
                                    Util.isNotNullAndNotEmpty(item.projectName)
                                ) {
                                    description += " | " + item.projectName;
                                }
                                if (Util.isNotNullAndNotEmpty(item.itemType)) {
                                    description += " | " + item.itemType;
                                }
                                if (
                                    Util.isNotNullAndNotEmpty(item.itemsCount)
                                ) {
                                    description +=
                                        " | 数量： " + item.itemsCount;
                                }

                                return (
                                    <ListItem
                                        key={item.id}
                                        avatarTitle={avatarTitle}
                                        title={title}
                                        description={description}
                                        item={item}
                                        onClick={onClick}
                                    />
                                );
                            })
                        ) : (
                            <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                                没有数据
                            </div>
                        )}
                    </Group.List>
                </Group>
            </div>
        );
    }
}

ShippingOrderList.defaultProps = {
    shippingOrders: [],
    showStatus: false,
    showHeader: true,
    showCreateItemIcon: false,
    onClick: (item: IShippingOrderForListDTO) => {},
    onCreateItemClick: () => {}
};

export default ShippingOrderList;
