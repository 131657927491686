import React from "react";
import { BlockStatus, IBlockForListDTO } from "../../app/WebAPIClients";
import BlockUtil from "../BlockUtil";
import ExpandableListItem from "../ExpandableListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";
import StockingAreaUtil from "../StockingAreaUtil";
import Util from "../Util";

declare interface IBlockExpandableListItemProps extends IListItemBaseProps {
    block: IBlockForListDTO;
    onClick?: (block: IBlockForListDTO) => void;
    onSelect?: (block: IBlockForListDTO) => void;
    onExpansionChange?: (item: IBlockForListDTO, expanded: boolean) => void;
    allowSelection?: boolean;
    selected?: boolean;
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
    customContent?: JSX.Element;
    hasTrimmingRecord?: boolean;
}

class BlockExpandableListItem extends React.PureComponent<
    IBlockExpandableListItemProps
> {
    public static defaultProps: IBlockExpandableListItemProps;
    public render() {
        const t = this;

        const {
            block,
            allowSelection,
            selected,
            onClick,
            onSelect,
            categoryList,
            gradeList,
            onExpansionChange,
            customContent,
            hasTrimmingRecord
        } = t.props;

        const title = (
            <span>
                {BlockUtil.getBlockTitle(block, categoryList) + " | "}
                <span>{BlockUtil.getBlockStatus(block.status)}</span>
            </span>
        );

        const showStockingArea =
            [
                BlockStatus.InStock,
                BlockStatus.Reserved,
                BlockStatus.Purchased
            ].includes(block.status) &&
            Util.isDefinedAndNotNull(block.stockingAreaId);

        const description = (
            <div>
                {BlockUtil.getBlockSpec(block, categoryList, gradeList)}
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    block.stockingAreaId
                )}
            </div>
        );

        const avatarTitle = hasTrimmingRecord ? "修过" : "未修";

        return (
            <div>
                <ExpandableListItem
                    key={block.id}
                    item={block}
                    avatarTitle={avatarTitle}
                    title={title}
                    description={description}
                    onClick={onClick}
                    allowSelection={allowSelection}
                    selected={selected}
                    onSelect={onSelect}
                    onExpansionChange={onExpansionChange}
                    customContent={customContent}
                />
            </div>
        );
    }
}

BlockExpandableListItem.defaultProps = {
    block: null,
    onClick: (): void => {},
    onSelect: (): void => {},
    onExpansionChange: (): void => {},
    allowSelection: false,
    selected: false,
    customContent: null,
    categoryList: [],
    gradeList: [],
    hasTrimmingRecord: false
};

export default BlockExpandableListItem;
