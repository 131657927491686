import * as React from "react";
import { Boxs, Group, Tab } from "saltui";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ESIcon from "../ESIcon";
import SalesOrderDetailListItem from "../SalesOrderDetailListItem";
import StoneArtifactUtil from "../StoneArtifactUtil";
import { ISalesOrderDetailForUI } from "../type";
import { IBundleForListDTO } from "../../app/WebAPIClients";
const HBox = Boxs.HBox;
const Box = Boxs.Box;

declare interface ISalesOrderDetailsGroupProps
    extends React.Props<SalesOrderDetailsGroup> {
    details: ISalesOrderDetailForUI[];
    showPrice?: boolean;
    allowDeletion?: boolean;
    allowEditing?: boolean;
    allowCreation?: boolean; // 用于判断无库存大板订单是创建还是编辑，在新建时不允许添加扎
    isEditing?: boolean; // 用于判断订单是创建还是编辑，true为编辑、false为新建销售订单
    isInternalOrder?: boolean;
    orderType?: number;
    showScanQrCode?: boolean;
    showTab?: boolean;
    onDelete?: (item: ISalesOrderDetailForUI) => void;
    onUnitPriceChange?: (
        item: ISalesOrderDetailForUI,
        priceTxt: string
    ) => void;
    onDeductedQuantityChange?: (
        item: ISalesOrderDetailForUI,
        deductedQuantityTxt: string
    ) => void;
    onCreateClick?: () => void;
    onScanQrCodeClick?: () => void;
    onPaymentIconClick?: (item: ISalesOrderDetailForUI) => void;
    onShipmentIconClick?: (item: ISalesOrderDetailForUI) => void;
    onClick?: (item: ISalesOrderDetailForUI) => void;
    salesOrderId?: number; // 用于点击扎的参考价信息查看其价格组成
    customerId?: number; // 用于点击扎的参考价信息查看其价格组成
    showBundlesWithSamePrefixes?: boolean;
    bundlesWithSamePrefixes?: IBundleForListDTO[];
}

declare interface ISalesOrderDetailsGroupState {
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
}

class SalesOrderDetailsGroup extends React.Component<
    ISalesOrderDetailsGroupProps,
    ISalesOrderDetailsGroupState
> {
    public static defaultProps: ISalesOrderDetailsGroupProps;
    constructor(props) {
        super(props);
        this.state = {};
    }

    public async componentDidMount() {
        const categoryList = await Consts.StoneCategories.getCategories();
        const gradeList = await Consts.StoneGrades.getGrades();
        this.setState({
            categoryList,
            gradeList
        });
    }

    public render() {
        const t = this;

        const {
            details,
            orderType,
            isEditing,
            isInternalOrder,
            ...other
        } = t.props;

        const blockItems = StoneArtifactUtil.getBlockItems(details);
        const bundleItems = StoneArtifactUtil.getBundleItems(details);
        const showBlockItems =
            blockItems && blockItems.length && blockItems.length > 0;
        const showBundleItems =
            bundleItems && bundleItems.length && bundleItems.length > 0;

        return (
            <Group>
                {showBlockItems || [10, 80].includes(orderType)
                    ? this.getStoneArtifactItemJSX("荒料列表", blockItems)
                    : null}
                {showBundleItems ||
                orderType === 30 ||
                (orderType === 80 && isEditing) ||
                (orderType === 40 && !isInternalOrder && isEditing)
                    ? this.getStoneArtifactItemJSX("大板列表", bundleItems)
                    : null}
            </Group>
        );
    }

    private getStoneArtifactItemJSX(
        lable: string,
        details: ISalesOrderDetailForUI[]
    ): JSX.Element {
        const t = this;
        const {
            showScanQrCode,
            isEditing,
            allowCreation,
            orderType,
            onCreateClick,
            onScanQrCodeClick,
            isInternalOrder,
            showTab,
            ...other
        } = t.props;

        const extraIcons =
            showScanQrCode &&
            (orderType === 30 ||
                (orderType === 40 && !isInternalOrder && isEditing)) ? (
                <HBox>
                    <Box className={"scanStyle"}>
                        <span onClick={t.handleOnScanQrCodeClick}>
                            <ESIcon
                                name="icon-scan"
                                fill="#1296db"
                                height={25}
                                width={25}
                            />
                        </span>
                    </Box>
                </HBox>
            ) : null;

        const tabItemOneTitle = isEditing ? "可编辑" : "待审批";
        const tabItemTwoTitle = isEditing ? "已结算或已装车" : "已审批";
        let detailsForTabItemOne: ISalesOrderDetailForUI[] = [];
        let detailsFortabItemTwo: ISalesOrderDetailForUI[] = [];
        if (showTab === true) {
            detailsForTabItemOne = details.filter(
                d => !d.hasShipped && !d.hasLoaded && !d.paid && !d.addedToPO
            );

            detailsFortabItemTwo = details.filter(
                d => d.hasShipped || d.hasLoaded || d.paid || d.addedToPO
            );
        }

        return (
            <Collapsible
                label={lable}
                expanded={true}
                extraIcons={extraIcons}
                onCreateClick={onCreateClick}
                allowCreation={
                    allowCreation !== null
                        ? allowCreation
                        : orderType === 30 ||
                          orderType === 10 ||
                          (orderType === 80 && lable !== "大板列表") ||
                          (orderType === 40 && !isInternalOrder && isEditing)
                }
            >
                {showTab !== null && showTab === true ? (
                    <Tab activeKey={"1"}>
                        <Tab.Item title={tabItemOneTitle} key={"1"}>
                            {this.getItemsGroupJSX(detailsForTabItemOne)}
                        </Tab.Item>
                        <Tab.Item title={tabItemTwoTitle} key={"2"}>
                            {this.getItemsGroupJSX(detailsFortabItemTwo)}
                        </Tab.Item>
                    </Tab>
                ) : (
                    this.getItemsGroupJSX(details)
                )}
            </Collapsible>
        );
    }

    private getItemsGroupJSX(details: ISalesOrderDetailForUI[]): JSX.Element {
        const t = this;
        const {
            showPrice,
            showScanQrCode,
            allowEditing,
            isEditing,
            allowCreation,
            onDelete,
            orderType,
            onUnitPriceChange,
            onDeductedQuantityChange,
            onCreateClick,
            onScanQrCodeClick,
            isInternalOrder,
            onPaymentIconClick,
            onShipmentIconClick,
            onClick,
            salesOrderId,
            customerId,
            showBundlesWithSamePrefixes,
            bundlesWithSamePrefixes,
            ...other
        } = t.props;
        const { categoryList, gradeList } = t.state;
        let { allowDeletion } = t.props;

        return (
            <Group>
                <Group.List lineIndent={15} itemIndent={[15, 15]}>
                    {details && details.length && details.length > 0 ? (
                        details.map((item, index) => {
                            const itemKey =
                                item.type.toString() +
                                "|" +
                                StoneArtifactUtil.getStoneArtifactItemId(
                                    item
                                ).toString();

                            if (allowDeletion !== null) {
                                allowDeletion =
                                    !item.hasLoaded &&
                                    !item.hasShipped &&
                                    !item.paid &&
                                    !item.addedToPO;

                                if (orderType === 80) {
                                    allowDeletion =
                                        item.bundleId != null
                                            ? false
                                            : item.block != null
                                                ? allowDeletion &&
                                                  (item.block.status === 50 ||
                                                      item.block.status === 10)
                                                : allowDeletion;
                                }
                            }
                            return (
                                <SalesOrderDetailListItem
                                    key={itemKey}
                                    details={t.props.details}
                                    salesOrderDetail={item}
                                    orderType={orderType}
                                    allowDeletion={allowDeletion}
                                    allowEditing={allowEditing}
                                    showPrice={showPrice}
                                    categoryList={categoryList}
                                    gradeList={gradeList}
                                    onDelete={onDelete}
                                    onUnitPriceChange={onUnitPriceChange}
                                    onDeductedQuantityChange={
                                        onDeductedQuantityChange
                                    }
                                    onPaymentIconClick={onPaymentIconClick}
                                    onShipmentIconClick={onShipmentIconClick}
                                    onClick={onClick}
                                    salesOrderId={salesOrderId}
                                    customerId={customerId}
                                    showBundlesWithSamePrefixes={
                                        showBundlesWithSamePrefixes
                                    }
                                    bundlesWithSamePrefixes={
                                        bundlesWithSamePrefixes
                                    }
                                />
                            );
                        })
                    ) : (
                        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                            没有数据
                        </div>
                    )}
                </Group.List>
            </Group>
        );
    }
    private handleOnScanQrCodeClick = e => {
        const t = this;
        const { onScanQrCodeClick } = t.props;

        if (onScanQrCodeClick) {
            onScanQrCodeClick();
        }

        if (e) {
            e.stopPropagation();
        }
    };
}

SalesOrderDetailsGroup.defaultProps = {
    details: [],
    showPrice: false,
    allowDeletion: null,
    allowCreation: null,
    isInternalOrder: false,
    allowEditing: false,
    isEditing: false,
    orderType: null,
    showScanQrCode: true,
    showTab: false,
    onDelete: item => {},
    onUnitPriceChange: (item, priceTxt) => {},
    onDeductedQuantityChange: (item, deductedQuantityTxt) => {},
    onCreateClick: () => {},
    onScanQrCodeClick: () => {},
    onClick: item => {},
    onPaymentIconClick: null,
    onShipmentIconClick: null,
    salesOrderId: null,
    customerId: null,
    showBundlesWithSamePrefixes: false,
    bundlesWithSamePrefixes: []
};

export default SalesOrderDetailsGroup;
