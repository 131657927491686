import React from "react";

declare interface IDataLoadingMessageProps {
    noMoreData: boolean;
}

class DataLoadingMessage extends React.PureComponent<IDataLoadingMessageProps> {
    public static defaultProps: IDataLoadingMessageProps;

    public render(): JSX.Element {
        const t = this;
        const { noMoreData } = t.props;
        return (
            <div className="loadMoreDataText">
                {noMoreData ? "没有更多数据了" : "滑动到最后加载更多数据..."}
            </div>
        );
    }
}

DataLoadingMessage.defaultProps = {
    noMoreData: false
};

export default DataLoadingMessage;
