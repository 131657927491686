import * as React from "react";
import { ApprovalStatus } from "../app/WebAPIClients";
import Consts from "./Consts";
const { ApprovalStatus: ApprovalStatusConsts } = Consts;

class ApprovalStatusUtil {
  public getStatusJSX(status: ApprovalStatus): JSX.Element {
    const statusName = ApprovalStatusConsts.getState(status).text;
    let className = "";
    switch (status) {
      case 10:
        className = "notStartedWO";
        break;
      case 20:
        className = "sawingWO";
        break;
      case 30:
        className = "trimmingWO";
        break;
      case 40:
        className = "abnormalSO";
        break;
    }
    return <span className={className}>{statusName}</span>;
  }
}

export default new ApprovalStatusUtil();
