import * as React from "react";
import { Boxs, Field, Group, NumberInfo } from "saltui";
import Perm from "../app/Perm";
import {
    IAppliedPriceItemDTO,
    ISalesOrderDetailDTO,
    ISalesOrderDTO,
    ISalesOrderForListDTO,
    ISalesOrderMaterialSpecDTO,
    ISalesOrderWithDetailsForListDTO,
    SalesOrderStatus,
    SalesOrderType,
    StoneArtifactType
} from "../app/WebAPIClients";
import BlockUtil from "./BlockUtil";
import Consts from "./Consts";
import ESIcon from "./ESIcon";
import ESNumberField from "./ESNumberField";
import ListItem from "./ListItem";
import PermCtl from "./PermCtrl";
import StoneArtifactUtil from "./StoneArtifactUtil";
import StoneBundleUtil from "./StoneBundleUtil";
import {
    ISalesOrdeForQuantityAndAmount,
    ISalesOrderDetailForUI,
    ISalesOrderForListForUI
} from "./type";
import Util from "./Util";

const {
    SalesOrderStatusOptions,
    SalesOrderType: SalesOrderTypeOptions
} = Consts;
const HBox = Boxs.HBox;
const Box = Boxs.Box;
const { NumberItem } = NumberInfo;

class SalesOrderUtil {
    // 显示销售订单类型
    public getSalesOrder(so: ISalesOrderDTO | ISalesOrderForListDTO): string {
        let description = so.isInternalOrder
            ? "内部订单"
            : "客户：" + so.customerName;
        if (!(so.projectName === null || so.projectName === "")) {
            description += " | 工程：" + so.projectName;
        }
        description +=
            " | " + SalesOrderTypeOptions.getOrderType(so.orderType).text;
        return description;
    }

    // 显示客户名称和订单状态
    public getSOTitle(so: ISalesOrderDTO | ISalesOrderForListDTO): JSX.Element {
        const soStatusName = this.getSOStatus(so);
        return (
            <span>
                {so.orderNumber + " | "}
                {soStatusName}
            </span>
        );
    }

    // 显示客户名称和订单状态
    public getSOStatus(
        so: ISalesOrderDTO | ISalesOrderForListDTO
    ): JSX.Element {
        const soStatusName = SalesOrderStatusOptions.getStatus(so.status).text;
        let className = "";
        switch (so.status) {
            case 5:
                className = "draftSO";
                break;
            case 10:
                className = "approvingSO";
                break;
            case 20:
            case 40:
                className = "normalSO";
                break;
            case 30:
            case 50:
                className = "abnormalSO";
                break;
        }
        return <span className={className}>{soStatusName}</span>;
    }

    // 显示荒料价格或大板的价格
    public price(sod: ISalesOrderDetailDTO): JSX.Element {
        return (
            <div className="materialSpecArea">
                <HBox className="demo3-t-list-text" flex={1}>
                    <Box className="separator">
                        {sod.blockId !== null
                            ? "荒料单价"
                            : sod.bundleId !== null
                                ? "大板单价"
                                : "工程板均价"}
                    </Box>
                    <Box className="separator" flex={1}>
                        <span className="unitPriceText salesOrderDetailPrice">
                            ￥{sod.price}
                        </span>
                    </Box>
                    <Box className="separator">
                        <p className="demo3-t-list-title">
                            <span className="demo3-t-list-text">共</span>
                            <span className="unitPriceText salesOrderDetailPrice">
                                ￥{sod.subTotal}
                            </span>
                        </p>
                    </Box>
                    <div className="materialSpecIconArea">
                        <div className="placeholderArea" />
                    </div>
                </HBox>
            </div>
        );
    }

    // 显示装车和出厂状态
    public showIcon(
        sod: ISalesOrderDetailDTO,
        onPaidClick?: React.MouseEventHandler<HTMLDivElement>,
        onShipmentClick?: React.MouseEventHandler<HTMLDivElement>
    ): JSX.Element {
        let icon = null;
        if (!sod) {
            return icon;
        }

        icon = this.getShipmentIcon(sod, onShipmentClick);

        if (sod.paid || sod.addedToPO) {
            const paidIcon = this.getPaidIcon(sod, onPaidClick);

            if (icon === null) {
                icon = paidIcon;
            } else {
                icon = (
                    <div>
                        {paidIcon}
                        <div style={{ marginTop: "10px" }}>{icon}</div>
                    </div>
                );
            }
        }
        return icon;
    }

    // 获取装车和出厂Icon
    public getShipmentIcon(
        sod: ISalesOrderDetailDTO,
        onShipmentClick?: React.MouseEventHandler<HTMLDivElement>
    ): JSX.Element {
        let icon = null;
        if (!sod) {
            return icon;
        }

        if (sod.hasShipped) {
            icon = (
                <ESIcon
                    name="icon-shipped"
                    fill="#00CC00"
                    className="icon-is"
                    height={20}
                    width={20}
                />
            );
        }
        if (sod.hasLoaded && !sod.hasShipped) {
            icon = (
                <ESIcon
                    name="icon-loaded"
                    fill="#1296db"
                    className="icon-is"
                    height={20}
                    width={20}
                />
            );
        }

        if (icon !== null) {
            icon = <div onClick={onShipmentClick}>{icon}</div>;
        }

        return icon;
    }

    // 获取是否结算Icon
    public getPaidIcon(
        sod: ISalesOrderDetailDTO,
        onPaidClick: React.MouseEventHandler<HTMLDivElement>
    ): JSX.Element {
        let icon = null;
        if (!sod) {
            return icon;
        }

        if (sod.paid || sod.addedToPO) {
            icon = sod.paid ? (
                <ESIcon
                    name="icon-currency"
                    className="icon-is"
                    fill="#00CC00"
                    height={20}
                    width={20}
                />
            ) : sod.addedToPO ? (
                <ESIcon
                    name="icon-addedtopo"
                    className="icon-is"
                    fill="#1296db"
                    height={20}
                    width={20}
                />
            ) : null;
        }

        if (icon !== null) {
            icon = <div onClick={onPaidClick}>{icon}</div>;
        }

        return icon;
    }

    // 显示销售订单的生产需求和进度信息
    public showSpecs(
        so: ISalesOrderForListForUI,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[],
        onClickShowMoreSpecs: () => {}
    ): JSX.Element {
        const specs = so && so.materialSpecs ? so.materialSpecs : [];
        return specs && specs.length && specs.length > 0 ? (
            <HBox>
                <Box flex={1}>
                    {specs.map((spec, indexOfMS) => {
                        let categoryName = null;
                        let gradeName = null;
                        let manufacturingProgress = null;
                        const type = null;
                        const ms = spec.materialSpec;

                        if (
                            categoryList &&
                            categoryList.length &&
                            categoryList.length > 0 &&
                            ms.categoryId
                        ) {
                            categoryName = categoryList.find(
                                cate => cate.id === ms.categoryId
                            ).name;
                        }

                        if (
                            gradeList &&
                            gradeList.length &&
                            gradeList.length > 0 &&
                            ms.gradeId
                        ) {
                            gradeName = gradeList.find(g => g.id === ms.gradeId)
                                .name;
                        }

                        manufacturingProgress = Util.toPercent(
                            spec.rawSlabManufacturingProgress
                        );

                        let specTitle =
                            categoryName + " | 厚： " + ms.thickness;
                        if (gradeName) {
                            specTitle += " | " + gradeName;
                        }
                        if (type) {
                            specTitle += " | " + type;
                        }

                        const specDesc = (
                            <div>
                                <p>{specTitle}</p>
                                {ms.area ? (
                                    <p className="separator">
                                        <span className="deductedReasonLabel unitPriceText">
                                            {ms.area}
                                            {"平方 "}
                                        </span>
                                        | 进度：<span className="progressText progressTextArea">
                                            {" " + manufacturingProgress}
                                        </span>
                                    </p>
                                ) : null}
                            </div>
                        );

                        const specAvatarTitle = Util.getPreAvatarText(
                            categoryName,
                            2
                        );

                        return so.showMoreSpecs || indexOfMS === 0 ? (
                            <ListItem
                                key={indexOfMS}
                                avatarTitle={specAvatarTitle}
                                description={specDesc}
                            />
                        ) : null;
                    })}
                </Box>
                <Box
                    onClick={specs.length > 1 ? onClickShowMoreSpecs : () => {}}
                >
                    {specs.length > 1 ? (
                        <div className="materialSpecIconArea">
                            <ESIcon
                                name={
                                    so.showMoreSpecs
                                        ? "icon-uparrow"
                                        : "icon-downarrow"
                                }
                                className={"icon-is iconField"}
                                fill="#1296db"
                                height={25}
                                width={25}
                            />
                        </div>
                    ) : (
                        <div className="materialSpecIconArea">
                            <div
                                style={{
                                    height: 20,
                                    width: 20
                                }}
                            />
                        </div>
                    )}
                </Box>
            </HBox>
        ) : null;
    }

    public getSalesOrderInfoJSX(
        so: ISalesOrderWithDetailsForListDTO,
        onClick: (item: ISalesOrderWithDetailsForListDTO) => void
    ): JSX.Element {
        const t = this;
        const sod = so.details[0];
        const canViewAmount = PermCtl.isAuthorized(Perm.SO_P_R);
        const avatarTitle = Util.getSalesOrderTypeAvatarText(so.orderType);
        return (
            <ListItem
                key={so.id}
                item={so}
                avatarTitle={avatarTitle}
                title={t.getSOTitle(so)}
                description={t.getSalesOrder(so)}
                extraInfo={canViewAmount && sod ? t.price(sod) : null}
                icon={t.showIcon(sod)}
                onClick={onClick}
            />
        );
    }

    public showShippedInfo(so: ISalesOrderForListDTO): JSX.Element {
        const shippedDetailCount = so.shippedDetailCount;
        const totalDetailCount = so.totalDetailCount;
        return (
            <HBox className="demo3-t-list-text">
                {so.orderType === 10 ? (
                    <Box flex={1} className="separator">
                        售出 {totalDetailCount} 颗 | 已出厂 {shippedDetailCount}{" "}
                        颗
                    </Box>
                ) : (
                    <Box flex={1} className="separator">
                        售出 {totalDetailCount} 扎 | 已出厂 {shippedDetailCount}{" "}
                        扎
                    </Box>
                )}
                <div className="materialSpecIconArea">
                    <div className="placeholderArea" />
                </div>
            </HBox>
        );
    }

    public getSalesOrderWithExtraInfoJSX(
        so: ISalesOrderForListForUI,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[],
        onClickForSO: (item) => void,
        onClickShowMoreSpecs: () => {}
    ) {
        const t = this;
        const priority = so.priority;
        const titleJSX = t.getSOTitle(so);
        const description = t.getSalesOrder(so);

        const extraInfoJSX = (
            <div className="materialSpecArea">
                {(so.orderType === 40 || so.orderType === 80) &&
                (so.status !== 40 && so.status !== 50)
                    ? t.showSpecs(
                          so,
                          categoryList,
                          gradeList,
                          onClickShowMoreSpecs
                      )
                    : null}
                {(so.orderType === 10 || so.orderType === 30) &&
                (so.status !== 40 && so.status !== 50)
                    ? t.showShippedInfo(so)
                    : null}
            </div>
        );

        const icon =
            priority === 3 ? (
                <ESIcon
                    name="icon-showExtraUrgentPriority"
                    fill="#d81e06"
                    className="icon-is iconField"
                    height={20}
                    width={20}
                />
            ) : priority === 2 ? (
                <ESIcon
                    name="icon-showUrgentPriority"
                    fill="#d81e06"
                    className="icon-is iconField"
                    height={20}
                    width={20}
                />
            ) : null;

        const avatarTitle = Util.getSalesOrderTypeAvatarText(so.orderType);

        return (
            <ListItem
                key={so.id}
                item={so}
                avatarTitle={avatarTitle}
                title={titleJSX}
                description={description}
                extraInfo={extraInfoJSX}
                icon={icon}
                onClick={onClickForSO}
            />
        );
    }

    public initSalesOrderList(salesOrderList: ISalesOrderForListForUI[]) {
        if (
            !(
                salesOrderList &&
                salesOrderList.length &&
                salesOrderList.length > 0
            )
        ) {
            return [];
        }

        salesOrderList.forEach(item => {
            if (item.orderType === 40) {
                const specs = item.materialSpecs;

                if (specs && specs.length && specs.length > 1) {
                    item.moreSpecs = true;
                    item.showMoreSpecs = false;
                }
            }
        });

        return salesOrderList;
    }

    public getTotalAppliedPrices(
        appliedPrices: Array<{ subtotal: number }>
    ): number {
        if (!appliedPrices) {
            return 0;
        }

        return appliedPrices.reduce<number>(
            (acc, price) => acc + price.subtotal,
            0
        );
    }

    // 选出销售订单中荒料的id列表
    public getDetailsBlockIds(details: ISalesOrderDetailDTO[]): number[] {
        const blockIdsFromDetail = [];
        details.forEach(d => {
            if (d.blockId !== null && !blockIdsFromDetail.includes(d.blockId)) {
                blockIdsFromDetail.push(d.blockId);
            }
        });
        return blockIdsFromDetail;
    }

    // 选出订单中对破产生的新荒料对应的销售明细列表
    public getSplittedBlockDetail(
        details: ISalesOrderDetailDTO[],
        orderType: number
    ): ISalesOrderDetailDTO[] {
        if (orderType !== 80) {
            return [];
        }

        const blockIdsFromDetail = this.getDetailsBlockIds(details);
        return details.filter(d => {
            return (
                d.blockId !== null &&
                d.block !== null &&
                d.block.originalBlockId !== null &&
                blockIdsFromDetail.includes(d.block.originalBlockId)
            );
        });
    }

    // 选出订单中荒料或扎对应的销售明细列表，排除对破产生的新荒料
    public getDetailWithOutSplittedBlock(
        details: ISalesOrderDetailDTO[],
        orderType: number
    ): ISalesOrderDetailDTO[] {
        if (orderType !== 80) {
            return [];
        }

        const blockIdsFromDetail = this.getDetailsBlockIds(details);
        return details.filter(d => {
            return (
                d.bundleId !== null ||
                (d.block !== null &&
                    (d.block.originalBlockId === null ||
                        (d.block.originalBlockId !== null &&
                            !blockIdsFromDetail.includes(
                                d.block.originalBlockId
                            ))))
            );
        });
    }

    // 计算所有材料规格的总面积
    public getTotalAreaForMaterialSpecs(materialSpecs) {
        let area = 0;
        if (typeof materialSpecs === "undefined" || materialSpecs === null) {
            return area;
        }
        materialSpecs.forEach(m => {
            if (typeof m.area !== "undefined" && m.area !== null) {
                area += parseFloat(m.area);
            }
        });
        area = Util.round(area, 3);

        return area;
    }

    /**
     * 获取销售订单明细的列表显示数据
     * @param orderType 销售订单类型
     * @param detail 销售订单明细对象
     * @param categoryList 石材种类列表，如果不传的话，结果中不包含石材种类
     * @param gradeList 石材等级列表，如果不传的话，结果中不包含石材种类
     * @param showBundleGrades 是否显示大扎等级，默认显示
     */
    public getSalesOrderDetailInfoForList(
        orderType: number,
        detail: ISalesOrderDetailDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[],
        showBundleGrades: boolean = true
    ): IListItem {
        let listItem = Util.getEmptyListItemData();
        if (
            typeof detail === "undefined" ||
            detail === null ||
            typeof orderType === "undefined" ||
            orderType === null
        ) {
            return listItem;
        }

        switch (orderType) {
            case 10: // 库存荒料
                listItem = BlockUtil.getBlockInfoForList(
                    detail.block,
                    categoryList,
                    gradeList
                );
                break;
            case 30: // 库存大板
            case 40: // 无库存大板
            case 50: // 无库存毛板
                listItem = StoneBundleUtil.getBundleInfoForList(
                    detail.bundle,
                    categoryList,
                    gradeList,
                    showBundleGrades
                );
                break;
            case 70: // 外来料加工
            case 80: // 销售荒料代加工
                listItem = this.getSAMSalesOrderDetailInfoForList(
                    detail,
                    categoryList,
                    gradeList,
                    showBundleGrades
                );
        }

        return listItem;
    }

    public getSAMSalesOrderDetailInfoForList(
        detail: ISalesOrderDetailDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[],
        showBundleGrades: boolean = true
    ): IListItem {
        if (!detail || (!detail.block && !detail.bundle)) {
            return Util.getEmptyListItemData();
        }
        if (detail.block !== null) {
            return {
                title: BlockUtil.getBlockTitle(detail.block, categoryList),
                text: BlockUtil.getBlockSpec(
                    detail.block,
                    categoryList,
                    gradeList
                )
            };
        }
        if (detail.bundle !== null) {
            return {
                title: StoneBundleUtil.getBundleTitle(detail.bundle),
                text: StoneBundleUtil.getBundleSpec(
                    detail.bundle,
                    categoryList,
                    gradeList,
                    showBundleGrades
                )
            };
        }
    }

    public getSalesOrderDetailAvatarText(
        orderType: number,
        detail: ISalesOrderDetailForUI
    ): string {
        let text = "";
        switch (orderType) {
            case 10:
                text = "荒料";
                break;
            case 30:
            case 40:
                if (
                    typeof detail.bundle !== "undefined" &&
                    detail.bundle !== null
                ) {
                    text = detail.bundle.type === 10 ? "毛板" : "光板";
                }
                break;
            case 60:
                text = "工程";
                break;
            case 80:
                if (detail.bundle) {
                    text = detail.bundle.type === 10 ? "毛板" : "光板";
                } else if (detail.block) {
                    text = "荒料";
                }
                break;
        }
        return text;
    }

    public getMSTotalAmount(
        so: ISalesOrdeForQuantityAndAmount,
        materialSpecs: ISalesOrderMaterialSpecDTO[]
    ): number {
        const t = this;
        let total = 0;
        const orderType = so.orderType;
        if (orderType === 40 && !so.isInternalOrder) {
            if (!(materialSpecs && materialSpecs.length > 0)) {
                return total;
            }

            materialSpecs.forEach(m => {
                total += Util.getSubTotalForMaterialSpec(m);
            });
        }

        return Util.round(total, 2);
    }

    public showQuantityAndTotalAmount(
        so: ISalesOrdeForQuantityAndAmount,
        details: ISalesOrderDetailDTO[],
        appliedPrices: IAppliedPriceItemDTO[],
        isEditPage: boolean,
        enableReferencePrice: boolean
    ): JSX.Element {
        if (!so) {
            return null;
        }

        const canViewAmount = PermCtl.isAuthorized(Perm.SO_P_R);
        let demandArea = 0;
        const msList = so.materialSpecs;
        const orderType = so.orderType;
        const bundleAmountLable =
            so.orderType === 80 ? "加工费(元)" : "大板总额(元)";
        const totalAmountOfAppliedPrices = this.getTotalAppliedPrices(
            appliedPrices
        ); // 其它收费总额
        const detailsInBlock = StoneArtifactUtil.getBlockItems(details);
        const detailsInBundle = StoneArtifactUtil.getBundleItems(details);
        let quantityOfBlock = 0; // 销售荒料代加工订单原材料总体积
        let quantityOfSplittedBlock = 0; // 销售荒料代加工订单对破产生的新荒料总体积
        let quantityOfBundle = 0; // 销售荒料代加工订单加工的大板总面积
        let detailsWithSplittedBlock = []; // 存放销售荒料代加工订单的销售明细中的是对破产生的新荒料的block明细
        let totalAmountOfMaterial = 0; // 销售荒料代加工订单的原材料总额
        let totalAmountOfManufacture = 0; // 销售荒料代加工订单的加工费总额
        let deductedQuantityForBundle = 0;
        let deductedQuantityForBlock = 0;

        // 计算订单中的原材料总体积、原材料总额、加工大板总面积、加工费
        // 选出订单中对破产生的新荒料对应的销售明细
        detailsWithSplittedBlock = this.getSplittedBlockDetail(
            details,
            so.orderType
        );

        const data: ISODQuantityAndTotalAmountData = this.getSODQuantityAndTotalAmount(
            details,
            orderType
        );

        totalAmountOfMaterial = data.totalAmountOfMaterial;
        totalAmountOfManufacture = data.totalAmountOfManufacture;
        quantityOfSplittedBlock = data.quantityOfSplittedBlock;
        quantityOfBlock = data.quantityOfBlock;
        quantityOfBundle = data.quantityOfBundle;
        deductedQuantityForBlock = data.deductedQuantityForBlock;
        deductedQuantityForBundle = data.deductedQuantityForBundle;

        if (orderType === 40) {
            demandArea = this.getTotalAreaForPolishedSlabNotInStock(msList);
        }

        // 大板销售均价
        const averageSoldPriceForBundle = this.getAverageSoldPrice(
            orderType,
            so.isInternalOrder,
            details,
            StoneArtifactType.StoneBundle
        );
        // 大板参考价均价
        const averageReferencePriceForBundle = this.getAverageReferencePrice(
            orderType,
            so.isInternalOrder,
            details,
            StoneArtifactType.StoneBundle
        );

        const blockDetailsWithOutSplittedBlock =
            orderType === 80
                ? this.getDetailWithOutSplittedBlock(details, so.orderType)
                : details;

        // 荒料销售均价
        const averageSoldPriceForBlock = this.getAverageSoldPrice(
            orderType,
            so.isInternalOrder,
            blockDetailsWithOutSplittedBlock,
            StoneArtifactType.Block
        );

        // 荒料参考价均价
        const averageReferencePriceForBlock = this.getAverageReferencePrice(
            orderType,
            so.isInternalOrder,
            blockDetailsWithOutSplittedBlock,
            StoneArtifactType.Block
        );

        const bundleDetailsNoRPCount = this.anyDetalesNoReferencePrice(
            orderType,
            so.isInternalOrder,
            details,
            StoneArtifactType.StoneBundle
        );
        const blockDetailsNoRPCount = this.anyDetalesNoReferencePrice(
            orderType,
            so.isInternalOrder,
            blockDetailsWithOutSplittedBlock,
            StoneArtifactType.Block
        );

        const showBundlePriceAndProfitInfo =
            enableReferencePrice &&
            canViewAmount &&
            detailsInBundle.length > 0 &&
            ([30].includes(orderType) ||
                (orderType === 40 && !so.isInternalOrder));

        const showBlockPriceAndProfitInfo =
            enableReferencePrice &&
            canViewAmount &&
            detailsInBlock.length > 0 &&
            [10, 80].includes(orderType);

        return (
            <Group.List lineIndent={15}>
                {showBundlePriceAndProfitInfo ? (
                    <div className="number-info-wrap">
                        {bundleDetailsNoRPCount > 0 ? (
                            <NumberInfo
                                label={
                                    <span
                                        style={{
                                            color: "red",
                                            fontSize: "16px"
                                        }}
                                    >
                                        {`有${bundleDetailsNoRPCount}扎大板无参考价，不参与计算均价和差额`}
                                    </span>
                                }
                            />
                        ) : null}
                        <NumberInfo label="大板销售均价（参考均价）">
                            <NumberItem
                                groupDigits={3}
                                spliter=","
                                number={averageSoldPriceForBundle}
                                unit="元"
                            />
                            <NumberItem
                                number={averageReferencePriceForBundle}
                                unit="元"
                                secondary={true}
                                positiveColor="#097BF7"
                            />
                        </NumberInfo>
                        <NumberInfo label="与参考总价差额">
                            <NumberItem
                                groupDigits={3}
                                spliter=","
                                number={this.getProfitAndLoss(
                                    orderType,
                                    so.isInternalOrder,
                                    details,
                                    StoneArtifactType.StoneBundle
                                )}
                                unit="元"
                                positiveColor="green"
                                negativeColor="red"
                                showSign={true}
                            />
                        </NumberInfo>
                    </div>
                ) : null}
                {showBlockPriceAndProfitInfo ? (
                    <div className="number-info-wrap">
                        {blockDetailsNoRPCount > 0 ? (
                            <NumberInfo
                                label={
                                    <span
                                        style={{
                                            color: "red",
                                            fontSize: "16px"
                                        }}
                                    >
                                        {`有${blockDetailsNoRPCount}颗荒料无参考价，不参与计算均价和差额`}
                                    </span>
                                }
                            />
                        ) : null}
                        <NumberInfo label="荒料销售均价（参考均价）">
                            <NumberItem
                                groupDigits={3}
                                spliter=","
                                number={averageSoldPriceForBlock}
                                unit="元"
                            />
                            <NumberItem
                                number={averageReferencePriceForBlock}
                                unit="元"
                                secondary={true}
                                positiveColor="#097BF7"
                            />
                        </NumberInfo>
                        <NumberInfo label="与参考总价差额">
                            <NumberItem
                                groupDigits={3}
                                spliter=","
                                number={this.getProfitAndLoss(
                                    orderType,
                                    so.isInternalOrder,
                                    details,
                                    StoneArtifactType.Block
                                )}
                                unit="元"
                                positiveColor="green"
                                negativeColor="red"
                                showSign={true}
                            />
                        </NumberInfo>
                        <NumberInfo label="荒料收料体积，入库体积，销售体积">
                            <NumberItem
                                number={data.totalQuarryReportedBlockVolume}
                            />
                            <NumberItem
                                number={data.totalValidatedBlockVolume}
                            />
                            <NumberItem
                                number={data.totalBlockVolumeToSell}
                                unit="立方"
                            />
                        </NumberInfo>
                        <NumberInfo label="荒料销售与收料差异，与入库差异">
                            <NumberItem
                                number={Util.round(
                                    data.totalBlockVolumeToSell -
                                        data.totalQuarryReportedBlockVolume,
                                    3
                                )}
                                showSign={true}
                                positiveColor="green"
                                negativeColor="red"
                            />
                            <NumberItem
                                number={Util.round(
                                    data.totalBlockVolumeToSell -
                                        data.totalValidatedBlockVolume,
                                    3
                                )}
                                unit="立方"
                                showSign={true}
                                positiveColor="green"
                                negativeColor="red"
                            />
                        </NumberInfo>
                    </div>
                ) : null}
                {detailsInBlock.length > 0 ? (
                    <Field label="荒料数量">
                        {detailsInBlock.length -
                            detailsWithSplittedBlock.length +
                            " 颗"}
                    </Field>
                ) : null}
                {quantityOfBlock === 0 ? null : (
                    <Field label="销售体积">
                        {data.totalBlockVolumeToSell} 立方{deductedQuantityForBlock >
                        0
                            ? " (" +
                              quantityOfBlock +
                              "扣方" +
                              deductedQuantityForBlock +
                              ")"
                            : ""}
                    </Field>
                )}
                {detailsWithSplittedBlock.length > 0 ? (
                    <Field label="对破新料">
                        {detailsWithSplittedBlock.length +
                            " 颗 | " +
                            quantityOfSplittedBlock +
                            " 立方"}
                    </Field>
                ) : null}
                {detailsInBundle.length > 0 ? (
                    <Field label="大板数量">
                        {detailsInBundle.length + " 扎"}
                    </Field>
                ) : null}
                {quantityOfBundle > 0 ? (
                    quantityOfBundle === 0 ? null : deductedQuantityForBundle >
                    0 ? (
                        <Field label="大板总计">
                            {quantityOfBundle} 平方 (扣：{
                                deductedQuantityForBundle
                            }平方)
                        </Field>
                    ) : (
                        <Field label="大板总计">{quantityOfBundle} 平方</Field>
                    )
                ) : null}
                {canViewAmount && totalAmountOfMaterial > 0 ? (
                    <ESNumberField
                        label={"荒料总额(元)"}
                        value={totalAmountOfMaterial.toString()}
                    />
                ) : null}
                {canViewAmount && totalAmountOfManufacture > 0 ? (
                    <ESNumberField
                        label={bundleAmountLable}
                        value={totalAmountOfManufacture.toString()}
                    />
                ) : null}
                {canViewAmount &&
                Util.isDefinedAndNotNull(totalAmountOfAppliedPrices) &&
                totalAmountOfAppliedPrices !== 0 ? (
                    <ESNumberField
                        label={"其它收费(元)"}
                        value={totalAmountOfAppliedPrices.toString()}
                    />
                ) : null}
                {!(so.isInternalOrder || so.orderType === 60) && isEditPage ? (
                    <ESNumberField
                        label={"总金额(元)"}
                        value={this.getTotalAmount(
                            so,
                            details,
                            appliedPrices
                        ).toString()}
                    />
                ) : null}
                {canViewAmount &&
                !so.isInternalOrder &&
                so.totalAmount !== null &&
                !isEditPage ? (
                    <ESNumberField
                        label={"总金额(元)"}
                        value={so.totalAmount.toString()}
                    />
                ) : null}
                {orderType === 40 ? (
                    <Field label="需求面积">{demandArea + " 平方"}</Field>
                ) : null}
                {canViewAmount && orderType === 40 && !so.isInternalOrder ? (
                    <ESNumberField
                        label={"预计金额(元)"}
                        value={this.getMSTotalAmount(so, msList).toString()}
                    />
                ) : null}
                {canViewAmount &&
                so.status !== SalesOrderStatus.Cancelled &&
                !so.isInternalOrder &&
                so.chargedAmount !== null &&
                !isEditPage ? (
                    <ESNumberField
                        label={"结算金额(元)"}
                        value={so.chargedAmount.toString()}
                    />
                ) : null}
            </Group.List>
        );
    }

    public getTotalAmount(
        so: ISalesOrdeForQuantityAndAmount,
        details: ISalesOrderDetailDTO[],
        appliedPrices: IAppliedPriceItemDTO[]
    ): number {
        const t = this;
        let total = 0;
        const orderType = so.orderType;

        if (
            ([10, 30, 80].includes(orderType) ||
                (orderType === 40 && !so.isInternalOrder)) &&
            (details && details.length > 0)
        ) {
            details.forEach((d, index) => {
                total += this.getSODSubTotal(index, details);
            });
        }

        if (!so.isInternalOrder) {
            const otherPrices = this.getTotalAppliedPrices(appliedPrices);
            total += otherPrices;
        }

        return Util.round(total, 2);
    }

    public getAverageSoldPrice(
        orderType: SalesOrderType,
        isInternalOrder: boolean,
        details: ISalesOrderDetailDTO[],
        type: StoneArtifactType
    ): number {
        let subTotal = 0;
        let quantity = 0;

        if (
            ([10, 30, 80].includes(orderType) ||
                (orderType === 40 && !isInternalOrder)) &&
            (details && details.length > 0)
        ) {
            details.forEach((d, index) => {
                const referencePrice = this.getPriceInfo(d);
                if (
                    d.type === type &&
                    Util.isDefinedAndNotNull(referencePrice) &&
                    referencePrice > 0
                ) {
                    subTotal += Util.calculateSubTotal(
                        d.quantity,
                        d.deductedQuantity,
                        d.price
                    );
                    quantity += d.quantity - d.deductedQuantity;
                }
            });
        }

        const averagePrice = quantity === 0 ? 0 : subTotal / quantity;

        return Util.round(
            Util.isNotNullAndNotNaN(averagePrice) ? averagePrice : 0,
            2
        );
    }

    public getAverageReferencePrice(
        orderType: SalesOrderType,
        isInternalOrder: boolean,
        details: ISalesOrderDetailDTO[],
        type: StoneArtifactType
    ): number {
        let subTotal = 0;
        let quantity = 0;

        if (
            ([10, 30, 80].includes(orderType) ||
                (orderType === 40 && !isInternalOrder)) &&
            (details && details.length > 0)
        ) {
            details.forEach((d, index) => {
                const referencePrice = this.getPriceInfo(d);
                if (
                    d.type === type &&
                    Util.isDefinedAndNotNull(referencePrice) &&
                    referencePrice > 0
                ) {
                    quantity += d.quantity - d.deductedQuantity;
                    subTotal += Util.calculateSubTotal(
                        d.quantity,
                        d.deductedQuantity,
                        referencePrice
                    );
                }
            });
        }

        const averageReferencePrice = quantity === 0 ? 0 : subTotal / quantity;

        return Util.round(
            Util.isNotNullAndNotNaN(averageReferencePrice)
                ? averageReferencePrice
                : 0,
            2
        );
    }

    public getProfitAndLoss(
        orderType: SalesOrderType,
        isInternalOrder: boolean,
        details: ISalesOrderDetailDTO[],
        type: StoneArtifactType
    ): number {
        let markSubTotal = 0;
        let soldSubTotal = 0;

        if (
            ([10, 30, 80].includes(orderType) ||
                (orderType === 40 && !isInternalOrder)) &&
            (details && details.length > 0)
        ) {
            details.forEach((d, index) => {
                const referencePrice = this.getPriceInfo(d);
                if (
                    d.type === type &&
                    Util.isDefinedAndNotNull(referencePrice) &&
                    referencePrice > 0
                ) {
                    soldSubTotal += Util.calculateSubTotal(
                        d.quantity,
                        d.deductedQuantity,
                        d.price
                    );
                    markSubTotal += Util.calculateSubTotal(
                        d.quantity,
                        d.deductedQuantity,
                        referencePrice
                    );
                }
            });
        }

        const profitAndLoss = soldSubTotal - markSubTotal;

        return Util.round(profitAndLoss, 2);
    }

    public getSODQuantityAndTotalAmount(
        details: ISalesOrderDetailDTO[],
        orderType: number
    ): ISODQuantityAndTotalAmountData {
        if (typeof details === "undefined" || details === null) {
            return null;
        }

        const detailsInBlock = StoneArtifactUtil.getBlockItems(details);
        const detailsInBundle = StoneArtifactUtil.getBundleItems(details);
        let quantityOfBlock = 0; // 销售荒料代加工订单原材料总体积
        let quantityOfSplittedBlock = 0; // 销售荒料代加工订单对破产生的新荒料总体积
        let quantityOfBundle = 0; // 销售荒料代加工订单加工的大板总面积
        let detailsWithSplittedBlock = []; // 存放销售荒料代加工订单的销售明细中的是对破产生的新荒料的block明细
        let totalAmountOfMaterial = 0; // 销售荒料代加工订单的原材料总额
        let totalAmountOfManufacture = 0; // 销售荒料代加工订单的加工费总额
        let deductedQuantityForBundle = 0;
        let deductedQuantityForBlock = 0;

        // Variables for different block dimensions
        let totalQuarryReportedBlockVolume = 0;
        let totalValidatedBlockVolume = 0;
        let totalBlockVolumeToSell = 0;

        // 计算订单中的原材料总体积、原材料总额、加工大板总面积、加工费
        // 选出订单中对破产生的新荒料对应的销售明细
        detailsWithSplittedBlock = this.getSplittedBlockDetail(
            details,
            orderType
        );
        detailsInBlock.forEach(db => {
            // 此处逻辑应该在分享订单的info界面做同样调整，但由于后端返回数据不支持，暂时不调整
            if (!detailsWithSplittedBlock.includes(db)) {
                totalAmountOfMaterial = totalAmountOfMaterial + db.subTotal;
                quantityOfBlock = quantityOfBlock + db.quantity;
                deductedQuantityForBlock =
                    deductedQuantityForBlock + db.deductedQuantity;

                let block = db.block;
                totalQuarryReportedBlockVolume += Util.calculateVolume(
                    block.quarryReportedLength,
                    block.quarryReportedWidth,
                    block.quarryReportedHeight
                );
                totalValidatedBlockVolume += Util.calculateVolume(
                    block.validatedLength,
                    block.validatedWidth,
                    block.validatedHeight
                );
            } else {
                quantityOfSplittedBlock = quantityOfSplittedBlock + db.quantity;
            }
        });

        detailsInBundle.forEach(db => {
            totalAmountOfManufacture = totalAmountOfManufacture + db.subTotal;
            quantityOfBundle = quantityOfBundle + db.quantity;
            deductedQuantityForBundle =
                deductedQuantityForBundle + db.deductedQuantity;
        });

        totalAmountOfMaterial = Util.round(totalAmountOfMaterial, 3);
        totalAmountOfManufacture = Util.round(totalAmountOfManufacture, 3);
        quantityOfSplittedBlock = Util.round(quantityOfSplittedBlock, 3);
        quantityOfBlock = Util.round(quantityOfBlock, 3);
        quantityOfBundle = Util.round(quantityOfBundle, 3);
        deductedQuantityForBlock = Util.round(deductedQuantityForBlock, 3);
        deductedQuantityForBundle = Util.round(deductedQuantityForBundle, 3);

        totalQuarryReportedBlockVolume = Util.round(
            totalQuarryReportedBlockVolume,
            3
        );
        totalValidatedBlockVolume = Util.round(totalValidatedBlockVolume, 3);
        totalBlockVolumeToSell = Util.round(
            quantityOfBlock - deductedQuantityForBlock,
            3
        );

        const data: ISODQuantityAndTotalAmountData = {
            totalAmountOfMaterial,
            totalAmountOfManufacture,
            quantityOfSplittedBlock,
            quantityOfBlock,
            quantityOfBundle,
            deductedQuantityForBlock,
            deductedQuantityForBundle,
            totalBlockVolumeToSell,
            totalQuarryReportedBlockVolume,
            totalValidatedBlockVolume
        };
        return data;
    }

    public getPriceInfo(sod): number | null {
        if (!sod) {
            return null;
        }

        // 当销售明细上存在参考价，说明是已被添加到销售订单中的，直接返回此参考价
        if (Util.isDefinedAndNotNull(sod.referencePrice)) {
            return sod.referencePrice;
        }

        let pi = null;

        // 对于新建销售订单、或编辑销售订单时添加的新的销售明细，其界面显示的参考价是扎或荒料上的参考价
        switch (sod.type) {
            case StoneArtifactType.Block:
                if (
                    Util.isDefinedAndNotNull(sod.block) &&
                    Util.isDefinedAndNotNull(sod.block.unitPrice)
                ) {
                    pi = sod.block.unitPrice;
                }
                break;
            case StoneArtifactType.StoneBundle:
                if (
                    Util.isDefinedAndNotNull(sod.bundle) &&
                    Util.isDefinedAndNotNull(sod.bundle.unitPrice)
                ) {
                    pi = sod.bundle.unitPrice;
                }
                break;
        }

        return pi;
    }

    private anyDetalesNoReferencePrice(
        orderType: SalesOrderType,
        isInternalOrder: boolean,
        details: ISalesOrderDetailDTO[],
        type: StoneArtifactType
    ): number {
        let noRPDetailCount = 0;

        if (
            !Util.isDefinedAndNotNull(orderType) ||
            (Util.isDefinedAndNotNull(orderType) &&
                ([10, 30, 80].includes(orderType) ||
                    (orderType === 40 && !isInternalOrder)) &&
                (details && details.length > 0))
        ) {
            details.forEach((d, index) => {
                const referencePrice = this.getPriceInfo(d);
                if (
                    d.type === type &&
                    !(
                        Util.isDefinedAndNotNull(referencePrice) &&
                        referencePrice > 0
                    )
                ) {
                    noRPDetailCount = noRPDetailCount + 1;
                }
            });
        }

        return noRPDetailCount;
    }

    private getSODSubTotal(
        index: number,
        details: ISalesOrderDetailDTO[]
    ): number {
        if (typeof index === "undefined" || index === null) {
            return 0;
        }
        const sod = details[index];

        if (!Util.isDefinedAndNotNull(sod)) {
            return 0;
        }

        return Util.calculateSubTotal(
            sod.quantity,
            sod.deductedQuantity,
            sod.price
        );
    }

    private getTotalAreaForPolishedSlabNotInStock(
        materialSpecs: ISalesOrderMaterialSpecDTO[]
    ): number {
        let area = 0;
        if (typeof materialSpecs === "undefined" || materialSpecs === null) {
            return area;
        }
        materialSpecs.forEach(m => {
            if (typeof m.area !== "undefined" && m.area !== null) {
                area += m.area;
            }
        });
        area = Util.round(area, 3);

        return area;
    }
}

export default new SalesOrderUtil();
