import * as React from "react";
import { Group } from "saltui";
import { IAppliedProcessDTO } from "../../app/WebAPIClients";
import AppliedProcessInfo from "../AppliedProcessInfo";
import ESHeader from "../ESHeader";
import Util from "../Util";

declare interface IAppliedProcessInfoListProps
    extends React.Props<AppliedProcessInfoList> {
    appliedProcesses: IAppliedProcessDTO[];
    showHeader?: boolean;
    label?: string;
    extraInfo?: JSX.Element;
}

class AppliedProcessInfoList extends React.Component<
    IAppliedProcessInfoListProps,
    {}
> {
    public static defaultProps: IAppliedProcessInfoListProps;
    public render() {
        const t = this;
        const { appliedProcesses, showHeader, label, extraInfo } = t.props;

        return (
            <div>
                <Group>
                    {!showHeader ? null : (
                        <ESHeader
                            label={
                                Util.isNotNullAndNotEmpty(label)
                                    ? label
                                    : "工艺列表"
                            }
                        />
                    )}
                    <Group.List lineIndent={15}>
                        {Util.isNotNullAndNotEmptyArray(appliedProcesses) ? (
                            appliedProcesses.map(p => {
                                return (
                                    <AppliedProcessInfo
                                        key={p.id}
                                        appliedProcess={p}
                                    />
                                );
                            })
                        ) : (
                            <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                                没有数据
                            </div>
                        )}
                        {extraInfo}
                    </Group.List>
                </Group>
            </div>
        );
    }
}

AppliedProcessInfoList.defaultProps = {
    appliedProcesses: [],
    showHeader: false,
    label: "",
    extraInfo: null
};

export default AppliedProcessInfoList;
