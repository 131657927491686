import * as React from "react";
import { Field, Group } from "saltui";
import SettingsDefinition from "../../components/SettingsDefinition";
import Collapsible from "../Collapsible";
import Settings from "../SettingInfo";

export interface ICollapsibleWOInfoProps
    extends React.Props<CollapsibleWOInfo> {
    blockNumber?: string;
    category: string;
    thickness: number;
    blockNumberFromQuarry: string;
}

export interface ICollapsibleWOInfoState {
    enableBlockNumberFromQuarry: boolean;
}

class CollapsibleWOInfo extends React.Component<
    ICollapsibleWOInfoProps,
    ICollapsibleWOInfoState
> {
    public static defaultProps: ICollapsibleWOInfoProps;
    constructor(props: ICollapsibleWOInfoProps) {
        super(props);
        this.state = {
            enableBlockNumberFromQuarry: Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            )
        };
    }

    public render() {
        const t = this;
        const s = t.state;

        const {
            blockNumber,
            category,
            thickness,
            blockNumberFromQuarry,
            ...other
        } = t.props;

        return (
            <Collapsible label={"生产信息"} expanded={true}>
                <Group>
                    <Group.List lineIndent={15}>
                        {blockNumber === null ? null : (
                            <Field label="荒料编号">
                                <span className="importantInfo">
                                    {blockNumber}
                                </span>
                            </Field>
                        )}
                        {s.enableBlockNumberFromQuarry &&
                        blockNumberFromQuarry !== null &&
                        typeof blockNumberFromQuarry !== "undefined" ? (
                            <Field label="矿山荒料号">
                                <span className="importantInfo">
                                    {blockNumberFromQuarry}
                                </span>
                            </Field>
                        ) : null}
                        <Field label="石材种类">
                            <span className="importantInfo">{category}</span>
                        </Field>
                        <Field label="生产厚度">
                            <span className="importantInfo">
                                {thickness + " 毫米"}
                            </span>
                        </Field>
                    </Group.List>
                </Group>
            </Collapsible>
        );
    }
}

CollapsibleWOInfo.defaultProps = {
    blockNumber: "",
    category: "",
    thickness: null,
    blockNumberFromQuarry: ""
};

export default CollapsibleWOInfo;
