﻿import {
  IAppliedPriceItemViewModel,
  IAppliedProcessDTO,
  IBlockAppliedProcessDTO,
  IBlockForListDTO,
  IBundleForListDTO,
  ICreateOrUpdateAppliedPriceItemWithSalesRepDto,
  ISalesOrderDetailDTO,
  ISalesOrderDTO,
  ISalesOrderForListDTO,
  ISalesOrderMaterialSpecViewModel,
  ISalesOrderSelectedProcessViewModel,
  ISlabDTO,
  ITileAreaCountByAreaNameDTO,
  ITileDTO,
  ITilePackingCaseForListDTO,
  ITransferDetailDTO,
} from "../app/WebAPIClients";

export declare interface IPriceQuantityTextItem {
  priceText?: string;
  deductedQuantityText?: string;
}

export declare interface ISalesOrderDetailForUI
  extends ISalesOrderDetailDTO,
    IPriceQuantityTextItem {}

export declare interface ISalesOrderDetailForBlockSelector
  extends Pick<
    ISalesOrderDetailForUI,
    | "blockId"
    | "block"
    | "quantity"
    | "deductedQuantity"
    | "price"
    | "type"
    | "subTotal"
    | "priceText"
    | "deductedQuantityText"
  > {}

export declare interface ISalesOrderDetailForBundleSelector
  extends Pick<
    ISalesOrderDetailForUI,
    | "bundleId"
    | "bundle"
    | "quantity"
    | "deductedQuantity"
    | "price"
    | "type"
    | "subTotal"
    | "priceText"
    | "deductedQuantityText"
  > {}

export declare interface ICostPriceSpec {
  thickness?: number;
  unitCost: number;
  unitPrice: number;
}

export declare interface ISOSelectedProcessSelector
  extends Pick<ISalesOrderSelectedProcessViewModel, "name" | "unit" | "notes"> {
  costPriceSpecs: ICostPriceSpec[];
  usedByTiles: boolean;
}

export declare interface ISalesOrderMaterialSpecSelector
  extends Pick<
    ISalesOrderMaterialSpecViewModel,
    | "categoryId"
    | "gradeId"
    | "type"
    | "thickness"
    | "dimensionRequirements"
    | "qualityRequirements"
    | "price"
    | "notes"
    | "manufacturingMethod"
  > {
  area?: number;
  usedByTiles: boolean;
}

export declare interface ITransferDetailForUI
  extends Partial<ITransferDetailDTO> {
  priceText?: string;
}

export declare interface IBundleForListWithSlabForUI extends IBundleForListDTO {
  slabs?: ISlabDTO[];
}

export declare interface ITilePackingCaseForListWithTileForUI
  extends ITilePackingCaseForListDTO {
  tiles?: ITileDTO[];
}

export declare interface ITileAreaCountByAreaNameWithTileForUI
  extends ITileAreaCountByAreaNameDTO {
  tiles?: ITileDTO[];
}

export declare interface IAppliedPriceItemViewModelForUI
  extends ICreateOrUpdateAppliedPriceItemWithSalesRepDto {
  subtotal?: number;
}

export declare interface ISalesOrderForListForUI extends ISalesOrderForListDTO {
  moreSpecs?: boolean;
  showMoreSpecs?: boolean;
}

export declare interface ISalesOrdeForQuantityAndAmount
  extends Pick<
    ISalesOrderDTO,
    | "orderType"
    | "totalAmount"
    | "chargedAmount"
    | "isInternalOrder"
    | "materialSpecs"
    | "status"
  > {}

export declare interface IBundleFullInfoForUI extends IBundleForListDTO {
  slabs?: ISlabForUI[];
}
export declare interface ISlabForUI extends ISlabDTO {
  discardedImageNames?: string;
  normalImageNames?: string;
  discarded?: boolean;
}

export declare interface IBlockForListDTOWithTrimmingDetail
  extends IBlockForListDTO {
  trimmingDetails: IBlockAppliedProcessDTO[];
}

export declare interface IAppliedProcessGroupByKey
  extends Pick<
    IAppliedProcessDTO,
    "startTime" | "endTime" | "machineryId" | "operatorName"
  > {}

export declare interface IAppliedProcessGroupByItem
  extends IAppliedProcessGroupByKey {
  appliedProcesses: IAppliedProcessDTO[];
}

export declare interface IBlockSelector {
  blockId: number;
}

export enum BlockDimensionType {
  /**
   * 收方尺寸
   */
  QuarryReported = "收方",

  /**
   * 入库尺寸
   */
  Validated = "入库",

  /**
   * 修边尺寸
   */
  Trimmed = "修边",
}

export enum TransferOrderType {
  /**
   * 荒料
   */
  Block = 10,

  /**
   * 产品
   */
  Product = 20,
}

export enum ReferencePriceType {
  /**
   * 荒料
   */
  Block = 10,

  /**
   * 扎
   */
  StoneBundle = 20,

  /**
   * 大板
   */
  Slab = 30,

  /**
   * 销售明细
   */
  SalesOrderDetail = 40,
}

/** 参考价显示尺寸 */
export enum ReferencePriceSize {
  /** 正常 */
  Normal,

  /** 小尺寸 */
  Small,
}

/** 图片尺寸 */
export enum ImageSize {
  /** 略缩图 */
  Thumbnail,

  /** 小尺寸图 */
  SmallSize,

  /** 全尺寸图片 */
  FullSize,
}
