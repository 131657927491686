class ESLogger {
    private static logger: ESLogger = null;
    private enabled: boolean;
    private canvas: HTMLDivElement;

    constructor() {
        if (ESLogger.logger === null) {
            ESLogger.logger = this;

            this.enabled = false;
            this.canvas = document.createElement("div");
            this.canvas.setAttribute("id", "esLogger");
        }

        return ESLogger.logger;
    }

    public i(info: string): void {
        if (this.enabled) {
            this.addLog(info, "i");
        }
    }

    public e(err: string): void {
        if (this.enabled) {
            this.addLog(err, "e");
        }
    }

    public clearLogs(): void {
        this.canvas.innerHTML = "";
    }

    public enable(): void {
        this.enabled = true;
        this.canvas.addEventListener("dblclick", this.clearLogs);
        document.body.appendChild(this.canvas);
    }

    public disable(): void {
        this.enabled = false;
        this.clearLogs();
        this.canvas.removeEventListener("dblclick", this.clearLogs);
        document.body.removeChild(this.canvas);
    }

    private addLog(msg: string, logType: string): void {
        const r = document.createElement("div");
        r.setAttribute("class", "log-row log-" + logType);
        r.innerHTML = msg;
        this.canvas.appendChild(r);
    }
}

export default new ESLogger();
