import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../app/WebAPIClientFactory";
import { SlabChargeableStructureDTO } from "../../app/WebAPIClients";
import Base from "../../store/base";
class Store extends Base {
    @observable
    public slabChargeableStructures: SlabChargeableStructureDTO[] = [];

    @action
    public async onLoadBundleSalesPriceItems({
        bundleId,
        customerId,
        salesOrderId
    }: {
        bundleId: number;
        customerId: number;
        salesOrderId: number;
    }): Promise<void> {
        const t = this;
        const bundleClient = ClientFactory.getStoneBundleClient();
        const callAPI = async () => {
            const content = await bundleClient.getBundleSalesPriceItems(
                bundleId,
                null,
                customerId,
                salesOrderId
            );

            runInAction(() => {
                this.slabChargeableStructures = content;
            });
        };
        await this.callAPIFunForLoadData(callAPI);
    }
}

export default new Store();
