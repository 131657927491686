import * as React from "react";
import {
  IBundleForListDTO,
  IBundleWithSlabsForListDTO,
  ISlabForListDTO,
} from "../app/WebAPIClients";
import Consts from "./Consts";
import SlabUtil from "./SlabUtil";
import Util from "./Util";

const { SlabBundleStatus: SlabBundleStatusOptions } = Consts;
class StoneBundleUtil {
  public getStoneBundleStatus(
    status: number,
    manufacturingState: number,
  ): JSX.Element {
    if (
      !Util.isDefinedAndNotNull(status) &&
      !Util.isDefinedAndNotNull(manufacturingState)
    ) {
      return <span />;
    }

    const stoneBundleName =
      status === 10 && manufacturingState === 90
        ? "等待入库"
        : SlabBundleStatusOptions.getStatus(status).text;

    let className = this.getStatusColorCode(status);
    return <span className={className}>{stoneBundleName}</span>;
  }

  // 用于计算工程板领料单实际领出的单扎大板的面积
  // bundle：工程板领料单中只包含实际领出大板信息
  // 返回 - 面积area
  public calculateAreaForBundle(bundle: IBundleWithSlabsForListDTO): number {
    let area = 0;

    if (typeof bundle === "undefined" || bundle === null) {
      return area;
    }

    bundle.slabs.forEach((s: ISlabForListDTO) => {
      area += SlabUtil.calculateAreaForSlab(s);
    });

    return Util.round(area, 3);
  }

  // 用于计算工程板领料单实际领出整扎大板的面积
  // bundles：工程板领料单中只包含实际领出整扎大板信息
  // 返回 - 面积area
  public calculateAreaForBundleList(bundles: IBundleWithSlabsForListDTO[]) {
    let area = 0;
    const t = this;

    if (
      typeof bundles === "undefined" ||
      bundles === null ||
      !bundles.length ||
      bundles.length === 0
    ) {
      return area;
    }

    bundles.forEach((b) => {
      area += this.calculateAreaForBundle(b);
    });
    return Util.round(area, 3);
  }

  public getBundleInfoForList(
    bundle: IBundleForListDTO,
    categoryList: IIdNameItem[],
    gradeList: IIdNameItem[],
    showGrade: boolean = true,
  ): IListItem {
    if (!bundle) {
      return Util.getEmptyListItemData();
    }

    return {
      title: this.getBundleTitle(bundle),
      text: this.getBundleSpec(bundle, categoryList, gradeList, showGrade),
    };
  }

  public getBundleNo(bundle: IBundleForListDTO): string {
    let title = "";
    const prefix = bundle.prefix;
    if (prefix) {
      title += prefix + " #";
    }

    title += bundle.totalBundleCount + "-" + bundle.bundleNo;
    return title;
  }

  public getBundleTitle(bundle: IBundleForListDTO): string {
    if (!bundle) {
      return "";
    }

    const bundleNo = this.getBundleNo(bundle);
    let title = bundleNo + " | 厚：" + bundle.thickness;

    if (bundle.totalSlabCount > 0) {
      title += " | " + bundle.totalSlabCount + "片";
    }

    return title;
  }

  public getBundleTitleWithCountFromSlabs(bundle: IBundleWithSlabsForListDTO) {
    const t = this;
    if (!bundle) {
      return "";
    }
    const bundleNo = this.getBundleNo(bundle);
    let title = bundleNo + " | 厚：" + bundle.thickness;
    title += " | " + bundle.slabs.length + "片";

    return title;
  }

  public getBundleSpec(
    bundle: IBundleForListDTO,
    categoryList: IIdNameItem[],
    gradeList: IIdNameItem[],
    showGrade: boolean = true,
  ): string {
    const cateName = Util.getItemName(bundle.categoryId, categoryList);
    const gradeName = Util.getItemName(bundle.gradeId, gradeList);
    return `${cateName} | ${showGrade ? gradeName + " | " : ""}${
      bundle.lengthAfterStockIn
    } | ${bundle.widthAfterStockIn} | ${bundle.area}平方`;
  }

  public getBundleSpecWithAreaFromSlabs(
    bundle: IBundleWithSlabsForListDTO,
    categoryList: IIdNameItem[],
    gradeList: IIdNameItem[],
    showGrade: boolean = true,
  ) {
    const cateName = Util.getItemName(bundle.categoryId, categoryList);
    const gradeName = Util.getItemName(bundle.gradeId, gradeList);
    let area = 0;
    area = this.calculateAreaForBundle(bundle);
    return `${cateName} | ${showGrade ? gradeName + " | " : ""}${
      bundle.lengthAfterStockIn
    } | ${bundle.widthAfterStockIn} | ${area}平方`;
  }

  public getStatusColorCode(status: number): string {
    let className = "";
    switch (status) {
      case 10:
        className = "stoneBundleManufacturing";
        break;
      case 20:
        className = "stoneBundleInStock";
        break;
      case 30:
        className = "stoneBundleCheckedOut";
        break;
      case 40:
        className = "stoneBundleSold";
        break;
      case 50:
      case 51:
      case 52:
        className = "stoneBundleReserved";
        break;
      case 60:
        className = "stoneBundleDiscarded";
        break;
    }
    return className;
  }
}

export default new StoneBundleUtil();
