import { ISlabSalesPriceDTO, SlabType } from "../../app/WebAPIClients";
import ClientFactory from "../../app/WebAPIClientFactory";
import StoneGradeStore from "../../store/StoneGradeStore";

class SlabGradeUnitPriceStore {
  private static instance: SlabGradeUnitPriceStore;
  private slabSalesUnitPriceCache: ISlabSalesPriceDTO[] = [];

  private constructor() {}

  public static getInstance(): SlabGradeUnitPriceStore {
    if (!SlabGradeUnitPriceStore.instance) {
      SlabGradeUnitPriceStore.instance = new SlabGradeUnitPriceStore();
    }
    return SlabGradeUnitPriceStore.instance;
  }

  public async getUnitPrice(
    factoryId: number,
    categoryId: number,
    gradeId: number,
    thickness: number,
    type: SlabType,
  ): Promise<number | null> {
    let salesPrice = this.getSalesPriceFromCache(
      factoryId,
      categoryId,
      gradeId,
      thickness,
      type,
    );

    if (salesPrice) {
      return salesPrice.unitPrice;
    }

    await this.loadAndCacheUnitPricesForGrades(
      factoryId,
      categoryId,
      thickness,
      type,
    );

    salesPrice = this.getSalesPriceFromCache(
      factoryId,
      categoryId,
      gradeId,
      thickness,
      type,
    );

    return salesPrice.unitPrice;
  }

  private async loadAndCacheUnitPricesForGrades(
    factoryId: number,
    categoryId: number,
    thickness: number,
    type: SlabType,
  ): Promise<void> {
    const grades = await StoneGradeStore.getGrades();
    const gradeIds = grades.map((g) => g.id);

    const client = ClientFactory.getUnitPriceClient();
    const response = await client.getSlabSalesUnitPrice(
      factoryId,
      categoryId,
      gradeIds,
      thickness,
      type,
    );

    this.slabSalesUnitPriceCache.push(...response);
  }

  private getSalesPriceFromCache(
    factoryId: number,
    categoryId: number,
    gradeId: number,
    thickness: number,
    type: SlabType,
  ): ISlabSalesPriceDTO | null {
    const unitPrice = this.slabSalesUnitPriceCache.find(
      (c) =>
        c.factoryId === factoryId &&
        c.categoryId === categoryId &&
        c.gradeId === gradeId &&
        c.thickness === thickness &&
        c.slabType === type,
    );

    return unitPrice ?? null;
  }
}

export default SlabGradeUnitPriceStore.getInstance();
