import React from "react";
import { Boxs } from "saltui";
import { ISalesOrderMaterialSpecDTO } from "../../app/WebAPIClients";
import Consts from "../Consts";
import ESIcon from "../ESIcon";
import ListItem from "../ListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";
import { ISalesOrderMaterialSpecSelector } from "../type";
import Util from "../Util";

const { HBox, Box } = Boxs;
const { ManufacturingMethod } = Consts;

declare interface IMaterialSpecListItemProps extends IListItemBaseProps {
    materialSpec?: ISalesOrderMaterialSpecSelector | ISalesOrderMaterialSpecDTO;
    orderType?: number;
    showPrice?: boolean;
    workOrderLinked?: boolean;
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
    slabTypeOptions?: IOption[];
}

class MaterialSpecListItem extends React.PureComponent<
    IMaterialSpecListItemProps
> {
    public static defaultProps: IMaterialSpecListItemProps;
    public render() {
        const t = this;

        const {
            materialSpec,
            orderType,
            showPrice,
            workOrderLinked,
            allowDeletion,
            allowSelection,
            selected,
            categoryList,
            gradeList,
            slabTypeOptions,
            ...other
        } = t.props;

        const ms = Util.getMaterialSpecTitle(
            materialSpec,
            categoryList,
            gradeList,
            slabTypeOptions
        );
        const className =
            workOrderLinked || allowDeletion || allowSelection
                ? "materialSpecSeparatorWithIcon"
                : "materialSpecSeparator";

        let priceJSX = null;
        if (showPrice) {
            if (orderType !== 60 && orderType !== 80) {
                priceJSX = (
                    <HBox>
                        <Box className="unitPriceLabelForPolishedSlabNotInStock">
                            单价：
                        </Box>
                        <Box>
                            <span className="unitPriceLabelForPolishedSlabNotInStock unitPriceText">
                                ￥{materialSpec.price}
                            </span>
                        </Box>
                        <Box className="deductedReasonLabel">面积：</Box>
                        <Box>
                            <span className="deductedReasonLabel unitPriceText">
                                {materialSpec.area}平方
                            </span>
                        </Box>
                    </HBox>
                );
            } else {
                priceJSX = (
                    <p>
                        单价：<span className="unitPriceText">
                            ￥{materialSpec.price}
                        </span>
                    </p>
                );
            }
        } else if (orderType !== 60 && orderType !== 80) {
            priceJSX = (
                <HBox>
                    <Box className="unitPriceLabelForPolishedSlabNotInStock">
                        面积：
                    </Box>
                    <Box>
                        <span className="unitPriceLabelForPolishedSlabNotInStock unitPriceText">
                            {materialSpec.area}平方
                        </span>
                    </Box>
                </HBox>
            );
        }

        if (priceJSX) {
            priceJSX = <div className={className}>{priceJSX}</div>;
        }

        let manufacturingMethod = null;
        if (materialSpec.manufacturingMethod !== null) {
            manufacturingMethod = ManufacturingMethod.getManufacturingMethod(
                materialSpec.manufacturingMethod
            ).text;
        }

        const title = (
            <HBox flex={1}>
                <Box className="demo3-t-list-text-content" flex={1}>
                    <p className="demo3-t-list-title t-omit">{ms}</p>
                    {priceJSX}
                    {showPrice && orderType !== 60 && orderType !== 80 ? (
                        <p className={className}>
                            小计：<span className="deductedReasonLabel unitPriceText">
                                ￥{Util.getSubTotalForMaterialSpec(
                                    materialSpec
                                )}
                            </span>
                        </p>
                    ) : null}
                    {materialSpec.dimensionRequirements !== null &&
                    materialSpec.dimensionRequirements !== "" ? (
                        <p className={className}>
                            规格要求：{materialSpec.dimensionRequirements}
                        </p>
                    ) : null}
                    {materialSpec.qualityRequirements !== null &&
                    materialSpec.qualityRequirements !== "" ? (
                        <p className={className}>
                            质量要求：{materialSpec.qualityRequirements}
                        </p>
                    ) : null}
                    {typeof manufacturingMethod !== "undefined" &&
                    manufacturingMethod !== null ? (
                        <p className={className}>
                            补磨方式：{manufacturingMethod}
                        </p>
                    ) : null}
                    {materialSpec.notes !== null &&
                    materialSpec.notes !== "" ? (
                        <p className={className}>备注：{materialSpec.notes}</p>
                    ) : null}
                </Box>
            </HBox>
        );

        let icon = null;
        if (workOrderLinked) {
            icon = (
                <ESIcon
                    name="icon-showWO"
                    fill="#1E90FF"
                    className="icon-is iconField"
                    height={20}
                    width={20}
                />
            );
        }

        const avatarTitle = Util.getMaterialSpecAvatarTitle(
            materialSpec,
            categoryList
        );

        return (
            <ListItem
                avatarTitle={avatarTitle}
                title={title}
                icon={icon}
                item={materialSpec}
                allowDeletion={!workOrderLinked && allowDeletion}
                allowSelection={allowSelection}
                selected={selected}
                onDelete={t.props.onDelete}
                onClick={t.props.onClick}
                onSelect={t.props.onSelect}
            />
        );
    }
}

MaterialSpecListItem.defaultProps = {
    materialSpec: null,
    orderType: null,
    showPrice: false,
    workOrderLinked: false,
    allowDeletion: false,
    allowSelection: false,
    selected: false,
    categoryList: [],
    gradeList: [],
    slabTypeOptions: [],
    onClick: () => {},
    onDelete: () => {},
    onSelect: () => {}
};

export default MaterialSpecListItem;
