import * as React from "react";
import { DatetimeField, Group } from "saltui";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import DateUtil from "../DateUtil";
import ESPickerField from "../ESPickerField";
import ESTextField from "../ESTextField";
import FilterButtonGroup from "../FilterButtonGroup";
import Util from "../Util";
const { ApprovalStatus } = Consts;

declare interface IBlockTrimmingRecordListFilterProps
    extends IFilterProps<IBlockTrimmingRecordListFilterData> {
    showOperatorName?: boolean;
}

export interface IBlockTrimmingRecordListFilterData {
    blockNumber?: string;
    selectedStatusOptions?: IOption[];
    startDate?: string;
    endDate?: string;
    operatorName?: string;
}

declare interface IBlockTrimmingRecordListFilterState
    extends IBlockTrimmingRecordListFilterData {
    statusOptions: IOption[];
}

class BlockTrimmingRecordListFilter extends React.Component<
    IBlockTrimmingRecordListFilterProps,
    IBlockTrimmingRecordListFilterState
> {
    public static defaultProps: IBlockTrimmingRecordListFilterProps;
    constructor(props: IBlockTrimmingRecordListFilterProps) {
        super(props);
        this.state = {
            statusOptions: Util.getOptionsWithPhoneticByOptionArr(
                ApprovalStatus.data
            ),
            ...props.filterData
        };
    }

    public componentWillReceiveProps(
        nextProps: IBlockTrimmingRecordListFilterProps
    ) {
        if (nextProps.filterData !== this.props.filterData) {
            this.setState({ ...nextProps.filterData });
        }
    }

    public async componentDidMount() {}

    public render() {
        const t = this;
        const s = t.state;
        const {
            onExpansionChanged,
            expanded,
            showStatusSelector,
            showOperatorName,
            ...other
        } = t.props;

        return (
            <Collapsible
                label={"筛选条件"}
                onExpansionChanged={onExpansionChanged}
                expanded={expanded}
            >
                <Group.List lineIndent={15}>
                    <ESTextField
                        label="荒料编号"
                        value={s.blockNumber}
                        onChange={t.handleBlockNumberChange}
                        placeholder="请输入荒料编号"
                    />
                    {showOperatorName ? (
                        <ESTextField
                            label="操作员"
                            value={s.operatorName}
                            onChange={t.handleOperatorNameChange}
                            placeholder="请输入操作员"
                        />
                    ) : null}
                    {showStatusSelector ? (
                        <ESPickerField
                            label="状态"
                            options={s.statusOptions}
                            onSelect={t.handleSelectStatusChange}
                            value={s.selectedStatusOptions}
                            placeholder="请选择状态"
                            multiple={true}
                        />
                    ) : null}
                    <DatetimeField
                        label="开始日期"
                        placeholder="请选择修边完成开始日期"
                        onSelect={t.handleStartDateChange}
                        value={s.startDate}
                        columns={DatetimeField.YMD}
                    />
                    <DatetimeField
                        label="结束日期"
                        placeholder="请选择修边完成结束日期"
                        onSelect={t.handleEndDateChange}
                        value={s.endDate}
                        columns={DatetimeField.YMD}
                    />
                    <FilterButtonGroup
                        onSearch={t.handleSearch}
                        onClearData={t.handleClearData}
                    />
                </Group.List>
            </Collapsible>
        );
    }

    private handleSearch = () => {
        const t = this;
        const s = t.state;
        const { showOperatorName } = t.props;
        const p: IBlockTrimmingRecordListFilterData = {
            selectedStatusOptions: s.selectedStatusOptions,
            blockNumber: s.blockNumber,
            startDate: s.startDate,
            endDate: s.endDate,
            operatorName: showOperatorName ? s.operatorName : undefined
        };

        const { onSearch } = t.props;
        if (onSearch) {
            onSearch(p);
        }
    };

    private handleClearData = () => {
        const t = this;
        const p: IBlockTrimmingRecordListFilterData = {
            selectedStatusOptions: [],
            blockNumber: undefined,
            startDate: undefined,
            endDate: undefined,
            operatorName: undefined
        };

        t.setState(p);

        const { onClear } = t.props;
        if (onClear) {
            onClear(p);
        }
    };

    private handleSelectStatusChange = (value: IOption[]) => {
        const t = this;
        t.setState({ selectedStatusOptions: value });
    };

    private handleBlockNumberChange = (value: string) => {
        this.setState({ blockNumber: value });
    };

    private handleOperatorNameChange = (value: string) => {
        this.setState({ operatorName: value });
    };

    private handleStartDateChange = value => {
        this.setState({
            startDate: Util.isDefinedAndNotNull(value.value)
                ? DateUtil.formatDate(new Date(value.value))
                : value.value
        });
    };

    private handleEndDateChange = value => {
        this.setState({
            endDate: Util.isDefinedAndNotNull(value.value)
                ? DateUtil.formatDate(new Date(value.value))
                : value.value
        });
    };
}

export default BlockTrimmingRecordListFilter;
