import React from "react";
import { Boxs } from "saltui";
import ListItem, { IListItemBaseProps } from "../ListItem/ListItem";
import SlabGradePriceInfo from "./SlabGradePriceInfo";
import SlabGradeSelector from "./SlabGradeSelector";
import {
  ISlabGradeChangeContentForUI,
  ISlabInfoForGradeChange,
} from "./interfaces";
import ESIcon from "../ESIcon";

const { HBox, Box } = Boxs;

declare interface ISlabGradeChangeContentListItemProps
  extends IListItemBaseProps {
  slab?: ISlabInfoForGradeChange;
  showUnitPrice?: boolean;
  allowEditing?: boolean;
  onGradeChange?: (
    item: ISlabGradeChangeContentForUI,
    toGradeId: number,
  ) => void;
}

function SlabGradeChangeContentListItem(
  props: ISlabGradeChangeContentListItemProps,
) {
  const { slab, item, showUnitPrice, allowEditing, onGradeChange, ...others } =
    props;

  const {
    slabId,
    bundlePrefix,
    sequenceNumber,
    fromGradeId,
    toGradeId,
    originalUnitPrice,
    destinationUnitPrice,
  } = item as ISlabGradeChangeContentForUI;

  const title = (
    <span>
      {bundlePrefix}{" "}
      <span style={{ fontSize: 18, color: "green" }}>#{sequenceNumber}</span>
    </span>
  );

  const handleGradeChange = (
    slab: ISlabInfoForGradeChange,
    toGradeId: number,
  ) => {
    if (onGradeChange) {
      onGradeChange(item as ISlabGradeChangeContentForUI, toGradeId);
    }
  };

  const icon: JSX.Element = (
    <ESIcon
      name="icon-rightarrow"
      fill="rgba(31,56,88,.3)"
      height={25}
      width={25}
    />
  );

  const extraInfo = (
    <HBox flex={1} className="slabGradeInfoArea">
      <Box className="unitPriceLabel" />
      <Box className="listItemContentArea" flex={1}>
        <HBox flex={1}>
          <Box>
            <SlabGradePriceInfo
              gradeId={fromGradeId}
              price={originalUnitPrice}
              showUnitPrice={showUnitPrice}
            />
          </Box>
          <Box className="t-FBAC slabGradeCentered">{icon}</Box>
          <Box>
            {allowEditing ? (
              <SlabGradeSelector
                slab={slab}
                toGradeId={toGradeId}
                onGradeChange={handleGradeChange}
                showUnitPrice={showUnitPrice}
              />
            ) : (
              <SlabGradePriceInfo
                gradeId={toGradeId}
                price={destinationUnitPrice}
                showUnitPrice={showUnitPrice}
              />
            )}
          </Box>
        </HBox>
      </Box>
    </HBox>
  );

  return (
    <ListItem
      key={slabId}
      item={item}
      avatarTitle={sequenceNumber.toString()}
      avatarSize="34"
      title={title}
      className="slab-item t-FBH t-FBAC"
      extraInfo={extraInfo}
      {...others}
    />
  );
}

export default SlabGradeChangeContentListItem;
