import React from "react";
import { ITileDTO } from "../../app/WebAPIClients";
import ExpandableListItem from "../ExpandableListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";
import TileUtil from "../TileUtil";
import { ITilePackingCaseForListWithTileForUI } from "../type";

declare interface ITilePackingCaseListItemProps
    extends Pick<
            IListItemBaseProps,
            Exclude<keyof IListItemBaseProps, "onSelect">
        > {
    tilePackingCase?: ITilePackingCaseForListWithTileForUI;
    details?: number[];
    onChildClick?: (tile: ITileDTO) => void;
    onExpansionChange?: (item: object, expanded: boolean) => void;
    onSelect?: (
        tpc: ITilePackingCaseForListWithTileForUI,
        tileSelectionStatus: number[]
    ) => void;
}

class TilePackingCaseListItem extends React.PureComponent<
    ITilePackingCaseListItemProps
> {
    public static defaultProps: ITilePackingCaseListItemProps;
    public render() {
        const t = this;

        const {
            tilePackingCase,
            onClick,
            details,
            allowSelection,
            onSelect,
            onExpansionChange,
            ...other
        } = t.props;

        const showOrderNumber = false;
        let selectionClickEvent = (
            tpc: ITilePackingCaseForListWithTileForUI
        ) => {};
        let onClickFun = onClick;
        let selected = false;
        if (allowSelection) {
            selectionClickEvent = t.handleTPCSelection;
            onClickFun = t.handleTPCSelection;
            selected = t.getTPCSelectionStatus(tilePackingCase.id);
        }

        return (
            <div>
                <ExpandableListItem
                    item={tilePackingCase}
                    avatarTitle={tilePackingCase.caseNumber}
                    title={TileUtil.getTileCaseTitle(tilePackingCase)}
                    description={TileUtil.getTileCaseDescription(
                        tilePackingCase,
                        showOrderNumber
                    )}
                    onClick={onClickFun}
                    onExpansionChange={onExpansionChange}
                    content={t.getChildren()}
                    onSelect={selectionClickEvent}
                    allowSelection={allowSelection}
                    selected={selected}
                    {...other}
                />
            </div>
        );
    }

    private handleTPCSelection = (
        tpc: ITilePackingCaseForListWithTileForUI
    ) => {
        if (!tpc) {
            return;
        }

        const id = tpc.id;
        const t = this;
        const { details, onSelect } = t.props;
        let newDetails = [...details];

        const selectedDetail = newDetails.some(tpcId => {
            return tpcId === id;
        });

        if (!selectedDetail) {
            // 没有被选中，将id添加到数组
            newDetails.push(id);
        } else {
            // 已经被选中，将id从数组中移除
            newDetails = newDetails.filter(tpcId => {
                return tpcId !== id;
            });
        }

        if (onSelect) {
            onSelect(tpc, newDetails);
        }
    };

    private getTPCSelectionStatus(id) {
        const t = this;
        const { details } = t.props;

        return details.some(tpcId => {
            return tpcId === id;
        });
    }

    private getChildren() {
        const t = this;
        const children = [];

        const { onChildClick, tilePackingCase } = t.props;

        if (tilePackingCase.tiles && tilePackingCase.tiles.length > 0) {
            TileUtil.getTilesGroupBy(tilePackingCase.tiles).forEach(tile => {
                const newChild = {
                    item: tile,
                    avatarTitle: tile.tileNumber.toString(),
                    title: TileUtil.getTileTitle(tile),
                    description: TileUtil.getTileTitleDescription(tile),
                    onClick: onChildClick,
                    selected: false
                };
                children.push(newChild);
            });
        }
        return children;
    }
}

TilePackingCaseListItem.defaultProps = {
    tilePackingCase: null,
    details: [],
    onClick: () => {},
    onSelect: () => {},
    onChildClick: () => {},
    onExpansionChange: () => {},
    allowSelection: false
};

export default TilePackingCaseListItem;
