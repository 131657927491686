import React from "react";
import { Boxs } from "saltui";
import Perm from "../../app/Perm";
import ListItem from "../ListItem";
import PermCtrl from "../PermCtrl";
import StockingAreaUtil from "../StockingAreaUtil";
import TransferOrderUtil from "../TransferOrderUtil";
import { ITransferDetailForUI } from "../type";
import Util from "../Util";

const { HBox, Box } = Boxs;

declare interface ITransferOrderDetailListItemProps
    extends React.Props<TransferOrderDetailListItem> {
    transferDetail: ITransferDetailForUI;
    showPrice?: boolean;
    showStockingArea?: boolean;
    allowDeletion?: boolean;
    allowSelection?: boolean;
    selected?: boolean;
    allowEditing?: boolean;
    categories: IIdNameItem[];
    grades: IIdNameItem[];
    saList: IIdNameItem[];
    factories: IIdNameItem[];
    warehouses: IIdNameItem[];
    onClick?: (item) => void;
    onSelect?: (item) => void;
    onDelete?: (item: ITransferDetailForUI) => void;
    onUnitPriceChange?: (item: ITransferDetailForUI, priceTxt: string) => void;
}

declare interface ITransferOrderDetailListItemState {
    showBundleGrades: boolean;
}

class TransferOrderDetailListItem extends React.Component<
    ITransferOrderDetailListItemProps,
    ITransferOrderDetailListItemState
> {
    public static defaultProps: ITransferOrderDetailListItemProps;
    constructor(props) {
        super(props);
        this.state = {
            showBundleGrades: PermCtrl.isAuthorized(Perm.SB_G_R)
        };
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;

        const {
            transferDetail,
            showPrice,
            showStockingArea,
            allowDeletion,
            categories,
            grades,
            saList,
            factories,
            warehouses,
            allowSelection,
            onSelect,
            selected,
            ...other
        } = t.props;

        const { allowEditing, onDelete, onClick } = t.props;

        const listItem = TransferOrderUtil.getTransferOrderDetailItemTitle(
            transferDetail,
            categories,
            grades,
            s.showBundleGrades
        );

        let extraInfo = null;
        const stockingAreaName = StockingAreaUtil.getTODStockingAreaName(
            transferDetail,
            saList,
            factories,
            warehouses
        );
        if (showPrice || showStockingArea) {
            extraInfo = (
                <div>
                    {showStockingArea &&
                    Util.isNotNullAndNotEmpty(stockingAreaName) ? (
                        <HBox flex={1} style={{ float: "left" }}>
                            <Box className="unitPriceArea unitPriceLabel">
                                区域
                            </Box>
                            <Box className="unitPriceArea">
                                <span className="unitStockingAreaText">
                                    {stockingAreaName}
                                </span>
                            </Box>
                        </HBox>
                    ) : null}
                    {showPrice && allowEditing ? (
                        <HBox flex={1} style={{ float: "right" }}>
                            <Box className="unitPriceArea unitPriceLabel">
                                费用
                            </Box>
                            <Box className="unitPriceArea">
                                <input
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    name="unitPrice"
                                    onChange={t.handleUnitPriceChange}
                                    className={"unitPriceTextBox"}
                                    value={transferDetail.priceText}
                                />
                            </Box>
                        </HBox>
                    ) : null}
                    {showPrice &&
                    !allowEditing &&
                    transferDetail.transportFee ? (
                        <HBox flex={1} style={{ float: "right" }}>
                            <Box className="unitPriceArea unitPriceLabel">
                                费用
                            </Box>
                            <Box className="unitPriceArea">
                                <span className="unitPriceText">
                                    ￥{transferDetail.transportFee}
                                </span>
                            </Box>
                        </HBox>
                    ) : null}
                </div>
            );
        }

        const avatarTitle = TransferOrderUtil.getGoodesTypeName(
            transferDetail.type
        );

        return (
            <ListItem
                item={transferDetail}
                avatarTitle={avatarTitle}
                title={listItem.title}
                description={listItem.text}
                extraInfo={extraInfo}
                allowDeletion={allowDeletion}
                onDelete={onDelete}
                onClick={onClick}
                allowSelection={allowSelection}
                onSelect={onSelect}
                selected={selected}
            />
        );
    }

    private handleUnitPriceChange = e => {
        let priceTxt = e.target.value;
        // 单价只保留两位小数
        priceTxt = Util.toFixedTowDecimal(priceTxt);
        const { transferDetail, onUnitPriceChange } = this.props;
        if (onUnitPriceChange) {
            onUnitPriceChange(transferDetail, priceTxt);
        }

        if (e) {
            e.stopPropagation();
        }
    };
}

TransferOrderDetailListItem.defaultProps = {
    transferDetail: null,
    showPrice: false,
    showStockingArea: false,
    allowDeletion: false,
    allowSelection: false,
    selected: false,
    allowEditing: false,
    categories: [],
    grades: [],
    saList: [],
    factories: [],
    warehouses: [],
    onClick: () => {},
    onDelete: item => {},
    onUnitPriceChange: (item, priceTxt) => {},
    onSelect: item => {}
};

export default TransferOrderDetailListItem;
