import * as React from "react";
import { Group } from "saltui";
import { FC } from "react";
import { IAppliedPriceItemDTO } from "../../app/WebAPIClients";
import PermCtrl from "../PermCtrl";
import Perm from "../../app/Perm";
import UserInfo from "../UserInfo";
import ESHeader from "../ESHeader";
import AppliedPriceListItem from "../AppliedPriceListItem";

declare interface IPaymentShippingOrderAppliedPriceGroupProps {
  appliedPrices: IAppliedPriceItemDTO[];
  orderType: "ShippingOrder" | "PaymentOrder";
}

export const canViewPaymentShippingOrderAppliedPriceAmount: (
  appliedPrice: IAppliedPriceItemDTO,
  currentUserId: string,
  orderType: "ShippingOrder" | "PaymentOrder",
) => boolean = (item, currentUserId, orderType) => {
  if (orderType === "PaymentOrder") {
    // 如果是结算单，且没有查看销售订单金额的权限，则不能查看其他收费金额
    if (!PermCtrl.isAuthorized(Perm.SO_P_R)) {
      return false;
    }

    // 如果是结算单且有查看所有结算单的权限，则可以查看其他收费金额
    if (PermCtrl.isAuthorized(Perm.SS_RA)) {
      return true;
    }

    // 如果仅有查看自己的结算单权限，且其他收费中的销售代表是自己，则可以查看其他收费金额
    return (
      PermCtrl.isAuthorized(Perm.SS_R) && item.salesRepId === currentUserId
    );
  } else {
    // 如果是装车单，且没有查看销售订单金额的权限或者没有创建和修改装车单权限，则不能查看其他收费金额
    if (
      !PermCtrl.isAnyAuthorized([
        Perm.SO_P_R,
        Perm.SPO_B_C,
        Perm.SPO_B_U,
        Perm.SPO_S_C,
        Perm.SPO_S_U,
        Perm.SPO_T_C,
        Perm.SPO_T_U,
      ])
    ) {
      return false;
    }

    // 如果有查看全部结算单的权限，则可以查看其他收费金额
    if (PermCtrl.isAuthorized(Perm.SPO_RA)) {
      return true;
    }

    // 如果仅有查看自己的装车单权限，且其他收费中的销售代表是自己，则可以查看其他收费金额
    return (
      PermCtrl.isAuthorized(Perm.SPO_R) && item.salesRepId === currentUserId
    );
  }
};

export const PaymentShippingOrderAppliedPriceGroup: FC<
  IPaymentShippingOrderAppliedPriceGroupProps
> = ({ appliedPrices, orderType }) => {
  const userId = UserInfo.getId();

  return (
    <Group>
      <ESHeader label={"其它收费"} />
      <Group.List lineIndent={15} itemIndent={[15, 15]}>
        {appliedPrices.map((item) => {
          const showPrice = canViewPaymentShippingOrderAppliedPriceAmount(
            item,
            userId,
            orderType,
          );
          return (
            <AppliedPriceListItem
              key={item.name}
              appliedPriceItem={item}
              showPrice={showPrice}
            />
          );
        })}
      </Group.List>
    </Group>
  );
};
