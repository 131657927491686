import React from "react";
// tslint:disable-next-line:no-implicit-dependencies
import Icon from "salt-icon";
import { ReferencePriceSize } from "../type";
import Util from "../Util";

export interface IReferencePriceProps {
    referencePrice: number | null;
    size?: ReferencePriceSize;
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
    showIcon?: boolean;
    lineThrough?: boolean;
    noReferencePriceText?: string;
}

class ReferencePrice extends React.Component<IReferencePriceProps, {}> {
    public static defaultProps: IReferencePriceProps;

    public render(): JSX.Element {
        const t = this;
        const {
            referencePrice,
            showIcon,
            size,
            lineThrough,
            noReferencePriceText
        } = t.props;
        const iconSize = size === ReferencePriceSize.Normal ? 14 : 12;
        const referencePriceClassName =
            size === ReferencePriceSize.Normal
                ? "referencePriceColor"
                : lineThrough
                    ? "referencePriceText referencePriceTextDecoration"
                    : "referencePriceText";
        return (
            <span
                className={referencePriceClassName}
                onClick={this.handleClick}
            >
                {referencePrice !== null
                    ? "￥" + referencePrice
                    : noReferencePriceText}
                {Util.isDefinedAndNotNull(referencePrice) && showIcon ? (
                    <Icon
                        name="info-circle"
                        fill="#097BF7"
                        width={iconSize}
                        height={iconSize}
                        className={"referencePriceIcon"}
                    />
                ) : null}
            </span>
        );
    }

    private handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        const { onClick } = this.props;
        if (!Util.isDefinedAndNotNull(onClick)) {
            return;
        }

        if (onClick) {
            onClick(event);
        }

        if (event) {
            event.stopPropagation();
        }
    };
}

ReferencePrice.defaultProps = {
    referencePrice: null,
    size: ReferencePriceSize.Normal,
    onClick: null,
    showIcon: false,
    lineThrough: false,
    noReferencePriceText: null
};

export default ReferencePrice;
