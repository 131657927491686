import * as React from "react";
import { StoneArtifactType } from "../app/WebAPIClients";
import StockingAreaItem from "./StockingAreaItem";
import { ISalesOrderDetailForUI, ITransferDetailForUI } from "./type";
import Util from "./Util";

class StockingAreaUtil {
    // 获取仓库选择级联
    public generateWarehouseCascadData(
        warehouseList,
        factories
    ): ITwoStageCascade[] {
        const options = [];

        warehouseList.forEach(element => {
            const wo = options.find(w => w.value === element.factoryId);
            if (wo) {
                wo.children.push({
                    value: element.id,
                    label: element.name
                });
            } else {
                options.push({
                    value: element.factoryId,
                    label: Util.getItemName(element.factoryId, factories),
                    children: [
                        {
                            value: element.id,
                            label: element.name
                        }
                    ]
                });
            }
        });

        return options;
    }

    // 根据Id获取仓库
    public getWarehouAreaForUI(warehouseId: number, warehouses, factories) {
        const warehouseForUI = [];
        if (!(warehouses && warehouseId)) {
            return null;
        }

        // 获取对应的仓库信息
        const warehouse = warehouses.find(w => w.id === warehouseId);
        if (!warehouse) {
            return null;
        }

        warehouseForUI.push(
            {
                value: warehouse.factoryId,
                text: Util.getItemName(warehouse.factoryId, factories)
            },
            {
                value: warehouse.id,
                text: warehouse.name
            }
        );

        return warehouseForUI;
    }

    public getSODStockingAreaName(
        item: ISalesOrderDetailForUI,
        saList: IIdNameItem[],
        factories,
        warehouses
    ): string {
        const saId: number = this.getStockingAreaId(item);

        return this.showStockingAreaText(saId, saList, factories, warehouses);
    }

    public getStockingAreaId(
        item: ISalesOrderDetailForUI | ITransferDetailForUI
    ): number {
        let saId: number = null;
        switch (item.type) {
            case StoneArtifactType.Block:
                saId = item.block.stockingAreaId;
                break;
            case StoneArtifactType.StoneBundle:
                saId = item.bundle.stockingAreaId;
                break;
            case StoneArtifactType.Tile:
                saId = item.tilePackingCase.stockingAreaId;
                break;
            case StoneArtifactType.Slab:
                if (
                    Util.isDefinedAndNotNull(
                        (item as ITransferDetailForUI).slab
                    )
                ) {
                    saId = (item as ITransferDetailForUI).slab.stockingAreaId;
                }
                break;
        }

        return saId;
    }

    public getTODStockingAreaName(
        item: ITransferDetailForUI,
        saList: IIdNameItem[],
        factories,
        warehouses
    ): string {
        const saId: number = this.getStockingAreaId(item);

        if (!saId) {
            return null;
        }

        return this.showStockingAreaText(saId, saList, factories, warehouses);
    }

    // 获取显示text
    public showStockingAreaText(
        stockingAreaId: number,
        saList,
        factories,
        warehouses
    ): string {
        if (!(stockingAreaId && saList && factories && warehouses)) {
            return;
        }

        // 获取已选择的区域信息
        const stockingArea = saList.find(sa => sa.id === stockingAreaId);

        if (!stockingArea) {
            return;
        }

        const warehouse = warehouses.find(
            w => w.id === stockingArea.warehouseId
        );

        if (!warehouse) {
            return;
        }

        warehouses = warehouses.filter(w => w.type === warehouse.type);

        let showText =
            warehouses.length === 1
                ? ""
                : this.getWarehouseName(
                      stockingArea.warehouseId,
                      factories,
                      warehouses
                  ) + "-";
        showText += stockingArea.name;

        return showText;
    }

    public getWarehouseName(
        warehouseId: number,
        factories: IIdNameItem[],
        warehouses
    ) {
        // 获取对应的仓库信息
        const warehouse = warehouses.find(w => w.id === warehouseId);

        if (!warehouse) {
            return "";
        }

        // 获取工厂名称
        const factoryName = Util.getItemName(warehouse.factoryId, factories);

        if (!factoryName) {
            return "";
        }

        return factoryName + "-" + warehouse.name;
    }

    // 获取选择库存区域Options
    public generateCascadeData(saList): IThreeStageCascade[] {
        const options = [];
        saList.forEach(sa => {
            this.addStockingAreaChild(options, sa);
        });

        return options;
    }

    public getStockingArea(
        areaOption: { section: string; segment: string; slot: string },
        warehouseId: number,
        saList
    ) {
        if (!areaOption.section) {
            return null;
        }

        const section = areaOption.section;
        const segment = areaOption.segment;
        const slot = areaOption.slot;

        const stockingArea = saList.find(
            sa =>
                sa.section === section &&
                sa.segment === segment &&
                sa.slot === slot &&
                sa.warehouseId === warehouseId
        );

        return stockingArea;
    }

    public showStockingArea(
        showStockingArea: boolean,
        stockingAreaId: number | null
    ) {
        if (
            !Util.isDefinedAndNotNull(stockingAreaId) ||
            showStockingArea === false
        ) {
            return null;
        }

        return <StockingAreaItem stockingAreaId={stockingAreaId} />;
    }

    private addStockingAreaChild(options, area) {
        const section = this.addNode(options, area.section);
        let segment = null;

        if (section && area.segment) {
            segment = this.addNode(section.children, area.segment);
        }
        if (segment && area.slot) {
            this.addNode(segment.children, area.slot, true);
        }
    }

    private addNode(nodeList, dataValue, noChildren = false) {
        let node = this.findNode(nodeList, dataValue);
        if (!node) {
            node = this.getNewNode(dataValue, dataValue, noChildren);
            nodeList.push(node);
        }
        return node;
    }

    private getNewNode(
        value: string | number,
        label: string | number,
        noChildren: boolean = false
    ) {
        if (!(value && label)) {
            return null;
        }

        let node: any = { value, label };
        if (!noChildren) {
            node = { ...node, children: [] };
        }
        return node;
    }

    private findNode(nodeList, nodeValue: string | number) {
        if (!(nodeList && nodeList.length && nodeList.length > 0)) {
            return null;
        }
        return nodeList.find(n => {
            return n.value === nodeValue;
        });
    }
}

export default new StockingAreaUtil();
