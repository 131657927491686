import React from "react";
import { Toast } from "saltui";
import Perm from "../../app/Perm";
import {
    IBundleWithSlabsForListDTO,
    ISlabCheckOutContentCreationViewModel,
    ISlabForListDTO,
    SlabBundleStatus
} from "../../app/WebAPIClients";
import ExpandableListItem from "../ExpandableListItem";
import { IListItemBaseProps } from "../ListItem/ListItem";
import PermCtrl from "../PermCtrl";
import SlabUtil from "../SlabUtil";
import StockingAreaUtil from "../StockingAreaUtil";
import StoneBundleUtil from "../StoneBundleUtil";
import Util from "../Util";
import { Boxs } from "saltui";
import ReferencePrice from "../ReferencePrice";
const HBox = Boxs.HBox;
const Box = Boxs.Box;

declare interface ISCORSelectorBundleListItemProps extends IListItemBaseProps {
    stoneBundle?: IBundleWithSlabsForListDTO;
    details: ISlabCheckOutContentCreationViewModel[];
    categoryList?: IIdNameItem[];
    gradeList?: IIdNameItem[];
    onExpansionChange?: (item: object, expanded: boolean) => void;
    showUnitPrice?: boolean;
}

declare interface ISCORSelectorBundleListItemState {
    showBundleGrades: boolean;
}

class SCORSelectorStoneBundleListItem extends React.PureComponent<
    ISCORSelectorBundleListItemProps,
    ISCORSelectorBundleListItemState
> {
    constructor(props) {
        super(props);
        this.state = {
            showBundleGrades: PermCtrl.isAuthorized(Perm.SB_G_R)
        };
    }
    public static defaultProps: ISCORSelectorBundleListItemProps;
    public render() {
        const t = this;

        const {
            stoneBundle,
            allowSelection,
            details,
            onClick,
            onSelect,
            onExpansionChange,
            categoryList,
            gradeList,
            showUnitPrice,
            ...other
        } = t.props;

        const { showBundleGrades } = t.state;

        const avatarTitle = Util.getBundleAvatarText(stoneBundle);
        let selectionClickEvent = onSelect;
        let onClickFun = onClick;
        let selected: SelectionStatus = false;

        // 判断扎中是否存在大板的品名和厚度与扎的是否一致，如果存在，则此扎显示可选择按钮
        const someSlabAllowSelection =
            allowSelection &&
            stoneBundle.slabs.some(
                slab =>
                    stoneBundle.categoryId === slab.categoryId &&
                    stoneBundle.thickness === slab.thickness
            );

        if (someSlabAllowSelection) {
            selected = t.getBundleSelection(stoneBundle);
            selectionClickEvent = t.handleBundleSelection;
            onClickFun = t.handleBundleSelection;
        }

        const showStockingArea = [
            SlabBundleStatus.InStock,
            SlabBundleStatus.ReservedBySlabCheckOutRequest
        ].includes(stoneBundle.status);
        const description = (
            <div>
                {StoneBundleUtil.getBundleSpecWithAreaFromSlabs(
                    stoneBundle,
                    categoryList,
                    gradeList,
                    showBundleGrades
                )}
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    stoneBundle.stockingAreaId
                )}
                {showUnitPrice ? (
                    <div className="referencePriceArea">
                        <HBox>
                            <Box className="referencePriceLabel">单价</Box>
                            <Box>
                                <ReferencePrice
                                    referencePrice={stoneBundle.unitPrice}
                                />
                            </Box>
                        </HBox>
                    </div>
                ) : null}
            </div>
        );

        return (
            <ExpandableListItem
                item={stoneBundle}
                avatarTitle={avatarTitle}
                title={StoneBundleUtil.getBundleTitleWithCountFromSlabs(
                    stoneBundle
                )}
                description={description}
                onClick={onClickFun}
                content={t.getChildren()}
                onExpansionChange={onExpansionChange}
                onSelect={selectionClickEvent}
                allowSelection={someSlabAllowSelection}
                selected={selected}
                {...other}
            />
        );
    }

    private getSelectedSlabState(item: ISlabForListDTO) {
        const t = this;
        let slabSelected = false;
        const { details } = t.props;
        if (typeof details === "undefined" || details === null) {
            return slabSelected;
        }
        const detail = details.find(b => {
            return b.bundleId === item.bundleId;
        });

        if (detail) {
            slabSelected = detail.slabIds.some(d => {
                return d === item.id;
            });
        }

        return slabSelected;
    }

    // 选择光板
    private handleSlabSelection = slab => {
        const t = this;
        const { stoneBundle: bundle } = t.props;

        const slabId = slab.id;
        if (!slabId) {
            return;
        }

        const { details, onSelect } = t.props;
        let newDetails = [...details];

        // 该片板所在扎是否在被选中
        const bundleSelected = details.some(d => {
            return d.bundleId === slab.bundleId;
        });
        // 如果扎没被选中
        if (!bundleSelected) {
            // 判断该扎板是否只有一片板，如果只有一片板，allSlabSelected=true。
            const allSlabSelected = bundle.slabs.length > 1 ? false : true;
            const detail = {
                bundleId: slab.bundleId,
                allSlabSelected,
                slabIds: [slabId]
            };
            newDetails.push(detail);
        }
        // 如果扎被选中
        else {
            const detail = newDetails.find(b => {
                return b.bundleId === slab.bundleId;
            });
            // 判断该块板是否被选中
            const slabSelected = detail.slabIds.some(s => {
                return s === slabId;
            });
            if (slabSelected) {
                if (detail.slabIds.length > 1) {
                    // 如果该扎选中有多片板，将该片板移除
                    detail.allSlabSelected = false;
                    const slabIds = detail.slabIds.filter(d => {
                        return d !== slabId;
                    });
                    detail.slabIds = slabIds;
                } else {
                    // 如果只有一片板，将该扎移除
                    newDetails = details.filter(d => {
                        return d.bundleId !== slab.bundleId;
                    });
                }
            } else {
                // 如果没被选中，添加该片板
                detail.slabIds.push(slabId);
                // 如果该扎下的所有在库存状态板被选中，如果完全选中，把allSlabSeleted设为true
                if (detail.slabIds.length === bundle.slabs.length) {
                    detail.allSlabSelected = true;
                }
            }
        }

        if (onSelect) {
            onSelect(newDetails);
        }

        if (event) {
            event.stopPropagation();
        }
    };

    // 选择扎
    private handleBundleSelection = item => {
        const t = this;
        const s = t.state;

        const bundleId = item.id;
        if (!bundleId) {
            return;
        }

        const canSelectAllSlabs = t.canSelectAllSlabs();

        const { details, onSelect } = t.props;
        if (item.slabs && item.slabs.length === 0) {
            Toast.show({
                type: "Fail",
                content:
                    item.prefix +
                    " " +
                    item.totalBundleCount +
                    "-" +
                    item.bundleNo +
                    " 没有大板数据，不能选择，请联系数据操作员导入大板数据！"
            });
            return;
        }

        let newDetails = [...details];

        const bundleSelected = details.some(d => {
            return d.bundleId === bundleId;
        });

        if (!bundleSelected) {
            // 没有被选中，添加扎
            newDetails = t.addBundleDetail(item, newDetails, canSelectAllSlabs);
        } else {
            // 已经被选中
            const detail = details.find(d => {
                return d.bundleId === bundleId;
            });
            newDetails = details.filter(d => {
                return d.bundleId !== bundleId;
            });
            // 如果该扎下的大板没有被全选，添加整扎
            if (!detail.allSlabSelected && canSelectAllSlabs) {
                newDetails = t.addBundleDetail(
                    item,
                    newDetails,
                    canSelectAllSlabs
                );
            }
        }

        if (onSelect) {
            onSelect(newDetails);
        }

        if (event) {
            event.stopPropagation();
        }
    };

    // 添加扎
    private addBundleDetail(item, newDetails, allSlabAllowSelection) {
        const t = this;
        const slabIds = [];
        const detail = {
            bundleId: null,
            allSlabSelected: null,
            slabIds: []
        };

        detail.bundleId = item.id;
        if (allSlabAllowSelection) {
            detail.allSlabSelected = true;
            item.slabs.map(slabItem => {
                slabIds.push(slabItem.id);
            });
        } else {
            detail.allSlabSelected = false;
            item.slabs.map(slabItem => {
                if (
                    item.categoryId === slabItem.categoryId &&
                    item.thickness === slabItem.thickness
                ) {
                    slabIds.push(slabItem.id);
                }
            });
        }

        detail.slabIds = slabIds;
        newDetails.push(detail);
        return newDetails;
    }

    // 获取扎的是否选中颜色
    private getBundleSelection(item): SelectionStatus {
        const t = this;
        let selectedFlag: SelectionStatus = false;
        let detail = null;
        let slabs = [];

        const { details } = t.props;

        if (typeof details === "undefined" || details === null) {
            return selectedFlag;
        }

        if (item.id) {
            detail = details.find(b => {
                return b.bundleId === item.id;
            });
            slabs = item.slabs;
        }

        // 扎下面的大板被全部选中
        if (detail && detail.allSlabSelected) {
            selectedFlag = "all";
        }
        // 扎下面的大板被部分选中
        else if (
            detail &&
            detail.slabIds.length > 0 &&
            detail.slabIds.length < slabs.length
        ) {
            selectedFlag = "partially";
        }
        // 扎下面的大板全部没有选中
        else {
            selectedFlag = "none";
        }
        return selectedFlag;
    }

    private getChildren() {
        const t = this;
        const children = [];

        const {
            stoneBundle,
            allowSelection,
            categoryList,
            gradeList,
            showUnitPrice
        } = t.props;

        if (stoneBundle.slabs && stoneBundle.slabs.length > 0) {
            Util.getSlabGroupBy(stoneBundle.slabs).forEach(slab => {
                const canSelection =
                    stoneBundle.categoryId === slab.categoryId &&
                    stoneBundle.thickness === slab.thickness;
                let newChild: IListItemBaseProps = {
                    item: slab,
                    avatarTitle: slab.sequenceNumber.toString(),
                    title: SlabUtil.getSlabTitle(slab, showUnitPrice),
                    description:
                        slab.bundlePrefix === slab.blockNumber
                            ? SlabUtil.getSlabSpec(slab, gradeList)
                            : SlabUtil.getSlabInfoSpec(
                                  slab,
                                  categoryList,
                                  gradeList
                              ),
                    allowSelection: allowSelection && canSelection
                };

                if (allowSelection) {
                    newChild = {
                        ...newChild,
                        selected: t.getSelectedSlabState(slab),
                        onSelect: t.handleSlabSelection,
                        onClick: t.handleSlabSelection
                    };
                } else {
                    newChild = {
                        ...newChild,
                        selected: false,
                        onSelect: () => {},
                        onClick: () => {}
                    };
                }

                children.push(newChild);
            });
        }
        return children;
    }

    private canSelectAllSlabs() {
        const t = this;
        const { stoneBundle, allowSelection } = t.props;

        // 判断扎中的所有大板的品名和厚度与扎的是否一致，如果不一致，则不能选择整扎，主要用于大板领料，不能领出重组新扎中不符合生产需求的大板
        return (
            allowSelection &&
            stoneBundle.slabs.every(
                slab =>
                    stoneBundle.categoryId === slab.categoryId &&
                    stoneBundle.thickness === slab.thickness
            )
        );
    }
}

SCORSelectorStoneBundleListItem.defaultProps = {
    stoneBundle: null,
    details: [],
    onClick: () => {},
    onSelect: () => {},
    onExpansionChange: () => {},
    categoryList: [],
    gradeList: [],
    allowSelection: false,
    showUnitPrice: false
};

export default SCORSelectorStoneBundleListItem;
