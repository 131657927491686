import nattyStorage from "natty-storage";
import { IUserInfoDTO } from "../app/WebAPIClients";
import Util from "./Util";

class UserInfo {
  public static getUserInfo(): IUserInfoDTO {
    const cache = this.getStorage();
    return cache && cache.get(this.userInfoCacheKey);
  }
  public static getPermissions(): string[] {
    const ui = this.getUserInfo();
    if (!ui || !ui.permissions) {
      return [];
    }
    return ui.permissions!;
  }
  public static getId(): string {
    const ui = this.getUserInfo();
    if (!ui) {
      return "";
    }
    return ui.id;
  }
  public static getName(): string {
    const cache = this.getStorage();
    const ui = cache.get(this.userInfoCacheKey);
    if (!ui) {
      return "";
    }
    return ui.name;
  }
  public static getFactoryId(): number {
    const cache = this.getStorage();
    const ui = cache.get(this.userInfoCacheKey);
    if (!ui) {
      return null;
    }
    return ui.factoryId;
  }

  public static loggedIn(): boolean {
    const cache = this.getStorage();
    const result = cache.has(this.userInfoCacheKey);
    return result.has;
  }

  public static setUserInfo(userInfo: IUserInfoDTO): void {
    if (!userInfo) {
      return;
    }
    const cache = this.getStorage();
    cache.set(this.userInfoCacheKey, userInfo);
  }

  public static getDDConfig(): any {
    const cache = this.getStorage();
    const ddConfig = cache.get(this.ddConfigCacheKey);
    return ddConfig;
  }

  public static setDDConfig(ddConfig): void {
    if (!ddConfig) {
      return;
    }
    const cache = this.getStorage();
    cache.set(this.ddConfigCacheKey, ddConfig);
  }

  public static getAccessToken(): string | null {
    const cache = this.getStorage();
    return cache.get(this.jwtTokenCacheKey);
  }

  public static setAccessToken(jwtToken: string): void {
    if (!jwtToken) {
      return;
    }
    const cache = this.getStorage();
    cache.set(this.jwtTokenCacheKey, jwtToken);
  }

  private static storage: any = null;
  private static storageKey: string = "userInfoStorage";
  private static userInfoCacheKey: string = "userInfo_Cache";
  private static ddConfigCacheKey: string = "ddConfig_Cache";
  private static jwtTokenCacheKey: string = "jwtToken_Cache";
  private static cacheDurationInMin: number = 10;

  private static getStorage(): any {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.storageKey, this.cacheDurationInMin),
      );
    }
    return this.storage;
  }
}
export default UserInfo;
