class SettingsDefinition {
  public static EnableBlockNumberFromQuarryKey: string =
    "EnableBlockNumberFromQuarry";
  public static BlockDimensionDisplayOrderKey: string =
    "BlockDimensionDisplayOrder";
  public static BlockDimensionLengthHeightWidth: string = "LHW";
  public static BlockDimensionLengthWidthHeight: string = "LWH";

  /**
   * Settings表中配置的是否启用全局装车单的key
   */
  public static EnableGlobalShippingOrdersKey: string =
    "EnableGlobalShippingOrders";

  /**
   * Settings表中配置的是否启用存入扣减款项
   */
  public static EnableDepositIncurredItemsKey: string =
    "EnableDepositIncurredItems";

  /** Settings表中配置的是否自动同步装车单和结算单 */
  public static EnableAutoSyncSPOToPOKey: string = "EnableAutoSyncSPOToPO";

  /**
   * Settings表中配置的是否启用调拨单的key
   */
  public static EnableTransferOrdersKey: string = "EnableTransferOrders";

  /**
   * Settings表中配置的是否启用库存产品定价功能的key
   */
  public static EnableReferencePriceKey: string = "EnableReferencePrice";

  /**
   * Settings表中配置的是否启用导出代加工核算报表的key
   */
  public static EnableExportOEMReportKey: string = "EnableExportOEMReport";

  /**
   * Settings表中配置的是否启用结算单审批流程的key
   */
  public static EnableApprovalPOKey: string = "EnableApprovalPO";

  /**
   * 表Settings中配置的是否启用阿里钉钉集成的key
   */
  public static EnableDingtalkIntegrationKey: string =
    "EnableDingtalkIntegration";

  /**
   * 表Settings中配置的修边刀数需要审批的刀数最小值的key，比如值是6，那么超过6刀就需要发起审批
   */
  public static TrimmingMaxCutsKey: string = "TrimmingMaxCuts";
  public static EnableSlabImagingDeviceKey: string = "EnableSlabImagingDevice";
  public static EnableTileImagingDeviceKey: string = "EnableTileImagingDevice";
  public static EnableReservedSOCancellationKey: string =
    "EnableReservedSOCancellation";

  /**
   * 按整颗料销售大扎的工厂ID列表的Key，这个设置中保存用英文逗号分隔的工厂Id，只要在这个列表中的工厂，所有库存大板都必须按整颗料销售
   * 销售员在新建销售订单时，如果选择的库存大扎属于这个列表中的工厂，则会把所有这个工厂库存中的同颗荒料下的所有大板都加入到销售订单中
   */
  public static FactoryIdsForSellingBundlesByWholeBlockKey: string =
    "FactoryIdsForSellingBundlesByWholeBlock";
}

export default SettingsDefinition;
