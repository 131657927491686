import Util from "../Util";
import {
    IPlanningOutputRatesForBackend,
    IPlanningRecordFilterData,
    ISlabPlanningItemForUI,
    ITilePlanningItemForUI
} from "./interfaces";

export default class PlanningRecordUtil {
    static convertSlabPlanningItemsToOutputRates(
        items: ISlabPlanningItemForUI[]
    ): IPlanningOutputRatesForBackend {
        let outputRates = items.reduce<IPlanningOutputRatesForBackend>(
            (acc, cur) => {
                if (
                    Util.isDefinedAndNotNull(cur.outputRate) &&
                    cur.outputRate > 0
                ) {
                    acc[cur.gradeId.toString()] = cur.outputRate;
                }
                return acc;
            },
            {}
        );
        if (Object.entries(outputRates).length === 0) {
            outputRates = null;
        }

        return outputRates;
    }

    static convertTilePlanningItemsToOutputRates(
        items: ITilePlanningItemForUI[]
    ): IPlanningOutputRatesForBackend {
        let outputRates = items.reduce<IPlanningOutputRatesForBackend>(
            (acc, cur) => {
                if (
                    Util.isDefinedAndNotNull(cur.outputRate) &&
                    cur.outputRate > 0
                ) {
                    acc[cur.size.toString()] = cur.outputRate;
                }
                return acc;
            },
            {}
        );
        if (Object.entries(outputRates).length === 0) {
            outputRates = null;
        }

        return outputRates;
    }

    static isPlanningRecordFilterDataEmpty(
        data: IPlanningRecordFilterData
    ): boolean {
        const {
            noTileOutputRates,
            planningTileLength,
            planningTileWidth,
            planningTileMinOutputRate,
            planningTileMaxOutputRate,
            planningTileLengthSource
        } = data;

        return (
            Util.isUndefinedOrNull(noTileOutputRates) &&
            Util.isUndefinedOrNull(planningTileLength) &&
            Util.isUndefinedOrNull(planningTileWidth) &&
            Util.isUndefinedOrNull(planningTileMinOutputRate) &&
            Util.isUndefinedOrNull(planningTileMaxOutputRate) &&
            Util.isUndefinedOrNull(planningTileLengthSource)
        );
    }

    static isPlanningRecordFilterDataValid(
        data: IPlanningRecordFilterData
    ): boolean {
        const {
            noTileOutputRates,
            planningTileLength,
            planningTileWidth,
            planningTileMinOutputRate,
            planningTileMaxOutputRate
        } = data;

        if (this.isPlanningRecordFilterDataEmpty(data)) {
            return true;
        }

        if (Util.isUndefinedOrNull(noTileOutputRates)) {
            return false;
        }

        if (noTileOutputRates) {
            return true;
        }

        if (
            Util.isUndefinedOrNull(planningTileLength) ||
            Util.isUndefinedOrNull(planningTileWidth)
        ) {
            return false;
        }

        if (
            Util.isUndefinedOrNull(planningTileMinOutputRate) &&
            Util.isUndefinedOrNull(planningTileMaxOutputRate)
        ) {
            return false;
        }

        return true;
    }

    static getPlanningRecordFilterDataDescription(
        data: IPlanningRecordFilterData
    ): string {
        if (!this.isPlanningRecordFilterDataValid(data)) {
            return "无规划方案";
        }

        const {
            noTileOutputRates,
            planningTileLength,
            planningTileWidth,
            planningTileMinOutputRate,
            planningTileMaxOutputRate
        } = data;

        if (noTileOutputRates) {
            return "荒料无成品板出材率";
        }

        const minPercentage: string = this.getPercentage(
            planningTileMinOutputRate
        );
        const maxPercentage: string = this.getPercentage(
            planningTileMaxOutputRate
        );
        const outputRateTxt = !minPercentage
            ? `<${maxPercentage}%`
            : !maxPercentage
                ? `>${minPercentage}%`
                : `${minPercentage}% ~ ${maxPercentage}%`;
        const sizeTxt = `${planningTileLength} x ${planningTileWidth}`;
        return `${sizeTxt} (${outputRateTxt})`;
    }

    static getPercentage(value: number | null): string | null {
        return Util.isUndefinedOrNull(value)
            ? null
            : Util.round(value * 100, 0).toString();
    }
}
