import * as React from "react";
import { Group } from "saltui";
import Collapsible from "../Collapsible";
import Consts from "../Consts";
import ShippingOrderDetailListItem from "../ShippingOrderDetailListItem";
import StoneArtifactUtil from "../StoneArtifactUtil";
import { ISalesOrderDetailForUI } from "../type";
import { FC, useEffect, useState } from "react";
import { IPaymentOrderItemDTO } from "../../app/WebAPIClients";
import PermCtrl from "../PermCtrl";
import Perm from "../../app/Perm";
import UserInfo from "../UserInfo";

declare interface IPaymentShippingItem
  extends Pick<IPaymentOrderItemDTO, "salesOrderDetail" | "salesRepId"> {}

declare interface IPaymentShippingItemGroupProps {
  items: IPaymentShippingItem[];
  showStockingArea?: boolean;
  allowDeletion?: boolean;
  isPaymentOrder?: boolean;
  showIconStatus?: ShowIconStatus;
  onDelete?: (item) => void;
  onPaymentIconClick?: (item: ISalesOrderDetailForUI) => void;
  onShipmentIconClick?: (item: ISalesOrderDetailForUI) => void;
  customShowReferencePrice?: boolean;
  orderType: "ShippingOrder" | "PaymentOrder";
}

const getPaymentShippingItemJSX: (
  lable: string,
  items: IPaymentShippingItem[],
  props: IPaymentShippingItemGroupProps,
  categoryList: IIdNameItem[],
  gradeList: IIdNameItem[],
) => JSX.Element = (lable, items, props, categoryList, gradeList) => {
  const {
    showStockingArea,
    onDelete,
    showIconStatus,
    onPaymentIconClick,
    onShipmentIconClick,
    customShowReferencePrice,
    allowDeletion,
    orderType,
  } = props;

  const userId = UserInfo.getId();

  return (
    <Collapsible label={lable} expanded={true}>
      <Group>
        <Group.List lineIndent={15} itemIndent={[15, 15]}>
          {items && items.length && items.length > 0 ? (
            items.map((item) => {
              const sodItem = item.salesOrderDetail;
              const itemKey =
                sodItem.type.toString() +
                "|" +
                StoneArtifactUtil.getStoneArtifactItemId(sodItem).toString();

              const showPrice = canViewPaymentShippingItemAmount(
                item,
                userId,
                orderType,
              );

              // 处理场景：更新装车单或结算单界面，若货物明细对应的销售订单不是已批准，则不允许删除此条明细
              const allowDeletionItem =
                allowDeletion && sodItem.orderStatus === 20;
              return (
                <ShippingOrderDetailListItem
                  key={itemKey}
                  item={sodItem}
                  showPrice={showPrice}
                  showStockingArea={showStockingArea}
                  categoryList={categoryList}
                  gradeList={gradeList}
                  allowDeletion={allowDeletionItem}
                  onDelete={onDelete}
                  showIconStatus={showIconStatus}
                  onPaymentIconClick={onPaymentIconClick}
                  onShipmentIconClick={onShipmentIconClick}
                  customShowReferencePrice={customShowReferencePrice}
                />
              );
            })
          ) : (
            <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">没有数据</div>
          )}
        </Group.List>
      </Group>
    </Collapsible>
  );
};

const getBlockItems: (
  items: IPaymentShippingItem[],
) => IPaymentShippingItem[] = (items) => {
  const sodItems = items.map((i) => i.salesOrderDetail);
  const blockSODItems = StoneArtifactUtil.getBlockItems(sodItems);
  return items.filter((i) => blockSODItems.includes(i.salesOrderDetail));
};

const getBundleItems: (
  items: IPaymentShippingItem[],
) => IPaymentShippingItem[] = (items) => {
  const sodItems = items.map((i) => i.salesOrderDetail);
  const bundleSODItems = StoneArtifactUtil.getBundleItems(sodItems);
  return items.filter((i) => bundleSODItems.includes(i.salesOrderDetail));
};

const getTilePackingCaseItems: (
  items: IPaymentShippingItem[],
) => IPaymentShippingItem[] = (items) => {
  const sodItems = items.map((i) => i.salesOrderDetail);
  const tileSODItems = StoneArtifactUtil.getTilePackingCaseItems(sodItems);
  return items.filter((i) => tileSODItems.includes(i.salesOrderDetail));
};

export const canViewPaymentShippingItemAmount: (
  item: IPaymentShippingItem,
  currentUserId: string,
  orderType: "ShippingOrder" | "PaymentOrder",
) => boolean = (item, currentUserId, orderType) => {
  // 如果没有查看销售订单金额的权限，则不能查看其他收费金额
  if (!PermCtrl.isAuthorized(Perm.SO_P_R)) {
    return false;
  }

  if (orderType === "PaymentOrder" && PermCtrl.isAuthorized(Perm.SS_RA)) {
    return true;
  }

  if (orderType === "ShippingOrder" && PermCtrl.isAuthorized(Perm.SPO_RA)) {
    return true;
  }

  const ownPerm = orderType === "PaymentOrder" ? Perm.SS_R : Perm.SPO_R;
  return PermCtrl.isAuthorized(ownPerm) && item.salesRepId === currentUserId;
};

export const PaymentShippingItemGroup: FC<IPaymentShippingItemGroupProps> = (
  props,
) => {
  const [categoryList, setCategoryList] = useState<IIdNameItem[]>();
  const [gradeList, setGradeList] = useState<IIdNameItem[]>();
  useEffect(() => {
    const fetchData = async () => {
      const categoryList = await Consts.StoneCategories.getCategories();
      const gradeList = await Consts.StoneGrades.getGrades();
      setCategoryList(categoryList);
      setGradeList(gradeList);
    };
    fetchData();
  }, []);

  const items = props.items;
  const blockItems = getBlockItems(items);
  const bundleItems = getBundleItems(items);
  const tileItems = getTilePackingCaseItems(items);
  const showBlockItems =
    blockItems && blockItems.length && blockItems.length > 0;
  const showBundleItems =
    bundleItems && bundleItems.length && bundleItems.length > 0;
  const showTPCItems = tileItems && tileItems.length && tileItems.length > 0;

  return (
    <Group>
      {showBlockItems
        ? getPaymentShippingItemJSX(
            "荒料列表",
            blockItems,
            props,
            categoryList,
            gradeList,
          )
        : null}
      {showBundleItems
        ? getPaymentShippingItemJSX(
            "大板列表",
            bundleItems,
            props,
            categoryList,
            gradeList,
          )
        : null}
      {showTPCItems
        ? getPaymentShippingItemJSX(
            "工程板列表",
            tileItems,
            props,
            categoryList,
            gradeList,
          )
        : null}
      {!(showBlockItems || showBundleItems || showTPCItems) ? (
        <Group.List lineIndent={15} itemIndent={[15, 15]}>
          <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">没有数据</div>
        </Group.List>
      ) : null}
    </Group>
  );
};
