import * as React from "react";
import { Boxs } from "saltui";
import {
    BlockDTO,
    BlockStatus,
    IBlockForListDTO,
    IBundleForListDTO,
    IBundleForSODTO,
    SlabBundleStatus
} from "../app/WebAPIClients";
import BlockUtil from "./BlockUtil";
import ReferencePriceInfo from "./ReferencePriceInfo";
import StockingAreaUtil from "./StockingAreaUtil";
import StoneBundleUtil from "./StoneBundleUtil";
import { ReferencePriceSize } from "./type";
const HBox = Boxs.HBox;
const Box = Boxs.Box;

class ReferencePriceUtil {
    public showUnitPriceInfo(
        referencePrice: number | null,
        status: JSX.Element | string | null,
        bundle: IBundleForListDTO = null,
        categoryList: IIdNameItem[] = [],
        gradeList: IIdNameItem[] = [],
        size: ReferencePriceSize = ReferencePriceSize.Normal,
        showIcon: boolean = false,
        salesOrderId: number | null = null,
        customerId: number | null = null
    ): JSX.Element {
        return (
            <div className="referencePriceArea">
                <HBox>
                    <Box className="referencePriceLabel">单价</Box>
                    <Box>
                        <ReferencePriceInfo
                            bundle={bundle}
                            categoryList={categoryList}
                            gradeList={gradeList}
                            showPrice={true}
                            referencePrice={referencePrice}
                            lineThrough={false}
                            showIcon={showIcon}
                            size={size}
                            salesOrderId={salesOrderId}
                            customerId={customerId}
                            noReferencePriceText={"无"}
                        />
                    </Box>
                    <Box className="referencePriceOperatorLabel">状态</Box>
                    <Box>
                        <span className="referenceOperatorStyl">{status}</span>
                    </Box>
                </HBox>
            </div>
        );
    }

    public getDescriptionWithReferencePriceForBundle(
        bundle: IBundleForListDTO | IBundleForSODTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[],
        showPrice: boolean,
        showIcon: boolean = true,
        salesOrderId: number | null = null,
        customerId: number | null = null,
        showGrade: boolean = true
    ): JSX.Element {
        const showStockingArea = [
            SlabBundleStatus.InStock,
            SlabBundleStatus.ReservedBySalesOrder,
            SlabBundleStatus.ReservedBySlabCheckOutRequest
        ].includes(bundle.status);
        return (
            <div>
                <div>
                    {StoneBundleUtil.getBundleSpec(
                        bundle,
                        categoryList,
                        gradeList,
                        showGrade
                    )}
                </div>
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    bundle.stockingAreaId
                )}
                {showPrice
                    ? this.showUnitPriceInfo(
                          bundle.unitPrice,
                          StoneBundleUtil.getStoneBundleStatus(
                              bundle.status,
                              bundle.manufacturingState
                          ),
                          bundle,
                          categoryList,
                          gradeList,
                          ReferencePriceSize.Normal,
                          showIcon,
                          salesOrderId,
                          customerId
                      )
                    : null}
            </div>
        );
    }

    public getDescriptionWithPriceForBlock(
        block: IBlockForListDTO | BlockDTO,
        categoryList: IIdNameItem[],
        gradeList: IIdNameItem[],
        showPrice: boolean
    ): JSX.Element {
        const showStockingArea = [
            BlockStatus.InStock,
            BlockStatus.Reserved,
            BlockStatus.Purchased
        ].includes(block.status);
        return (
            <div>
                <div>
                    {BlockUtil.getBlockSpec(block, categoryList, gradeList)}
                </div>
                {StockingAreaUtil.showStockingArea(
                    showStockingArea,
                    block.stockingAreaId
                )}
                {showPrice
                    ? this.showUnitPriceInfo(
                          block.unitPrice,
                          BlockUtil.getBlockStatus(block.status)
                      )
                    : null}
            </div>
        );
    }

    public setPrice(item: number, canSetPrice: boolean): number {
        let price: number = 0;
        if (!item) {
            return price;
        }

        if (canSetPrice) {
            price = item;
        }

        return price;
    }
}

export default new ReferencePriceUtil();
